import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        width: 343,
    },
    containerInputButton: {
        justifyContent: 'space-between',
        marginTop: 18,
        width: '75%',
    },
    enterBtn: {
        marginTop: 15,
    },
    description: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 17.5,
        marginHorizontal: '12%',
        marginTop: 10,
        textAlign: 'center',
    },
    footerContainer: {
        flexDirection: 'row',
        marginBottom: 12,
        marginTop: 12,
    },
    textButton: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontStyle: 'normal',
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
        textDecorationColor: theme.colors.primary,
        textDecorationLine: 'underline',
    },
    textQuestion: {
        color: colors.darkGray,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginRight: 1,
    },
    title: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 20.29,
        marginTop: 16,
        textAlign: 'center',
    },
});
