import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Text,
    TouchableOpacity,
    View,
    StyleSheet
} from 'react-native';
import AppIntroSlider from 'react-native-app-intro-slider';

import {
    LeftReturnIcon,
    OnBoardingFinancialFundamentals,
    OnboardingPageOne,
    OnboardingPageThree,
    OnboardingRebate,
    RightReturnIcon,
} from '@Assets/index';
import { Toolbar } from '@Components/Toolbar.web';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import Languages from '@i18n/index';
import { OnboardingSlide } from '@OnBoarding/interfaces';
import OnboardingService from '@OnBoarding/service/OnboardingService';
import { styles } from '@OnBoarding/styles/OnboardingScreenStyleWeb';
import { useWindowDimensions } from 'react-native';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface State {
    name: string;
    open: boolean;
    email: string;
};

interface Props {
    name: string;
};

interface SlideItem {
    index: number;
    item: OnboardingSlide;
};

const slides: OnboardingSlide[] = [
    {
        key: 'one',
        title: Languages.TitleCreateProfile,
        text: Languages.DescriptionCreateProfile,
        image: OnboardingPageOne,
        backgroundColor: colors.white,
    },
    {
        key: 'two',
        title: Languages.OnBoardingTitleRebate,
        text: '',
        image: OnboardingRebate,
        backgroundColor: colors.white,
    },
    {
        key: 'three',
        title: Languages.TitleMakingPayment,
        text: Languages.DescriptionMakingPayment,
        image: OnboardingPageThree,
        backgroundColor: colors.white,
    },
    {
        key: 'four',
        title: Languages.FinancialFundamentals,
        text: Languages.FinancialFundamentalsDescription,
        image: OnBoardingFinancialFundamentals,
        backgroundColor: colors.white,
    },
];

export const OnboardingScreenWeb = ({ name }: Props) => {
    const { width: windowWidth } = useWindowDimensions();
    const isMobile: boolean = useCheckMobileScreen();

    const [isVisible, setIsVisible] = useState(false);
    const email = useRef('');
    const slide = useRef<any>();

    useEffect(() => {
        const subscription = OnboardingService.subscribe(async (state: State) => {
            if (state.name !== name) {
                return;
            }
            setIsVisible(state.open);
            email.current = state.email;
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [name]);

    const renderDoneButton = () => (
        <TouchableOpacity
            accessibilityLabel={'onboardingScreenTouchableDone'}
            onPress={done}
            style={styles.viewButton}
            testID={'onboardingScreenTouchableDone'}
        >
            <Text style={styles.textButton} >{Languages.Continue}</Text>
        </TouchableOpacity>
    );

    const handleRenderItem = ({ index, item }: SlideItem) => (
        <View style={[styles.view, isMobile && styles.viewMobile]}>
            <View style={styles.viewImage}>
                <TouchableOpacity
                    accessibilityLabel={'onboardingScreenTouchableLeft'}
                    disabled={index === 0 ? true : false}
                    key={index}
                    onPress={() => slide.current.goToSlide(index - 1, true)}
                    style={{ marginRight: 32 }}
                    testID={'onboardingScreenTouchableLeft'}
                >
                    <LeftReturnIcon
                        fill={index === 0 ? colors.grayLigth : theme.colors.primary}
                    />
                </TouchableOpacity>
                <View style={isMobile && styles.viewImageCenterContainer}>
                    <item.image style={{ height: windowWidth < 600 ? 160 : 320 }} />
                </View>
                <TouchableOpacity
                    accessibilityLabel={'onboardingScreenTouchableRight'}
                    disabled={index === slides.length - 1 ? true : false}
                    onPress={() => slide.current.goToSlide(index + 1, true)}
                    style={{ marginLeft: 32 }}
                    testID={'onboardingScreenTouchableRight'}
                >
                    <RightReturnIcon
                        fill={index === slides.length - 1 ? colors.grayLigth : theme.colors.primary}
                    />
                </TouchableOpacity>
            </View>
            <Text style={styles.introTitleStyle}>
                {item.title}
            </Text>
            <View style={styles.viewText}>
                <Text style={styles.introTextStyle}>
                    {item.text}
                </Text>
            </View>
        </View>
    );

    const done = () => {
        setIsVisible(false);
        OnboardingService.hide(email.current);
    };

    if (isVisible) {
        return (
            <View style={styles.viewP}>
                <View style={{ height: 66 }}>
                    <Toolbar context={'OnBoarding'} />
                </View>
                <View style={styles.viewContainer}>
                    <View style={isMobile ? styles.viewAppIntroMobile : styles.viewAppIntro}>
                        <View style={styles.containerTitle}>
                            <Text style={[
                                styles.title,
                                ...windowWidth < 600 ? [
                                    StyleSheet.create({ titleMobile: {
                                        fontSize: 30
                                    }}).titleMobile
                                ]:[]
                            ]}>Welcome to MyEasyPay!</Text>
                        </View>
                        <AppIntroSlider
                            accessibilityLabel={'onboardingScreenAppIntroSliderSlides'}
                            activeDotStyle={styles.activeDot}
                            bottomButton
                            data={slides}
                            dotStyle={styles.dotStyle}
                            nextLabel={'Continue'}
                            ref={(ref) => (slide.current = ref)}
                            renderDoneButton={renderDoneButton}
                            renderItem={handleRenderItem}
                            showNextButton={false}
                            showSkipButton={false}
                            testID={'onboardingScreenAppIntroSliderSlides'}
                        />
                    </View>
                </View>
            </View>
        );
    }
    return null;
};
