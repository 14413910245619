import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    StyleProp,
    ViewStyle,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { TIMEOUT_REQUEST } from '@Api/index';

import { createShimmerPlaceholder } from './ShimmerPlaceholder';

interface Props {
    children?: JSX.Element | boolean;
    visible: boolean;
    contentStyle?: StyleProp<ViewStyle>;
    shimmerStyle: StyleProp<ViewStyle>;
}

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

export const ShimmerApp = ({
    children,
    visible,
    contentStyle,
    shimmerStyle,
}: Props) => {
    const [isVisible, setIsVisble] = useState(false);
    const isComponentMountedRef = useRef(true);

    useEffect(() => {
        if (!isComponentMountedRef.current) {
            return;
        }
        if (!visible) {
            const timeOut = setTimeout(() => {
                if (isComponentMountedRef.current) {
                    setIsVisble(true);
                }
                clearTimeout(timeOut);
            }, TIMEOUT_REQUEST);
        }
        return () => {
            isComponentMountedRef.current = false;
        };
    }, []);

    return (
        <ShimmerPlaceHolder
            visible={visible || isVisible}
            contentStyle={contentStyle}
            shimmerStyle={[{ borderRadius: 8 }, shimmerStyle]}
            duration={1250}
        >
            {children}
        </ShimmerPlaceHolder>
    );
};
