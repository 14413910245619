import React, {
    useCallback,
    useEffect,
} from 'react';

import {
    Linking,
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { NotificationsData } from '@Notifications/interfaces';
import { clearNotificationBadge } from '@Notifications/state/notificationsActions';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const NotificationScreen = () => {
    const dispatch = useAppDispatch();

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    const notificationData = useAppSelector(
        ({ notificationsReducer }) => notificationsReducer[loanSelected?.applicationId || 0],
    ) as NotificationsData;

    useEffect(() => {
        dispatch(clearNotificationBadge());
    }, []);

    const handlePress = useCallback(async () => {
        const { notifications } = notificationData;
        const { title } = notifications[0];
        if (title) {
            LoggerService.logEvent('rebateProgramNotification', {});
            const isSupported = await Linking.canOpenURL(title);
            if (isSupported) {
                await Linking.openURL(title);
            };
        }
    }, [notificationData]);

    return (
        <View style={styles.container}>
            <Text style={styles.textNotifications}>{Languages.Notifications}</Text>
            <View style={styles.containerPromo}>
                <View style={styles.textContainer}>
                    <Text style={styles.textPromo}>{Languages.EasyRepeatsPromo}</Text>
                </View>
                <AppButton
                    accessibilityLabel={'notificationAppButtonEasyRepeat'}
                    buttonStyle={styles.button}
                    handleOnChange={handlePress}
                    testID={'notificationAppButtonEasyRepeat'}
                    textStyle={styles.buttonText}
                    title={'View'}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    button: {
        borderRadius: 5,
        height: 30,
        width: 86,
        flex: 30,
    },
    buttonText: {
        fontFamily: fonts.SFProText,
        fontSize: 16,
        lineHeight: 22,
        textAlign: 'center',
    },
    container: {
        flex: 1,
        paddingTop: 22,
        paddingBottom: 16,
    },
    containerPromo: {
        flex: 100,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 25,
        paddingHorizontal: 24,
    },
    textContainer: {
        flex: 70,
    },
    textNotifications: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        lineHeight: 29,
        textAlign: 'center',
    },
    textPromo: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        lineHeight: 24,
        textAlign: 'left',
    },
});
