import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { LoadingDialogState } from '@LoadingDialog/interfaces';
import * as loadingDialogActions from '@LoadingDialog/state/loadingDialogActions';

type ActionsType = ActionType<typeof loadingDialogActions>;

export const initialState: LoadingDialogState = {
    isVisible: false,
};

export const loadingDialogReducer = createReducer<LoadingDialogState, ActionsType>(
    initialState,
).handleAction(loadingDialogActions.loadingDialogShow, () => ({
    isVisible: true,
})).handleAction(loadingDialogActions.loadingDialogHide, () => ({
    isVisible: false,
}));
