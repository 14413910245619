import React, {
    FC,
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    Text,
    View,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import SwipeUpDownModal from 'react-native-swipe-modal-up-down';

import { DragAndDropSvg } from '@Assets/index';
import Languages from '@i18n/index';

import PrimaryMethodInfoModalService from '../services/PrimaryMethodInfoModalService';
import { styles } from '../styles/PrimaryMethodsModalStyle';

interface PropsRenderItem {
    item: PrimaryMethodModalDefinition;
};
interface PrimaryMethodModalDefinition {
    id: string;
    title: string;
    subtitle: string;
    icon: FC<SvgProps>;
};

const OPTIONS: PrimaryMethodModalDefinition[] = [
    {
        id: '1',
        title: Languages.AutoPayMethodOfPayment,
        subtitle: `${Languages.WhenAutoPayIsOn}\n\n${Languages.SwipeLeftToManage}`,
        icon: DragAndDropSvg,
    },
];

export const PrimaryMethodInfoModal = () => {
    const [hasShowModal, setHasShowModal] = useState<boolean>(false);

    useEffect(() => {
        const subscription = PrimaryMethodInfoModalService.subscribe((state: any) => {
            setHasShowModal(state.open);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const closeModal = () => {
        PrimaryMethodInfoModalService.hide();
    };

    const handleRenderItem = ({ item }: PropsRenderItem) => (
        <View style={styles.itemContainer}>
            <Text style={styles.title}>{item.title}</Text>
            <View style={styles.viewIcon}>
                {
                    // <item.icon
                    //     height={198}
                    //     preserveAspectRatio={'xMidYMid slice'}
                    //     width={249}
                    // />
                }
            </View>
            <View style={styles.viewText}>
                <Text style={styles.name}>{item.subtitle}</Text>
            </View>
        </View>
    );

    const handleRenderContentModal = () => (
        <FlatList
            data={OPTIONS}
            renderItem={handleRenderItem}
            keyExtractor={(item, _) => item.id.toString()}
            removeClippedSubviews={Platform.OS === 'android'}
            style={styles.flatList}
        />
    );
    const handleRenderHeaderContent = () => (
        <View style={styles.headerContentStyle}>
            <View style={styles.headerBar} />
            <View style={styles.appTextContainer}>
                <Text style={styles.appText}>{Languages.PaymentMethods}</Text>
            </View>
        </View>
    );
    return (
        <SwipeUpDownModal
            PressToanimate={true}
            modalVisible={hasShowModal}
            ContentModal={handleRenderContentModal()}
            HeaderStyle={styles.headerStyle}
            ContentModalStyle={styles.contentModel}
            HeaderContent={handleRenderHeaderContent()}
            onClose={closeModal}
        />
    );
};
