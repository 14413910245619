import { Platform } from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

export const styles = AppStyleSheet({
    boxFooter: {
        backgroundColor: colors.green,
        borderRadius: 4,
        marginTop: 20,
        ...Platform.select({
            web: {
                paddingTop: '1%',
            },
        }),
    },
    footer: {
        ...Platform.select({
            web: {
                alignItems: 'center',
                width: '100%',
                paddingTop: '1%',
                paddingBottom: '5%',
            },
        }),
    },
    icon: {
        paddingRight: 10,
    },
    text: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 24,
        width: 250,
    },
    question: {
        bottom: '65%',
        position: 'absolute',
        right: '5%',
    },
    textBox: {
        color: colors.greenLigth,
        fontFamily: fonts.SFProText,
        fontSize: 10,
        fontWeight: '400',
        paddingLeft: 5,
    },
    textHelp: {
        color: colors.blue,
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 15,
        fontWeight: '600',
    },
    viewHelp: {
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        ...Platform.select({
            web: {
                paddingTop: '1%',
                paddingLeft: 35,
                paddingRight: 35,
            },
        }),
    },
    viewRow: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 10,
    },
});
