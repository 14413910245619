import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    map,
    mergeMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    sendCodeEmailAPI,
    sendCodePhoneNumberAPI,
    updatePasswordEmail,
    updatePasswordPhone,
} from '@Auth/api/forgotPasswordApi';
import {
    SendCodeEmailRequestAction,
    SendCodePhoneNumberRequestAction,
    UpdatePasswordEmailRequestAction,
    UpdatePasswordPhoneRequestAction,
} from '@Auth/interfaces';
import ForgotService from '@Auth/services/ForgotService';
import UpdatePasswordService from '@Auth/services/UpdatePasswordService';
import ForgotPasswordActionsTypes from '@Auth/state/forgotPasswordActionsTypes';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';

export const sentCodePhoneNumberEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ForgotPasswordActionsTypes.SEND_CODE_PHONE_REQUEST),
    delay(250),
    mergeMap(({ payload }: SendCodePhoneNumberRequestAction) => from(sendCodePhoneNumberAPI(payload.phoneNumber))
        .pipe(
            map((response: any) => {
                ForgotService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                ForgotService.onError(error);
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

export const sentCodeEmailEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ForgotPasswordActionsTypes.SEND_CODE_EMAIL_REQUEST),
    delay(250),
    mergeMap(({ payload }: SendCodeEmailRequestAction) => from(sendCodeEmailAPI(payload.email))
        .pipe(
            map((response: any) => {
                ForgotService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                ForgotService.onError(error);
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

export const updatePasswordPhoneEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ForgotPasswordActionsTypes.UPDATE_PASSWORD_PHONE_REQUEST),
    delay(250),
    mergeMap(({ payload }: UpdatePasswordPhoneRequestAction) => from(updatePasswordPhone(payload))
        .pipe(
            map((response: any) => {
                const { storeData } = useStorage();
                storeData({ data: false, key: StorageKeys.BIOMETRICS });
                UpdatePasswordService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

export const updatePasswordEmailEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ForgotPasswordActionsTypes.UPDATE_PASSWORD_EMAIL_REQUEST),
    delay(250),
    mergeMap(({ payload }: UpdatePasswordEmailRequestAction) => from(updatePasswordEmail(payload))
        .pipe(
            map((response: any) => {
                const { storeData } = useStorage();
                storeData({ data: false, key: StorageKeys.BIOMETRICS });
                UpdatePasswordService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);
