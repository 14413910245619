import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 245,
        width: 343,
    },
    loadingText: {
        marginTop: 16,
        marginBottom: 24,
        fontFamily: fonts.SFProText,
        fontWeight: '700',
        color: colors.grayColor,
        textAlign: 'center',
        lineHeight: 20,
    },
    lottie: {
        width: 160,
        height: 160,
    },
    mainContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.blackOpacity,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        elevation: 999,
    },
});
