import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    button: {
        width: 120,
        height: 30,
        borderRadius: 5,
        backgroundColor: colors.white,
    },
    buttonText: {
        fontFamily: fonts.SFProText,
        fontSize: 16,
        lineHeight: 22,
        textAlign: 'center',
        color: colors.grayLigth,
    },
    container: {
        flex: 100,
        flexDirection: 'row',
    },
    cardShimmerPrimaryText: {
        height: 20,
        width: '90%',
    },
    cardShimerAppButton: {
        height: 25,
        width: 25,
        borderRadius: 27.5,
    },
    cardShimerBankIcon: {
        height: 33,
        width: 33,
        borderRadius: 27.5,
    },
    cardShimerNickname: {
        height: 30,
        width: '100%',
    },
    cardShimerLastFourDigit: {
        height: 20,
        width: '100%',
    },
    item: {
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 8,
        flex: 1,
        flexDirection: 'row',
        minHeight: 110,
        minWidth: 225,
        padding: 15,
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,
    },
    containerTooltip: {
        width: 385,
        height: 313,
        alignItems: 'center',
        paddingVertical: 16,
    },
    containerActionsTooltip: {
        width: 120,
        alignItems: 'center',
    },
    containerIconTooltip: {
        marginBottom: 16,
    },
    descriptionDragAndDrop: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        width: 320,
        textAlign: 'left',
    },
    textBoldCustom: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    itemLeft: {
        marginHorizontal: 12,
        borderColor: theme.colors.primary,
        borderWidth: 2,
        marginLeft: 24,
        marginRight: 24,
    },
    itemRight: {
        marginVertical: 12,
    },
    itemRightMargin: {
        marginLeft: 24,
    },
    itemDataContainer: {
        flex: 90,
        flexDirection: 'column',
    },
    menu: {
        ...globalStyle.dropShadow,
        width: 150,
    },
    menuItem: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontStyle: 'normal',
        fontSize: 15,
    },
    menuItemDisabled: {
        fontFamily: fonts.SFProText,
        fontStyle: 'normal',
        fontSize: 15,
    },
    primaryContainer: {
        flex: 10,
        paddingTop: 10,
        marginHorizontal: 14,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 999,
    },
    primaryText: {
        fontSize: 13,
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        lineHeight: 24,
        color: colors.grayDarkColor,
        flex: 80,
    },
    textTitle: {
        fontSize: 15,
        lineHeight: 17.9,
        color: colors.grayLigth,
        fontFamily: fonts.SFProTextMedium,
        fontWeight: '500',
        marginTop: 14,
        marginBottom: 8,
    },
    textSubtitle: {
        fontSize: 15,
        lineHeight: 18,
        color: theme.colors.primary,
        fontFamily: fonts.Roboto,
        fontWeight: '500',
    },
    shimmerView: {
        height: 100,
        width: '80%',
    },
    radioShimmer: {
        height: 20,
        width: 20,
        borderRadius: 27.5,
    },
    secondRadioShimmer: {
        marginStart: 'auto',
        height: 25,
        width: 25,
        borderRadius: 27.5,
    },
    itemDataShimme: {
        width: '100%',
        height: 15,
        marginTop: 10,
    },
    itemLeftShimmer: {
        marginHorizontal: 12,
        borderColor: theme.colors.background,
        borderWidth: 2,
        marginLeft: 24,
        marginRight: 24,
    },
    itemRightShimmer: {
        marginVertical: 4,
        width: '90%',
    },
    itemRightMarginShimmer: {
        marginLeft: 20,
        minWidth: '80%',
    },
    itemShimmer: {
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 8,
        flex: 1,
        flexDirection: 'row',
        minHeight: 110,
        minWidth: 225,
        padding: 15,
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,
    },
});

export const dropZoneStyles = {
    dropZoneLeft: {
        backgroundColor: colors.veryLightGray,
        borderRadius: 10,
        flex: 30,
        minHeight: 220,
        // minWidth: 300,
        zIndex: 9999,
        display: 'flex',
    },
    dropZoneRight: {
        flex: 70,
        marginTop: 35,
    },
};

export const noAutoPayStyle = {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center'
};

export const noAutoPayTextStyle = {
    padding: 15,
    backgroundColor: colors.greenBalance,
    borderRadius: 10,
    maxWidth: '90%'
}
