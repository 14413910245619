import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    link: {
        color: colors.blue,
        fontFamily: fonts.SFProText,
        textDecorationLine: 'underline',
        marginLeft: 2,
        fontSize: 10,
        lineHeight: 15,
    },
    linkSing: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontWeight: 'bold',
        fontSize: 13,
        textDecorationLine: 'underline',
        marginLeft: 2,
        lineHeight: 24,
    },
    signIn: {
        alignItems: 'center',
        color: colors.darkGray,
        fontSize: 13,
        fontWeight: '400',
        textAlign: 'center',
        fontFamily: fonts.SFProText,
        lineHeight: 24,
    },
    text: {
        color: colors.darkGray,
        textAlign: 'center',
        fontSize: 10,
        fontWeight: '400',
        lineHeight: 15,
        fontFamily: fonts.SFProText,
    },
    textAccount: {
        fontFamily: fonts.SFProDisplay,
        fontWeight: '500',
        color: theme.colors.primary,
        width: '80%',
        fontSize: 24,
        lineHeight: 29,
    },
});
