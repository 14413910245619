import React, { useState } from 'react';

import {
    Text,
    TouchableWithoutFeedback,
    View,
} from 'react-native';

import { styles } from '@ApplicationsModal/styles/ApplicationsModalItemStyles';
import { SelectedAppIcon } from '@Assets/index';
import Languages from '@i18n/index';
import { SelectableApplication } from '@Profile/interfaces';
import { formatAmount } from '@Utils/index';

interface Props {
    item: SelectableApplication;
    onPress: (item: SelectableApplication) => void;
};

export const ApplicationsModalItem = ({ item, onPress }: Props) => {
    const [isPressed, setIsPressed] = useState(false);
    const { figures } = item;
    const { currentBalance } = figures;
    const loanAmount: string = currentBalance.units.toFixed(2) ?? '0.00';

    return (
        <TouchableWithoutFeedback
            accessibilityLabel={`applicationsModalItemTouchable${item.applicationId}`}
            onPress={() => onPress(item)}
            onPressIn={() => setIsPressed(true)}
            onPressOut={() => setIsPressed(false)}
            testID={`applicationsModalItemTouchable${item.applicationId}`}
        >
            <View
                style={[
                    styles.viewFlat,
                    isPressed && styles.touchableSPressed,
                ]}
            >
                <View style={styles.viewRow}>
                    <Text style={styles.textNumber}>{Languages.Account}{item.applicationId}</Text>
                    <Text style={styles.textAmount}>{Languages.LoanAmount}: ${formatAmount(loanAmount)}</Text>
                </View>
                {item.isSelected ? (
                    <View style={styles.icon}>
                        <SelectedAppIcon />
                    </View>
                ) : null}
            </View>
        </TouchableWithoutFeedback>
    );
};
