import * as React from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import Languages from '@i18n/index';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

interface Props {
    show: boolean;
    paymentAmount: string;
    paymentDate: string;
    handleAccept: () => void;
    handleCancel: () => void;
};

export const ConfirmSubmitPaymentModal = ({
    show,
    paymentAmount,
    paymentDate,
    handleAccept,
    handleCancel,
}: Props) => (
    <AppModal
        animationType='fade'
        closeModal={handleCancel}
        transparent
        visible={show}
    >
        <View style={styles.container}>
            <Text style={styles.title}>{Languages.SubmitPayment}?</Text>
            <Text style={styles.description}>
                Clicking "Pay Now" will submit your payment of ${paymentAmount} on {paymentDate}.
            </Text>
            <AppButton
                accessibilityLabel={'confirmSubmitPaymentAppButtonAccept'}
                buttonStyle={styles.acceptButton}
                handleOnChange={handleAccept}
                testID={'confirmSubmitPaymentAppButtonAccept'}
                title={Languages.PayNow}
            />
            <TouchableOpacity style={styles.cancelButtonContainer} onPress={handleCancel}>
                <Text style={styles.cancelButton}>
                    {Languages.Cancel}
                </Text>
            </TouchableOpacity>
        </View>
    </AppModal>
);

const styles = AppStyleSheet({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        width: 343,
        paddingBottom: 15
    },
    title: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '700',
        lineHeight: 20,
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    description: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 18,
        textAlign: 'center',
        width: '100%',
        marginBottom: 10,
        paddingLeft: 5,
        paddingRight: 5,
    },
    acceptButton: {
        width: '78%',
        marginBottom: 0,
    },
    cancelButtonContainer: {
        width: '78%',
        height: 47,
        alignItems: 'center',
        borderRadius: 5,
        justifyContent: 'center',
    },
    cancelButton: {
        fontSize: 17,
        fontWeight: '700',
        fontFamily: fonts.SFProText,
        color: theme.colors.primary,
    }
});
