import { authenticateLogoutAction } from "@Auth/state/authenticateActions";
import { useAppDispatch } from "@Hooks/appHooks";
import LoggerService from "@Logger/services/LoggerService";
import { colors } from "@Theme/appTheme";
import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";

export const SignOutComponent = () => {
    const dispatch = useAppDispatch();

    const doLogOut = () => {
        LoggerService.logEvent('logOut', {
            from: 'SignOutComponent',
        });
        dispatch(authenticateLogoutAction());
    };

    return (
        <TouchableOpacity onPress={doLogOut} style={styles.container}>
            <Text style={styles.label}> Sign Out</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        alignContent: "center", alignItems: 'center'
    },
    label: {
        color: colors.white,
        fontSize: 17,
        fontWeight: '600',
        textAlign: 'center',
        marginTop: 2
    }
});