import React, { useState } from 'react';

import {
    Image,
    Platform,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import {
    AccountNumberImg,
    QuestionActiveIcon,
    RoutingNumberImg,
} from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { AppTooltip } from '@Components/AppTooltip';
import { TextInput } from '@Components/TextInput';
import { useFormik } from '@Hooks/useForm';
import Languages from '@i18n/index';
import {
    AHCEditSchema,
    AHCSchema,
} from '@MethodsOfPayment/config/AHCSchema';
import { TypeAHC } from '@MethodsOfPayment/interfaces/index';
import { AddNewPaymentMethodModal } from '@MethodsOfPayment/modals/AddNewPaymentMethodModal';
import RoutingNumberModalService from '@MethodsOfPayment/services/RoutingNumberModalService';
import { styles } from '@MethodsOfPayment/styles/AddCheckingFormStyle';
import {
    colors,
    theme,
} from '@Theme/appTheme';
import { CheckBox } from '@Components/CheckBox';

interface Props {
    handleSubmit: (values: TypeAHC) => void;
    initialData?: TypeAHC | undefined;
    isAutoPayEnabled?: boolean | undefined;
    handleCancel: () => void;
};

export const AddCheckingForm = ({
    handleSubmit,
    initialData,
    isAutoPayEnabled,
    handleCancel
}: Props) => {

    const initialFormAHC: TypeAHC = {
        routingNumber: '',
        accountNumber: '',
        bankName: '',
        enableAutoPay: isAutoPayEnabled ?? false
    };

    const form = useFormik(
        handleSubmit,
        initialData ?? initialFormAHC,
        initialData ? AHCEditSchema : AHCSchema,
    );

    const [isChecked, setIsChecked] = useState(isAutoPayEnabled ?? false);

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        submitForm,
        setFieldValue,
        touched,
    } = form;

    const handleOnTapInfoPaymentOption = (type: any) => {
        requestAnimationFrame(() => {
            RoutingNumberModalService.show(type);
        });
    };

    const fillEyeColor = (error: any, touched: any) => {
        if (Boolean(error) && touched) {
            return colors.red;
        }
        return theme.colors.primary;
    };

    const handleCheckboxChange = (newState: boolean) => {
        setIsChecked(newState);
        setFieldValue('enableAutoPay', newState);
    };

    const renderRightIconRouting = () => Platform.OS === 'web' ? (
        <AppTooltip
            Icon={QuestionActiveIcon}
            iconColor={fillEyeColor(errors.routingNumber, touched.routingNumber)}
            place='left'
        >
            <View style={styles.containerTooltip}>
                <View style={styles.containerImgtooltip}>
                    <Image source={RoutingNumberImg} style={styles.tooltimg} resizeMode='contain' />
                </View>
                <View style={styles.containerTextTooltip}>
                    <Text style={styles.textTooltip}>{Languages.RoutingNumber}</Text>
                </View>
            </View>
        </AppTooltip>
    ) : (
        <TouchableOpacity
            accessibilityLabel={'addCheckingFormTouchableRoutingModalQuestion'}
            onPress={() => handleOnTapInfoPaymentOption('routingModal')}
            testID={'addCheckingFormTouchableRoutingModalQuestion'}
        >
            <QuestionActiveIcon fill={fillEyeColor(errors.routingNumber, touched.routingNumber)} />
        </TouchableOpacity>
    );

    const renderRightIconAccount = () => Platform.OS === 'web' ? (
        <AppTooltip
            Icon={QuestionActiveIcon}
            iconColor={fillEyeColor(errors.accountNumber, touched.accountNumber)}
            place='left'
        >
            <View style={styles.containerTooltip}>
                <View style={styles.containerImgtooltip}>
                    <Image source={AccountNumberImg} style={styles.tooltimg} resizeMode='contain' />
                </View>
                <View style={styles.containerTextTooltip}>
                    <Text style={styles.textTooltip}>{Languages.Account}</Text>
                </View>
            </View>
        </AppTooltip>
    ) : (
        <TouchableOpacity
            accessibilityLabel={'addCheckingFormTouchableAccountModalQuestion'}
            onPress={() => handleOnTapInfoPaymentOption('accountModal')}
            testID={'addCheckingFormTouchableAccountModalQuestion'}
        >
            <QuestionActiveIcon fill={fillEyeColor(errors.accountNumber, touched.accountNumber)} />
        </TouchableOpacity>
    );

    const shouldBeDisableButtonSave = (): boolean => {
        if (initialData) {
            // return Boolean(errors.bankName);
        }

        return Boolean(errors.routingNumber
            || errors.accountNumber
            // || errors.bankName
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.contentForm}>
                <TextInput
                    accessibilityLabel={'addCheckingFormTextInputRoutingNumber'}
                    containerStyle={styles.inputLarge}
                    editable={!initialData}
                    error={touched.routingNumber && Boolean(errors.routingNumber)}
                    helperText={touched.routingNumber ? errors.routingNumber : ''}
                    keyboardType='numeric'
                    maxCharacters={9}
                    onBlur={handleBlur('routingNumber')}
                    onChangeText={handleChange('routingNumber')}
                    placeholder={Languages.RoutingNumber}
                    returnKeyType='done'
                    RightIcon={renderRightIconRouting}
                    testID={'addCheckingFormTextInputRoutingNumber'}
                    value={values.routingNumber}
                />
                {!initialData &&
                    <View style={styles.containerInitialData}>
                        <View style={styles.containerInputRow}>
                            <TextInput
                                accessibilityLabel={'addCheckingFormTextInputAccountNumber'}
                                containerStyle={styles.inputMedium}
                                editable={!initialData}
                                error={touched.accountNumber && Boolean(errors.accountNumber)}
                                helperText={touched.accountNumber ? errors.accountNumber : ''}
                                keyboardType='numeric'
                                maxCharacters={12}
                                onBlur={handleBlur('accountNumber')}
                                onChangeText={handleChange('accountNumber')}
                                placeholder={Languages.Account}
                                returnKeyType='done'
                                RightIcon={renderRightIconAccount}
                                testID={'addCheckingFormTextInputAccountNumber'}
                                value={values.accountNumber}
                            />
                        </View>
                        <View style={styles.containerCheckbox}>
                            <CheckBox
                                title={Languages.EnableAutoPayCheckbox}
                                selected={isChecked}
                                onChange={handleCheckboxChange}
                            />
                        </View>
                    </View>
                }
                {initialData &&
                    <TextInput
                        accessibilityLabel={'addCheckingFormTextInputAccountNumber'}
                        containerStyle={styles.inputLarge}
                        editable={!initialData}
                        error={touched.accountNumber && Boolean(errors.accountNumber)}
                        helperText={touched.accountNumber ? errors.accountNumber : ''}
                        keyboardType='numeric'
                        maxCharacters={12}
                        onBlur={handleBlur('accountNumber')}
                        onChangeText={handleChange('accountNumber')}
                        placeholder={Languages.Account}
                        returnKeyType='done'
                        RightIcon={renderRightIconAccount}
                        testID={'addCheckingFormTextInputAccountNumber'}
                        value={values.accountNumber}
                    />

                }
                <AppButton
                    accessibilityLabel={'addCheckingFormAppButtonSave'}
                    buttonStyle={[styles.buttonSave, { backgroundColor: shouldBeDisableButtonSave() ? colors.darkGray : theme.colors.primary }]}
                    disabled={shouldBeDisableButtonSave()}
                    handleOnChange={submitForm}
                    testID={'addCheckingFormAppButtonSave'}
                    title={Languages.Save}
                />
                <AppButton
                    accessibilityLabel={'addCheckingFromButtonCancel'}
                    buttonStyle={styles.buttonCancel}
                    handleOnChange={handleCancel}
                    testID={'addCheckingFromButtonCancel'}
                    textStyle={styles.textCancel}
                    title={Languages.Cancel}
                />
            </View>
            <AddNewPaymentMethodModal />
        </View >
    );
};
