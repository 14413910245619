import React, { useCallback } from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    EmailIconSvg,
    PhoneIconSvg,
    QuestionIconSvg,
} from '@Assets/index';
import { styles } from '@Auth/styles/ForgotPasswordFooterStyles';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { clearPhoneNumberFormat } from '@Utils/FormatUtils';
import {
    makeACall,
    sendAnEmail,
} from '@Utils/index';

export const ForgotPasswordFooterComponent = () => {
    const handleOnPhoneTap = useCallback(async () => {
        try {
            LoggerService.logEvent('contactHelp', {
                by: 'phone',
            });
            const phoneNumber = clearPhoneNumberFormat(Languages.ContactPhoneNumber);
            const result = await makeACall(phoneNumber);
            if (!result) {
                AppAlertService.showError({
                    message: `${Languages.DontNowHowToOpenThisURL}: ${phoneNumber}`,
                });
            }
        } catch (error) {
            LoggerService.logError(error);
            AppAlertService.showError({
                message: Languages.DontNowHowToOpenThisURL,
            });
        }
    }, []);

    const handleOnEmailTap = useCallback(async () => {
        try {
            LoggerService.logEvent('contactHelp', {
                by: 'email',
            });
            const email = Languages.ContactEmail;
            const result = await sendAnEmail(email, Languages.INeedHelp);
            if (!result) {
                AppAlertService.showError({
                    message: `${Languages.DontNowHowToOpenThisURL}: ${email}`,
                });
            }
        } catch (error) {
            LoggerService.logError(error);
            AppAlertService.showError({
                message: Languages.DontNowHowToOpenThisURL,
            });
        }
    }, []);

    return (
        <View style={styles.footer}>
            <Text style={styles.text}>{Languages.WantToUpdateYouEmail}</Text>

            <View style={styles.boxFooter} >
                <View style={styles.question}>
                    <QuestionIconSvg
                        width={40}
                        height={59}
                    />
                </View>
                <View style={styles.viewHelp}>
                    <Text style={styles.textHelp}>{Languages.NeedHelContactUs}</Text>
                    <TouchableOpacity
                        style={styles.viewRow}
                        onPress={handleOnPhoneTap}
                    >
                        <View style={styles.icon}>
                            <PhoneIconSvg
                                width={12}
                                height={12}
                            />
                        </View>
                        <Text style={styles.textBox}>{Languages.ContactPhoneNumber}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.viewRow}
                        onPress={handleOnEmailTap}
                    >
                        <View style={styles.icon}>
                            <EmailIconSvg
                                width={15}
                                height={12}
                            />
                        </View>
                        <Text style={styles.textBox}>{Languages.ContactEmail}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};
