import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import { isExpired } from 'react-jwt';
import {
    Platform,
    StatusBar,
} from 'react-native';
import SplashScreen from 'react-native-splash-screen';

import { AppAlert } from '@Alert/screens/AppAlert';
import { AuthenticateResponse } from '@Auth/interfaces';
import AuthenticateService, { AuthService } from '@Auth/services/AuthenticateService';
import { authenticateLogoutAction } from '@Auth/state/authenticateActions';
import { TermsOfUseDialog } from '@Dialogs/screens/TermsOfUseDialog';
import { useAppDispatch } from '@Hooks/appHooks';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import { LoadingDialog } from '@LoadingDialog/screens/LoadingScreen';
import LoggerService from '@Logger/services/LoggerService';
import { BottomTabNavigator } from '@Navigation/BottomTabNavigator';
import { NoAuthorizeNavigationStack } from '@Navigation/NoAuthorizeNavigation';
import { WebNavigator } from '@Navigation/WebNavigator';
import { AppNavigationOnboarding } from '@OnBoarding/navigation';
import OnboardingService from '@OnBoarding/service/OnboardingService';
import {
    getStateFromPath,
    LinkingOptions,
    NavigationContainer,
} from '@react-navigation/native';
import { store } from '@Store/index';
import { linkingPrefixes } from '@Utils/index';
import {
    VerificationCodeDialogScreen,
} from '@VerificationCodeDialog/screens/VerificationCodeDialogScreen';

import { NotFoundedNavigationStack } from './NotFoundedNavigationStack';
import { RefreshingWebStack } from './RefreshingWebStack';
import {
    isReadyRef,
    navigationRef,
    routeNameRef,
} from './RootNavigation';

const isWeb = Platform.OS === 'web';

export const AppNavigation = () => {
    const dispatch = useAppDispatch();

    const isFoundedRef = useRef<boolean>(true);
    const [isLogged, setIsLogged] = useState<boolean | undefined>(
        isWeb ? undefined : false,
    );

    /**
     * Linking configuration
     * 
     * Example url params
     * - ForgotPassword: '/account-confirmation/:email?/:code?',
     */
    const config = {
        screens: {
            AccountConfirmationScreen: 'account-confirmation',
            Activity: 'activity',
            ActivityAll: 'activityAll',
            BusinessCenterValidationScreen: 'register-business-center',
            CustomerService: 'customerservice',
            FaqScreen: 'faq',
            FinancialFundamentalsScreen: 'financialfundamentals',
            ForgotPassword: 'forgotpassword',
            Home: 'home',
            InternalLogin: 'internal-login',
            LinkExpireScreen: 'linkexpire',
            LoginScreen: 'signin',
            NewPasswordScreen: 'newpassword',
            NotificationScreen: 'notifications',
            PasswordResetConfirmationScreen: 'password-reset-confirmation',
            PaymentsScreen: 'payments',
            ProfileScreen: {
                screens: {
                    ProfileInfoScreen: 'profileinfo',
                    ApplicationsScreen: 'accounts',
                    ProfileFaqScreen: 'profilefaqscreen',
                },
            },
            ProgramRebateScreen: 'programrebate',
            RefreshingScreen: 'refreshing',
            RegisterScreen: 'register',
            StatementsScreen: 'statements',
        },
    };

    const linking: LinkingOptions = {
        prefixes: linkingPrefixes,
        config,
        getStateFromPath: (path, options) => {
            if (path.includes('?')) {
                const splits = path.split('?');
                const leftPart = splits.shift();
                return getStateFromPath(
                    `${leftPart?.toLocaleLowerCase()}?${splits.join('')}`,
                    options,
                );
            }
            return getStateFromPath(path.toLocaleLowerCase(), options);
        },
    };

    useEffect(() => {
        LoggerService.initialize();
        return () => {
            // @ts-ignore
            isReadyRef.current = false;
        };
    }, []);

    useEffect(() => {
        const validateSession = async () => {
            const { getData } = useStorage();
            const result = await getData({
                key: StorageKeys.LOGIN_METADATA,
            });
            const { userInfo } = store.getState().profileReducer;
            const isFounded = userInfo && userInfo.dob !== '' && userInfo.last4ssn !== '';

            const authResponse: AuthenticateResponse = result.value;
            if (result.success && authResponse.refreshToken !== '' && isFounded) {
                const isJWTExpired = isExpired(authResponse.accessToken);
                setTimeout(() => {
                    OnboardingService.show(userInfo.email);
                }, 100);
                setIsLogged(!isJWTExpired);
            } else {
                setIsLogged(false);
            }
        };
        if (isWeb) {
            validateSession();
        }
    }, []);

    useEffect(() => {
        const authenticateSubscribe = AuthenticateService.subscribe(async (state: AuthService) => {
            const { isSessionExpired, isFounded, success: isSuccess } = state;
            isFoundedRef.current = isFounded ?? false;
            if (!isSuccess && isSessionExpired) {
                LoggerService.logEvent('logOut', {
                    from: 'sessionExpired',
                });
                dispatch(authenticateLogoutAction());
            }
            setIsLogged(isSuccess);
        });
        return () => {
            authenticateSubscribe.unsubscribe();
        };
    }, []);

    /**
     * Method to hide the splash screen 
     * 
     * @returns true
     */
    const handleOnReady = () => {
        // @ts-ignore
        isReadyRef.current = true;
        // @ts-ignore
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        if (Platform.OS !== 'web') {
            SplashScreen.hide();
        };
    };

    const renderAppNavigator = () => {
        if (isLogged === true && isWeb) {
            if (!isFoundedRef.current) {
                return (
                    <>
                        <NotFoundedNavigationStack />
                        <AppNavigationOnboarding />
                    </>
                );
            }
            return (
                <WebNavigator />
            );
        } else if (isLogged === true) {
            if (!isFoundedRef.current) {
                return (
                    <>
                        <NotFoundedNavigationStack />
                        <AppNavigationOnboarding />
                    </>
                );
            }
            return (
                <BottomTabNavigator />
            );
        }
    };

    return (
        <NavigationContainer
            onReady={handleOnReady}
            linking={linking}
            ref={navigationRef as any}
            onStateChange={() => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;
                if (previousRouteName !== currentRouteName) {
                    LoggerService.logScreenView(currentRouteName, currentRouteName);
                }
                // @ts-ignore
                routeNameRef.current = currentRouteName;
            }}
        >
            <StatusBar
                backgroundColor={'transparent'}
                translucent
                barStyle='dark-content'
            />
            <LoadingDialog name='root' />
            <VerificationCodeDialogScreen name='verificationCodeDialog' />
            <AppAlert />
            <TermsOfUseDialog />
            {!isWeb && <AppNavigationOnboarding />}
            {(isLogged === false) && <NoAuthorizeNavigationStack />}
            {isLogged === undefined && <RefreshingWebStack />}
            {renderAppNavigator()}
        </NavigationContainer >
    );
};
