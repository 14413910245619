import AppApi from '@Api/appApi';
import { Commands } from '@Api/config';
import LoggerService from '@Logger/services/LoggerService';
import { Loan } from '@Profile/interfaces';

export const getApplicationsAPI = () => new Promise<Loan[]>(async (resolve, reject) => {
    try {
        const response = await AppApi.get<Loan[]>(Commands.GET_APPLICATIONS);
        resolve(response.data);
    } catch (error) {
        LoggerService.logError(error);
        reject(error);
    }
});

export const getDocumentAPI = ({ applicationId, responseType }: any): Promise<any> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_DOCUMENT.replace('{applicationId}', `${applicationId}`);
        const response = await AppApi.get<any>(command, responseType && { responseType });
        resolve(response?.data);
    } catch (error) {
        LoggerService.logError(error);
        reject(error);
    }
});
