import {
    Error,
    Response,
} from '@Interfaces/BaseResponse';

export enum STATUS_LOAN {
    VIEW_DOCUMENTS = 'VIEW_DOCUMENTS',
    FUNDED = 'FUNDED',
    PAID_IN_FULL = 'PAID_IN_FULL',
    UNDETERMINED = 'UNDETERMINED',
};

export interface UserInfo {
    address: string;
    aptNumber: string;
    city: string;
    dob: string;
    email: string;
    firstName: string;
    last4ssn: string;
    lastName: string;
    phoneNumber: string;
    secondaryPhoneNumber?: string;
    state: string;
    zipCode: string;
    phoneNumberVerificationCode?: string;
};

export interface GetProfileResponse {
    address: ContactAddress;
    contactInformation: ContactInformation;
    dob: string;
    last4Ssn: string;
    lastName: string;
    name: string;
};

export interface ContactAddress {
    address1: string;
    apartmentNumber: string;
    city: string;
    state: string;
    zipCode: string;
};

export interface ContactInformation {
    email: string;
    mainPhone: string;
    mainPhoneVerificationCode?: string;
    secondaryPhone: string;
};

export interface PutProfileRequest extends GetProfileResponse { }

export interface PutProfileActionRequest {
    payload: UserInfo;
};

export interface MenuOptions {
    menuOptions: any[];
};

export interface ProfileState {
    userInfo: UserInfo;
    menuOptions: any[];
    isLoading: boolean;
};

export interface MeunOptionsSuccessAction {
    payload: MenuOptions;
};

export interface MeunOptionsErrorAction {
    payload: Error;
};

export interface UserInfoSuccessAction {
    payload: UserInfo;
};

export interface UserInfoErrorAction {
    payload: Error;
};

export interface Amount {
    units: number;
    cents: number;
};

export interface FiguresLoans {
    currentBalance: Amount;
    pastDueAmount: Amount;
    amountFinanced: Amount;
    paymentAmount: Amount;
};

export interface StatusLoan {
    name: string;
    description: string;
};

export interface Permission {
    canAddMethodOfPayment: boolean;
    canDeleteMethodOfPayment: boolean;
    canEditMethodOfPayment: boolean;
    canMakeAPayment: boolean;
    canSetAutoPay: boolean;
    canSetPrimaryMethod: boolean;
};
export interface Loan {
    applicationId: number;
    paymentFrequency: string;
    contractTerms: number;
    paymentsRemaining: number;
    interestRate: number;
    loanId: number;
    merchantName: string;
    figures: FiguresLoans;
    status: StatusLoan;
    contractDate: string;
    fccpExpirationDate: string;
    permissions: Permission;
};

export interface LoanModal {
    applicationId: number;
    loanId: number;
    merchantName: string;
    figures: FiguresLoans;
    status: StatusLoan;
    selected: boolean;
};

export interface GetApplicationsSuccess {
    payload: Loan[];
};

export interface GetApplicationsError {
    payload: Error;
};
export interface ApplicationsState {
    loans: Loan[];
    loanSelected?: Loan | undefined;
    selectableApplications: SelectableApplication[];
};

export interface ApplicationResponse extends Response {
    data: Loan[];
};

export interface SaveApplications {
    loans: Loan[];
    loanSelected?: Loan | undefined;
    selectableApplications?: SelectableApplication[];
};

export interface SelectableApplication extends Loan {
    isSelected: boolean;
};

export interface FAQCategoryRenderItem {
    item: FAQCategory;
    index: number;
};
export interface ActionFilterItem {
    id: number;
    title: string;
};
export interface QuestionRenderItem {
    item: FAQ;
    index: number;
};

export interface FaqInterface { };

export interface FaqServiceResponse {
    faqs: FAQ[];
    categories: FAQCategory[];
};

export interface FAQCategory extends Selectable {
    name: string;
};

export interface FAQ extends Selectable {
    question: string;
    answer: string;
    category: string;
};

export interface Selectable {
    selected: boolean;
};

export interface FAQRequest {
    category: string;
    filter: string;
};

export interface FAQRequestAction {
    payload: FAQRequest;
};

export interface ProfileUpdatedState {
    success: boolean;
    wihtMobileNumber: boolean;
};

export interface PostPhoneUpdateRequest {
    phone: string;
};

export interface PostPhoneUpdateActionRequest {
    payload: PostPhoneUpdateRequest;
};

export interface GetZipCodeRequest {
    zipCode: string;
};

export interface GetZipCodeActionRequest {
    payload: GetZipCodeRequest;
};

export interface GetZipCodeResponse extends Response {
    zipCode: string;
    city: string;
    state: string;
    utcOffset: number;
};

export interface ZipCodeInfo {
    zipCode: string;
    city: string;
    state: string;
    utcOffset: number;
};

export interface PostClaimProfileRequest {
    dob: string;
    ssn: string;
};

export interface PostClaimProfileActionRequest {
    payload: PostClaimProfileRequest;
};
