import { createAction } from 'typesafe-actions';

export enum DelightedSurveyActionTypes {
    GET_SURVEY_ANSWERED_ACTION = '@@home/GET_SURVEY_ANSWERED_ACTION',
    GET_SURVEY_ANSWERED_ACTION_SUCCESS = '@@home/GET_SURVEY_ANSWERED_ACTION_SUCCESS',
    SET_SURVEY_ANSWERED_ACTION = '@@home/MARK_SURVEY_ANSWERED_ACTION',
};

export const getSurveyAnsweredRequestAction = createAction(
    DelightedSurveyActionTypes.GET_SURVEY_ANSWERED_ACTION,
    (request: { applicationId: number }) => (request)
)();

export const getSurveyAnsweredRequestActionSuccess = createAction(
    DelightedSurveyActionTypes.GET_SURVEY_ANSWERED_ACTION_SUCCESS,
    (response: { success: boolean }) => (response),
)();

export const setSurveyAnsweredRequestAction = createAction(
    DelightedSurveyActionTypes.SET_SURVEY_ANSWERED_ACTION,
    (request: { applicationId: number }) => (request),
)();
