import { GetStatementRequest, PaymentStatementResponse } from '@Activity/interfaces';
import AppApi from '@Api/appApi';
import { Commands } from '@Api/config';


export const getNewStatementsAPI = ({ applicationId }: GetStatementRequest): Promise<PaymentStatementResponse[]> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_DOCUMENTS.replace('{applicationId}', `${applicationId}`);
        const response = await AppApi.get<PaymentStatementResponse[]>(command);
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const getDocumentNewStatementsAPI = ({ id }: any): Promise<any> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_DOCUMENT_STATEMENT.replace('{id}', `${id}`);
        const response = await AppApi.get<any>(command, { responseType: 'blob' });
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});