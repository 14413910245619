import { Observable, from, of } from "rxjs";

import { DelightedSurveyActionTypes } from '@Home/state/delightedSurveyActions';
import { getSurveyAnsweredAPI, setSurveyAnsweredAPI } from '@Home/api/delightedSurveyApi'
import { ofType } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import { loadingDialogHide } from "@LoadingDialog/state/loadingDialogActions";
import AppAlertService from "@Alert/services/AppAlertService";
import { Error } from '@Interfaces/BaseResponse';
import SurveyService from "@Home/services/SurveyService";

export const getSurveyAnsweredEpic = ($action: Observable<any>, $state: any) => $action.pipe(
    ofType(DelightedSurveyActionTypes.GET_SURVEY_ANSWERED_ACTION),
    mergeMap(({ payload: { applicationId } }: { payload: { applicationId: number } }) => {
        return from(getSurveyAnsweredAPI({
            applicationId,
        })).pipe(
            map((response: any) => {
                SurveyService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            })
        )
    })
);

export const setSurveyAnsweredEpic = ($action: Observable<any>) => $action.pipe(
    ofType(DelightedSurveyActionTypes.SET_SURVEY_ANSWERED_ACTION),
    mergeMap(({ payload: { applicationId } }: { payload: { applicationId: number } }) => {
        return from(setSurveyAnsweredAPI({
            applicationId,
        })).pipe(
            map(() => loadingDialogHide()),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            })
        )
    })
);
