import AuthApi from '@Api/authApi';
import { Commands } from '@Api/config';
import {
    PostConfirmAccountRequest,
    PostRegisterEmailBusinessCenterRequest,
    PostRegisterEmailBusinessCenterValidateRequest,
    PostRegisterEmailBusinessCenterValidateResponse,
    PostRegisterVerificationCodeResendRequest,
    RegisterByEmailRequest,
    RegisterRequest,
    ValidateVerificationCodeRequest,
    VerificationCodeRequest,
} from '@Auth/interfaces';
import { Response } from '@Interfaces/BaseResponse';

export const register = ({ phone, password, verificationCode }: RegisterRequest) => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<Response>(Commands.REGISTER, {
            phone,
            password,
            verificationCode,
        });
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const registerByEmail = ({ email, password }: RegisterByEmailRequest) => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<Response>(Commands.REGISTER_EMAIL, {
            email,
            password,
        });
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const registerVerificationCode = ({ phone }: VerificationCodeRequest) => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<Response>(Commands.REGISTER_VERIFICATION_CODE, {
            phone,
        });
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const registerValidateVerificationCode = ({ context, owner, value }: ValidateVerificationCodeRequest) => new Promise(async (resolve, reject) => {
    try {
        await AuthApi.post<Response>(Commands.VALIDATE_VERIFICATION_CODE, {
            context,
            owner,
            value,
        });
        resolve(true);
    } catch (error) {
        reject(error);
    }
});

export const confirmAccountAPI = (request: PostConfirmAccountRequest) => new Promise(async (resolve, reject) => {
    try {
        await AuthApi.post<Response>(Commands.POST_CONFIRM_ACCOUNT, request);
        resolve(true);
    } catch (error) {
        reject(error);
    }
});

export const registerByEmailBusinessCenterAPI = (request: PostRegisterEmailBusinessCenterRequest) => new Promise(
    async (resolve, reject) => {
        try {
            await AuthApi.post<Response>(Commands.POST_REGISTER_EMAIL_BUSINESS_CENTER, request);
            resolve(true);
        } catch (error) {
            reject(error);
        }
    },
);

export const registerByEmailBusinessCenterValidateAPI = (
    request: PostRegisterEmailBusinessCenterValidateRequest,
) => new Promise<PostRegisterEmailBusinessCenterValidateResponse>(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<PostRegisterEmailBusinessCenterValidateResponse>(
            Commands.POST_REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE,
            request,
        );
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const registerVerificationCodeResendAPI = (
    request: PostRegisterVerificationCodeResendRequest,
) => new Promise<Response>(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<Response>(
            Commands.POST_REGISTER_EMAIL_VERIFICATION_CODE_RESEND,
            request,
        );
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});
