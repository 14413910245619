import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    ScrollView,
    Text,
    TextInput as Input,
    TouchableOpacity,
    View,
} from 'react-native';
import { formatPhoneNumber } from 'react-phone-number-input';

import AppAlertService from '@Alert/services/AppAlertService';
import { AuthenticateResponse } from '@Auth/interfaces';
import AuthenticateService from '@Auth/services/AuthenticateService';
import {
    authenticateLogoutAction,
    authenticateRefreshTokenAction,
} from '@Auth/state/authenticateActions';
import { AppButton } from '@Components/AppButton';
import { AppModalAlert } from '@Components/AppModalAlert';
import { ShimmerApp } from '@Components/ShimerApp';
import { TextInput } from '@Components/TextInput';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import { useDebouncedValue } from '@Hooks/useDebouncedValue';
import { useFormik } from '@Hooks/useForm';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import {
    LocateAccountSchema,
    ProfileInfoSchema,
} from '@Profile/config/ProfileInfoSchema';
import {
    UserInfo,
    ZipCodeInfo,
} from '@Profile/interfaces';
import ClaimProfileService, { ClaimProfile } from '@Profile/services/ClaimProfileService';
import UpdateProfileService from '@Profile/services/UpdateProfileService';
import ZipCodeService from '@Profile/services/ZipCodeService';
import { downloadApplications } from '@Profile/state/applicationsAction';
import {
    getUserInfoAction,
    getZipCodeInformationAction,
    postClaimProfileAction,
    postPhoneUpdateVerificationAction,
    putUserInfoAction,
} from '@Profile/state/profileActions';
import { styles } from '@Profile/styles/ProfileOptionsScreenWebStyles';
import {
    clearformatSSN,
    clearPhoneNumberFormat,
    formatDOB,
    formatSSN,
} from '@Utils/FormatUtils';
import { linksApp } from '@Utils/index';
import VerificationCodeDialogService
    from '@VerificationCodeDialog/service/VerificationCodeDialogService';
import VerificationCodeService from '@VerificationCodeDialog/service/VerificationCodeService';

interface Props {
    isClaimProcess?: boolean;
};

export const ProfileInfoScreen = ({ isClaimProcess }: Props) => {
    const dispatch = useAppDispatch();

    const titleAlertRef = useRef<string>('');
    const subtitleAlertRef = useRef<string>('');
    const titleBottomSectionAlertRef = useRef<string>('');
    const subTitleBottomSectionAlertRef = useRef<string>('');
    const hasCompletedRegistrationRef = useRef(false);
    const isComponentMountedRef = useRef(true);

    const [isEditable, setIsEditable] = useState<boolean>(true);
    const [isValidAccount, setIsValidAccount] = useState(false);
    const [isAppAlertShown, setIsAppAlertShown] = useState(false);
    const [hasExceededAttempts, setHasExceededAttempts] = useState(false);

    const { userInfo } = useAppSelector(
        ({ profileReducer }) => profileReducer,
    );

    const TextBoldTouchable = ({ children, onPress, textStyle }: any) => (
        <View style={{ marginTop: -2, alignItems: 'center', justifyContent: 'center' }}>
            <TouchableOpacity onPress={onPress} >
                <Text style={textStyle}>{children}</Text>
            </TouchableOpacity>
        </View>
    );

    const firstNameInputRef = useRef<Input>(null);
    const lastNameInputRef = useRef<Input>(null);
    const addressInputRef = useRef<Input>(null);
    const aptNumberInputRef = useRef<Input>(null);
    const zipCodeInputRef = useRef<Input>(null);
    const dobInputRef = useRef<any>(null);
    const last4SSN = useRef<Input>(null);
    const emailInputRef = useRef<Input>(null);
    const phoneRef = useRef<Input>(null);
    const secondaryPhoneRef = useRef<Input>(null);
    const [isLoading, setisLoading] = useState(true);

    const greet = () => isComponentMountedRef.current && setisLoading(false);

    useEffect(() => {
        setTimeout(greet, 1000);
        const shouldEnableFields = () => {
            if (userInfo && userInfo.dob !== '' && userInfo.last4ssn !== '') {
                setIsValidAccount(true);
                setIsEditable(false);
            } else {
                setIsEditable(true);
            }
        };
        shouldEnableFields();
    }, [userInfo]);

    const onSubmitForm = (userInfo: UserInfo) => dispatch(putUserInfoAction(userInfo));

    const form = useFormik(
        onSubmitForm,
        userInfo,
        isValidAccount ? ProfileInfoSchema : LocateAccountSchema,
    );

    const {
        errors,
        values,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
        setFieldError,
        validateField,
        setFieldTouched,
    } = form;

    const { debouncedValue } = useDebouncedValue(values.last4ssn);
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (!isComponentMountedRef.current) {
            return;
        }
        if (!isValidAccount) {
            const formatedValue = formatSSN(debouncedValue);
            setFieldValue('last4ssn', formatedValue);
        } else {
            setFieldValue('last4ssn', values.last4ssn.replace('-', ''));
        }
    }, [debouncedValue]);

    useEffect(() => {
        const claimProfileSubscribe = ClaimProfileService.subscribe((state: ClaimProfile) => {
            const { errorCode, title, subtitle, titleBottomSection, subTitleBottomSection, success: isSuccess } = state;
            if (!isComponentMountedRef.current) {
                return;
            }
            
            if (isSuccess) {
                AppAlertService.showSuccess({
                    message: Languages.PleaseReviewYourProfileInformation,
                });
                hasCompletedRegistrationRef.current = true;
                dispatch(getUserInfoAction());
            } else {
                LoggerService.logEvent('claimProfile', {
                    success: false,
                    errorCode,
                });
                setHasExceededAttempts(errorCode === 407);
                titleAlertRef.current = title ?? '';
                subtitleAlertRef.current = subtitle ?? '';
                titleBottomSectionAlertRef.current = titleBottomSection ?? '';
                subTitleBottomSectionAlertRef.current = subTitleBottomSection ?? '';
                setIsAppAlertShown(true);
            }
        });
        const subscription = ZipCodeService.subscribe((state: ZipCodeInfo) => {
            setFieldValue('city', state.city);
            setFieldValue('state', state.state);
        });
        const updateProfileServiceSubscribe = UpdateProfileService.subscribe(async () => {
            if (hasCompletedRegistrationRef.current) {
                hasCompletedRegistrationRef.current = false;
                dispatch(downloadApplications());
                LoggerService.logEvent('claimProfile', {
                    success: true,
                });
                AuthenticateService.onSuccess({
                    success: false,
                });
                const { getData } = useStorage();
                const result = await getData({
                    key: StorageKeys.LOGIN_METADATA,
                });
                const authResponse: AuthenticateResponse = result.value;
                if (result.success && authResponse.refreshToken !== '') {
                    dispatch(authenticateRefreshTokenAction({
                        refreshToken: authResponse.refreshToken,
                    }));
                }
            }
            AppAlertService.showSuccess({
                message: Languages.ProfileSuccessfullyUpdated,
            });
        });
        const verificationCodeSubscribe = VerificationCodeService.subscribe(() => {
            showVerificationCodeDialog();
        });
        return () => {
            isComponentMountedRef.current = false;
            claimProfileSubscribe.unsubscribe();
            subscription.unsubscribe();
            updateProfileServiceSubscribe.unsubscribe();
            verificationCodeSubscribe.unsubscribe();
        };
    }, [values.phoneNumber]);

    useEffect(() => {
        if (userInfo.zipCode !== values.zipCode
            || (userInfo.city === '' || userInfo.state === '')) {
            dispatch(getZipCodeInformationAction({
                zipCode: values.zipCode,
            }));
        } else {
            setFieldValue('city', userInfo.city);
            setFieldValue('state', userInfo.state);
        }
    }, [values.zipCode]);

    const onChangeTextSpecial = (text: string, fieldName: string) => {
        if (fieldName === 'zipCode') {
            if (text.length === 0) {
                setFieldTouched(fieldName, true);
                validateField(fieldName);
            } else {
                setFieldError(fieldName, undefined);
            }

            setFieldValue(fieldName, text);
        } else if (fieldName === 'phoneNumber' || fieldName === 'secondaryPhoneNumber') {
            let newPhoneNumber = '';
            if (text.length < 10) {
                if (text.length === 0) {
                    setFieldTouched(fieldName, true);
                    validateField(fieldName);
                } else {
                    setFieldError(fieldName, undefined);
                }
                setFieldValue(fieldName, text);
            } else {
                newPhoneNumber = formatPhoneNumber(`+1${text}`);
                setFieldValue(fieldName, newPhoneNumber);
            }
            setFieldError(fieldName, undefined);
        }

        if (fieldName === 'dob'
            || fieldName === 'firstName'
            || fieldName === 'lastName'
            || fieldName === 'address'
            || fieldName === 'last4ssn'
            || fieldName === 'email'
        ) {
            if (text.length === 0) {
                setFieldTouched(fieldName, true);
                validateField(fieldName);
            } else {
                setFieldError(fieldName, undefined);
            }
            setFieldValue(fieldName, text);
        };
    };

    const shouldDisableSuccessButton = (): boolean => {
        if (!isValidAccount && isClaimProcess) {
            return (
                hasExceededAttempts ||
                Object.keys(errors).length !== 0 ||
                JSON.stringify(values) === JSON.stringify(userInfo)
            );
        } else {
            return Object.keys(errors).length !== 0 ||
                JSON.stringify(values) === JSON.stringify(userInfo);
        }
    };

    const shouldDisplayCancelButton = (): boolean => isClaimProcess
        || !isValidAccount
        || JSON.stringify(values) !== JSON.stringify(userInfo);

    const handleOnVerificationCodeEnter = (code: string) => {
        VerificationCodeDialogService.hide();
        AppAlertService.showSuccess({
            message: Languages.NewMobileNumberSuccessfullyVerified,
        });
        setFieldValue('phoneNumberVerificationCode', code);
    };

    const handleOnVerificationCodeResend = () => {
        dispatch(postPhoneUpdateVerificationAction({
            phone: clearPhoneNumberFormat(values.phoneNumber),
        }));
    };

    const handleVerificationNumber = () => {
        dispatch(postPhoneUpdateVerificationAction({
            phone: clearPhoneNumberFormat(values.phoneNumber),
        }));
    };

    const showVerificationCodeDialog = () => {
        VerificationCodeDialogService.show({
            handleClickEnter: handleOnVerificationCodeEnter,
            handleClickResend: handleOnVerificationCodeResend,
            _owner: values.phoneNumber,
            context: 'ProfilePhoneUpdate',
        });
    };

    const shouldDisableVerifyNumberButton = (): boolean => userInfo.phoneNumber === values.phoneNumber;

    const maySubmitForm = () => {
        const ssn = clearformatSSN(values.last4ssn);
        setFieldValue('las4ssn', ssn);
        if (isValidAccount) {
            if ((values.phoneNumber !== userInfo.phoneNumber) && values.phoneNumberVerificationCode === '') {
                AppAlertService.showError({
                    message: Languages.PleaseVerifyTheNewMobileNumber,
                });
            } else {
                if ((values.phoneNumber === userInfo.phoneNumber) && values.phoneNumberVerificationCode !== '') {
                    values.phoneNumberVerificationCode = '';
                }
                form.handleSubmit();
            }
        } else if (values.dob) {
            const formatedDOB = formatDOB(values.dob);
            dispatch(postClaimProfileAction({
                dob: formatedDOB,
                ssn,
            }));
        }
    };

    const handleCancel = () => {
        if (JSON.stringify(values) === JSON.stringify(userInfo)) {
            if (!isValidAccount) {
                dispatch(authenticateLogoutAction());
            }
        } else {
            form.resetForm();
        }
    };

    const renderAppAlert = useMemo(
        () => (
            <AppModalAlert
                accessibilityLabel={'profileInfoAppModalAlertClaimError'}
                handleAccept={() => setIsAppAlertShown(false)}
                show={isAppAlertShown}
                subtitle={subtitleAlertRef.current}
                testID={'profileInfoAppModalAlertClaimError'}
                title={titleAlertRef.current}
                titleBottomSection={titleBottomSectionAlertRef.current}
                subTitleBottomSection={subTitleBottomSectionAlertRef.current}
            />
        ),
        [isAppAlertShown],
    );

    const openPrivacyPolicy = () => window.open(linksApp.privacyPolicy, '_blank');

    return (
        <ScrollView
            showsVerticalScrollIndicator={false}
            style={styles.container}
        >
            <View style={styles.containerBody}>
                <View style={styles.containerInputs}>
                    <ShimmerApp
                        visible={Boolean(!isLoading)}
                        shimmerStyle={styles.shimmerInput}
                    >
                        <TextInput
                            accessibilityLabel={'profileInfoTextInputDOB'}
                            containerStyle={styles.profileInput}
                            editable={isEditable}
                            error={touched.dob && Boolean(errors.dob)}
                            helperText={touched.dob ? errors.dob : ''}
                            keyboardType='numeric'
                            maxCharacters={10}
                            myRef={dobInputRef}
                            onBlur={handleBlur('dob')}
                            onChangeText={(text: string) => onChangeTextSpecial(text, 'dob')}
                            onSubmitEditing={() => isValidAccount ? firstNameInputRef.current?.focus() : last4SSN.current?.focus()}
                            options={{
                                format: 'MM/dd/yyyy',
                            }}
                            placeholder={isValidAccount ? Languages.Dob : Languages.DobRequired}
                            returnKeyType='done'
                            testID={'profileInfoTextInputDOB'}
                            type='Mask'
                            value={values.dob}
                        />
                    </ShimmerApp>
                    {isValidAccount && (
                        <>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <TextInput
                                    accessibilityLabel={'profileInfoTextInputFirstName'}
                                    containerStyle={styles.profileInput}
                                    editable={isEditable}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName ? errors.firstName : ''}
                                    myRef={firstNameInputRef}
                                    onBlur={handleBlur('firstName')}
                                    onChangeText={(text: string) => onChangeTextSpecial(text, 'firstName')}
                                    onSubmitEditing={() => addressInputRef.current?.focus()}
                                    placeholder={Languages.FirstName}
                                    returnKeyType='next'
                                    testID={'profileInfoTextInputFirstName'}
                                    value={values.firstName}
                                />
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <TextInput
                                    accessibilityLabel={'profileInfoTextInputAddress'}
                                    containerStyle={styles.profileInput}
                                    error={touched.address && Boolean(errors.address)}
                                    helperText={touched.address ? errors.address : ''}
                                    myRef={addressInputRef}
                                    onBlur={handleBlur('address')}
                                    onChangeText={(text: string) => onChangeTextSpecial(text, 'address')}
                                    onSubmitEditing={() => aptNumberInputRef.current?.focus()}
                                    placeholder={Languages.Address}
                                    returnKeyType='next'
                                    testID={'profileInfoTextInputAddress'}
                                    value={values.address}
                                />
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <View style={[styles.containerSecondBody, styles.divider]}>
                                    <TextInput
                                        accessibilityLabel={'profileInfoTextInputAptNumber'}
                                        containerStyle={styles.textOtherInput}
                                        error={touched.aptNumber && Boolean(errors.aptNumber)}
                                        helperText={touched.aptNumber ? errors.aptNumber : ''}
                                        keyboardType='numeric'
                                        myRef={aptNumberInputRef}
                                        onBlur={handleBlur('aptNumber')}
                                        onChangeText={handleChange('aptNumber')}
                                        onSubmitEditing={() => zipCodeInputRef.current?.focus()}
                                        placeholder={Languages.AptNumber}
                                        returnKeyType='done'
                                        testID={'profileInfoTextInputAptNumber'}
                                        value={values.aptNumber}
                                    />
                                    <View style={styles.divider} />
                                    <TextInput
                                        accessibilityLabel={'profileInfoTextInputZipCode'}
                                        containerStyle={styles.textOtherInput}
                                        error={touched.zipCode && Boolean(errors.zipCode)}
                                        helperText={touched.zipCode ? errors.zipCode : ''}
                                        keyboardType='numeric'
                                        maxCharacters={5}
                                        myRef={zipCodeInputRef}
                                        onBlur={handleBlur('zipCode')}
                                        onChangeText={(text: string) => onChangeTextSpecial(text, 'zipCode')}
                                        onSubmitEditing={() => last4SSN.current?.focus()}
                                        placeholder={Languages.ZipCode}
                                        returnKeyType='done'
                                        testID={'profileInfoTextInputZipCode'}
                                        value={values.zipCode}
                                    />
                                </View>
                            </ShimmerApp>

                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <View style={[styles.containerSecondBody, styles.divider]}>
                                    <TextInput
                                        accessibilityLabel={'profileInfoTextInputCity'}
                                        containerStyle={styles.textOtherInput}
                                        editable={false}
                                        error={touched.city && Boolean(errors.city)}
                                        helperText={touched.city ? errors.city : ''}
                                        onBlur={handleBlur('city')}
                                        onChangeText={handleChange('city')}
                                        placeholder={Languages.City}
                                        returnKeyType='next'
                                        testID={'profileInfoTextInputCity'}
                                        value={values.city}
                                    />
                                    <View style={styles.divider} />
                                    <TextInput
                                        accessibilityLabel={'profileInfoTextInputState'}
                                        containerStyle={styles.textOtherInput}
                                        editable={false}
                                        error={touched.state && Boolean(errors.state)}
                                        helperText={touched.state ? errors.state : ''}
                                        onBlur={handleBlur('state')}
                                        onChangeText={handleChange('state')}
                                        placeholder={Languages.State}
                                        testID={'profileInfoTextInputState'}
                                        value={values.state}
                                    />
                                </View>
                            </ShimmerApp>
                        </>
                    )}
                </View>
                <View style={styles.containerInputs}>
                    <ShimmerApp
                        visible={Boolean(!isLoading)}
                        shimmerStyle={styles.shimmerInput}
                    >
                        <TextInput
                            accessibilityLabel={'profileInfoTextInputSSN'}
                            containerStyle={styles.profileInput}
                            editable={isEditable}
                            error={touched.last4ssn && Boolean(errors.last4ssn)}
                            helperText={touched.last4ssn ? errors.last4ssn : ''}
                            keyboardType='numeric'
                            maxCharacters={11}
                            myRef={last4SSN}
                            onBlur={handleBlur('last4ssn')}
                            onChangeText={(text: string) => onChangeTextSpecial(text, 'last4ssn')}
                            onSubmitEditing={() => lastNameInputRef.current?.focus()}
                            placeholder={Languages.InsuranceNumber}
                            returnKeyType='done'
                            testID={'profileInfoTextInputSSN'}
                            value={isValidAccount && !isEditable ? `SSN - ${values.last4ssn}` : values.last4ssn}
                        />
                    </ShimmerApp>
                    {isValidAccount && (
                        <>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <TextInput
                                    accessibilityLabel={'profileInfoTextInputLastName'}
                                    containerStyle={styles.profileInput}
                                    editable={isEditable}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName ? errors.lastName : ''}
                                    myRef={lastNameInputRef}
                                    onBlur={handleBlur('lastName')}
                                    onChangeText={(text: string) => onChangeTextSpecial(text, 'lastName')}
                                    onSubmitEditing={() => emailInputRef.current?.focus()}
                                    placeholder={Languages.LastName}
                                    returnKeyType='next'
                                    testID={'profileInfoTextInputLastName'}
                                    value={values.lastName}
                                />
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <TextInput
                                    accessibilityLabel={'profileInfoTextInputEmailAddress'}
                                    containerStyle={styles.profileInput}
                                    editable={isEditable}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email ? errors.email : ''}
                                    keyboardType='email-address'
                                    myRef={emailInputRef}
                                    onBlur={handleBlur('email')}
                                    onChangeText={(text: string) => onChangeTextSpecial(text, 'email')}
                                    onSubmitEditing={() => phoneRef.current?.focus()}
                                    placeholder={Languages.EmailAddress}
                                    returnKeyType='next'
                                    testID={'profileInfoTextInputEmailAddress'}
                                    value={values.email}
                                />
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <View style={[styles.containerSecondBody, styles.divider]}>
                                    <TextInput
                                        accessibilityLabel={'profileInfoTextInputPhoneNumber'}
                                        containerStyle={styles.textOtherInput}
                                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                        helperText={touched.phoneNumber ? errors.phoneNumber : ''}
                                        keyboardType='phone-pad'
                                        maxCharacters={14}
                                        myRef={phoneRef}
                                        onBlur={handleBlur('phoneNumber')}
                                        onChangeText={(text: string) => onChangeTextSpecial(text, 'phoneNumber')}
                                        onSubmitEditing={() => secondaryPhoneRef.current?.focus()}
                                        placeholder={Languages.MobileNumber}
                                        returnKeyType='done'
                                        testID={'profileInfoTextInputPhoneNumber'}
                                        value={values.phoneNumber}
                                    />
                                    <View style={styles.divider} />
                                    <AppButton
                                        accessibilityLabel={'profileInfoAppButtonVerifyNumber'}
                                        buttonStyle={styles.verifyNumber}
                                        disabled={shouldDisableVerifyNumberButton()}
                                        handleOnChange={handleVerificationNumber}
                                        testID={'profileInfoAppButtonVerifyNumber'}
                                        textStyle={styles.verifyNumberText}
                                        title={Languages.VerifyNumber}
                                    />
                                </View>
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.shimmerInput}
                            >
                                <View style={[styles.containerSecondBody, styles.divider]}>
                                    <TextInput
                                        accessibilityLabel={'profileInfoTextInputSecondaryPhoneNumber'}
                                        containerStyle={styles.textOtherInput}
                                        error={touched.secondaryPhoneNumber && Boolean(errors.secondaryPhoneNumber)}
                                        helperText={touched.secondaryPhoneNumber ? errors.secondaryPhoneNumber : ''}
                                        keyboardType='numeric'
                                        maxCharacters={14}
                                        myRef={secondaryPhoneRef}
                                        onBlur={handleBlur('secondaryPhoneNumber')}
                                        onChangeText={(text: string) => onChangeTextSpecial(text, 'secondaryPhoneNumber')}
                                        placeholder={Languages.SecondaryMobileNumber}
                                        returnKeyType='done'
                                        testID={'profileInfoTextInputSecondaryPhoneNumber'}
                                        value={values.secondaryPhoneNumber}
                                    />
                                    <View style={styles.divider} />
                                    <View style={styles.textOtherInput} />
                                </View>
                            </ShimmerApp>
                        </>
                    )}
                </View>
            </View>
            <View style={[styles.containerFooterButtons, { marginTop: !isValidAccount ? 240 : 0 }]}>
                {
                    !isValidAccount && <View style={styles.privacyPolicyContainer}>
                        <Text style={styles.privacyPolicyText}>
                            {Languages.WeTakeDataSecurityVerySeriouslyPleaseReadOur}
                            <TextBoldTouchable
                                accessibilityLabel={'profileInfoTextPrivacyPolicy'}
                                nativeID={'profileInfoTextPrivacyPolicy'}
                                onPress={openPrivacyPolicy}
                                testID={'profileInfoTextPrivacyPolicy'}
                                textStyle={styles.txtPrivacyPolicy}
                            >
                                {Languages.PrivacyPolicy}
                            </TextBoldTouchable>
                            {Languages.ToLearnMore}
                        </Text>
                    </View>
                }
                <View style={styles.btnsContainer}>
                    <ShimmerApp
                        visible={Boolean(!isLoading)}
                        shimmerStyle={styles.shimmerButton}
                        contentStyle={[styles.shimmerViewButton, isMobile && { width: 100 }]}
                    >
                        <>
                            {shouldDisplayCancelButton() && (
                                <AppButton
                                    accessibilityLabel={'profileInfoAppButtonCancel'}
                                    buttonStyle={styles.buttonCancel}
                                    handleOnChange={handleCancel}
                                    testID={'profileInfoAppButtonCancel'}
                                    textStyle={styles.textCancel}
                                    title={Languages.Cancel}
                                />
                            )}
                        </>
                    </ShimmerApp>
                    <ShimmerApp
                        visible={Boolean(!isLoading)}
                        shimmerStyle={styles.shimmerButton}
                        contentStyle={[styles.shimmerViewButton, isMobile && { width: 100 }]}
                    >
                        <AppButton
                            accessibilityLabel={'profileInfoAppButtonSubmit'}
                            buttonStyle={styles.buttonSuccess}
                            disabled={shouldDisableSuccessButton()}
                            handleOnChange={maySubmitForm}
                            testID={'profileInfoAppButtonSubmit'}
                            textStyle={!isValidAccount && styles.textSuccess}
                            title={isValidAccount ? Languages.Save : Languages.LocateAccount}
                        />
                    </ShimmerApp>
                </View>
            </View >
            {renderAppAlert}
        </ScrollView >
    );
};
