import React, { useEffect } from 'react';

import {
    ImageBackground,
    StyleSheet,
    Text,
    View,
} from 'react-native';

import {
    EasyPayLogoComplete,
    Mep,
} from '@Assets/index';
import { LoginForm } from '@Auth/components/LoginForm';
import { AppLinksComponent } from '@Auth/components/AppLinksComponent';
import {
    authenticateAction,
    authenticateInternalAction,
} from '@Auth/state/authenticateActions';
import { useAppDispatch } from '@Hooks/appHooks';
import useKeyboardEvent from '@Hooks/useKeyboardEvent';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import {
    colors,
    fonts,
    t,
    theme,
} from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'LoginScreen'> { };

export const LoginScreen = ({
    navigation,
    route,
}: Props) => {
    const dispatch = useAppDispatch();

    const keyboardEvent = useKeyboardEvent('Enter', 'signin');
    const {
        loginParams,
        internalLoginParams,
    } = route?.params || {};

    useEffect(() => {
        if (loginParams) {
            const {
                autoLogin: isAutoLogin,
                username,
                password,
            } = loginParams;
            if (isAutoLogin) {
                const email = username ?? '';
                const pass = password ?? '';
                doLogin(email, pass, isAutoLogin);
            }
        } else if (internalLoginParams) {
            dispatch(authenticateInternalAction({
                token: internalLoginParams.token,
            }));
        }
    }, [loginParams, internalLoginParams]);

    const validateSendKeyboardEvent = () => {
        if (keyboardEvent?.screen && window.location.href.indexOf(keyboardEvent?.screen) > -1) {
            return keyboardEvent;
        }
    };

    const doLogin = (email: string, password: string, autoLogin?: boolean) => {
        LoggerService.logEvent('login', {
            autoLogin,
            email,
        });
        dispatch(authenticateAction({
            email,
            password,
        }));
    };

    return (
        <ImageBackground
            source={Mep}
            imageStyle={[t.wFull, t.hFull]}
            style={[t.flex1, t.itemsEnd, t.justifyCenter, t.pX7]}
        >
            
            <View style={[
                { width: 464 },
                t.minW80,
                t.maxW2_5,
                { height: 'auto' },
                { paddingHorizontal: 16, paddingVertical: 24 },
                t.maxHFull,
                t.bgWhite,
                t.itemsCenter,
                t.roundedSm,
            ]}>
                <View style={[t.mT10]}>
                    <EasyPayLogoComplete />
                </View>
                <Text style={[
                    styles.signInTxt,
                    t.text2xl,
                    t.leadingNormal,
                    t.textLeft,
                    t.mB5,
                    t.mT12
                ]}>{Languages.SignIn}</Text>
                <LoginForm
                    navigation={navigation}
                    route={route}
                    doLogin={doLogin}
                    keyboardEvent={validateSendKeyboardEvent()}
                />
            </View>
            <Text style={[
                styles.versionText,
                t.absolute,
                t.bottom0,
                t.right0,
                t.mR1,
                t.mB1,
            ]}>{Languages.StoreVersion}</Text>
            <AppLinksComponent />
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    signInTxt: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontWeight: '500',
        width: '80%',
        lineHeight: 29,
        fontSize: 24,
    },
    versionText: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        lineHeight: 24,
        fontSize: 13,
        fontWeight: '400',
    },
});
