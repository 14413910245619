import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    activeDot: {
        marginTop: 10,
        backgroundColor: theme.colors.primary,
    },
    title: {
        fontFamily: fonts.SFProDisplaySemibold,
        fontWeight: '700',
        fontSize: 50,
        lineHeight: 59.67,
        textAlign: 'center',
        color: theme.colors.primary,
    },
    containerTitle: {
        alignItems: 'center',
        marginTop: 32,
        width: '100%',
    },
    dotStyle: {
        marginTop: 10,
        backgroundColor: colors.gray,
    },
    introTextStyle: {
        color: colors.grayLigth,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        paddingTop: 20,
        paddingVertical: 30,
        textAlign: 'center',
    },
    introTitleStyle: {
        fontSize: 24,
        fontFamily: fonts.SFProDisplayMedium,
        color: colors.grayDarkColor,
        textAlign: 'center',
        marginTop: 10,
        fontWeight: '500',
    },
    textButton: {
        alignItems: 'center',
        color: colors.white,
        flexDirection: 'column',
        fontFamily: fonts.SFProText,
        fontSize: 17,
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        marginVertical: 15,
    },
    viewButton: {
        alignSelf: 'center',
        backgroundColor: colors.greenContainer,
        borderRadius: 5,
        flex: 1,
        height: 45,
        justifyContent: 'center',
        overflow: 'hidden',
        paddingBottom: 1,
        width: 255,
    },
    viewImage: {
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 121,
        width: '100%',
    },
    viewImageCenterContainer: {
        flex: 1,
        width: '50%',
        alignItems: 'center',
    },
    viewText: {
        height: 200,
        paddingHorizontal: 84,
        width: 470,
    },
    viewP: {
        backgroundColor: colors.backgroundWhiteLight,
        height: '100%',
        position: 'absolute',
        width: '100%',
        zIndex: 999,
    },
    viewContainer: {
        alignItems: 'center',
        backgroundColor: colors.backgroundWhiteLight,
        flex: 1,
        justifyContent: 'center',
    },
    viewAppIntro: {
        ...globalStyle.dropShadow,
        backgroundColor: colors.white,
        borderRadius: 10,
        flex: 1,
        justifyContent: 'center',
        marginVertical: 32,
        overflow: 'hidden',
        width: '75%',
    },
    viewAppIntroMobile: {
        backgroundColor: colors.white,
        flex: 1,
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100%',
    },
    view: {
        alignItems: 'center',
        backgroundColor: theme.colors.background,
        bottom: 80,
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 217,
        overflow: 'hidden',
    },
    viewMobile: {
        bottom: 80,
        marginHorizontal: 10,
    },
});
