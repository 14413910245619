import React from 'react';

import { ReturnSvg } from '@Assets/index';
import { ToolbarIcon } from '@Components/ToolbarIcon';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import {
    ParamListBase,
    useNavigation,
} from '@react-navigation/native';
import {
    StackNavigationProp,
    StackScreenProps,
} from '@react-navigation/stack';
import {
    AppStyleSheet,
    switchcase,
} from '@Utils/index';

interface Props extends Partial<StackScreenProps<RootStackParamList>> {
    fill?: string;
    screen?: string;
}

enum ScreenTypes {
    Activity = 'Activity',
    AddPaymentMethodScreen = 'AddPaymentMethodScreen',
    ApplicationsScreen = 'ApplicationsScreen',
    AutoPayScreen = 'AutoPayScreen',
    BottomTabNavigator = 'BottomTabNavigator',
    Home = 'Home',
    DateMakeAPaymentScreen = 'DateMakeAPaymentScreen',
    MakeAPaymentScreen = 'MakeAPaymentScreen',
    NewStatementsScreen = 'NewStatementsScreen',
    SelectMethodOfPaymentScreen = 'SelectMethodOfPaymentScreen',
    Payments = 'Payments',
    PaymentsScreen = 'PaymentsScreen',
    Profile = 'Profile',
    ProfileInfoScreen = 'ProfileInfoScreen',
    ProfileScreen = 'ProfileScreen',
    ProfileStack = 'ProfileStack',
    ProgramRebateScreen = 'ProgramRebateScreen',
    MethodsOfPaymentsScreen = 'MethodsOfPaymentsScreen',
    NotificationScreen = 'NotificationScreen',
    NotificationToolbarIcon = 'NotificationToolbarIcon',
    NotificationsStack = 'NotificationsStack',
}

export const BackToolbarIcon = ({
    fill,
    screen,
}: Props) => {
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

    const resetToHome = () => {
        navigation.reset({
            index: 0,
            routes: [
                {
                    name: 'Dashboard',
                },
            ],
        });
    };

    const mayResetNavigation = () => {
        const state = navigation.getState();
        const params = state.routes[1].params;
        // @ts-ignore
        if (params.action) {
            resetToHome();
        } else {
            if (navigation.canGoBack()) {
                navigation.goBack();
            } else {
                resetToHome();
            }
        }
    };

    const goTo = () => {
        if (!navigation.canGoBack() ) {
            return navigation.reset({
                index: 0,
                routes: [{
                    name: 'Dashboard',
                },]
            });
        }
        switchcase({
            [ScreenTypes.MakeAPaymentScreen]: () => {
                mayResetNavigation();
            },
            [ScreenTypes.ApplicationsScreen]: () => {
                mayResetNavigation();
            },
        })(() => navigation.goBack())(screen)();
    };

    return (
        <ToolbarIcon
            accessibilityLabel={'toolbarIconTouchableBack'}
            onPress={goTo}
            renderIcon={() => <ReturnSvg fill={fill} />}
            style={styles.container}
            testID={'toolbarIconTouchableBack'}
        />
    );
};

const styles = AppStyleSheet({
    container: {
        width: 55,
        height: '100%',
    },
});
