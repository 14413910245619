import { parseISO } from 'date-fns';
import {
    ofType,
    StateObservable,
} from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import { getPendingPaymentsAPI } from '@Activity/api/activityApi';
import { PaymentPendingResponse } from '@Activity/interfaces';
import AppAlertService from '@Alert/services/AppAlertService';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import {
    currentPaymentMonthly,
    saveAPaymenScheduleAPI,
} from '@MakeAPayment/api/MakeAPaymetApi';
import {
    GetPaymentStatus,
    GetPaymentStatusActionRequest,
    MakeAPaymentRequestAction,
    SaveAPaymentRequestAction,
} from '@MakeAPayment/interfaces';
import { GetPaymentResponse } from '@MakeAPayment/interfaces/index';
import MakeAPaymentService from '@MakeAPayment/services/MakeAPaymentService';
import SaveAPaymentService from '@MakeAPayment/services/SaveAPaymentService';
import { shouldShowPaymentWarningAction } from '@MakeAPayment/state/MakeAPaymentActions';
import MakeAPaymentActionsTypes from '@MakeAPayment/state/MakeAPaymentActionsTypes';
import { getPaymentMethodAutoPayAPI } from '@MethodsOfPayment/api/MethodsOfPaymentApi';
import { PaymentMethodAutoPay } from '@MethodsOfPayment/interfaces';
import { parseDate } from '@Utils/FormatUtils';
import { formatAmount } from '@Utils/index';

export const getPaymentEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.GET_CURRENT_PAYMENT_REQUEST),
    delay(250),
    mergeMap(({ payload }: MakeAPaymentRequestAction) => from(currentPaymentMonthly({
        ...payload,
    })).pipe(
        map((response: GetPaymentResponse) => {
            MakeAPaymentService.onSuccess({
                pastDue: formatAmount(response.pastDueAmount.units.toFixed(2) ?? '0.00'),
                minimumPayment: formatAmount(response.paymentAmount.units.toFixed(2) ?? '0.00'),
                currentMonthlyPayment: formatAmount(response.paymentAmount.units.toFixed(2) ?? '0.00'),
                payOffToday: formatAmount(response.payOffTodayAmount.units.toFixed(2) ?? '0.00'),
                dueDate: parseDate(response.dueDate, 'MMM dd, yyyy'),
            });

            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const saveAPaymentEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.SAVE_NEW_PAYMENTH_SCHEDULE_REQUEST),
    delay(250),
    mergeMap(({ payload }: SaveAPaymentRequestAction) => from(saveAPaymenScheduleAPI(payload))
        .pipe(
            map((response: any) => {
                SaveAPaymentService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const shouldShowPaymentWarningEpic = (action$: Observable<any>, state$: StateObservable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.GET_PAYMENT_STATUS_REQUEST),
    switchMap(({ payload }: GetPaymentStatusActionRequest) => from(
        new Promise<GetPaymentStatus>(async (resolve) => {
            const { autopay: currentAutopay } = state$.value.methodsOfPaymentReducer;
            let response: GetPaymentStatus = {
                autopayResponse: undefined,
                pendingPaymentsResponse: undefined,
            };
            if (currentAutopay === undefined) {
                try {
                    const autopayResponse: PaymentMethodAutoPay = await getPaymentMethodAutoPayAPI(payload);
                    response = {
                        ...response,
                        autopayResponse,
                    };
                } catch (error) { }
            } else {
                response = {
                    ...response,
                    autopayResponse: {
                        autopay: currentAutopay,
                    },
                };
            }
            if (response.autopayResponse === undefined || !response.autopayResponse.autopay) {
                try {
                    const pendingPaymentsResponse: PaymentPendingResponse[] = await getPendingPaymentsAPI(payload);
                    response = {
                        ...response,
                        pendingPaymentsResponse,
                    };
                } catch (error) { }
            }
            resolve(response);
        }),
    ).pipe(
        mergeMap((response: GetPaymentStatus) => {
            const { autopayResponse, pendingPaymentsResponse } = response;
            if (autopayResponse) {
                const { autopay: isAutopay } = autopayResponse;
                if (isAutopay) {
                    return [
                        shouldShowPaymentWarningAction({
                            show: true,
                        }),
                        loadingDialogHide(),
                    ];
                }
            }
            if (pendingPaymentsResponse) {
                const hasPendingPayment = hasACurrentPendingPayment(
                    pendingPaymentsResponse,
                    payload.dateSelected,
                );
                return [
                    shouldShowPaymentWarningAction({
                        show: hasPendingPayment,
                    }),
                    loadingDialogHide(),
                ];
            }
            return [
                shouldShowPaymentWarningAction({
                    show: false,
                }),
                loadingDialogHide(),
            ];
        }),
        catchError((error: Error) => {
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

const hasACurrentPendingPayment = (pendingPayments: PaymentPendingResponse[], date: Date): boolean => {
    if (pendingPayments && pendingPayments.length > 0) {
        const data = pendingPayments.find((item: PaymentPendingResponse) => {
            const runDate: Date = parseISO(item.runDate);
            return runDate.getMonth() === date.getMonth()
                && runDate.getFullYear() === date.getFullYear();
        });
        return data !== undefined;
    }
    return false;
};
