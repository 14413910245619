import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        flexDirection: 'row',
        height: 80,
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        ...globalStyle.dropShadow,
        ...Platform.select({
            web: {
                marginHorizontal: 0,
            },
            default: {
                marginHorizontal: 16,
            },
        }),
    },
    containerSelected: {
        backgroundColor: theme.colors.primary,
        borderRadius: 10,
        flexDirection: 'row',
        height: 80,
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        ...globalStyle.dropShadow,
        ...Platform.select({
            web: {
                marginHorizontal: 0,
                marginBottom: 9,
            },
            default: {
                marginHorizontal: 16,
                marginBottom: 16,
            },
        }),
    },
    containerButton: {
        height: 30,
        width: 80,
    },
    titleText: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 20,
    },
    titleTextSelected: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 20,
    },
    dateText: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 20,
        ...Platform.select({
            ios: {
                marginTop: 5,
            },
        }),
    },
    dateTextSelected: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 18,
        ...Platform.select({
            ios: {
                marginTop: 5,
            },
        }),
    },
});
