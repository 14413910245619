import React from 'react';

import { Platform } from 'react-native';

import { NotificationIcon } from '@Assets/index';
import { BadgeComponent } from '@Components/BadgeComponent';
import { ToolbarIcon } from '@Components/ToolbarIcon';
import { useAppSelector } from '@Hooks/appHooks';
import { NotificationsData } from '@Notifications/interfaces';

interface Props {
    onPress: () => void;
};

export const NotificationToolbarIcon = ({ onPress }: Props) => {
    const handleOnPress = () => onPress();

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    const notificationData = useAppSelector(
        ({ notificationsReducer }) => notificationsReducer[loanSelected?.applicationId || 0],
    ) as NotificationsData;

    const {
        badgeText,
    } = notificationData || {
        badgeText: undefined,
    };

    if (Platform.OS === 'web' && !notificationData) {
        return null;
    }

    return (
        <BadgeComponent
            text={badgeText || undefined}
        >
            <ToolbarIcon
                accessibilityLabel={'toolbarIconTouchableNotification'}
                onPress={handleOnPress}
                renderIcon={() => <NotificationIcon />}
                testID={'toolbarIconTouchableNotification'}
            />
        </BadgeComponent>
    );
};
