enum MethodsOfPaymentActionsTypes {
    CLEAR_PAYMENT = '@@payment/CLEAR_PAYMENT',
    DELETE_CARD_PAYMENT_REQUEST = '@@payment/DELETE_CARD_PAYMENT_REQUEST',
    DELETE_CHECK_PAYMENT_REQUEST = '@@payment/DELETE_CHECK_PAYMENT_REQUEST',
    GET_AUTOPAY = '@@payment/GET_AUTOPAY',
    GET_AUTOPAY_SUCCESS  = '@@payment/GET_AUTOPAY_SUCCESS',
    GET_PAYMENT_METHOD_ERROR = '@@payment/GET_PAYMENT_METHOD_ERROR',
    GET_PAYMENT_METHOD_REQUEST = '@@payment/GET_PAYMENT_METHOD_REQUEST',
    GET_PAYMENT_METHOD_SUCCESS = '@@payment/GET_PAYMENT_METHOD_SUCCESS',
    GET_PLAID_LINK_TOKEN = '@@payment/GET_PLAID_LINK_TOKEN',
    LOAD_PAYMENT = '@@payment/LOAD_PAYMENT',
    PATCH_PAYMENT_METHODS_SORT = '@@payment/PATCH_PAYMENT_METHODS_SORT',
    PATCH_PAYMENT_SET_PRIMARY_REQUEST = '@@payment/PATCH_PAYMENT_SET_PRIMARY_REQUEST',
    PUT_AUTOPAY = '@@payment/PUT_AUTOPAY',
    PUT_PAYMENT_METHOD_ACH_REQUEST = '@@payment/PUT_PAYMENT_METHOD_ACH_REQUEST',
    PUT_PAYMENTH_METHOD_CARD_REQUEST = '@@payment/PUT_PAYMENTH_METHOD_CARD_REQUEST',
    RESQUEST_SELECTED = '@@payment/RESQUEST_SELECTED',
    SAVE_NEW_PAYMENTH_METHOD_AHC_ERROR = '@@payment/SAVE_NEW_PAYMENTH_METHOD_AHC_ERROR',
    SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST = '@@payment/SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST',
    SAVE_NEW_PAYMENTH_METHOD_AHC_SUCCESS = '@@payment/SAVE_NEW_PAYMENTH_METHOD_AHC_SUCCESS',
    SAVE_NEW_PAYMENTH_METHOD_CARD_ERROR = '@@payment/SAVE_NEW_PAYMENTH_METHOD_CARD_ERROR',
    SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST = '@@payment/SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST',
    SAVE_NEW_PAYMENTH_METHOD_CARD_SUCCESS = '@@payment/SAVE_NEW_PAYMENTH_METHOD_CARD_SUCCESS',
    SET_SELECTED = '@@payment/SET_SELECTED',
    SET_AUTOPAY = '@@payment/SET_AUTOPAY',
    UNSELECT_ALL = '@@payment/UNSELECT_ALL',
};

export default MethodsOfPaymentActionsTypes;
