import * as React from 'react';

import {
    Text,
    View,
} from 'react-native';

import { CongratulationsIcon } from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import Languages from '@i18n/index';
import {
    colors,
    fonts,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

interface Props {
    show: boolean;
    handleAccept: () => void;
};

export const CongratulationsModal = ({
    show,
    handleAccept,
}: Props) => (
    <AppModal
        animationType='fade'
        closeModal={handleAccept}
        transparent
        visible={show}
    >
        <View style={styles.container}>
            <Text style={styles.title}>{Languages.Congratulations}</Text>
            <Text style={styles.description}>{Languages.DescriptionsCongratulations}</Text>
            <CongratulationsIcon />
            <AppButton
                accessibilityLabel={'congratulationsModalAppButtonAccept'}
                buttonStyle={styles.acceptButton}
                handleOnChange={handleAccept}
                testID={'congratulationsModalAppButtonAccept'}
                title={Languages.Close}
            />
        </View>
    </AppModal>
);

const styles = AppStyleSheet({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        width: 343,
    },

    title: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '700',
        lineHeight: 20,
        textAlign: 'center',
        marginTop: 30,
    },
    description: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 18,
        textAlign: 'center',
        width: 257,
        marginTop: 6,
        marginBottom: 23,
    },
    acceptButton: {
        width: '78%',
        marginTop: 25,
        marginBottom: 71,
    },
});
