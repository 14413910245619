import React, {
    FC,
    LegacyRef,
    useState,
} from 'react';

import {
    KeyboardTypeOptions,
    NativeSyntheticEvent,
    Platform,
    ReturnKeyTypeOptions,
    StyleProp,
    StyleSheet,
    TextInput as Input,
    TextInputFocusEventData,
    TextInputProps,
    TouchableOpacity,
    View,
    ViewStyle,
} from 'react-native';
import {
    TextInputMask,
    TextInputMaskOptionProp,
} from 'react-native-masked-text';

import { AppTextInput } from '@Components/AppTextInput';
import {
    colors,
    fonts,
} from '@Theme/appTheme';

interface Props extends TextInputProps {
    placeholder: string;
    onChangeText?: (text: string, rawString?: string) => void;
    containerStyle?: StyleProp<ViewStyle>;
    error?: Boolean;
    value?: string;
    onBlur?: (e: any) => void;
    helperText?: any;
    maxCharacters?: number;
    type?: string;
    options?: TextInputMaskOptionProp;
    editable?: boolean;
    returnKeyType?: ReturnKeyTypeOptions;
    keyboardType?: KeyboardTypeOptions;
    onSubmitEditing?: (ref: any) => void;
    myRef?: LegacyRef<any>;
    securityEntry?: boolean;
    RightIcon?: FC<any>;
    LeftIcon?: FC<any>;
    onClickRight?: () => void;
    onEndEditing?: () => void;
};

export const TextInput = ({
    placeholder,
    containerStyle,
    error,
    value,
    onBlur = () => { },
    onChangeText,
    helperText = '',
    maxCharacters,
    type = 'default',
    options,
    editable = true,
    returnKeyType,
    keyboardType,
    onSubmitEditing,
    myRef,
    securityEntry,
    RightIcon,
    LeftIcon = () => <></>,
    accessibilityLabel,
    testID,
    onClickRight = () => { },
    onEndEditing = () => { },
    autoCapitalize,
}: Props) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleOnBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (editable) {
            setIsFocused(false);
            onBlur(event);
        }
    };

    const handleOnFocus = () => {
        setIsFocused(true);
    };

    return (
        <AppTextInput
            isError={error === true}
            error={helperText}
            containerStyle={containerStyle}
            isFocused={editable && isFocused}
            enabled={editable}
            disableErrorIcon={RightIcon !== undefined}
        >
            <View style={styles.container}>
                <LeftIcon />

                {type === 'Mask' ?
                    <TextInputMask
                        ref={myRef}
                        type='datetime'
                        placeholder={placeholder}
                        placeholderTextColor={colors.darkGray}
                        style={styles.textInput}
                        onChangeText={onChangeText}
                        numberOfLines={1}
                        value={value}
                        onBlur={handleOnBlur}
                        maxLength={maxCharacters}
                        autoCapitalize={autoCapitalize}
                        options={options}
                        returnKeyType={returnKeyType}
                        onFocus={handleOnFocus}
                        editable={editable}
                        onSubmitEditing={onSubmitEditing}
                        accessibilityLabel={accessibilityLabel}
                        testID={testID}
                    /> :
                    <Input
                        ref={myRef}
                        placeholder={placeholder}
                        placeholderTextColor={colors.darkGray}
                        style={styles.textInput}
                        onChangeText={onChangeText}
                        numberOfLines={1}
                        value={value}
                        onBlur={handleOnBlur}
                        maxLength={maxCharacters}
                        editable={editable}
                        returnKeyType={returnKeyType}
                        onFocus={handleOnFocus}
                        keyboardType={keyboardType}
                        onSubmitEditing={onSubmitEditing}
                        secureTextEntry={securityEntry}
                        accessibilityLabel={accessibilityLabel}
                        testID={testID}
                        onEndEditing={onEndEditing}
                        accessibilityElementsHidden={false}
                        autoCapitalize={autoCapitalize}
                    />
                }
                {RightIcon && (
                    <TouchableOpacity
                        onPress={onClickRight}
                        style={styles.rightBtn}
                    >
                        <RightIcon />
                    </TouchableOpacity>
                )}
            </View>
        </AppTextInput>
    );
};

const styles = StyleSheet.create({
    textInput: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        width: '90%',
        height: '100%',
        ...Platform.select({
            web: {
                outlineColor: 'transparent',
                outlineStyle: 'none',
                outlineWidth: 0,
            },
            android: {
                marginTop: 10,
                height: 40,
            },
        }),
    },
    containerIcon: {
        ...Platform.select({
            web: {
                paddingTop: '1%',
            },
        }),
    },
    container: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    rightBtn: {
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
