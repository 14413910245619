import React, {
    LegacyRef,
    useEffect,
    useState,
} from 'react';

import {
    Platform,
    StyleSheet,
    TextInput,
    View,
} from 'react-native';

import { AppTextInput } from '@Components/AppTextInput';
import { useDebouncedValue } from '@Hooks/useDebouncedValue';
import {
    colors,
    fonts,
} from '@Theme/appTheme';
import { validateEmail } from '@Utils/FormatUtils';

interface Props {
    disable?: boolean;
    error?: string;
    handleOnChange: (text: string) => void;
    isError?: boolean;
    placeholder: string;
    textInputRef?: LegacyRef<TextInput>;
    value?: string;
};

export const EmailTextInput = ({
    disable,
    error = '',
    handleOnChange,
    isError = false,
    placeholder,
    textInputRef,
    value = '',
}: Props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [text, setText] = useState(value);
    const [isShowError, setIsShowError] = useState(false);
    const [errorMesssage, setErrorMessage] = useState('');
    const { debouncedValue } = useDebouncedValue(text);

    useEffect(() => {
        handleOnDebouncedValue(debouncedValue);
    }, [debouncedValue]);

    useEffect(() => {
        setIsShowError(isError);
        setErrorMessage(error);
    }, [isError, error]);

    const handleOnDebouncedValue = (value: string) => {
        mayShowErrorMessage(value);
        handleOnChange(value);
        setText(value);
    };

    const mayShowErrorMessage = (value: string) => {
        const { isError, message } = validateEmail(value);
        setIsShowError(isError);
        setErrorMessage(message);
    };

    const handleFocus = () => {
        setIsFocused(!isFocused);
    };

    return (
        <AppTextInput
            isError={isShowError}
            error={errorMesssage}
            isFocused={!disable && isFocused}
            enabled={!disable}
        >
            <View style={styles.container}>
                <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={false}
                    keyboardType={'email-address'}
                    numberOfLines={1}
                    onBlur={handleFocus}
                    onChangeText={setText}
                    onFocus={handleFocus}
                    placeholder={placeholder}
                    placeholderTextColor={colors.darkGray}
                    ref={textInputRef}
                    style={styles.textInput}
                    value={text}
                    editable={!disable}
                />
            </View>
        </AppTextInput>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    textInput: {
        color: colors.grayDarkColor,
        flex: 0.9,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        height: 60,
        ...Platform.select({
            web: {
                outlineColor: 'transparent',
                outlineStyle: 'none',
                outlineWidth: 0,
                height: '100%',
            },
        }),
    },
});
