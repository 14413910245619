import {
    name as appName,
    store as storeVersion,
} from '../../app.json';

export default {
    Accept: 'Accept',
    Account: 'Account Number ',
    AccountConfirmation: 'Account Confirmation',
    AccountDetails: 'Account Details',
    AccountNotFound: 'Account not found',
    AccountNotFunded: 'Account not funded',
    AccountNumberMinCharacters: 'Must be at least 12 characters',
    AccountOnly: 'Account ',
    Activity: 'Activity',
    ActivityDescription: 'You can see your statements up to the last 6 months',
    AddNew: 'Add New',
    AddNewMethod: '+ Add New Method',
    AddNewMethodOfPayment: 'Add New Method of Payment',
    AddNewPaymentMethod: 'Add New Payment Method',
    AddBankAccount: 'Add Bank Account',
    AddCreditCard: 'Add Credit Card',
    Address: 'Address *',
    AltFee: 'Alt fee',
    Amount: 'Amount',
    AmountDue: 'Amount Due',
    AmountReceived: 'Amount Received',
    AnErrorHasOcurred: 'An error has occurred, please try again.',
    AppLinkText: 'Download the MyEasyPay app to view statements, schedule payments, and more!',
    AptNumber: 'Apt Number',
    AttemptsExceeded: 'Attempts Exceeded',
    AutoPay: 'AutoPay',
    AutoPayIsOff: 'AutoPay is off.',
    AutoPayTurnedOffDescription: 'With AutoPay turned off, you will need to remember to schedule your payments.',
    AutoPayDescription: 'You’ll make an auto-payment on each due date. You can turn off AutoPay later in your account',
    AutoPayScheduledFor: 'AutoPay scheduled for',
    Billing: 'Billing',
    Balance: 'Balance',
    BusinessCenterValidation: 'Business Center Validation',
    ByContinuingIAgreeTo: 'By continuing, I agree to EasyPay Finance’s',
    Call: 'Call',
    Cancel: 'Cancel',
    CancelPayment: 'Cancel Payment',
    CancelPendingQuestion: `Clicking "Cancel" will cancel your scheduled payment of {amount} on {date}?`,
    CancelTransaction: 'Cancel Transaction',
    CancelScheduledPayment: 'Cancel Scheduled Payment?',
    CancelScheduledPaymentDetail: 'Clicking "Cancel" will cancel your scheduled payment',
    CardNumber: 'Card Number *',
    City: 'City',
    ClickBelowToBeginFinancial: 'Click below to begin your journey to financial literacy and set yourself up for success.',
    ClickHere: 'Click Here',
    Close: 'Close',
    ConfirmNewPassword: 'Confirm New Password',
    ConfirmPassword: 'Confirm Password',
    Congratulations: 'Congratulations!',
    ConnectYourBank: 'Connect your bank',
    ContactEmail: 'customerservice@easypayfinance.com',
    ContactPhoneNumber: '(866) 438-8372',
    Continue: 'Continue',
    ContinueQuestion: 'Continue?',
    ContractDate: 'Contract Date',
    ContractTerm: 'Contract Term (Months)',
    CreateAccount: 'Create Account',
    CreateAnAccount: 'Create an Account',
    CreatePassword: 'Create Password',
    CurrentAccounts: 'Current Accounts',
    CurrentBalance: 'Current Balance',
    CurrentPromotionalBalance: 'Current Promotional Balance',
    CurrentMonthlyPayment: 'Current Monthly Payment',
    CurrentPaymentAmount: 'Current Payment Amount ',
    CustomerCare: 'Customer Care',
    CustomerService: 'Customer Service',
    ChangeAutoPayMethod: 'Change AutoPay Method?',
    Dashboard: 'Dashboard',
    Date: 'Date',
    Day: 'Day',
    DaysRebatePromotion: '90-Day\nFinance Charge\nCap Promotion',
    Delete: 'Delete',
    DescriptionCreateProfile: 'Get access to your current accounts!',
    DescriptionMakingPayment: 'Just a few clicks is all it takes.',
    DescriptionMinimumPayment: 'Contractual or modified payment amount (Does NOT include fees owed)',
    DescriptionPastDue: 'Past due minimum payment and any fees owed on account',
    DescriptionRebate: 'Yes. Interest accrues daily from the date of your agreement. However, you will pay no more than $40 in Finance Charges if you pay your full balance during the 90-day promotional period, have no payment that was over 30 days past due, and meet the promotion’s other',
    DescriptionsCongratulations: 'Your payment has been scheduled',
    DescriptionTodayPayOff: 'Includes Principal Balance, Interest and any fees owed',
    DescriptionVerificationCode: 'Please enter the verification code we just sent to',
    DidNotGetToCode: 'Didn\'t get a code?',
    DisableAutoPay: 'With AutoPay turned off, please remember to schedule your payments',
    DisableAutoPayQuestion: 'Disable AutoPay?',
    DisableAutoPayFirstWarning: 'Disabling AutoPay will stop all current and future AutoPay payments.',
    DisableAutoPaySecondWarning: 'Are you sure you would like to disable AutoPay for this account?',
    Dob: 'DOB (MM/DD/YYYY)',
    DobRequired: 'DOB (MM/DD/YYYY) *',
    DontAllow: 'Don\'t allow',
    DontHaveAnAccountQuestion: 'Don\'t have an account?',
    DontNowHowToOpenThisURL: 'Don\'t know how to open this URL',
    Download: 'Download',
    DownloadStatement: 'Download Statement',
    DoYouReallyWantToInactivatePaymentMethod: 'Do you really want to inactivate the payment method?',
    DoYouWantToAllowToUse: `Do you want to allow ${appName} to use`,
    DoYouWantToContinue: 'Are you sure you want to continue?',
    DragAndDropSecond: ' (from the right column) you want to be primary',
    DragAndDropTheFirst: 'Drag and Drop the ',
    DragAndDropToOrganize: 'Drag and drop to organize your payment methods',
    SwipeLeftToManage: 'Swipe left to manage your existing payment method(s)',
    EasyPay: 'EasyPay Finance © 2024',
    EasyPayFinanceIsCommitted: 'That’s why EasyPay Finance is committed to providing you access to quality financial education content free of charge. We believe that a strong financial future begins with understanding core financial concepts and how they impact our daily lives.',
    EasyRepeatsPromo: 'EasyRepeats Promo',
    Edit: 'Edit',
    EditPayment: 'Edit Payment',
    EditPaymentMethod: 'Edit Payment Method',
    Email: 'Email',
    EmailAddress: 'Email Address *',
    EmailAddressOrPhoneNumber: 'Email Address or Phone Number',
    emailInValid: 'Enter valid email',
    emptyField: 'This field cannot be empty',
    EnableAutoPay: 'With AutoPay on, your payments will automatically schedule. AutoPay will not run if your account is past due.',
    EnableAutoPayCheckbox: 'Enable AutoPay',
    EndDate: 'Promotion End Date',
    EndingIn: 'ending in',
    Enter: 'Enter',
    Error: 'Error',
    ErrorEmailInvalid: 'Error: Email Invalid',
    ErrorPasswordInvalid: 'Error: Password Invalid',
    ErrorPdfInvalid: 'Unable to open statement',
    ErrorPhoneNumberInvalid: 'Error: Phone number Invalid',
    ExpirationDateCardFormat: 'Expiration Date *(MM/YY)',
    FAQ: 'FAQ',
    FAQs: 'FAQs',
    Fax: 'Fax: (866) 688-0275',
    FCCP: 'FCCP',
    FCCPExpirationDate: 'FCCP Expiration Date',
    FinancialFundamentals: 'Financial Fundamentals',
    FinancialFundamentalsDescription: 'A financial education platform designed to help you meet your goals and help improve your unique financial position',
    FinancialFundamentalsProgram: 'Financial Fundamentals Program',
    FinancialMakeChoicesBold: 'budget, managing our debt, to planning for our children’s education and our own retirement.',
    FinancialMakeChoicesEnd: 'The financial decisions we make have impacts that stretch long after a decision was made. But as a society, we don’t teach financial education as part of schooling, and this leaves many Americans playing catch-up.',
    FinancialMakeChoicesStart: 'Every day, we make choices that affect our finances; from setting a',
    FirstContractualPayment: 'First Contractual Payment',
    FirstName: 'First Name *',
    ForgotPassword: 'Forgot Password',
    ForgotPasswordQuestion: 'Forgot Password?',
    GoBack: 'Go Back',
    HaveAnAccount: 'Have an account?',
    HaveNoPaymentOver30DaysPastDue: 'Have no payment that was over 30 days past due',
    Hide: 'Hide',
    Home: 'Home',
    HowCanWeHelpYou: 'How can we help you?',
    HowDoesItWork: 'How does it work?',
    Inactivate: 'Inactivate',
    Inactive: 'Inactive',
    INeedHelp: 'I need help',
    InsuranceNumber: 'Full SSN *',
    Interest: 'Interest',
    InterestRate: 'Interest Rate',
    InternalLogin: 'Internal Login',
    InternetConection: 'You are connected',
    InvalidAmount: 'Invalid Amount',
    InvalidContract: 'You do not have an account with a contract',
    IsInterestAccruing: 'Is interest accruing during this promotion period?',
    LastName: 'Last Name *',
    LateFee: 'Late Fee',
    LatestTransaction: 'Latest Transaction',
    LearnMore: 'Learn More',
    LeaseToOwnCustomers: 'Lease to Own Customers:',
    LeaseToOwnCustomersPortal: 'EasyPay Leasing Portal',
    LeaseToOwnCustomersServicePhone: 'Please contact (800) 447-6215 for further assistance.',
    LoanAmount: 'Loan Amount',
    LocateAccount: 'Locate Account',
    LocateYourAccount: 'Locate Your Account',
    LogOut: 'Log Out',
    MailingAdress: 'Mailing Address: PO Box 2549 Carlsbad, CA 92018-2549',
    MakeAPayment: 'Make a Payment',
    ManageAutoPay: 'Manage AutoPay',
    Method: 'Method',
    MethodOfPayment: 'Method of Payment',
    MethodsOfPayment: 'Methods of Payment',
    MinimumPayment: 'Minimum Payment',
    MinimumPaymentDefinition: 'Contractual or modified payment amount (Does NOT include fees owed)',
    MobileNumber: 'Mobile Number *',
    MobileNumberRequired: 'Mobile Number *',
    Month: 'Month',
    MonthlyStatement: 'Monthly Statement',
    MyEasyPayApp: 'My EasyPay App',
    NeedHelContactUs: 'Need Help? Contact Us!',
    NewMobileNumberSuccessfullyVerified: 'New mobile number successfully verified',
    NewPassword: 'New Password',
    Next: 'Next',
    NextPaymentAmount: 'Next Payment Amount',
    NextPendingPayment: 'Next Pending Payment',
    NextDueDate: 'Next Due Date',
    Nickname: 'Nickname * (i. e. Checkings Account)',
    NoActivity: 'No Activity',
    NoInformationAvailable: 'No Information Available',
    NonCash: 'Non-Cash',
    NoPrevious: 'No Previous',
    Notifications: 'Notifications',
    NotInternetConeccion: 'It’s necessary to have internet conection to use the app',
    NotNow: 'Not Now',
    NotOtherwiseBeIn: 'NOT otherwise be in default under your agreement during the Promotional Period',
    NSFFee: 'NSF Fee',
    OK: 'OK',
    ON: 'ON',
    OFF: 'OFF',
    OnBoardingTitleRebate: '90 Day Finance Charge Cap Promotion',
    OnThe: 'on the',
    OopsLooksLikeLinkExpire: 'Oops! Looks like the link you are looking for has expire.',
    OopsLooksLikeLinkExpireResetPassword: 'Oops! Looks like the link you are looking for to reset your password has expire.',
    OopsLooksLikeLinkExpireVerifyAccount: 'Oops! Looks like the link you are looking for to verify your account has expire.',
    OriginalFinanceAmount: 'Original Finance Amount',
    OtherAmount: 'Other Amount',
    OtherAmountDefiniton: 'Any amount you want to pay towards your account',
    Password: 'Password',
    PasswordDoesNotMatch: ' Password doesn\'t match',
    PasswordResetConfirmation: 'Password Reset Confirmation',
    PasswordRule: 'Use 8 or more characters with a combination of letters, numbers, symbols and at least one upper case.',
    PastAccounts: 'Past Accounts',
    PastDue: 'Past Due',
    PastDueDefinition: 'Past due minimum payment and any fees owed on account',
    Payment: 'Payment',
    PaymentActivity: 'Payment Activity',
    PaymentAmount: 'Payment Amount',
    PaymentDate: 'Payment Date',
    PaymentDefinitions: 'Payment Definitions',
    PaymentDetails: 'Payment Details',
    PaymentFrequency: 'Payments Frequency',
    PaymentMethod: 'Payment Method',
    PaymentMethods: 'Payment Methods',
    PaymentMethodsWidget: 'Primary Method of Payment',
    PaymentOptions: 'Payment Options',
    PaymentRemaining: 'Payments Remaining',
    Payments: 'Payments',
    PaymentType: 'Payment Type *',
    PaymentTypeDetails: '{type} ending in {lastFour}',
    PayNow: 'Pay Now',
    PaytheTotalAmountFinanced: 'Pay the entire Amount Financed, any fees owed (such as Late Fees or Dishonored Payment Fees), and the earned Finance Charges up to $40',
    Pdf: 'PDF',
    Pending: 'Pending',
    PendingTransactions: 'Pending Transactions',
    PersonalInformation: 'Personal Information',
    Placeholder: 'placeholder',
    PlaceholderSearch: 'Search by name, topic or keyword.',
    PleaseCheckYourInbox: 'Please check your inbox to confirm your registration.',
    PleaseEnterAndConfirm: 'Please enter and confirm your new password.',
    PleaseEnterOnlyLetters: 'Please enter only letters',
    PleaseEnterOnlyNumbers: 'Please enter only numbers',
    PleaseEnterYourRegisterd: 'Please enter your registered email below to receive password reset instructions.',
    PleaseReviewYourProfileInformation: 'Please review your profile information. Click "Save" to continue',
    PleaseVerifyTheNewMobileNumber: 'Please verify the new Mobile Number',
    PrimaryMethodOfPayment: 'Primary Method of Payment',
    AutoPayMethodOfPayment: 'AutoPay Method of Payment',
    NoAutoPayMethodOfPayment: `AutoPay is currently disabled`,
    PrimaryMethodOfPaymentSuccessfullyUpdated: 'Primary Method of Payment successfully updated',
    Principal: 'Principal',
    PrivacyPolicy: 'Privacy Policy',
    Profile: 'Profile',
    ProfileSuccessfullyUpdated: 'Profile successfully updated',
    ProgramRebateFCP: '90-Day Finance Charge Cap Promotion',
    RebateProgramEndDateDays: 'Finance Charge Cap Promotion Days Left',
    RebateProgramEndDateDaysDetails: 'Your 90-day promotion expires TODAY! You have until 12pm PST to pay the account in full under the promotional terms.',
    RecentTransactions: 'Recent Transactions',
    Register: 'Register',
    RegisterNow: 'Register Now',
    RequestNewLink: 'Request New Link',
    Resend: 'Resend',
    ResetPassword: 'Reset Password',
    ResetPasswordSuccess: 'If an account exists for that email address, we will email you instructions for resetting your password',
    Return: 'Return',
    Returned: 'Returned',
    RoutingNumber: 'Routing Number',
    RoutingNumberMinCharacteres: 'Must be at least 9 characters',
    Save: 'Save',
    SaveAPaymentSuccess: 'You have added successfully a new make of payment',
    SecondaryMobileNumber: 'Secondary Number',
    SeeActivity: 'See Activity',
    SelectAccount: 'Select Account',
    SelectAPaymentDate: 'Select a Payment Date',
    SelectAPaymentDateDescription: 'Payments made after 1:30 PM PST today will post on the next business day.',
    SelectAPaymentDateDescriptionCalendar: 'If you pay before 1:30 PM PST, we’ll credit your account for the date you choose, but you might not see it for 1-2 days.',
    SelectAPaymentDateMinimunPastDueDescriptionCalendar: 'Payments scheduled after 1:30 PM PST can be scheduled for the next business day or later date.',
    SelectMethodOfPayment: 'Select Method of Payment',
    SelectPaymentAmount: 'Select Payment Amount',
    SelectPaymentDate: 'Select Payment Date',
    SelectPaymentMethod: 'Select Payment Method',
    SelectedPaymentMethod: 'Selected Payment Method',
    SelectPaymentDateStaticMessage: `If you pay before 1:30 PM PST, we'll credit your account for the date you choose, but you might not see it for 1-2 days.`,
    SelectPaymentDateAfterOnePmPSTStaticMessage: `Payments scheduled after 1:30 PM PST can be scheduled for the next business day or later date.`,
    SelectPaymentDatePendingStaticMessage: `As a reminder before making another payment, you have at least one pending payment due to post.`,
    SelectFromStoredPaymentMethods: 'Select from stored Payment Methods',
    Send: 'Send',
    SessionExpiredPleaseSignInAgain: 'Session expired. Please sign in again.',
    SessionExpiredPleaseSignInAgainWithCredentials: 'Session expired. Sign in again with your credentials.',
    SetAsPrimary: 'Set as Primary',
    SetAutoPayOn: 'AutoPay ON',
    SetAutoPayOff: 'AutoPay OFF',
    SetUpAutoPay: 'Set up AutoPay (Optional)',
    SignIn: 'Sign In',
    SimpleToQualifyYouHaveTo: 'Simple! To qualify you need to:',
    StartNow: 'Start Now',
    State: 'State',
    Statement: 'Statement',
    Statements: 'Statements',
    StoreVersion: `Version ${storeVersion}`,
    Submit: 'Submit',
    SubmitPayment: 'Submit Payment',
    Success: 'Success',
    SwipeEdit: 'Edit',
    SwipeInactivate: '- Inactivate',
    SwitchAccount: 'Switch Account',
    TermsAndConditions: 'Terms and Conditions',
    TermsOfUse: 'Terms of Use',
    TheUsernameOrPasswordIsInvalid: 'The username or password is invalid.',
    TitleAnother: 'Another',
    TitleOtherAmount: 'Other Amount',
    TitleCreateProfile: 'Create your profile',
    TitleMakingPayment: 'Making a Payment!',
    TitleMinimun: 'Minimum Payment',
    TitlePastDue: 'Past Due',
    TitleRebate: '90-Day Finance Charge Cap Promotion',
    TitleTodaysPay: 'Today’s Payoff',
    TitleChangeAutoPayPaymentMethod: 'Change AutoPay Payment Method',
    toAuthenticateOn: `to authenticate on ${appName}`,
    TodaysPayoff: 'Today’s Payoff ',
    TodaysPayoffDefinition: 'Includes Principal Balance, Interest and any fees owed',
    ToLearnMore: ' to learn more',
    ToReadOur: 'To read our',
    TotalLoanAmount: 'Total Loan Amount:',
    TransactionDetails: 'Transaction Details',
    Transactions: 'Transactions',
    Type: 'Type',
    UpdatePassword: 'Update Password',
    Use: 'Use',
    validPhoneNumber: 'Enter valid phone number',
    VerificationCode: 'Verification Code',
    VerifyNumber: 'Verify Number',
    VerifyYourAccount: 'Verify Your Account',
    View: 'View',
    ViewAll: 'View All',
    ViewAllTransactions: 'View All Transactions',
    ViewEcontract: 'View eContract',
    ViewStatements: 'View Statements',
    ViewMore: 'View More',
    WaiveFees: 'Waive Fees',
    WantToUpdateYouEmail: 'Want to update your email? Contact us to help you.',
    WarningPayment: 'As a reminder before making another payment, you have at least one pending payment due to post.',
    WeAreCurrentlyWorking: 'We are currently working on finalizing your account. If you do not see your account details within 48 hours, please feel free to give us a call: (833) 500-0603',
    WeAreProudToIntroduceBold: 'Financial Fundamentals.',
    WeAreProudToIntroduceEnd: 'A free-to-use financial education platform with a robust library of courses with topics ranging from saving for college to reducing debt to home ownership and planning for retirement.',
    WeAreProudToIntroduceStart: 'We are proud to introduce',
    WeDoNotHaveAnActiveAccount: 'We do not have an active account matching your personal information.',
    WeDoNotHaveAnActiveAccountDetails: 'Please contact (833) 500-0603 if you have any questions.',
    WeJustTextedYou: 'We Just Texted You',
    WeNeedSomeInformationToVerifyYourAccount: 'We need some information to verify your account',
    WeTakeDataSecurityVerySeriouslyPleaseReadOur: 'We take data security very seriously. Please read our ',
    WhenAutoPayIsOn: 'When AutoPay is on, we will run your selected method of payment',
    WillBeTheNewPrimaryMethodOfPayment: 'will be the new autopay method of payment',
    Year: 'Year',
    YouHaveNoRecentTransactions: 'You have no recent transactions.',
    YouHaveNoPendingTransactions: 'You have no pending transactions.',
    YouHaveAddedSuccessfullyNewMethodOfPayment: 'You have added successfully a new method of payment',
    YouHaveExceededTheNumberOfAttempts: 'You have exceeded the number of incorrect attempts. Please try again in 1 hour',
    YouHaveUpdatedSuccessfullyYourMethodOfPayment: 'You have updated successfully your method of payment',
    YourAccountHasBeenConfirmedSuccessfully: 'Your email has been verified!',
    YourDueDate: 'Your Due Date',
    YourEmailHasBeenRegistered: 'Your email has been registered',
    YourFileDownloadedSuccessfully: 'Your file downloaded successfully',
    YourPasswordHasBeenUpdatedSuccessfully: 'Your password has been updated successfully',
    ZipCode: 'Zip Code *',
    IsTodayPaidoffMessage: 'By selecting today’s payoff, you cannot edit your payment date. For further assistance, please contact Customer Support at (866) 438-8372.'
};
