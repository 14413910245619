import React from 'react';

import { BackToolbarIcon } from '@Components/BackToolbarIcon';
import Languages from '@i18n/index';
import { PdfScreen } from '@Pdf/screens/PdfScreen';
import { createStackNavigator } from '@react-navigation/stack';
import {
    colors,
    theme,
} from '@Theme/appTheme';
import { ProfileToolbarIcon } from '@Components/ProfileToolbarIcon';
import { ProfileScreen } from '@Profile/screens/ProfileScreen';
import { ProfileInfoScreen } from '../profile/screens/ProfileInfoScreen';
import { ApplicationsScreen } from '@Profile/screens/ApplicationsScreen';
import { FaqScreen } from '@Profile/screens/FaqScreen';
import { SignOutComponent } from '@Components/SignOutComponent';
import { ActivityMobileScreen } from '../activityMobile/screens/ActivityMobileScreen';
import { ActivityMobileAllScreen } from '@ActivityMobile/screens/ActivityMobileAllScreen';
import { ActivityMobileItemScreen } from '@ActivityMobile/screens/ActivityMobileItemScreen';

const Stack = createStackNavigator();

export const ActivityStack = () => (
    <Stack.Navigator
        screenOptions={({ navigation }) => ({
            headerRight: () => (
                <ProfileToolbarIcon onPress={
                    () => navigation.navigate('ProfileScreen', {})}
                />
            ),
            headerRightContainerStyle: {
                marginRight: 20,
                alignSelf: 'center',
            },
            headerTitleAlign: 'center',
            headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,
                backgroundColor: theme.colors.primary,
            },
            headerTitleStyle: {
                color: colors.white,
                alignSelf: 'center',
            },
            headerBackTitleStyle: {
                color: colors.white,
                alignSelf: 'center',
            },
            headerLeftContainerStyle: {
                alignSelf: 'center',
            },
            headerBackTitleVisible: false,
            headerTintColor: colors.white,
        })}
    >
        <Stack.Screen
            name="Activity"
            options={{ title: Languages.Activity }}
            component={ActivityMobileScreen}
        />
        <Stack.Screen
            name="ActivityAll"
            options={{ title: Languages.Transactions }}
            component={ActivityMobileAllScreen}
        />
        <Stack.Screen
            name="ActivityItem"
            options={{ title: Languages.TransactionDetails }}
            component={ActivityMobileItemScreen}
        />
        <Stack.Screen
            name="ProfileScreen"
            options={{
                title: Languages.Profile,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
                headerRight: () => <SignOutComponent/>
            }}
            component={ProfileScreen}
        />
        <Stack.Screen
            name="ProfileInfoScreen"
            options={{
                title: Languages.Profile,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
                headerRight: () => false,
            }}
            component={ProfileInfoScreen}
        />
        <Stack.Screen
            name="ApplicationsScreen"
            options={{
                title: Languages.Profile,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
                headerRight: () => false,
            }}
            component={ApplicationsScreen}
        />
        <Stack.Screen
            name="PdfScreen"
            options={{
                title: Languages.Pdf,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={PdfScreen}
        />
        <Stack.Screen
            name='FaqScreen'
            options={{
                title: Languages.FAQ,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={FaqScreen}
        />
    </Stack.Navigator>
);
