import { createRef } from 'react';

import { PartialState } from '@react-navigation/native';

export const navigationRef = createRef<any>();
export const routeNameRef = createRef<string>();
export const isReadyRef = createRef<boolean>();

export const navigate = (name: never | string, params: never | any) => {
    const navigateTimeout = setTimeout(() => {
        if (navigationRef.current && isReadyRef.current) {
            navigationRef.current.navigate(name, params);
        }
        clearTimeout(navigateTimeout);
    }, 0);
};

export const reset = (state: PartialState<any> | any) => {
    const resetTimeout = setTimeout(() => {
        if (navigationRef.current && isReadyRef.current) {
            navigationRef.current.reset(state);
        }
        clearTimeout(resetTimeout);
    }, 0);
};
export const goBack = () => {
    const goBackTimeout = setTimeout(() => {
        if (navigationRef.current && isReadyRef.current) {
            navigationRef.current.goBack();
        }
        clearTimeout(goBackTimeout);
    }, 0);
};
