import React from 'react';

import {
    Platform,
    StyleProp,
    Text,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';

import {
  colors,
  fonts
} from '@Theme/appTheme';

import {
    CheckBoxEnabled,
    CheckBoxDisabled,
} from '@Assets/index';
import { AppStyleSheet } from '@Utils/index';

interface Props {
  selected: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  title: string;
  onChange: (selected: boolean) => void;
}

export const CheckBox = ({
  selected,
  containerStyle,
  title,
  onChange 
}: Props) => {

  const checkBoxStyle = Platform.OS === 'web' ? styles.checkboxWeb : styles.checkboxMobile;
  
  return (
  <TouchableOpacity 
    style={containerStyle ? containerStyle : styles.checkboxContainer}
    onPress={() => onChange(!selected)}
    >
    {selected ? <CheckBoxEnabled style={checkBoxStyle}/> : <CheckBoxDisabled style={checkBoxStyle}/>}
    <Text style={styles.title}>{title}</Text> 
  </TouchableOpacity>);
};

const styles = AppStyleSheet({
  checkboxContainer: {
    // width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center'
  },
  title:{
    fontSize: 17,
    marginLeft:5,
    color: colors.grayColor,
    fontFamily: fonts.SFProText,
    width:130
  },
  checkboxMobile: {
    width: 20,
    height: 20,
    borderRadius: 5,
    borderColor: 'black',
  },
  checkboxWeb: {
    width: 20,
    height: 20,
    borderRadius: 5,
    borderColor: 'black',
  }
});