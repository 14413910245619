import React, { LegacyRef } from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';

import { AutoPayOnIcon, DeleteIcon, EditActiveSmallIcon } from '@Assets/index';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

interface Props {
    accessibilityLabel?: string;
    children: JSX.Element;
    disableDeleteAction?: boolean;
    disableEditAction?: boolean;
    hideDelete: Boolean;
    isSwipe: Boolean;
    item: any;
    onClickDelete: (item: any) => void;
    onClickEdit: (item: any) => void;
    onSwipe: (isSwipe: boolean) => void;
    onClickAutoPay: (item: any) => void;
    swipeableRef?: LegacyRef<any>;
    testID?: string;
};
export const AppSwipeable = ({
    accessibilityLabel = 'appSwipeable',
    children,
    disableDeleteAction,
    disableEditAction,
    hideDelete,
    item,
    onClickDelete,
    onClickEdit,
    onClickAutoPay,
    onSwipe = () => { },
    swipeableRef,
    testID = 'appSwipeable',
}: Props) => {

    const renderBtnAction = (
        title: string = '',
        Icon?: any,
        color: string = 'white',
        key?: string,
        onClick?: (item: any) => void,
        disable?: boolean,
    ) => (
        <TouchableOpacity
            accessibilityLabel={`${accessibilityLabel}${key}`}
            activeOpacity={0.7}
            disabled={disable}
            key={key}
            onPress={() => onClick && onClick(item)}
            style={[styles.actionBtn, {
                backgroundColor: disable ? `${color}60` : color,
                width: hideDelete ? '50%' : '33.33%',
            }]}
            testID={`${testID}${key}`}
        >
            <View style={styles.containerIcon}>
                {Icon && <Icon />}
                <Text style={[styles.textAction, { marginLeft: Icon && styles.textMarginLeft.marginLeft }]} >{title}</Text>
            </View>
        </TouchableOpacity>
    );

    const renderRightActions = () => (
        <View style={styles.containerSwipeActionRight}>
            {renderBtnAction('', AutoPayOnIcon, item.isPrimary ? colors.softRed : colors.blue, 'AutoPayOn', onClickAutoPay)}
            {!hideDelete && renderBtnAction('', DeleteIcon, colors.softRed, 'Delete', onClickDelete, disableDeleteAction)}
            {renderBtnAction('', EditActiveSmallIcon, theme.colors.primary, 'Edit', onClickEdit, disableEditAction)}
        </View>
    );

    return (
        <Swipeable
            ref={swipeableRef}
            renderRightActions={renderRightActions}
            onSwipeableRightOpen={() => onSwipe(true)}
            onSwipeableClose={() => onSwipe(false)}
        >
            {children}
        </Swipeable>
    );
};

const styles = AppStyleSheet({
    actionBtn: {
        height: '84%',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
    },
    containerIcon: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerSwipeActionRight: {
        width: '52.5%',
        flexDirection: 'row',
        height: '100%',
    },
    textAction: {
        color: colors.white,
        fontWeight: '600',
        fontFamily: fonts.SFProText,
        fontSize: 16,
        lineHeight: 20,
    },
    textMarginLeft: {
        marginLeft: 8,
    },
});
