import React from 'react';

import { AppEmptyState } from '@Components/AppEmptyState';

interface Props {
    accessibilityLabel?: string;
    children: JSX.Element;
    displayEmptyState: boolean;
    Icon?: any;
    testID?: string;
    title: string;
};

export const AppListEmptyState = ({
    accessibilityLabel = 'appListEmptyState',
    children,
    displayEmptyState,
    Icon = null,
    testID = 'appListEmptyState',
    title,
}: Props) => (
    <>
        {
            displayEmptyState
                ? <>
                    {children}
                </>
                : <AppEmptyState
                    accessibilityLabel={accessibilityLabel}
                    Icon={Icon}
                    testID={testID}
                    title={title}
                />
        }
    </>
);
