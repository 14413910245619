import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import { styles } from '@ActivityWeb/styles/ActivitiesStyles';
import Languages from '@i18n/index';
import { colors, fonts } from '@Theme/appTheme';
import { Entity, EntityStatus, StatusConfig } from '@Activity/interfaces';
import { useAppDispatch, useAppSelector } from '@Hooks/appHooks';
import { cancelPaymentPendingAction, getPaymentActivityAction, updatePaymentPending } from '@Activity/state/activityActions';
import { format } from 'date-fns';
import { PendingPayment } from '../../activity/interfaces/index';
import { AppButton } from '@Components/AppButton';
import { AppModalAlert } from '@Components/AppModalAlert';
import LoggerService from '@Logger/services/LoggerService';
import { AppCalendar, PressedDay } from '@Components/AppCalendar';
import { clearAllFormat } from '@Utils/FormatUtils';
import { ArrowLeftIcon } from '@Assets/index';

interface Status {
    [key: string]: StatusConfig;
};

const STATUS: Status = {
    [EntityStatus.Payment]: {
        text: Languages.Payment,
        pillColor: colors.verySoftBlue,
        textColor: colors.blue,
    },
    [EntityStatus.BouncedPayment]: {
        text: Languages.Payment,
        pillColor: colors.verySoftBlue,
        textColor: colors.blue,
    },
    [EntityStatus.GoodPayment]: {
        text: Languages.Payment,
        pillColor: colors.verySoftBlue,
        textColor: colors.blue,
    },
    [EntityStatus.NonCashAdjustment]: {
        text: Languages.NonCash,
        pillColor: colors.grayishCyan,
        textColor: colors.darkGrayishCyan,
    },
    [EntityStatus.ReturnedPayment]: {
        text: Languages.Returned,
        pillColor: colors.verySoftPink,
        textColor: colors.slightlyDesaturatedPink,
    },
    [EntityStatus.WaiveFees]: {
        text: Languages.WaiveFees,
        pillColor: colors.cyanLimeGreen,
        textColor: colors.darkCyanLimeGreen,
    },
    [EntityStatus.Pending]: {
        text: Languages.Pending,
        pillColor: colors.verySoftOrange,
        textColor: colors.softOrange,
    },
};

export const ActivityWebItemScreen = ({
    item,
    goBack,
}: { item: Entity | PendingPayment, goBack: () => void }) => {
    const dispatch = useAppDispatch();
    const { width: windowWidth } = useWindowDimensions();

    const isPending = item.status === EntityStatus.Pending;
    const [pendingItem, setPendingItem] = useState<PendingPayment>();
    const [paymentItem, setPaymentItem] = useState<Entity>();
    const [isCancelPending, setIsCancelPending] = useState<boolean>(false);
    const [dateSelected, setDateSelected] = useState('');
    const [isShowCalendar, setIsShowCalendar] = useState(false);

    useEffect(() => {
        if (isPending) {
            setPendingItem(item as unknown as PendingPayment);
        } else {
            setPaymentItem(item as unknown as Entity);
        }
    },[isPending])

    const details = useAppSelector(
        ({ activityReducer }) => activityReducer.paymentActivityDetails[item.id] || undefined,
    );

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        if (!details) {
            dispatch(getPaymentActivityAction({
                id: item.id,
            }));
        }
    }, [details]);

    const renderPaymentItem = (renderItem: Entity) => {
        return (
            <>
                <View style={[styles.activityGridRow, styles.activityGridRowAlt]}>
                    <View>
                        <Text style={styles.activityGridText}>
                            {Languages.PaymentDate}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.activityGridText}>
                            {format(new Date(renderItem.dateReceived), 'MMM dd, yyyy')}
                        </Text>
                    </View>
                </View>
                <View style={styles.activityGridRow}>
                    <View>
                        <Text style={styles.activityGridText}>
                            {Languages.PaymentMethod}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.activityGridText}>
                            {`${renderItem.paymentType} *${renderItem.paymentLastFourDigit}`}
                        </Text>
                    </View>
                </View>
                
                { details && details.filter(d => d.title.toLocaleLowerCase() !== 'amount received').map((detail, index: number) => (
                    <View key={index} style={[styles.activityGridRow, index % 2 === 0 && styles.activityGridRowAlt]}>
                        <View>
                            <Text style={styles.activityGridText}>
                                {detail.title}
                            </Text>
                        </View>
                        <View>
                            <Text style={[styles.activityGridText, detail.amount.includes('-$') && styles.activityReturnColor]}>
                                {detail.amount}
                            </Text>
                        </View>
                    </View>
                ))}
            </>
        );
    };

    const handleCancelPendingPayment = () => {
        setIsCancelPending(true);
    };

    const handleAccetpCancelPending = () => {
        if (pendingItem) {
            LoggerService.logEvent('canceledPayment', {
                date: pendingItem.date,
                methodType: pendingItem.paymentMethodType,
            });
            dispatch(cancelPaymentPendingAction({
                id: pendingItem.id,
                loanId: pendingItem.loanId,
            }));
            setIsCancelPending(false);
        }
    };

    const cancelPendingAlert = useMemo(() => (
        <AppModalAlert
            accessibilityLabel={'pendingAppModalAlertCancelPending'}
            handleAccept={handleAccetpCancelPending}
            handleCancel={() => setIsCancelPending(false)}
            cancelButtonText={Languages.NotNow}
            acceptButtonText={Languages.Cancel}
            destroyAction={true}
            show={isCancelPending}
            testID={'pendingAppModalAlertCancelPending'}
            title={Languages.CancelPendingQuestion.replace('{amount}', `${pendingItem ? pendingItem.amount : '$0.00'}`).replace('{date}', `${format(pendingItem ? new Date(pendingItem.date as string) : new Date(), 'MMM dd, yyyy')}`)}
        />

    ), [isCancelPending]);

    const handleEdit = () => {
        if (pendingItem) {
            const splitItemDate = pendingItem.date.split('/');
            setDateSelected(`${splitItemDate[2]}-${splitItemDate[0]}-${splitItemDate[1]}`);
            setIsShowCalendar(true);
        }
    };

    const handleResponsive = () => {
        return StyleSheet.create({
            buttons: {
                width: windowWidth < 968 ? '100%' : '49%',
                marginVertical: windowWidth < 968 ? 5 : 0,
                marginHorizontal: windowWidth < 968 ? 0 : 5
            },
            buttonsContainer: {
                justifyContent: windowWidth < 968 ? 'center' : 'flex-end',
                flexWrap: windowWidth < 968 ? 'wrap' : 'nowrap'
            },
            card: {
                width: windowWidth < 968 ? '100%' : '49%',
                maxWidth: windowWidth < 968 ? '100%' : 700,
            },
            mainContainer: {
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 20
            },
        });
    }

    const onChangeDate = (date: PressedDay) => {
        if (pendingItem) {
            setIsShowCalendar(false);
            dispatch(updatePaymentPending({
                amount: Number(clearAllFormat(pendingItem.amount)),
                id: pendingItem.id,
                loanId: pendingItem.loanId,
                paymentmethodId: pendingItem.paymentMethodId,
                paymentmethodType: pendingItem.paymentMethodType,
                runDate: date.date,
            }));
            setPendingItem({
                ...pendingItem,
                date: format(date.date, 'MMM dd, yyyy'),
            });
        }
    };

    const renderPendingItem = (renderItem: PendingPayment) => {
        if (!pendingItem) {
            return (<></>);
        }
        return (
            isShowCalendar && pendingItem ? (
                <View>
                    <AppCalendar
                        daySelected={dateSelected}
                        limitDayHour={pendingItem.isTodayPayoff ? 0 : 13}
                        limitDayMinutes={pendingItem.isTodayPayoff ? 0 : 30}
                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 3))}
                        onPressDay={onChangeDate}
                        selectToday={false}
                    />

                    <AppButton
                        accessibilityLabel={'pendingAppButtonCalendar'}
                        buttonStyle={styles.goBackBtn}
                        handleOnChange={() => setIsShowCalendar(false)}
                        testID={'pendingAppButtonCalendar'}
                        textStyle={styles.goBackBtnTitle}
                        title={Languages.Close}
                    />
                </View>
            ) : (
            <>
                <View style={[styles.activityGridRow, styles.activityGridRowAlt]}>
                    <View>
                        <Text style={styles.activityGridText}>
                            {Languages.PaymentDate}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.activityGridText}>
                            {format(new Date(pendingItem.date), 'MMM dd, yyyy')}
                        </Text>
                    </View>
                </View>
                <View style={styles.activityGridRow}>
                    <View>
                        <Text style={styles.activityGridText}>
                            {Languages.PaymentMethod}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.activityGridText}>
                            {`${renderItem.paymentMethodType} *${renderItem.paymentMethod.lastFourDigit}`}
                        </Text>
                    </View>
                </View>
                {renderItem.isTodayPayoff && (
                            <View style={{ marginTop: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <View style={styles.isTodayPaidoffMessage}>
                                    <Text style={styles.isTodayPaidoffText}>
                                        {`${Languages.IsTodayPaidoffMessage}`}
                                    </Text>
                                </View>
                            </View>
                        )}
                <View style={[styles.activityGridRow, styles.activityGridRowAlt, handleResponsive().buttonsContainer ]}>
                    <AppButton
                        accessibilityLabel={`pendingAppButtonItemEdit${item.id}`}
                        buttonStyle={[styles.buttonEdit, handleResponsive().buttons]}
                        handleOnChange={() => handleEdit()}
                        disabled={renderItem.isTodayPayoff}
                        testID={`pendingAppButtonItemEdit${renderItem.id}`}
                        textStyle={styles.buttonEditText}
                        title={Languages.EditPayment}
                    />
                    <AppButton
                        accessibilityLabel={`pendingAppButtonItemCancel${item.id}`}
                        buttonStyle={[styles.buttonCancel, handleResponsive().buttons]}
                        handleOnChange={() => handleCancelPendingPayment()}
                        testID={`pendingAppButtonItemCancel${item.id}`}
                        textStyle={styles.buttonCancelText}
                        title={Languages.CancelPayment}
                    />
                </View>
                {cancelPendingAlert}
            </>)
        );
    }
    return (
        <View style={[styles.activitiesContainer, handleResponsive().mainContainer]}>
            <View style={[styles.activityCard, handleResponsive().card]}>
                <View style={styles.activityCardTitle}>
                    <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row'}}).item]}>
                        <TouchableOpacity onPress={() => goBack()}>
                            <ArrowLeftIcon />
                        </TouchableOpacity>
                        <Text style={[styles.activityCardTitleText, StyleSheet.create({ item: { fontSize: 16, color: colors.grayColor, fontFamily: fonts.SFProTextSemibold, marginLeft: 10 }}).item]}>
                            {`${isPending ? Languages.Payment : paymentItem?.title}:`} <Text style={[StyleSheet.create({ item: { fontSize: 16, color: paymentItem?.paymentAmountColor, fontFamily: fonts.SFProTextSemibold}}).item]}>{isPending ? pendingItem?.amount : (item as Entity).paymentAmount}</Text>
                        </Text>
                    </View>
                    <View style={[styles.activityPaymentItemStatus, StyleSheet.create({ item: { backgroundColor: STATUS[item.status].pillColor }}).item]}>
                        <Text style={[styles.activityPaymentItemStatusText, StyleSheet.create({ item: { color: STATUS[item.status].textColor }}).item ]}>
                            {STATUS[item.status].text}
                        </Text>
                    </View>
                </View>
                <View style={styles.activityCardBody}>
                    <View style={styles.activityGrid}>
                        { isPending ? pendingItem && renderPendingItem(pendingItem as PendingPayment) : paymentItem && renderPaymentItem(paymentItem as Entity)}
                    </View>
                </View>
            </View>
        </View>
    );
}