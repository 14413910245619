import React, {
    useEffect,
    useState,
} from 'react';

import {
    Platform,
    SafeAreaView,
    StatusBar,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AlertRequest } from '@Alert/interfaces';
import AppAlertService from '@Alert/services/AppAlertService';
import { styles } from '@Alert/styles/AppAlertStyles';
import { TimesSvg } from '@Assets/index';
import { colors } from '@Theme/appTheme';

const statusBarSpace = Platform.OS === 'web' ? 25 : 50;

export const AppAlert = () => {
    const { top } = useSafeAreaInsets();
    const statusBar = StatusBar.currentHeight || 0;

    const [height, setHeight] = useState<number>(78);
    const [request, setRequest] = useState<AlertRequest>();
    const [isShowingStatic, setIsShowingStatic] = useState(false);

    let closeTimeout: any;
    useEffect(() => {
        const subscription = AppAlertService.subscribe((state: AlertRequest) => {
            if (isShowingStatic && !state.show && state.fixed) {
                setIsShowingStatic(false);
                setRequest(undefined);
                return;
            }
            if (isShowingStatic && state.show && !state.fixed) {
                return;
            }
            if (state.fixed && state.show) {
                setIsShowingStatic(true);
            } else {
                if (closeTimeout) {
                    clearTimeout(closeTimeout);
                }
                closeTimeout = setTimeout(() => {
                    setRequest(undefined);
                    clearTimeout(closeTimeout);
                }, 5000);
            }
            if (state.subtitle) {
                setRequest(state);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [isShowingStatic]);

    const getBackgroundColor = () => request?.backgroundColor || colors.limeGreen;

    const renderIcon = () => {
        if (request?.icon) {
            const { color, Icon } = request?.icon;
            return (
                <View style={styles.iconContainer}>
                    <Icon fill={color} />
                </View>
            );
        }
        return (
            <View style={styles.noIconContainer} />
        );
    };

    if (!request) {
        return null;
    };

    return (
        <>
            <SafeAreaView
                accessibilityLabel={'AppAlert'}
                nativeID={'AppAlert'}
                style={[
                    styles.floatContainer,
                    { backgroundColor: getBackgroundColor() },
                    { height: height + 40 },
                    { top: top + (Platform.OS === 'android' ? statusBar : statusBarSpace) },
                ]}
                testID={'AppAlert'}
            >
                <View style={styles.mainContainer}>
                    <View style={styles.dataContainer}>
                        {renderIcon()}
                        <View style={styles.infoContainer}>
                            <Text
                                accessibilityLabel={'AppAlertTitle'}
                                nativeID={'AppAlertTitle'}
                                style={styles.title}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                                testID={'AppAlertTitle'}
                            >{request.title}</Text>
                            <Text
                                accessibilityLabel={'AppAlertSubtitle'}
                                nativeID={'AppAlertSubtitle'}
                                onLayout={(event) => {
                                    const { height } = event.nativeEvent.layout;
                                    setHeight(height);
                                }}
                                style={styles.subtitle}
                                testID={'AppAlertSubtitle'}
                            >{request.subtitle}</Text>
                        </View>
                    </View>
                    {
                        !isShowingStatic && (
                            <TouchableOpacity
                                accessibilityLabel={'AppAlertCloseTouchable'}
                                onPress={() => setRequest(undefined)}
                                style={styles.timesContainer}
                                testID={'AppAlertCloseTouchable'}
                            >
                                <TimesSvg />
                            </TouchableOpacity>
                        )
                    }
                </View>
            </SafeAreaView>
        </>
    );
};
