import { createAction } from 'typesafe-actions';

import {
    Loan,
    SaveApplications,
    SelectableApplication,
} from '@Profile/interfaces';
import ApplicationsActionTypes from '@Profile/state/applicationsActionTypes';

export const downloadApplications = createAction(
    ApplicationsActionTypes.DOWNLOAD_APPLICATIONS_REQUEST
)();

export const getApplications = createAction(
    ApplicationsActionTypes.GET_APPLICATIONS_REQUEST
)();

export const saveApplications = createAction(
    ApplicationsActionTypes.SAVE_APPLICATIONS,
    (response: SaveApplications) => (response),
)();

export const saveApplicationSelected = createAction(
    ApplicationsActionTypes.SAVE_APPLICATION_SELECTED,
    (response: Loan) => (response),
)();

export const setSelectableApplications = createAction(
    ApplicationsActionTypes.SET_SELECTABLE_APPLICATIONS,
    (response: SelectableApplication[]) => (response),
)();

export const setApplicationSelected = createAction(
    ApplicationsActionTypes.SET_APPLICATION_SELECTED,
    (response: Loan | undefined) => (response),
)();

export const emptyAction = createAction(
    ApplicationsActionTypes.EMPTY,
)();

export const getDocumentAction = createAction(
    ApplicationsActionTypes.GET_DOCUMENT,
    (request: any) => (request),
)();

export const getDocumentArrayBufferAction = createAction(
    ApplicationsActionTypes.GET_DOCUMENT_ARRAY_BUFFER,
    (request: any) => (request),
)();
