import { Subject } from 'rxjs';

import { PrimaryMethodModalStateParams } from '../interfaces';

const PrimaryMethodModalService = () => {
    const subject = new Subject();

    const show = (params: PrimaryMethodModalStateParams) => {
        subject.next({
            params,
            open: true,
        });
    };

    const hide = () => {
        subject.next({
            open: false,
        });
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        hide,
        show,
        subscribe,
    };
};

export default PrimaryMethodModalService();
