import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import CurrencyInput from 'react-native-currency-input';

import { QuestionActiveIcon } from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { AppTextInput } from '@Components/AppTextInput';
import { AppTooltip } from '@Components/AppTooltip';
import { RadioButton } from '@Components/RadioButton';
import { ShimmerApp } from '@Components/ShimerApp';
import { useAppDispatch } from '@Hooks/appHooks';
import { useDebouncedValue } from '@Hooks/useDebouncedValue';
import Languages from '@i18n/index';
import { PaymentOptionsShimmerItem } from '@MakeAPayment/components/PaymentOptionsShimmerItem';
import {
    CurrentPaymentService,
    MakeAPaymentOption,
    MakeAPaymentOptionType,
} from '@MakeAPayment/interfaces';
import { resetLeftMenu } from '@MakeAPayment/state/MakeAPaymentActions';
import { styles } from '@MakeAPayment/styles/PaymentOptionsWebStyles.ts';
import {
    colors,
    theme,
} from '@Theme/appTheme';

import { PaymentProcessDialogScreen } from './PaymentProcessDialogScreen';

interface Props {
    payment?: CurrentPaymentService;
    onTapInfoPaymentOptions: () => void;
};

interface PropsItem {
    index: number;
    item: MakeAPaymentOption;
};

export const PaymentOptionsScreen = ({ payment }: Props) => {
    const dispatch = useAppDispatch();
    const [options, setOptions] = useState<MakeAPaymentOption[]>([]);
    const [newValue, setNewValue] = useState<number | null>(null);
    const [isShowError, setIsShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const amountRef = useRef<number>(0);
    const [isMakeAPayment, setIsMakeAPayment] = useState<boolean>(false);

    const { debouncedValue } = useDebouncedValue(newValue?.toString());

    useEffect(() => {
        handleOnDebouncedValue(parseFloat(debouncedValue));
    }, [debouncedValue]);

    const onClickItem = (index: any) => {
        const lastSelected: any = options.find((item: any) => item.selected);
        if (lastSelected) {
            const indexLastSelected = options.indexOf(lastSelected);
            options[indexLastSelected].selected = false;
        }
        options[index].selected = true;

        setNewValue(null);
        setIsShowError(false);
        setErrorMessage('');
        setOptions([
            ...options,
        ]);
    };

    const handleOnDebouncedValue = (value: number) => {
        if (value && payment?.payOffToday) {
            if (value > parseFloat(payment.payOffToday.replace(/[$,]/g, ''))) {
                setIsShowError(true);
                setErrorMessage(Languages.InvalidAmount);
            } else {
                setIsShowError(false);
                setErrorMessage('');
            }
        }
    };

    const handleRenderSeparator = () => (
        <View style={styles.itemSeparator} />
    );

    const shouldDisableButton = () => {
        const currentOption = options.find((item: MakeAPaymentOption) => item.selected);
        if (!currentOption) {
            return true;
        }
        if (currentOption?.isInput) {
            if (newValue && payment?.payOffToday) {
                if (newValue > parseFloat(payment.payOffToday.replace(/[$,]/g, ''))) {
                    return true;
                }
            }
            return !newValue;
        }
        return !currentOption?.selected;
    };

    const handleRenderItem = useMemo(
        () => ({ index, item }: PropsItem) => {
            if (item.isShowShimmer) {
                return (<PaymentOptionsShimmerItem />);
            } else {
                return (
                    <View
                        style={styles.viewPayment}
                        key={index}
                    >
                        <TouchableOpacity
                            accessibilityLabel={`paymentOptionsScreenTouchable${item.title.replace(/\s/g, '')}`}
                            disabled={!item.enabled}
                            onPress={() => onClickItem(index)}
                            style={styles.touchableOpacity}
                            testID={`paymentOptionsScreenTouchable${item.title.replace(/\s/g, '')}`}
                        >
                            <RadioButton
                                selected={item.selected}
                            />
                        </TouchableOpacity >
                        {
                            item.isInput ?
                                <View style={styles.viewIsInput}>
                                    <AppTextInput
                                        isFocused={item.selected}
                                        isError={isShowError}
                                        error={errorMessage}
                                    >
                                        <View style={styles.containerInputStyle}>
                                            <CurrencyInput
                                                accessibilityLabel={'paymentOptionsCurrencyInputOtherAmount'}
                                                delimiter=","
                                                editable={item.selected}
                                                onChangeValue={setNewValue}
                                                placeholder={'Other Amount'}
                                                placeholderTextColor={colors.darkGray}
                                                precision={2}
                                                minValue={0.01}
                                                maxValue={99999}
                                                prefix="$"
                                                separator="."
                                                style={styles.textInput}
                                                testID={'paymentOptionsCurrencyInputOtherAmount'}
                                                value={newValue}
                                            />

                                        </View>
                                    </AppTextInput>
                                </View>
                                :
                                <View style={styles.viewTextTitle}>
                                    <ShimmerApp
                                        visible={Boolean(payment)}
                                        contentStyle={styles.viewIsInput}
                                        shimmerStyle={styles.viewIsInput}
                                    >
                                        <Text style={styles.textTitle}>{item.title}</Text>
                                    </ShimmerApp>
                                    <AppTooltip
                                        Icon={QuestionActiveIcon}
                                        iconColor={theme.colors.primary}
                                        place='bottom'
                                    >
                                        <View style={styles.containerTooltip}>
                                            <Text style={styles.textTooltip}>{item.tooltipDescription}</Text>
                                        </View>
                                    </AppTooltip>
                                </View>
                        }
                        {!item.isInput &&
                            <View style={styles.viewAmount}>
                                <Text style={styles.textAmountPayment}>
                                    {item.amount}
                                </Text>
                            </View>
                        }
                    </View >
                );
            };

        }, [options, newValue, isShowError, errorMessage],
    );

    useEffect(() => {
        setOptions([
            {
                id: MakeAPaymentOptionType.MINIMUM_PAYMENT,
                selected: false,
                title: Languages.TitleMinimun,
                amount: `$${payment?.minimumPayment}`,
                isInput: false,
                tooltipDescription: Languages.DescriptionMinimumPayment,
                isShowShimmer: !payment,
                enabled: payment?.minimumPayment !== '0.00',
            },
            {
                id: MakeAPaymentOptionType.PAST_DUE,
                selected: false,
                title: Languages.TitlePastDue,
                amount: `$${payment?.pastDue}`,
                isInput: false,
                tooltipDescription: Languages.DescriptionPastDue,
                isShowShimmer: !payment,
                enabled: payment?.pastDue !== '0.00',
            },
            {
                id: MakeAPaymentOptionType.TODAYS_PAYOFF,
                selected: false,
                title: Languages.TitleTodaysPay,
                amount: `$${payment?.payOffToday}`,
                isInput: false,
                tooltipDescription: Languages.DescriptionTodayPayOff,
                isShowShimmer: !payment,
                enabled: payment?.payOffToday !== '0.00',
            },
            {
                id: MakeAPaymentOptionType.OTHER_AMOUNT,
                selected: false,
                title: Languages.TitleAnother,
                amount: '',
                isInput: true,
                isShowShimmer: !payment,
                enabled: payment?.payOffToday !== '0.00',
            },
        ]);
    }, [payment]);

    const obtainOptionSelected = (): MakeAPaymentOption | undefined => options.find((item: MakeAPaymentOption) => item.selected);

    const isTodayPayoff = (): boolean => obtainOptionSelected()?.id === MakeAPaymentOptionType.TODAYS_PAYOFF;

    const obtainAmount = () => {
        let amount = 0;
        const lastSelected = obtainOptionSelected();
        if (lastSelected?.isInput === true) {
            const newValueAsString = (newValue?.toFixed(2) as any).toString();
            amount = parseInt(newValueAsString.replace(/[$,.]/g, ''), 10);
        } else if (lastSelected?.amount) {
            amount = parseInt(lastSelected?.amount.replace(/[$,.]/g, ''), 10);
        }
        amountRef.current = amount;
    };

    const handleMakeAPayment = () => {
        dispatch(resetLeftMenu(false));
        obtainAmount();
        setIsMakeAPayment(true);
    };

    const handleCancel = () => setIsMakeAPayment(false);

    const footer = useCallback(() => (
        <View style={styles.footerPayment}>
            <ShimmerApp
                visible={Boolean(payment)}
                shimmerStyle={styles.buttonShimmer}
            >
                <AppButton
                    accessibilityLabel={'paymentOptionsScreenAppButtonMakeAPayment'}
                    buttonStyle={styles.buttonStyle}
                    disabled={shouldDisableButton()}
                    handleOnChange={handleMakeAPayment}
                    testID={'paymentOptionsScreenAppButtonMakeAPayment'}
                    title={Languages.MakeAPayment}
                />
            </ShimmerApp>
        </View>
    ), [shouldDisableButton]);

    return (
        <View style={styles.viewFlatlist}>
            <ShimmerApp
                visible={Boolean(payment)}
                shimmerStyle={styles.headerShimmer}
            >
                <Text style={styles.titlePayment}>{`${Languages.PaymentOptions} `}</Text>
            </ShimmerApp>
            <FlatList
                data={options}
                renderItem={handleRenderItem}
                keyExtractor={(item: MakeAPaymentOption) => item.id.toString()}
                showsVerticalScrollIndicator={false}
                scrollEnabled={false}
                ItemSeparatorComponent={handleRenderSeparator}
                ListFooterComponent={footer}
                style={styles.flatList}
            />
            <PaymentProcessDialogScreen
                amount={amountRef.current}
                dueDate={payment?.dueDate}
                handleCancel={handleCancel}
                isVisible={isMakeAPayment}
                optionSelected={obtainOptionSelected()}
                isTodayPayoff={isTodayPayoff()}
            />
        </View>
    );
};
