import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    exhaustMap,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    confirmAccountAPI,
    register,
    registerByEmail,
    registerByEmailBusinessCenterAPI,
    registerByEmailBusinessCenterValidateAPI,
    registerValidateVerificationCode,
    registerVerificationCode,
    registerVerificationCodeResendAPI,
} from '@Auth/api/registerApi';
import {
    PostConfirmAccountActionRequest,
    PostRegisterEmailBusinessCenterActionRequest,
    PostRegisterEmailBusinessCenterValidateActionRequest,
    PostRegisterEmailBusinessCenterValidateResponse,
    PostRegisterVerificationCodeResendActionRequest,
    RegisterByEmailRequestAction,
    RegisterRequestAction,
    ValidateVerificationCodeRequestAction,
    VerificationCodeRequestAction,
} from '@Auth/interfaces/index';
import RegisterBusinessCenterService from '@Auth/services/RegisterBusinessCenterService';
import RegisterService from '@Auth/services/RegisterService';
import RegisterActionTypes from '@Auth/state/registerActionTypes';
import Languages from '@i18n/index';
import {
    Error,
    Response,
} from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import ValidateVerficationCodeService
    from '@VerificationCodeDialog/service/ValidateVerficationCodeService';
import VerificationCodeService from '@VerificationCodeDialog/service/VerificationCodeService';

export const registerEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.REGISTER_REQUEST),
    delay(250),
    mergeMap(({ payload }: RegisterRequestAction) => from(register({
        ...payload,
    })).pipe(
        map((response: any) => {
            RegisterService.onSuccess(response);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const registerByEmailEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.REGISTER_EMAIL_REQUEST),
    delay(250),
    mergeMap(({ payload }: RegisterByEmailRequestAction) => from(registerByEmail({
        ...payload,
    })).pipe(
        map((response: any) => {
            RegisterService.onSuccess(response);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const registerVerificationCodeEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.REGISTER_VERIFICATION_CODE_REQUEST),
    delay(250),
    mergeMap(({ payload }: VerificationCodeRequestAction) => from(registerVerificationCode({
        ...payload,
    })).pipe(
        map((response: any) => {
            VerificationCodeService.onSuccess(response);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const registerValidateVerificationCodeEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.VALIDATE_VERIFICATION_CODE_REQUEST),
    delay(250),
    mergeMap(({ payload }: ValidateVerificationCodeRequestAction) => from(registerValidateVerificationCode({
        ...payload,
    })).pipe(
        map((response: any) => {
            ValidateVerficationCodeService.onSuccess(response);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            ValidateVerficationCodeService.onError(error);
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const confirmAccountEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.CONFIRM_ACCOUNT_REQUEST),
    delay(250),
    switchMap(({ payload }: PostConfirmAccountActionRequest) => from(confirmAccountAPI(payload))
        .pipe(
            map((response: any) => {
                AppAlertService.showSuccess({
                    message: Languages.YourAccountHasBeenConfirmedSuccessfully,
                });
                RegisterService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                RegisterService.onError(error);
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const registerByEmailBusinessCenterEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_REQUEST),
    delay(250),
    exhaustMap(({ payload }: PostRegisterEmailBusinessCenterActionRequest) => from(registerByEmailBusinessCenterAPI(payload))
        .pipe(
            map(() => {
                RegisterBusinessCenterService.registration();
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const registerByEmailBusinessCenterValidateEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE_REQUEST),
    delay(250),
    switchMap(({ payload }: PostRegisterEmailBusinessCenterValidateActionRequest) => from(registerByEmailBusinessCenterValidateAPI(payload))
        .pipe(
            map((response: PostRegisterEmailBusinessCenterValidateResponse) => {
                RegisterBusinessCenterService.validation(response.email);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                RegisterBusinessCenterService.onError(error);
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const registerVerificationCodeResendEpic = (action$: Observable<any>) => action$.pipe(
    ofType(RegisterActionTypes.REGISTER_VERIFICATION_CODE_RESEND_REQUEST),
    delay(250),
    switchMap(({ payload }: PostRegisterVerificationCodeResendActionRequest) => from(
        registerVerificationCodeResendAPI(payload),
    ).pipe(
        map((response: Response) => {
            RegisterService.onValidation(response);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            RegisterService.onError(error);
            LoggerService.logError(error);
            return of(loadingDialogHide());
        })
    )),
);
