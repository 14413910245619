import React from 'react';

import {
    SafeAreaView,
    View,
} from 'react-native';

import {
    EasypayLogoGreenSvg,
    EasypayTypographyLogoSvg,
} from '@Assets/index';
import { styles } from '@Auth/styles/WelcomeStyle';
import { AppButton } from '@Components/AppButton';
import Languages from '@i18n/index';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';

interface Props extends StackScreenProps<RootStackParamList, 'WelcomeScreen'> { }

export const WelcomeScreen = ({
    navigation,
}: Props) => (
    <SafeAreaView style={styles.container} >
        <View style={styles.containerGeneral}>
            <View style={styles.imgContainer}>
                <EasypayLogoGreenSvg
                    width={125}
                    height={125}
                />
                <View style={styles.typographyLogo}>
                    <EasypayTypographyLogoSvg
                        width={167}
                        height={47}
                    />
                </View>
            </View>
            <View style={styles.buttonsContainer}>
                <AppButton
                    accessibilityLabel={'welcomeScreenAppButtonSignIn'}
                    buttonStyle={styles.marginBottomSignIn}
                    handleOnChange={() => navigation.navigate('LoginScreen', {})}
                    testID={'welcomeScreenAppButtonSignIn'}
                    textStyle={styles.textBtnStyle}
                    title={Languages.SignIn}
                />
                <AppButton
                    accessibilityLabel={'welcomeScreenAppButtonRegister'}
                    buttonStyle={styles.btnRegisterStyle}
                    handleOnChange={() => navigation.navigate('RegisterScreen', {})}
                    testID={'welcomeScreenAppButtonRegister'}
                    textStyle={styles.textBtnStyleRegister}
                    title={Languages.Register}
                />
            </View>
        </View>
    </SafeAreaView>
);
