import {
    Error,
    Response,
} from '@Interfaces/BaseResponse';

export interface AuthenticateRequest {
    email: string;
    password: string;
};

export interface AuthenticateResponse extends Response {
    accessToken: string;
    refreshToken: string;
};

export interface AuthenticateState { };

export interface AuthenticateRequestAction {
    payload: AuthenticateRequest;
};

export interface AuthenticateSuccessAction {
    payload: AuthenticateResponse;
};

export interface AuthenticateErrorAction {
    payload: Error;
};

export interface AuthenticateRefreshTokenRequest {
    refreshToken: string;
};

export interface AuthenticateRefreshTokenRequestAction {
    payload: AuthenticateRefreshTokenRequest;
};

export interface RegisterState { };

export interface ForgotPassswordState {
    isSendCodePhone: Boolean;
    isSendCodeEmail: Boolean;
    error: string;
};

export interface RegisterRequest {
    phone: string;
    password: string;
    verificationCode: string;
};

export interface RegisterByEmailRequest {
    email: string;
    password: string;
};

export interface ValidateVerificationCodeRequest {
    context: string;
    owner: string;
    value: string;
};

export interface RegisterRequestAction {
    payload: RegisterRequest;
};

export interface RegisterByEmailRequestAction {
    payload: RegisterByEmailRequest;
};

export interface RegisterSuccessAction {
    payload: Response;
};

export interface RegisterErrorAction {
    payload: Error;
};

export interface VerificationCodeRequest {
    phone: string;
};

export interface VerificationCodeResponse {
    verificationCode: string;
};

export interface VerificationCodeRequestAction {
    payload: VerificationCodeRequest;
};

export interface ValidateVerificationCodeRequestAction {
    payload: ValidateVerificationCodeRequest;
};

export interface VerificationCodeSuccessAction {
    payload: Response;
};

export interface VerificationCodeErrorAction {
    payload: Error;
};

export interface LoginParams {
    autoLogin?: boolean;
    username?: string;
    password?: string;
};

export interface InternalLoginParams {
    token: string;
};

export interface SendCodePhoneNumberRequest {
    phoneNumber: string;
};
export interface SendCodeEmailRequest {
    email: string;
};

export interface SendCodePhoneNumberRequestAction {
    payload: SendCodePhoneNumberRequest;
};

export interface SendCodeEmailRequestAction {
    payload: SendCodeEmailRequest;
};

export interface SendErrorCode {
    payload: Error;
};

export interface FirstTimeUser {
    email: string;
    isFirstTime: boolean;
    wasReset: boolean;
};

export interface UpdatePasswordRequest {
    requester: string;
    verificationCode: string;
    password: string;
};

export interface UpdatePasswordEmailRequestAction {
    payload: UpdatePasswordRequest;
};

export interface UpdatePasswordPhoneRequestAction {
    payload: UpdatePasswordRequest;
};

export interface UpdatePasswordResponse extends Response {

};

export interface PostConfirmAccountRequest {
    email: string;
    verificationCode: string;
};

export interface PostConfirmAccountResponse extends Response {

};

export interface PostConfirmAccountActionRequest {
    payload: PostConfirmAccountRequest;
};

export interface RegisterBusinessCenterParams {
    content: string;
    email: string;
};

export interface PostRegisterEmailBusinessCenterRequest {
    content: string;
    password: string;
};

export interface PostRegisterEmailBusinessCenterActionRequest {
    payload: PostRegisterEmailBusinessCenterRequest;
};

export interface PostRegisterEmailBusinessCenterValidateRequest {
    content: string;
};

export interface PostRegisterEmailBusinessCenterValidateActionRequest {
    payload: PostRegisterEmailBusinessCenterValidateRequest;
};

export interface PostRegisterEmailBusinessCenterValidateResponse extends Response {
    email: string;
};

export interface PostRegisterVerificationCodeResendRequest {
    email: string;
};

export interface PostRegisterVerificationCodeResendActionRequest {
    payload: PostRegisterVerificationCodeResendRequest;
};

export enum InputType {
    password = 'password',
    confirmPassword = 'confirmPassword'
};

export interface AuthenticateInternalRequest {
    token: string;
};

export interface AuthenticateInternalActionRequest {
    payload: AuthenticateInternalRequest;
};
