import React from 'react';
import { ProfileTabIconSvg } from '@Assets/index';
import { BadgeComponent } from '@Components/BadgeComponent';
import { ToolbarIcon } from '@Components/ToolbarIcon';
import { theme } from '@Theme/appTheme';

interface Props {
    onPress: () => void;
};

export const ProfileToolbarIcon = ({ onPress }: Props) => {
    const handleOnPress = () => onPress();        

    return (
        <BadgeComponent
            text={undefined}
        >
            <ToolbarIcon
                accessibilityLabel={'toolbarIconTouchableNotification'}
                onPress={handleOnPress}
                renderIcon={() => <ProfileTabIconSvg fill={theme.colors.text}
                width={22}
                height={22} />}
                testID={'toolbarIconTouchableNotification'}
            />
        </BadgeComponent>
    );
};
