import AuthApi from '@Api/authApi';
import { Commands } from '@Api/config';
import { UpdatePasswordRequest } from '@Auth/interfaces';

export const sendCodePhoneNumberAPI = async (phone: string) => new Promise(async (resolve, rejected) => {
    try {
        await AuthApi.post(Commands.FORGOT_PASSWORD_PHONE_NUMBER, {
            phone,
        });
        resolve({
            success: true,
        });
    } catch (error) {
        rejected(error);
    }
});

export const sendCodeEmailAPI = async (email: string) => new Promise(async (resolve, rejected) => {
    try {
        await AuthApi.post(Commands.FORGOT_PASSWORD_EMAIL, {
            email,
        });
        resolve({
            success: true,
        });
    } catch (error) {
        rejected(error);
    }
});

export const updatePasswordPhone = async (request: UpdatePasswordRequest) => new Promise(async (resolve, rejected) => {
    try {
        await AuthApi.post(Commands.UPDATE_PASSWORD_PHONE, request);
        resolve({
            success: true,
        });
    } catch (error) {
        rejected(error);
    }
});

export const updatePasswordEmail = async (request: UpdatePasswordRequest) => new Promise(async (resolve, rejected) => {
    try {
        await AuthApi.post(Commands.UPDATE_PASSWORD_EMAIL, request);
        resolve({
            success: true,
        });
    } catch (error) {
        rejected(error);
    }
});
