import * as React from 'react';
import { useEffect, useState } from 'react';

import {
    Platform,
    Text,
    View,
} from 'react-native';

import { PaymentStatement } from '@Activity/interfaces';
import DocumentStatementsService from '@Activity/services/DocumentStatementsService';
import { styles } from '@Activity/styles/PaymentStatementItemStyle';
import { AppButton } from '@Components/AppButton';
import { useAppDispatch } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import { useNavigation } from '@react-navigation/native';
import { format } from 'date-fns';
import { getDocumentNewStatement } from '../state/statementActions';

interface Props {
    item: PaymentStatement;
};

const isNotWeb = Platform.OS !== 'web';

export const NewStatementItem = ({ item }: Props) => {
    const dispatch = useAppDispatch();
    const navigation = useNavigation();
    const [openTab, setOpenTab] = useState(false); 
    const [pdfData, setPdfData] = useState(''); 

    useEffect(() => {
        const documentStatementSubscriber = DocumentStatementsService.subscribe((data: string) => {
            console.log('----Document', data);
            setPdfData(data);
        });
        return () => {
            documentStatementSubscriber.unsubscribe();
        };
    }, []);

    const handledGetDocument = (id: number) => {
        setOpenTab(true);

        dispatch(getDocumentNewStatement({
            id,
        }));
    };

    useEffect(() => {
        if(!openTab)
            return;
        navigationPdf(pdfData);
    }, [pdfData])

    const navigationPdf = (data: string) => {

        if (isNotWeb) {
            navigation.navigate('PdfScreen', { base64: data });
            return;
        };

        if(!openTab)
            return;
        setOpenTab(false);

        var pdfData = `data:application/pdf;base64,${data}`;
        var w = window.open("");
        if (w) {
            w.document.write(`<iframe width="100%" height="100%" src="${pdfData}" type="application/pdf" />`);
        } else {
            console.error("Error opening PDF in new tab:","Failed to open a new tab.");
        }

    };

    const getTitleLabel = (timestamp:string) =>
     {
        const date = new Date(timestamp);
        const month = format(date, 'MMMM');
        return month.concat(' ', Languages.Statement)
     }

    return (
        <View style={styles.container}>
            <View>
                <Text style={styles.titleText}>{getTitleLabel(item.timestamp)}</Text>
                <Text style={styles.dateText}>{format(new Date(item.timestamp), 'MMM dd, yyyy')}</Text>
            </View>

            <AppButton
                accessibilityLabel={`statementsAppButtonGetDocument${item.id}`}
                buttonStyle={styles.containerButton}
                handleOnChange={() => handledGetDocument(item.id)}
                testID={`statementsAppButtonGetDocument${item.id}`}
                title={Languages.View}
            />
        </View>
    );
};
