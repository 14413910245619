import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    containerCardInfo: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // width: '85%',
        // flexWrap: 'wrap'
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    containerPaymentMethod: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderColor: 'transparent',
        borderRadius: 10,
        borderWidth: 3,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 16,
        paddingHorizontal: 10,
        paddingVertical: 10,
        // marginHorizontal: 8,
        // width: '98%',
    },
    textBankName: {
        fontFamily: fonts.SFProTextMedium,
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15.51,
        textAlign: 'center',
        // width: 162,
    },
    textExpireDate: {
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontWeight: '400',
        lineHeight: 15.51,
        textAlign: 'right',
    },
    textLastFour: {
        fontFamily: fonts.Roboto,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 17.58,
        textAlign: 'left',
    },
});
