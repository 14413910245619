import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    GridContextProvider,
    GridDropZone,
    GridItem,
} from 'react-grid-drag';
import {
    Platform,
    ScrollView,
    Text,
    useWindowDimensions,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    MethodOfPayment,
    QuestionActiveIcon,
} from '@Assets/index';
import { AppModalAlert } from '@Components/AppModalAlert';
import { AppTooltip } from '@Components/AppTooltip';
import { ShimmerApp } from '@Components/ShimerApp';
import { useAppDispatch } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { PaymentMethodsDropShimmer } from '@MethodsOfPayment/components/PaymentMethodsDropShimmer';
import PaymentMethodsDropZoneItem from '@MethodsOfPayment/components/PaymentMethodsDropZoneItem';
import {
    PaymentMethodResponseBase,
    TypesMethod,
} from '@MethodsOfPayment/interfaces';
import MethodsOfPaymentService from '@MethodsOfPayment/services/MethodsOfPaymentService';
import PaymentOfMethodService from '@MethodsOfPayment/services/PaymentOfMethodService';
import PrimaryMethodModalService from '@MethodsOfPayment/services/PrimaryMethodModalService';
import {
    deleteCard,
    deleteCheck,
    getPaymentMethodsAction,
    patchPaymentSetPrimaryAction,
} from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import {
    dropZoneStyles,
    noAutoPayStyle,
    noAutoPayTextStyle,
    styles,
} from '@MethodsOfPayment/styles/PaymentMethodsDropZoneStyle';
import { Loan } from '@Profile/interfaces';
import { theme } from '@Theme/appTheme';
import { getPaymentMethodAutoPayAction, setPaymentMethodAutoPayAction } from '../state/MethodsOfPaymentActions';
import RefreshMethodsOfPaymentService from '@MethodsOfPayment/services/RefreshMethodsOfPaymentService';

type ItemSide = 'left' | 'right';
const TextBold = ({ children }: any) => <Text style={styles.textBoldCustom}>{children}</Text>;
interface Item {
    type: string;
    id: number;
    lastFourDigit: string;
}

interface Props {
    loan?: Loan;
    isAutoPay?: boolean;
    onEdit?: (paymentMethod: PaymentMethodResponseBase) => void;
    onAutoPayOff?: (paymentMethod: PaymentMethodResponseBase) => void;
};

interface Item {
    type: string;
    id: number;
    lastFourDigit: string;
}

const isWeb = Platform.OS === 'web';

export const PaymentMethodsDropZone = ({ loan: loanSelected, isAutoPay, onEdit = () => { }, onAutoPayOff = () => {} }: Props) => {
    const dispatch = useAppDispatch();

    const { width: windowWidth } = useWindowDimensions();
    const [leftPaymentMethods, setLeftPaymentMethods] = useState<PaymentMethodResponseBase[]>([]);
    const [rightPaymentMethods, setRightPaymentMethods] = useState<PaymentMethodResponseBase[]>([]);
    const [isAppAlertShown, setIsAppAlertShown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [objectAlert, setObjectAlert] = useState<Item>();

    useEffect(() => {
        mayGetPaymentMethods();
        setRightPaymentMethods(new Array(4).fill(
            {
                active: true,
                bank: '',
                expirationDate: '',
                id: -1,
                isPrimary: false,
                lastFourDigit: '',
                loanId: -1,
                nickname: '',
                note: '',
                paymentNetwork: '',
                routingNumber: '',
                selected: false,
                token: '',
                type: '',
                isShowShimmer: true,
            }));
        setLeftPaymentMethods([{
            active: true,
            bank: '',
            expirationDate: '',
            id: -1,
            isPrimary: false,
            lastFourDigit: '',
            loanId: -1,
            nickname: '',
            note: '',
            paymentNetwork: '',
            routingNumber: '',
            selected: false,
            token: '',
            type: '',
            isShowShimmer: true,
        }]);
    }, []);

    useEffect(() => {
        const methodsSubs = MethodsOfPaymentService.subscribe((state: PaymentMethodResponseBase[]) => {
            if (state.length) {
                fillPaymentMethods(state);
            }
            setIsLoading(false);
        });
        const paymentMethodService = PaymentOfMethodService.subscribe(() => {
            mayGetPaymentMethods();
        });
        const refreshPayments = RefreshMethodsOfPaymentService.subscribe(() => {
            mayGetPaymentMethods();
        })
        return () => {
            refreshPayments.unsubscribe();
            methodsSubs.unsubscribe();
            paymentMethodService.unsubscribe();
        };
    }, [loanSelected]);

    const mayGetPaymentMethods = () => {
        if (loanSelected?.loanId) {
            const {
                loanId,
            } = loanSelected;
            dispatch(getPaymentMethodsAction(loanId));
        }
    };

    const fillPaymentMethods = (state: PaymentMethodResponseBase[]) => {
        const paymentMethods = [...state];
        const primaryMethod: PaymentMethodResponseBase[] = paymentMethods.filter(pm => !!pm.isPrimary);
        const additionalMethods: PaymentMethodResponseBase[] = paymentMethods.filter(pm => !pm.isPrimary);

        setLeftPaymentMethods(primaryMethod.length > 0 ? primaryMethod : []);
        setRightPaymentMethods(additionalMethods);
    };

    const deletePaymentMethod = (type: string, id: number) => {
        if (type === TypesMethod.CREDITCARD || type === TypesMethod.DEBITCARD) {
            LoggerService.logEvent('deleteCard', {
                type,
            });
            dispatch(deleteCard(id));
        } else {
            LoggerService.logEvent('deleteACH', {});
            dispatch(deleteCheck(id));
        }
    };

    const handleOnChangeGridDrag = (_: string, sourceIndex: number, targetIndex: number, targetId?: string) => {
        if (targetId) {
            const currentLeft = { ...leftPaymentMethods[0] };
            let currentRights = [...rightPaymentMethods];
            const newLefts = new Array(1).fill(rightPaymentMethods[sourceIndex]);
            if (leftPaymentMethods.length > 0) {
                currentRights[sourceIndex] = currentLeft;
            } else {
                currentRights = [...currentRights.filter((x, idx) => idx !== sourceIndex)];
            }
            PrimaryMethodModalService.show({
                lastFourDigits: `* ${newLefts[0].lastFourDigit}`,
                paymentNetwork: newLefts[0].paymentNetwork,
                onTapAcceptButton: () => {
                    AppAlertService.showSuccess({
                        message: Languages.PrimaryMethodOfPaymentSuccessfullyUpdated,
                    });
                    setLeftPaymentMethods(newLefts);
                    setRightPaymentMethods(currentRights);
                    dispatch(patchPaymentSetPrimaryAction({
                        loanId: loanSelected?.loanId || 0,
                        paymentMethodId: newLefts[0].id,
                        paymentMethodType: newLefts[0].type,
                    }));

                    dispatch(setPaymentMethodAutoPayAction({ autopay: newLefts.length > 0 }));
                },
            });
        }
    };

    const renderAppAlert = useMemo(() => {
        if (objectAlert) {
            return (
                <AppModalAlert
                    accessibilityLabel={'methodsOfPaymentAppModalInactive'}
                    handleAccept={() => {
                        deletePaymentMethod(objectAlert.type, objectAlert.id);
                        setIsAppAlertShown(false);
                    }}
                    handleCancel={() => setIsAppAlertShown(false)}
                    show={isAppAlertShown}
                    subtitle={`${objectAlert.lastFourDigit}`}
                    testID={'methodsOfPaymentAppModalInactive'}
                    title={Languages.DoYouReallyWantToInactivatePaymentMethod}
                />
            );
        }
    }, [isAppAlertShown]);

    const handleOnEdit = (item: PaymentMethodResponseBase) => onEdit(item);

    const handleOnInactivate = (item: PaymentMethodResponseBase) => {
        setIsAppAlertShown(true);
        setObjectAlert({
            type: item.type,
            id: item.id,
            lastFourDigit: item.lastFourDigit,
        });
    };

    const handleOnSetAsPrimary = (index: number) => handleOnChangeGridDrag('right', index, 2, 'left');
    const handleAutoPayTurnOff = async (item: PaymentMethodResponseBase) => {
        await onAutoPayOff(item);
        mayGetPaymentMethods();
    };

    const renderGridItem = (index: number = 1, item: PaymentMethodResponseBase, side: ItemSide) => (
        <GridItem key={index}>
            {item.isShowShimmer ?
                <PaymentMethodsDropShimmer />
                :
                <PaymentMethodsDropZoneItem
                    index={index}
                    item={item}
                    loanSelected={loanSelected}
                    onEdit={handleOnEdit}
                    onInactivate={handleOnInactivate}
                    onSetAsPrimary={handleOnSetAsPrimary}
                    onAutoPayTurnOff={handleAutoPayTurnOff}
                    style={[
                        side === 'right' ? styles.itemRight : styles.itemLeft,
                        (index % 2 !== 0 && windowWidth > 1350) && styles.itemRightMargin,
                    ]}
                />
            }
        </GridItem>
    );

    const renderAutoPayColumn = () => {
        if (leftPaymentMethods.length === 0) {
            return (
                <GridItem key={0} style={noAutoPayStyle}>
                    <Text style={noAutoPayTextStyle}>{Languages.NoAutoPayMethodOfPayment}</Text>
                </GridItem>
            )
        } else {
            return renderGridItem(999, leftPaymentMethods[0], 'left');
        }
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <GridContextProvider onChange={handleOnChangeGridDrag}>
                <View style={[styles.container, windowWidth < 1350 && { flexDirection: 'column' }]}>
                    <GridDropZone
                        className="dropzone left"
                        id='left'
                        boxesPerRow={1}
                        rowHeight={50}
                        disableDrag
                        style={{...dropZoneStyles.dropZoneLeft, minHeight: leftPaymentMethods.length === 0 ? 120 : 200}}
                    >
                        <View style={styles.primaryContainer}>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                shimmerStyle={styles.cardShimmerPrimaryText}
                            >
                                <Text
                                    style={styles.primaryText}
                                    numberOfLines={1}
                                    ellipsizeMode={'tail'}
                                >
                                    {Languages.AutoPayMethodOfPayment}
                                </Text>
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(!isLoading)}
                                contentStyle={styles.cardShimerAppButton}
                                shimmerStyle={styles.cardShimerAppButton}
                            >
                                {
                                    !isWeb && (<AppTooltip
                                        Icon={QuestionActiveIcon}
                                        iconColor={theme.colors.primary}
                                        place='bottom'
                                    >
                                        <View style={styles.containerTooltip}>
                                            <View style={styles.containerIconTooltip}>
                                                <MethodOfPayment />
                                            </View>
                                            <Text style={styles.descriptionDragAndDrop}>
                                                {Languages.DragAndDropTheFirst}
                                                <TextBold>{Languages.PaymentMethods}</TextBold>
                                                {Languages.DragAndDropSecond}
                                            </Text>
                                        </View>
                                    </AppTooltip>)
                                }
                            </ShimmerApp>
                        </View>
                        {

                        }
                        {renderAutoPayColumn()}
                    </GridDropZone>
                    <GridDropZone
                        className="dropzone right"
                        id='right'
                        boxesPerRow={windowWidth < 1350 ? 1 : 2}
                        rowHeight={140}
                        disableDrag
                        style={{...dropZoneStyles.dropZoneRight, marginTop: windowWidth > 500 ? 0 : 35, marginLeft: windowWidth > 500 ? 10 : 0 }}
                    >
                        {
                            rightPaymentMethods.length > 0 && rightPaymentMethods.map((item, index) => (
                                renderGridItem(index, item, 'right')
                            ))
                        }
                    </GridDropZone>
                </View>
            </GridContextProvider>
            {renderAppAlert}
        </ScrollView>
    );
};
