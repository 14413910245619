import React from 'react';

import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import TermsOfUseService from '@Dialogs/services/TermsOfUseService';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import Languages from '@i18n/index';
import { navigate } from '@Navigation/RootNavigation';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { linksApp } from '@Utils/index';

export const Footerbar = () => {
    const isMobile = useCheckMobileScreen();

    const onClickName = () => {
        navigate('FaqScreen', { from: 'customer' });
    };

    const handleOnOpenTermsOfUse = () => TermsOfUseService.showDialog();

    const handleOnOpenPrivacyPolicy = () => window.open(linksApp.privacyPolicy, '_blank');

    return (
        <>
            {
                !isMobile && (
                    <View style={styles.container}>
                        <View style={styles.viewInfo}>
                            <Text style={styles.text}
                                ellipsizeMode={'middle'}
                                numberOfLines={2}
                            >{Languages.Fax}</Text>
                            <View style={styles.lineWhite}></View>
                            <Text style={styles.text}
                                ellipsizeMode={'middle'}
                                numberOfLines={2}
                            >{Languages.MailingAdress}</Text>
                            <View style={styles.lineWhite}></View>
                            <TouchableOpacity
                                accessibilityLabel={'footerBarTouchableCustomerService'}
                                onPress={onClickName}
                                testID={'footerBarTouchableCustomerService'}
                            >
                                <Text style={styles.text}
                                    ellipsizeMode={'middle'}
                                    numberOfLines={2}
                                >{Languages.FAQs}</Text>
                            </TouchableOpacity>
                            <View style={styles.lineWhite}></View>
                            <Text style={styles.text}
                                ellipsizeMode={'middle'}
                                numberOfLines={2}
                            >{Languages.EasyPay}</Text>
                            <View style={styles.lineWhite}></View>
                            <TouchableOpacity
                                accessibilityLabel={'footerBarTouchablePrivacyPolicy'}
                                onPress={handleOnOpenPrivacyPolicy}
                                testID={'footerBarTouchablePrivacyPolicy'}
                            >
                                <Text style={styles.text}
                                    ellipsizeMode={'middle'}
                                    numberOfLines={2}
                                >{Languages.PrivacyPolicy}</Text>
                            </TouchableOpacity>
                            <View style={styles.lineWhite}></View>
                            <TouchableOpacity
                                accessibilityLabel={'footerBarTouchableTermsOfUse'}
                                onPress={handleOnOpenTermsOfUse}
                                testID={'footerBarTouchableTermsOfUse'}
                            >
                                <Text style={styles.text}
                                    ellipsizeMode={'middle'}
                                    numberOfLines={2}
                                >{Languages.TermsOfUse}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                )
            }
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: theme.colors.primary,
        flexDirection: 'column',
        height: 40,
        justifyContent: 'space-between',
        paddingHorizontal: 4,
    },
    lineWhite: {
        backgroundColor: colors.white,
        height: 15,
        width: 1,
    },
    text: {
        color: colors.white,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 17,
        paddingHorizontal: 15,
    },
    viewInfo: {
        alignItems: 'center',
        flex: 100,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
});
