import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        flex: 100,
        backgroundColor: colors.white,
    },
    containerSearch: {
        height: 45,
        borderColor: theme.colors.border,
        flex: 1,
    },
    flatList: {
        flexDirection: 'row',
        marginHorizontal: 12,
    },
    icon: {
        paddingRight: 5,
    },
    iconContainer: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    listFooter: {
        marginBottom: 50,
    },
    principalView: {
        justifyContent: 'flex-start',
        backgroundColor: colors.white,
        flexWrap: 'wrap',
    },
    questionList: {
        paddingTop: 8,
        backgroundColor: colors.white,
        flexWrap: 'wrap',
    },
    textHelp: {
        fontFamily: fonts.SFProText,
        fontSize: 18,
        lineHeight: 21,
        color: colors.grayColor,
        paddingTop: 32,
        paddingBottom: 8,
    },
    textMenu: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 22,
        textAlign: 'center',
    },
    textQuestion: {
        color: colors.grayColor,
        flex: 8,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        height: '100%',
        left: 20,
        lineHeight: 20,
        textAlign: 'left',
        textAlignVertical: 'top',
        top: 20,
    },
    touchableQuestion: {
        alignItems: 'flex-end',
        flex: 1,
        marginHorizontal: 8,
        top: 25,
    },
    viewBox: {
        minWidth: 200,
    },
    viewFlatlistItem: {
        marginVertical: 8,
        backgroundColor: colors.veryGray,
        height: 1,
    },
    viewMenu: {
        alignItems: 'center',
        borderColor: colors.grayVeryLigth,
        borderRadius: 5,
        borderWidth: 2,
        height: 45,
        justifyContent: 'center',
        marginBottom: 10,
        marginRight: 10,
        marginTop: 15,
        flex: 1,
        minWidth: 80,
    },
    viewTop: {
        flexDirection: 'row',
        alignItems: 'baseline',
        backgroundColor: colors.white,
        alignContent: 'flex-start',
        flexWrap: 'wrap',
    },
    viewSearch: {
        flex: 0.9,
        minWidth: 365,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    viewQuestions: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 5,
        flexDirection: 'row',
        height: 80,
        marginHorizontal: 12,
        marginTop: 7,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 13,
    },
});
