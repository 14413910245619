import { StyleSheet } from 'react-native';

import {
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
        width: '100%',
    },
    containerTooltip: {
        paddingVertical: 16,
        alignItems: 'center',
    },
    containerTextTooltip: {
        width: '100%',
    },
    containerInputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    contentForm: {
        alignItems: 'center',
        width: '100%',
    },
    inputLarge: {
        width: '100%',
    },
    inputMedium: {
        width: '100%',
    },
    textInput: {
        flex: 1,
    },
    textTooltip: {
        fontFamily: fonts.SFProText,
        fontSize: 15,
        color: theme.colors.primary,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 8,
        marginLeft: 4,
        textAlign: 'left',
    },
    containerImgtooltip: {
        height: 100,
        width: 250,
    },
    tooltimg: {
        height: '100%',
        width: '100%',
    },
    containerInput: {
        marginBottom: 5,
        width: '100%',
    },
    containerButton: {
        width: '78%',
        marginTop: 20,
    },
    containerRowBtn: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    textSecondaryAHC: {
        textAlign: 'center',
    },
    buttonSave: {
        width: '100%',
    },
    routingStyle: {
        fontFamily: fonts.SFProText,
        fontWeight: '600',
        fontSize: 15,
        lineHeight: 24,
    },
    buttonCancel: {
        marginTop: 5,
        backgroundColor: 'transparent'
    },
    textCancel: {
        color: theme.colors.primary,
    },
    containerCheckbox: {
        marginBottom: 15,
        marginTop: 5,
    },
    containerInitialData: {
        paddingTop: 7,
        width: '100%'
    }
});
