import React, { useEffect } from 'react';

import {
    FlatList,
    Platform,
    Text,
    View,
} from 'react-native';

import { ApplicationsModalItem } from '@ApplicationsModal/components/ApplicationsModalItem';
import { styles } from '@ApplicationsModal/styles/ApplicationsModalStyle';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import { SelectableApplication } from '@Profile/interfaces';
import {
    downloadApplications,
    saveApplicationSelected,
} from '@Profile/state/applicationsAction';

interface PropsRenderItem {
    item: SelectableApplication;
};

interface Props {
    forceToClose?: () => void;
};

export const ApplicationsModal = ({ forceToClose = () => { } }: Props) => {
    const dispatch = useAppDispatch();

    const { selectableApplications } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        dispatch(downloadApplications());
    }, []);

    const handleOnPressItem = (item: SelectableApplication) => {
        dispatch(saveApplicationSelected(item));
        forceToClose();
    };

    const handleRenderItem = ({ item }: PropsRenderItem) => (
        <ApplicationsModalItem
            item={item}
            onPress={handleOnPressItem}
        />
    );

    const handleRenderSeparator = () => (
        <View style={styles.itemSeparator} />
    );

    const handleRenderHeader = () => (
        <View style={styles.appTextContainer}>
            <Text style={styles.appText}>{Languages.SelectAccount}</Text>
        </View>
    );

    return (
        <View style={{ flex: 100 }}>
            <FlatList
                data={selectableApplications}
                renderItem={handleRenderItem}
                keyExtractor={(item) => item?.applicationId?.toString()}
                ItemSeparatorComponent={handleRenderSeparator}
                removeClippedSubviews={Platform.OS === 'android'}
                style={styles.contentModalStyle}
                ListHeaderComponent={handleRenderHeader}
                showsVerticalScrollIndicator={false}
            />
        </View>
    );
};
