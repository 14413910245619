import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    button: {
        height: 55,
        marginBottom: 16,
        width: '100%',
    },
    container: {
        flex: 1,
        marginHorizontal: 17,
    },
    containerBodyData: {
        alignItems: 'center',
    },
    containerBodyLeft: {
        alignItems: 'center',
        marginTop: 24,
    },
    containerBodyRight: {
        alignItems: 'center',
        marginTop: 24,
    },
    containerDescription: {
        marginBottom: 16,
        marginTop: 24,
    },
    containerHeader: {
        alignItems: 'center',
        backgroundColor: colors.greenBalance,
        borderRadius: 10,
        flex: 1,
        flexDirection: 'row',
        height: 155,
        paddingHorizontal: 16,
    },
    containerHeaderData: {
        flex: 1,
        marginBottom: 30,
    },
    containerHeaderImage: {
        alignItems: 'flex-end',
        flex: 1,
        marginBottom: 50,
    },
    containerRight: {
        backgroundColor: 'white',
        borderRadius: 10,
        marginBottom: 50,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
    },
    description: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    financialBooksImage: {
        alignItems: 'center',
        marginBottom: 24,
        marginTop: 16,
    },
    financialGraphImage: {
        alignItems: 'center',
    },
    financialPigImage: {
        alignItems: 'center',
        marginBottom: 16,
        marginTop: 24,
    },
    subtitleBody: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
        textAlign: 'left',
    },
    subtitleBodyGreen: {
        color: theme.colors.primary,
        fontWeight: '500',
    },
    title: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 31,
        fontWeight: '500',
        lineHeight: 37,
        marginBottom: 30,
        marginTop: 25,
    },
    titleBody: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '500',
        lineHeight: 29,
    },
    titleHeader: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '600',
        lineHeight: 29,
    },
});
