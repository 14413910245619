enum RegisterActionTypes {
    CLEAR = '@@register/clear',
    CONFIRM_ACCOUNT_REQUEST = '@@register/CONFIRM_ACCOUNT_REQUEST',
    REGISTER_EMAIL_BUSINESS_CENTER_REQUEST = '@@register/REGISTER_EMAIL_BUSINESS_CENTER_REQUEST',
    REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE_REQUEST = '@@register/REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE_REQUEST',
    REGISTER_EMAIL_REQUEST = '@@register/REGISTER_EMAIL_REQUEST',
    REGISTER_ERROR = '@@register/REGISTER_ERROR',
    REGISTER_REQUEST = '@@register/REGISTER_REQUEST',
    REGISTER_SUCCESS = '@@register/REGISTER_SUCCESS',
    REGISTER_VERIFICATION_CODE_ERROR = '@@register/REGISTER_VERIFICATION_CODE_ERROR',
    REGISTER_VERIFICATION_CODE_REQUEST = '@@register/REGISTER_VERIFICATION_CODE_REQUEST',
    REGISTER_VERIFICATION_CODE_RESEND_REQUEST = '@@register/REGISTER_VERIFICATION_CODE_RESEND_REQUEST',
    REGISTER_VERIFICATION_CODE_SUCCESS = '@@register/REGISTER_VERIFICATION_CODE_SUCCESS',
    VALIDATE_VERIFICATION_CODE_REQUEST = '@@register/VALIDATE_VERIFICATION_CODE_REQUEST',
};

export default RegisterActionTypes;
