import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import {
    Entity,
    PaymentActivity,
} from '@Activity/interfaces';
import PaymentActivityService from '@Activity/services/PaymentActivityService';
import { getPaymentActivities } from '@Activity/state/activityActions';
import { styles } from '@ActivityMobile/styles/ActivitiesStyles';
import { ArrowRightIcon, EmptyActivityIcon } from '@Assets/index';
import { AppEmptyState } from '@Components/AppEmptyState';
import Languages from '@i18n/index';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import { format } from 'date-fns';
import { useNavigation } from '@react-navigation/native';

interface PropsItem {
    item: Entity;
    index: number;
};

export const ActivityMobileAllScreen = () => {
    const dispatch = useAppDispatch();
    const [entities, setEntities] = useState<Entity[]>([]);
    const [page, setPage] = useState<number>(1);
    const totalPagesRef = useRef(0);
    const navigation = useNavigation();

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        dispatch(getPaymentActivities({
            loanId: loanSelected?.loanId,
            page,
            pageSize: 10,
            source: 'screen',
        }));
    }, [loanSelected, page]);

    useEffect(() => {
        const subscription = PaymentActivityService.subscribe((state: PaymentActivity) => {
            const { entities: newEntities, source, totalPages } = state;
            if (source === 'screen') {
                totalPagesRef.current = totalPages;
                setEntities([...entities, ...newEntities].sort((a, b) => a.timestamp < b.timestamp ? 1 : -1));
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [entities]);

    const handleRenderItem = ({ item, index }: PropsItem) => (
        <TouchableOpacity style={styles.activityItemAmount} onPress={() => navigation.navigate('ActivityItem', { item, index, onView: () => {}})}>
            <View style={[styles.activityItem, StyleSheet.create({ item: { borderTopWidth: index === 0 ? 0 : 1 }}).item]}>
                <View style={styles.activityItemLeft}>
                    <Text style={styles.activityItemTitle}>{item.title}</Text>
                    <Text style={styles.activityItemMetaData}>{format(new Date(item.dateString), 'MMM dd, yyyy')}</Text>
                    <Text style={styles.activityItemMetaData}>
                        { Languages.PaymentTypeDetails.replace('{type}', item.paymentType || '').replace('{lastFour}', item.paymentLastFourDigit || 'XXXX')}
                    </Text>
                </View>
                <View style={styles.activityItemRight}>
                    <Text style={[styles.activityAmount, StyleSheet.create({ item: { color: item.paymentAmountColor, marginRight: 10 }}).item ]}>{item.paymentAmount}</Text>
                    <ArrowRightIcon />
                </View>
            </View>
        </TouchableOpacity>
    );

    const handleOnEndReached = () => {
        if (page < totalPagesRef.current) {
            setPage(page + 1);
        }
    };

    return (
        <>{entities.length ?
            <FlatList
                data={entities}
                initialNumToRender={10}
                keyExtractor={(_: Entity, index: number) => index.toString()}
                removeClippedSubviews={Platform.OS === 'android'}
                onEndReached={handleOnEndReached}
                renderItem={handleRenderItem}
                showsVerticalScrollIndicator={false}
                style={styles.itemList}
                onEndReachedThreshold={0.4}
            />
        :
            <View style={styles.emptyStateContainer}>
                <AppEmptyState
                    accessibilityLabel={'paymentActivityAppEmptyStateActvity'}
                    Icon={EmptyActivityIcon}
                    subTitle='No Recent'
                    testID={'paymentActivityAppEmptyStateActvity'}
                    title='Transactions'
                    content=''
                />
            </View>
        }</>
    );
};
