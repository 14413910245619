import React from 'react';

import {
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import Languages from '@i18n/index';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

interface Props {
    acceptButtonText?: string;
    accessibilityLabel?: string;
    cancelButtonText?: string;
    destroyAction?: boolean;
    handleAccept: () => void;
    handleCancel?: () => void;
    show: boolean;
    subtitle?: string;
    testID?: string;
    title: string;
    titleBottomSection?: string;
    subTitleBottomSection?: string;
};

export const AppModalAlert = ({
    acceptButtonText = Languages.Accept,
    accessibilityLabel = 'appModalAlert',
    cancelButtonText = Languages.Cancel,
    destroyAction,
    handleAccept,
    handleCancel,
    show,
    subtitle,
    testID = 'appModalAlert',
    title,
    titleBottomSection,
    subTitleBottomSection,
}: Props) => {

    const subtitleSection = () =>{
        if(subtitle === undefined)
            return '';
        if(Array.isArray(subtitle))
            return subtitle.map(s => <Text style={styles.placeholder}>{s}</Text>);
        return (<Text style={styles.placeholder}>{subtitle}</Text>);
    }

    return (
        <AppModal
            animationType='fade'
            closeModal={handleCancel}
            transparent
            visible={show}
        >
            <View style={styles.container}>
            <Text style={styles.title}>{title}</Text>
            {subtitleSection()}
            {titleBottomSection && <Text style={styles.titleBottom}>{titleBottomSection}</Text>}
            {subTitleBottomSection && <Text style={styles.placeholder}>{subTitleBottomSection}</Text>}
            <AppButton
                accessibilityLabel={`${accessibilityLabel}Accept`}
                buttonStyle={[styles.acceptButton, destroyAction ? styles.acceptButtonDestroyAction : {}, !handleCancel && {
                    marginBottom: 20,
                }]}
                handleOnChange={handleAccept}
                testID={`${testID}Accept`}
                title={acceptButtonText}
            />
            {handleCancel && (
                <AppButton
                    accessibilityLabel={`${accessibilityLabel}Cancel`}
                    buttonStyle={[styles.cancelButton, destroyAction ? styles.cancelButtonDestroyAction : {} ] }
                    handleOnChange={handleCancel}
                    testID={`${testID}Cancel`}
                    textStyle={[styles.textBtnCancel, destroyAction ? styles.textBtnCancelDestroyAction : {}]}
                    title={cancelButtonText}
                />
            )}
            </View>
        </AppModal>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        width: 343,
    },
    title: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '700',
        lineHeight: 20,
        textAlign: 'center',
        marginTop: 30,
        width: 255,
    },
    titleBottom: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '700',
        lineHeight: 20,
        textAlign: 'center',
        marginTop: 15,
        width: 255,
    },
    placeholder: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 18,
        textAlign: 'center',
        width: 257,
        marginTop: 6,
        marginBottom: 10
    },
    acceptButton: {
        width: '78%',
        marginTop: 20,
        marginBottom: 5,
        backgroundColor: theme.colors.primary
    },
    acceptButtonDestroyAction: {
        backgroundColor: colors.softRed,
    },
    cancelButton: {
        borderColor: theme.colors.primary,
        backgroundColor: 'transparent',
        width: '78%',
        marginBottom: 10,
    },
    cancelButtonDestroyAction: {
        backgroundColor: 'transparent',
    },
    textBtnCancel: {
        color: theme.colors.primary,
    },
    textBtnCancelDestroyAction: {
        color: colors.grayDarkColor,
        fontWeight: 'bold',
    },
});
