import { ImageSourcePropType } from 'react-native';

export enum TypesMethod {
    DEBITCARD = 'Debit',
    CREDITCARD = 'Credit',
    ACH = 'Checking',
};

export interface MethodsOfPaymentState {
    autopay: boolean | undefined;
};

export interface PaymentMethod {
    bankName: string;
    nickName: string;
    selected: boolean;
    disabled: boolean;
    card: Card;
};

export interface TypeCard {
    numberCard: string;
    expirationDate: string;
    nickname: string;
    type?: string;
    enableAutoPay: boolean;
};

export interface TypeAHC {
    routingNumber: string;
    accountNumber: string;
    bankName: string;
    enableAutoPay: boolean;
};

export interface PaymentsMethodResponse extends Partial<Card>, Partial<BankCheckResponse> {
    active: boolean;
    id: number;
    loanId: number;
    nickname: string;
    note: string;
    token: string;
    type: 'Debit' | 'Credit' | 'Checking';
    isPrimary: boolean;
};

export interface CardResponse {
    id: number;
    loanId: number;
    token: string;
    expirationDate: string;
    nickname: string;
    note: string;
    active: boolean;
    paymentNetwork: null | string;
    lastFourDigit: string;
    type: string;
};

export interface Card {
    lastFourDigit: string;
    expirationDate: string;
    paymentNetwork: string;
};

export interface BankCheckResponse {
    routingNumber: string;
    bank: string;
};

export interface PaymentMethodResponseBase extends Card, BankCheckResponse {
    id: number;
    loanId: number;
    token: string;
    type: string;
    note: string;
    active: boolean;
    nickname: string;
    isPrimary: Boolean;
    selected: boolean;
    isShowShimmer: boolean;
};

export interface SaveCardRequest extends TypeCard {
    loanId: number;
    fromManageAutopayScreen?: boolean;
};

export interface SaveAHCRequest extends TypeAHC {
    loanId: number;
    fromManageAutopayScreen?: boolean;
};

export interface SaveCardActionRequest {
    payload: SaveCardRequest;
};

export interface SaveAHCActionRequest {
    payload: SaveAHCRequest;
};

export interface PutCardRequest {
    id: number;
    nickname: string;
    enableAutoPay: boolean;
    expirationDate: string;
};

export interface PutCardActionRequest {
    payload: PutCardRequest;
};

export interface PutACHRequest {
    id: number;
    loanId: number;
    nickname: string;
};

export interface PutACHActionRequest {
    payload: PutACHRequest;
};

export interface CheckResponse {
    id: number;
    loanId: number;
    type: string;
    token: string;
    routingNumber: string;
    nickname: string;
    bank: string;
    note: string;
    active: true;
};

export interface PaymentMethodSelected {
    id: number;
    value: boolean;
};

export interface PaymentMethods {
    items: PaymentMethodResponseBase[];
}

export interface PaymentMethodActionLoad {
    payload: PaymentMethods;
};

export interface PaymentMethodActionSelected {
    payload: PaymentMethodSelected;
};

export interface PrimaryMethodModalState {
    open: boolean;
    params: PrimaryMethodModalStateParams;
};

export interface PrimaryMethodModalStateParams {
    paymentNetwork: string;
    lastFourDigits: string;
    onTapAcceptButton: () => void;
    onTapCancelButton?: () => void;
};

export interface AddNewPaymentDefinitionItem {
    title: string;
    subtitle: string;
    image: ImageSourcePropType;
    name: string;
};

export interface GetPlaidTokenResponse {
    token: string;
};

export interface PlaidToken {
    token: string;
};

export interface DeletePaymentMethodActionRequest {
    payload: number;
};

export interface PatchPaymentMethodsSortRequest {
    loanId: number;
    paymentMethods: PaymentMethodSort[];
};

export interface PatchPaymentSetPrimaryActionRequest {
    payload: PatchPaymentSetPrimaryRequest;
};

export interface PatchPaymentSetPrimaryRequest {
    loanId: number;
    paymentMethodId: number;
    paymentMethodType: string;
};

export interface PaymentMethodSort {
    paymentMethodId: number;
    type: string;
};

export interface PaymentMethodsSortRequest {
    loanId: number;
    order: number[];
    paymentMethods: PaymentMethodResponseBase[];
};

export interface PaymentMethodsSortActionRequest {
    payload: PaymentMethodsSortRequest;
};

export interface PutPaymentMethodAutoPayRequest {
    loanId: number;
    autopay: boolean;
};

export interface PutPaymentMethodAutoPayActionRequest {
    payload: PutPaymentMethodAutoPayRequest;
};

export interface GetPaymentMethodAutoPayRequest {
    loanId: number;
};

export interface GetPaymentMethodAutoPayActionRequest {
    payload: GetPaymentMethodAutoPayRequest;
};

export interface PaymentMethodAutoPay {
    paymentMethodType?: string;
    paymentMethodId?: number;
    autopay: boolean;
};

export interface PaymentMethodSetAutoPay {
    autopay: boolean;
}

export interface GetPaymentMethodAutoPayResponse {
    response: PaymentMethodAutoPay[];
};

export interface GetPaymentMethodAutoPaySuccessAction {
    payload: PaymentMethodAutoPay;
};
