import appAPI from '@Api/appApi';
import { Commands } from '@Api/config';
import {
    GetNotificationOffersResponse,
    GetNotificationRequest,
    GetNotificationResponse,
    GetOffersRequest,
} from '@Notifications/interfaces';

export const getNotificationsAPI = ({ applicationId }: GetNotificationRequest): Promise<GetNotificationResponse> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.NOTIFICATION_OFFERS.replace('{applicationId}', `${applicationId}`);
        const response = await appAPI.get<GetNotificationOffersResponse>(command);
        resolve({
            data: [{
                time: '',
                title: response.data.url,
                type: '',
            }],
        });
    } catch (error) {
        reject(error);
    }
});

export const getOffersAPI = ({ applicationId }: GetOffersRequest): Promise<GetNotificationOffersResponse> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.NOTIFICATION_OFFERS.replace('{applicationId}', `${applicationId}`);
        const response = await appAPI.get<GetNotificationOffersResponse>(command);
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});
