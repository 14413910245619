import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import {
    CloseIcon,
    SearchIcon,
} from '@Assets/index';
import { ContactHelpComponent } from '@Components/ContactHelpComponent';
import { TextInput } from '@Components/TextInput';
import { useAppDispatch } from '@Hooks/appHooks';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import { useDebouncedValue } from '@Hooks/useDebouncedValue';
import Languages from '@i18n/index';
import FAQItemWeb from '@Profile/components/FAQItemWeb';
import {
    FAQ,
    FAQCategory,
    FAQCategoryRenderItem,
    FaqServiceResponse,
    QuestionRenderItem,
} from '@Profile/interfaces';
import faqService from '@Profile/services/FaqService';
import {
    getFaqRequest,
    searchFaq,
} from '@Profile/state/faqAction';
import { styles } from '@Profile/styles/FaqScreenWebStyles';
import {
    colors,
    theme,
} from '@Theme/appTheme';

export const ProfileFaqScreen = () => {
    const isComponentMountedRef = useRef(true);
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>('All');
    const [text, setText] = useState('');
    const [categories, setCategories] = useState<FAQCategory[]>();
    const [questions, setQuestions] = useState<FAQ[]>([]);

    const dispatch = useAppDispatch();
    const { debouncedValue } = useDebouncedValue(text, 100);
    const isMobile: boolean = Platform.OS === 'web' && useCheckMobileScreen();

    useEffect(() => {
        dispatch(searchFaq({
            category: selectedCategoryName,
            filter: text,
        }));
    }, [selectedCategoryName, text]);

    useEffect(() => {
        dispatch(getFaqRequest());
        const getFaqSubscribe = faqService.subscribe((state: FaqServiceResponse) => {
            if (isComponentMountedRef.current) {
                if (selectedCategoryName === 'All') {
                    setCategories(state.categories);
                }
                setQuestions(state.faqs);
            }
        });
        return () => {
            isComponentMountedRef.current = false;
            getFaqSubscribe.unsubscribe();
        };
    }, []);

    const renderCategoryItem = ({ item }: FAQCategoryRenderItem) => {
        const backgroundColor = item.name === selectedCategoryName ? theme.colors.primary : colors.grayVeryLigth;
        const color = item.name === selectedCategoryName ? theme.colors.primary : colors.grayVeryLigth;

        return (
            <TouchableOpacity
                accessibilityLabel={`faqTouchableCategory${item.name.replace(/\s/g, '')}`}
                onPress={() => onPressCategory(item)}
                style={[styles.viewMenu, { borderColor: backgroundColor }]}
                testID={`faqTouchableCategory${item.name.replace(/\s/g, '')}`}
            >
                <Text
                    style={[styles.textMenu, { color, marginHorizontal: 10 }]}
                >{item.name}</Text>
            </TouchableOpacity>
        );
    };

    const onPressCategory = (item: FAQCategory) => {
        setSelectedCategoryName(item.name);
    };

    const renderQuestions = useCallback(
        ({ item, index }: QuestionRenderItem) => (
            <FAQItemWeb item={item} index={index} handleOnPress={() => onPressFAQItem(index)} />
        ),
        [questions],
    );

    const onPressFAQItem = (index: number) => {
        questions[index].selected = !questions[index].selected;
        setQuestions([
            ...questions,
        ]);
    };

    const onChangeTextSearch = (text: string) => setText(text);

    const footer = useMemo(() => (<ContactHelpComponent />), []);

    const handlePress = () => setText('');

    const renderIcon = () => (
        <View style={styles.iconContainer}>
            <TouchableOpacity
                accessibilityLabel={'faqTouchableClear'}
                onPress={handlePress}
                style={styles.icon}
                testID={'faqTouchableClear'}
            >
                <CloseIcon />
            </TouchableOpacity>
            <SearchIcon />
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={styles.principalView}>
                <Text style={styles.textHelp}>{Languages.HowCanWeHelpYou}</Text>
                <View style={styles.viewTop}>
                    <View style={styles.viewSearch}>
                        <TextInput
                            accessibilityLabel={'faqTextInputSearch'}
                            containerStyle={styles.containerSearch}
                            onChangeText={onChangeTextSearch}
                            placeholder={Languages.PlaceholderSearch}
                            RightIcon={renderIcon}
                            testID={'faqTextInputSearch'}
                            value={text}
                        />
                    </View>
                    <View style={[styles.viewBox, isMobile && { width: '100%' }]}>
                        {
                            !debouncedValue.length && (
                                <View style={styles.flatList}>
                                    <FlatList
                                        data={categories}
                                        renderItem={renderCategoryItem}
                                        keyExtractor={(item: FAQCategory, _) => item.name.toString()}
                                        showsVerticalScrollIndicator={false}
                                        horizontal={true}
                                    />
                                </View>
                            )
                        }
                    </View>
                </View>
            </View>
            <FlatList
                data={questions}
                renderItem={renderQuestions}
                initialNumToRender={10}
                keyExtractor={(item: FAQ, _) => item.question.toString()}
                showsVerticalScrollIndicator={false}
                ItemSeparatorComponent={() => <View style={styles.viewFlatlistItem} />}
                style={styles.questionList}
                ListFooterComponent={footer}
                removeClippedSubviews={Platform.OS === 'android'}
                ListFooterComponentStyle={styles.listFooter}
                scrollEnabled={true}
            />
        </View>
    );
};
