import React, {
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    Text,
    View,
} from 'react-native';
import SwipeUpDownModal from 'react-native-swipe-modal-up-down';

import Languages from '@i18n/index';
import {
    PaymentDefinitionsItemModal,
} from '@PaymentDefinitionsModal/components/PaymentDefinitionItemModal';
import { PaymentDefinitionItem } from '@PaymentDefinitionsModal/interfaces/index';
import PaymentDefinitionsModalService
    from '@PaymentDefinitionsModal/services/PaymentDefinitionsModalService';
import { styles } from '@PaymentDefinitionsModal/styles/PaymentDefinitionsModalStyles';

interface PropsRenderItem {
    item: PaymentDefinitionItem;
};

export const PaymentDefinitionsModal = () => {
    const [options, setOptions] = useState<PaymentDefinitionItem[]>([]);
    const [hasShowModal, setHasShowModal] = useState(false);

    useEffect(() => {
        const subscription = PaymentDefinitionsModalService.subscribe((state: any) => {
            setHasShowModal(state.open);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (hasShowModal) {
            setOptions([
                {
                    id: '1',
                    title: Languages.MinimumPayment,
                    subtitle: Languages.MinimumPaymentDefinition,
                },
                {
                    id: '2',
                    title: Languages.PastDue,
                    subtitle: Languages.PastDueDefinition,
                },
                {
                    id: '3',
                    title: Languages.TodaysPayoff,
                    subtitle: Languages.TodaysPayoffDefinition,
                },
                {
                    id: '4',
                    title: Languages.OtherAmount,
                    subtitle: Languages.OtherAmountDefiniton,
                },
            ]);
        }
    }, [hasShowModal]);

    const closeModal = () => {
        PaymentDefinitionsModalService.hide();
    };

    const handleRenderItem = ({ item }: PropsRenderItem) => (
        <PaymentDefinitionsItemModal item={item}/>
    );

    const handleRenderContentModal = () => (
        <>
            <FlatList
                data={options}
                renderItem={handleRenderItem}
                keyExtractor={(item, _) => item.id.toString()}
                removeClippedSubviews={Platform.OS === 'android'}
                style={styles.flatList}
            />
        </>
    );

    const handleRenderHeaderContent = () => (
        <View style={styles.headerContentStyle}>
            <View style={styles.headerBar} />
            <View style={styles.appTextContainer}>
                <Text style={styles.appText}>{Languages.PaymentDefinitions}</Text>
            </View>
        </View>
    );

    return (
        <SwipeUpDownModal
            PressToanimate={true}
            modalVisible={hasShowModal}
            ContentModal={handleRenderContentModal()}
            HeaderStyle={styles.headerStyle}
            ContentModalStyle={styles.contentModel}
            HeaderContent={handleRenderHeaderContent()}
            onClose={closeModal}
        />
    );
};
