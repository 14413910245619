import { Dimensions, StyleSheet } from 'react-native';

import { colors } from '@Theme/appTheme';
const isMobileDimension = Dimensions.get('window').width < 496;

export const styles = StyleSheet.create({
    body: {
        flex: 100,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-evenly',
    },
    cardBalance: {
        backgroundColor: colors.greenBalance,
        borderRadius: 10,
        flex: 20,
        height: 171,
        marginBottom: 30,
        marginRight: 16,
        minWidth: 300,
    },
    buttonMakePaymentShimmer: {
        height: 70,
        width: '100%',
        display: isMobileDimension ? 'none' : 'flex',
    },
    viewMobileButton: {
        flex: 40,
        marginRight: 16,
        minWidth: 320,
        marginBottom: 25,
        marginTop: -15,
        display: isMobileDimension ? 'flex' : 'none'
    },
    buttonMakePaymentShimmerMobile: {
        height: 70,
        width: '100%',
        display: isMobileDimension ? 'flex' : 'none',
    },
    cardShimmerBalance: {
        alignItems: 'center',
        height: 155,
        justifyContent: 'center',
        width: '100%',
    },
    cards: {
        backgroundColor: colors.white,
        borderRadius: 10,
        flex: 3,
        margin: 10,
    },
    cardsFlex: {
        borderRadius: 10,
        flex: 100,
        marginHorizontal: 10,
    },
    cardPaymentInfo: {
        flex: 20,
        height: 'auto',
        marginRight: 16,
        minWidth: 320,
        marginBottom: 25,
    },
    cardsPayment: {
        flex: 40,
        marginRight: 16,
        minWidth: 350,
        marginBottom: 25,
    },
    cardStatement: {
        flex: 40,
        height: 400,
        marginRight: 16,
        minWidth: 350,
        marginBottom: 25,
    },
    makeAPaymentButton: {
        height: 55,
        marginTop: 12,
        width: '100%',
    },
    paymentActivityWidgetContainer: {
        height: 150,
        marginRight: 8,
        marginTop: 40,
    },
    pendingWidgetContainer: {
        height: 110,
        marginTop: 40,
    },
    viewActionCards: {
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        flex: 20,
        height: 171,
        marginBottom: 30,
        marginRight: 16,
        minWidth: 216,
    },
    viewBalance: {
        flex: 3,
        margin: 10,
    },
    viewCards: {
        flex: 3,
        margin: 10,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    viewNewCardls: {
        flex: 3,
        marginBottom: 15,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    viewBottom: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: 16,
    },
    viewTop: {
        flex: 30,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 30,
        paddingLeft: 16,
        justifyContent: 'center',
    },
    viewText: {
        flex: 5,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginTop: 25,
    },
});
