import { createAction } from 'typesafe-actions';

import { FAQRequest } from '@Profile/interfaces';
import FaqActionTypes from '@Profile/state/faqActionTypes';

export const getFaqRequest = createAction(
    FaqActionTypes.GET_FAQ_REQUEST,
)();

export const searchFaq = createAction(
    FaqActionTypes.SEARCH_FAQ,
    (request: FAQRequest) => (request),
)();
