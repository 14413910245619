import React from 'react';

import { View } from 'react-native';

import { AppModal } from '@Components/AppModal';
import { MakeAPaymentOption } from '@MakeAPayment/interfaces';
import { PaymentWebStack } from '@Navigation/PaymentWebStack';
import { PaymentMethodSelectionWebStack } from '@Navigation/PaymentMethodSelectionWebStack';
import { useEffect } from 'react';

interface Props {
    amount?: number;
    dueDate?: string;
    handleCancel?: () => void;
    handleSelection?: (item: any) => any;
    isVisible: boolean;
    optionSelected?: MakeAPaymentOption;
    isTodayPayoff?: boolean,
    isSelectionOnly?: boolean,
};

export const PaymentProcessDialogScreen = ({
    amount,
    dueDate,
    handleCancel = () => { },
    handleSelection = (item: any) => item,
    isVisible,
    optionSelected,
    isTodayPayoff,
    isSelectionOnly,
}: Props) => {
    const [smQuery, setSmQuery] = React.useState<any>({
        matches: window.innerWidth < 768 ? true : false,
    });
    const [mdQuery, setMdQuery] = React.useState<any>({
        matches: (window.innerWidth > 767 && window.innerWidth < 968) ? true : false,
    });
    useEffect(() => {
        let mediaQuery = window.matchMedia('(max-width: 767px)');
        mediaQuery.addEventListener('query', setSmQuery);

        return () => mediaQuery.removeEventListener('query', setSmQuery);
    }, []);
    
    useEffect(() => {
        let mediaQueryMd = window.matchMedia('(max-width: 968px)');
        mediaQueryMd.addEventListener('query', setMdQuery);

        return () => mediaQueryMd.removeEventListener('query', setMdQuery);
    }, []);

    const getModalWidth = () => {
        if (!!smQuery && smQuery.matches) return '90%';
        if (!!mdQuery && mdQuery.matches) return '70%';
        return '50%';
    }
    return (
        <AppModal
            animationType='fade'
            transparent
            visible={isVisible}
        >
            <View style={{
                borderRadius: 10,
                height: '85%',
                overflow: 'hidden',
                width: getModalWidth(),
            }}>
                {isSelectionOnly ? 
                    (<PaymentMethodSelectionWebStack
                        handleCancel={handleCancel}
                        handleSelection={handleSelection}
                        isSelectionOnly={true}
                    />)
                    : (<PaymentWebStack
                        amount={amount}
                        dueDate={dueDate}
                        isTodayPayoff={isTodayPayoff}
                        handleCancel={handleCancel}
                        optionSelected={optionSelected}
                    />)}
            </View>
        </AppModal>
    );
};
