import { isMacOs, isWindows } from 'react-device-detect';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

export const styles = AppStyleSheet({
    mainBox: {
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxAppLink: {
        backgroundColor: colors.blackMinOpacity,
        width: (isWindows || isMacOs) ? '450px': '100%',
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
    },
    icon: {
        marginRight: 10,
        padding: 6,
        backgroundColor: theme.colors.primary,
        borderRadius: 5,
    },
    textBox: {
        color: colors.white,
        fontFamily: fonts.SFProDisplay,
        fontSize: 13,
        fontWeight: '400',
        paddingLeft: 5,
    },
    viewRow: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 10,
    },
});
