import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    amount: {
        alignItems: 'center',
        color: colors.white,
        fontFamily: fonts.SFProDisplay,
        fontSize: 50,
        fontWeight: '400',
        lineHeight: 60,
        textAlign: 'center',
        ...Platform.select({
            web: {
                color: colors.greenSolid,
                fontSize: 50,
                fontWeight: '700',
            },
        }),
    },
    containerAmount: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        flex: 15,
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 5,
    },
    container: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        width: '100%',
    },
    current: {
        color: colors.white,
        fontFamily: fonts.SFProDisplaySemibold,
        fontSize: 24,
        fontWeight: '600',
        lineHeight: 29,
        textAlign: 'center',
        width: '100%',
        ...Platform.select({
            web: {
                color: colors.grayColor,
            },
        }),
    },
    currentContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginBottom: 6,
    },
    total: {
        alignItems: 'center',
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        lineHeight: 16,
        ...Platform.select({
            web: {
                color: colors.grayColor,
            },
        }),
    },
    cardShimmerBalanceTitle: {
        height: 30,
        width: '100%',
    },
    cardShimmerBalanceAmount: {
        height: 50,
        width: '100%',
    },
});
