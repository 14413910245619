import { PageMenu } from '@Home/interfaces';

const menuList: PageMenu[] = [
    {
        Icon: 'HomeMenu',
        index: 0,
        name: 'Home',
        screen: 'Home',
        selected: false,
    },
    {
        Icon: 'PaymentsMenu',
        index: 1,
        name: 'Billing',
        screen: 'PaymentsScreen',
        selected: false,
    },
    {
        Icon: 'ActivityMenu',
        index: 2,
        name: 'Activity',
        screen: 'Activity',
        selected: false,
    },
    {
        Icon: 'FinancialFundamentalsMenu',
        index: 3,
        name: 'Financial Fundamentals',
        screen: 'FinancialFundamentalsScreen',
        selected: false
    },
    {
        Icon: 'ProfileMenu',
        index: 4,
        name: 'Profile',
        screen: 'ProfileScreen',
        selected: false,
    },
    {
        Icon: 'HelpMenu',
        index: 5,
        name: 'Help',
        onlyMobile: true,
        screen: 'FaqScreen',
        selected: false,
    },
];

export const getMenuListApi = (): Promise<PageMenu[]> => new Promise((resolve, reject) => {
    try {
        resolve(menuList);
    } catch (error) {
        reject(error);
    }
});
