import React from 'react';

import { AutoPayScreen } from '@AutoPay/screens/AutoPayScreen';
import { BackToolbarIcon } from '@Components/BackToolbarIcon';
import Languages from '@i18n/index';
import { DateMakeAPaymentScreen } from '@MakeAPayment/screens/DateMakeAPaymentScreen';
import { MakeAPaymentScreen } from '@MakeAPayment/screens/MakeAPaymentScreen';
import { AddPaymentMethodScreen } from '@MethodsOfPayment/screens/AddPaymentMethodScreen';
import { MethodsOfPaymentsScreen } from '@MethodsOfPayment/screens/MethodsOfPaymentsScreen';
import { ManageAutoPayScreen } from '@MakeAPayment/screens/ManageAutoPayScreen';
import {
    SelectMethodOfPaymentScreen,
} from '@MethodsOfPayment/screens/SelectMethodOfPaymentsScreen';
import { PaymentsScreen } from '@Payments/screens/PaymentsScreen';
import { createStackNavigator } from '@react-navigation/stack';
import {
    colors,
    theme,
} from '@Theme/appTheme';
import { ProfileToolbarIcon } from '@Components/ProfileToolbarIcon';
import { ProfileScreen } from '@Profile/screens/ProfileScreen';
import { ApplicationsScreen } from '@Profile/screens/ApplicationsScreen';
import { ProfileInfoScreen } from '@Profile/screens/ProfileInfoScreen';
import { FaqScreen } from '@Profile/screens/FaqScreen';
import { SignOutComponent } from '@Components/SignOutComponent';
import { NewStatementsScreen } from '../statements/screens/newStatementsScreen';
import { PdfScreen } from '@Pdf/screens/PdfScreen';

const Stack = createStackNavigator();

export const PaymentStack = (props: any) => (
    <Stack.Navigator
        screenOptions={({ navigation }) => ({
            headerRight: () => (
                <ProfileToolbarIcon onPress={
                    () => navigation.navigate('ProfileScreen', { screen: 'Profile', initial: false})}
                />
            ),
            headerRightContainerStyle: {
                marginRight: 20,
                alignSelf: 'center',
            },
            headerTitleAlign: 'center',
            headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,
                backgroundColor: theme.colors.primary,
            },
            headerTitleStyle: {
                color: colors.white,
                alignSelf: 'center',
            },
            headerBackTitleStyle: {
                color: colors.white,
                alignSelf: 'center',
            },
            headerLeftContainerStyle: {
                alignSelf: 'center',
            },
            headerBackTitleVisible: false,
            headerTintColor: colors.white,
        })}
    >
        <Stack.Screen
            name='PaymentsScreen'
            initialParams={props?.route?.params}
            options={{ title: Languages.Billing }}
            component={PaymentsScreen}
        />
        <Stack.Screen
            name='MethodsOfPaymentsScreen'
            options={{
                gestureEnabled: false,
                title: Languages.PaymentMethods,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={MethodsOfPaymentsScreen}
        />
        <Stack.Screen
            name='SelectMethodOfPaymentScreen'
            options={{
                title: Languages.PaymentMethods,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={SelectMethodOfPaymentScreen}
        />
        <Stack.Screen
            name='MakeAPaymentScreen'
            options={({ route }) => ({
                title: Languages.MakeAPayment,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} screen={route.name} />
                ),
            })}
            component={MakeAPaymentScreen}
        />
        <Stack.Screen
            name="NewStatementsScreen"
            options={( { route }) => ({
                title: Languages.Statements,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} screen={route.name} />
                )
            })}
            component={NewStatementsScreen}
        />
        <Stack.Screen
            name='AddPaymentMethodScreen'
            options={({ route }) => ({
                gestureEnabled: false,
                title: Languages.AddNewMethodOfPayment,
                headerLeft: () => (
                    <BackToolbarIcon
                        fill={colors.white}
                        screen={route.name}
                    />
                ),
            })}
            component={AddPaymentMethodScreen}
        />
         <Stack.Screen
            name="PdfScreen"
            options={{
                title: Languages.Pdf,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={PdfScreen}
        />
        <Stack.Screen
            name='AutoPayScreen'
            options={{
                title: Languages.AutoPay,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={AutoPayScreen}
        />
        <Stack.Screen
            name='DateMakeAPaymentScreen'
            options={{
                title: Languages.MakeAPayment, headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={DateMakeAPaymentScreen}
        />
        <Stack.Screen
            name='ManageAutoPayScreen'
            options={{
                title: Languages.ManageAutoPay, headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={ManageAutoPayScreen}
        />
        <Stack.Screen
            name="ProfileScreen"
            options={{
                title: Languages.Profile,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
                headerRight: () => <SignOutComponent />
            }}
            component={ProfileScreen}
        />
        <Stack.Screen
            name="ProfileInfoScreen"
            options={{
                title: Languages.Profile,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
                headerRight: () => false,
            }}
            component={ProfileInfoScreen}
        />
        <Stack.Screen
            name="ApplicationsScreen"
            options={{
                title: Languages.Profile,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
                headerRight: () => false,
            }}
            component={ApplicationsScreen}
        />
        <Stack.Screen
            name='FaqScreen'
            options={{
                title: Languages.FAQ,
                headerLeft: () => (
                    <BackToolbarIcon fill={colors.white} />
                ),
            }}
            component={FaqScreen}
        />
    </Stack.Navigator>
);
