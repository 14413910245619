import * as React from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import { Icon } from '@Components/Icon';
import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import { styles } from '@MethodsOfPayment/styles/ItemListWebStyle';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Props {
    index: number;
    item: PaymentMethodResponseBase;
    onClickItem: (index: number) => void;
}

export const ItemListWeb = ({
    index,
    item,
    onClickItem,
}: Props) => {
    const colorFocus = item.selected ? theme.colors.primary : 'transparent';
    const colorDisabled = item.active ? colors.grayDisabled : colors.white;
    const colorMethodTitle = item.active ? colors.grayLigth : colors.grayDarkColor;
    const colorLastFour = item.active ? colors.grayLigth : theme.colors.primary;
    const colorExpireDate = item.active ? colors.grayLigth : colors.softRed;

    return (
        <TouchableOpacity
            accessibilityLabel={`selectMethodOfPaymentTouchable${item.nickname.replace(/\s/g, '')}`}
            disabled={item.active}
            onPress={() => onClickItem(index)}
            style={[styles.containerPaymentMethod, { borderColor: colorFocus, width: '100%', backgroundColor: colorDisabled }]}
            testID={`selectMethodOfPaymentTouchable${item.nickname.replace(/\s/g, '')}`}
        >
            <View style={[styles.cardContainer]}>
                <Icon name={item.paymentNetwork} />
                <Text style={[styles.textBankName, { color: colorMethodTitle }]}>
                    {`${item.nickname}`}
                </Text>
            </View>
            <View style={styles.containerCardInfo}>
                <Text style={[styles.textLastFour, { color: colorLastFour }]}>
                    {`* ${item.lastFourDigit}`}
                </Text>
                <Text style={[styles.textExpireDate, { color: colorExpireDate }]}>
                    {item.expirationDate}
                </Text>
            </View>
        </TouchableOpacity>
    );
};
