import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: colors.veryLightGray,
        flex: 1,
    },
    containerCardInfo: {
        marginRight: 19,
    },
    containerHeader: {
        alignItems: 'center',
    },
    containerRowTitleMethod: {
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    containerPaymentMethods: {
        width: '100%',
    },
    containerAutoPay: {
        marginTop: 15,
        width: '85%',
    },
    containerFlatFooter: {
        marginHorizontal: 16,
    },
    containerRadioBtn: {
        marginLeft: 18,
        marginRight: 10,
    },
    containerFooterBtns: {
        marginTop: 48,
        width: '75%',
        ...Platform.select({
            web: {
                marginTop: 16,
                width: '100%',
            },
        }),
    },
    containerBtnCancel: {
        backgroundColor: 'transparent',
        marginTop: 10,
    },
    containerRowAutoPay: {
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%',
    },
    containerFooter: {
        alignItems: 'center',
        width: '100%',
    },
    containerButton: {
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 60,
        justifyContent: 'center',
        width: '100%',
    },
    containerPaymentMethod: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderColor: 'transparent',
        borderRadius: 10,
        borderWidth: 3,
        flexDirection: 'column',
        height: 60,
        justifyContent: 'space-between',
        marginBottom: 15,
        marginHorizontal: 16,
    },
    addNewMethodBtn: {
        backgroundColor: 'transparent',
        borderColor: colors.grayOpacity,
        borderStyle: 'dashed',
        borderWidth: 2,
        height: 45,
        marginHorizontal: 9,
    },
    textAddNewMethod: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 20.29,
    },
    textAutoPay: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 20.29,
    },
    textDescriptionAutoPay: {
        color: colors.darkGray,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 10,
        fontWeight: '500',
        width: 240,
    },
    title: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '500',
        lineHeight: 29,
        marginBottom: 18,
        marginTop: 16,
        ...Platform.select({
            web: {
                marginTop: 0,
                marginBottom: 0,
            },
        }),
    },
    textBtnContinue: {
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        lineHeight: 22,
    },
    textBtnCancel: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        lineHeight: 22,
    },
    textBankName: {
        fontFamily: fonts.SFProTextMedium,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 15.51,
        width: 162,
    },
    textLastFour: {
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 18,
        fontWeight: '600',
        lineHeight: 21.48,
        textAlign: 'right',
    },
    textExpireDate: {
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontWeight: '400',
        lineHeight: 15.51,
        textAlign: 'right',
    },
});
