import { AlertRequest } from '@Alert/interfaces';
import {
    CheckCircleSvg,
    ExclamationTriangleSvg,
} from '@Assets/index';
import Languages from '@i18n/index';
import { colors } from '@Theme/appTheme';

interface AlertConfig {
    [key: string]: AlertRequest;
};

export enum AppAlertTypes {
    SUCCESS_ALERT = '@alert/SUCCESS_ALERT',
    ERROR_ALERT = '@alert/ERROR_ALERT',
};

export const ALERT_CONFIG: AlertConfig = {
    [AppAlertTypes.SUCCESS_ALERT]: {
        backgroundColor: colors.limeGreen,
        icon: {
            Icon: CheckCircleSvg,
            color: colors.white,
        },
        onClose: () => { },
        subtitle: '',
        title: Languages.Success,
    },
    [AppAlertTypes.ERROR_ALERT]: {
        backgroundColor: colors.softRed,
        icon: {
            Icon: ExclamationTriangleSvg,
            color: colors.veryLightRed,
        },
        onClose: () => { },
        subtitle: '',
        title: Languages.Error,
    },
};
