import React from 'react';

import {
    Platform,
} from 'react-native';

import { OnboardingScreen } from '@OnBoarding/screens/OnboardingScreen';
import { OnboardingScreenWeb } from '@OnBoarding/screens/OnboardingScreenWeb';

export const AppNavigationOnboarding = () => {
    if (Platform.OS === 'web') {
        return (
            <OnboardingScreenWeb name='onboardingScreen' />
        );
    } else {
        return (
            <OnboardingScreen name='onboardingScreen' />
        );
    }
};
