import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import { getMenuListApi } from '@Home/api/menuApi';
import { PageMenu } from '@Home/interfaces';
import { getListMenuActionSuccess } from '@Home/state/menuActions';
import MenuActionsTypes from '@Home/state/menuActionsTypes';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';

export const getMenuListEpic = ($action: Observable<any>, $state: any) => $action.pipe(
    ofType(MenuActionsTypes.GET_MENU_LIST_REQUEST),
    delay(150),
    switchMap(() => from(getMenuListApi())
        .pipe(
            mergeMap((pages: PageMenu[]) => {
                const { value } = $state;
                const { menuReducer } = value;

                const currentPages: PageMenu[] = menuReducer.pages;

                let currentSelectedIndex: number = currentPages.findIndex((page: PageMenu) => page.selected);
                if (currentSelectedIndex > -1) {
                    pages[currentSelectedIndex].selected = true;
                } else {
                    currentSelectedIndex = 0;
                    pages[0].selected = true;
                }
                currentPages.forEach((page: PageMenu, index: number) => {
                    if (index !== currentSelectedIndex) {
                        page.selected = false;
                    }
                });

                return [
                    getListMenuActionSuccess(pages),
                    loadingDialogHide(),
                ];
            }),
            catchError(() => of(loadingDialogHide()))
        )
    )
);
