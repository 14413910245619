import { Platform, StyleSheet } from 'react-native';

import {
    colors,
    globalStyle,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    containerShimmer: {
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 80,
        justifyContent: 'space-between',
        marginBottom: -10,
        marginTop: 40,
        paddingHorizontal: 16,
        ...globalStyle.dropShadow,
        ...Platform.select({
            web: {
                marginHorizontal: 0,
            },
            default: {
                marginHorizontal: 16,
            },
        }),
    },
    dataContainer: {
        flex: 80,
        justifyContent: 'center',
    },
    dateShimmer: {
        width: '45%',
        height: '20%',
    },
    textShimmer: {
        width: '70%',
        height: '25%',
        marginBottom: 7,
    },
});
