import * as React from 'react';

import { View } from 'react-native';

import { ShimmerApp } from '@Components/ShimerApp';
import { styles } from '@Activity/styles/PaymentStatementDocumentShimmerStyles';

export const PaymentStatementDocumentItemShimmer = () => {

    const numberRows = [1, 2, 3, 4];

    const renderListShimmer = numberRows.map((_: any, index: number) => (
        <View key={index}
            style={styles.containerShimmer}>
            <View style={styles.dataContainer}>
                <ShimmerApp
                    visible={Boolean(false)}
                    shimmerStyle={styles.textShimmer}
                />
                <ShimmerApp
                    visible={Boolean(false)}
                    shimmerStyle={styles.dateShimmer}
                />
            </View>
        </View>
    ));

    return (
        <>
            {renderListShimmer}
        </>
    );
};
