import React from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import HTMLView from 'react-native-htmlview';

import {
    AddIcon,
    LessIcon,
} from '@Assets/index';
import { FAQ } from '@Profile/interfaces';
import { styles } from '@Profile/styles/FAQItemWebStyles';
import { theme } from '@Theme/appTheme';

interface Props {
    handleOnPress: (index: number) => void;
    index: number;
    item: FAQ;
};

const FAQItem = ({
    handleOnPress,
    index,
    item,
}: Props) => (
    <TouchableOpacity
        accessibilityLabel={`faqTouchableItem${index + 1}`}
        onPress={() => handleOnPress(index)}
        style={styles.container}
        testID={`faqTouchableItem${index + 1}`}
    >
        <View style={styles.viewQuestions}>
            <Text style={styles.textQuestion}>
                {item.question}
            </Text>
            <View style={styles.touchableQuestion}>
                {item.selected ?
                    <LessIcon
                        fill={theme.colors.primary}
                    />
                    :
                    <AddIcon
                        fill={theme.colors.primary}
                    />
                }
            </View>
        </View>
        {
            item.selected && (
                item.answer.includes('</a>') ? (
                    <HTMLView
                        value={`<p>${item.answer}</p>`}
                        stylesheet={styles} />
                ) : (
                    <Text style={styles.textanswer}>
                        {item.answer}
                    </Text>
                )
            )
        }
    </TouchableOpacity>
);

export default React.memo(
    FAQItem,
);
