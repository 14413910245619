import React, { useEffect, useState } from 'react';
import { RootStackParamList } from "@Navigation/BottomTabNavigator";
import { StackScreenProps } from "@react-navigation/stack";
import { FlatList, Platform, View } from "react-native";
import { useAppDispatch, useAppSelector } from '@Hooks/appHooks';
import { NewStatementResponse } from '../interfaces';
import NewStamentService from '../services/NewStatementService';
import { getNewStatements } from '../state/statementActions';
import { styles } from '../styles/newStatementStyles';
import { AppListEmptyState } from '@Components/AppListEmptyState';
import { ShimmerApp } from '@Components/ShimerApp';
import { AppEmptyState } from '@Components/AppEmptyState';
import { NewStatementItem } from '../components/NewStatementItem';

interface Props extends StackScreenProps<RootStackParamList, 'NewStatementsScreen'> { }

const isWeb = Platform.OS === 'web';

export const NewStatementsScreen = ({ navigation }: Props) =>  {
    const dispatch = useAppDispatch();
    const [statements, setStataments] = useState<NewStatementResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        setIsLoading(true);
        const statementSubscriber = NewStamentService.subscribe((data: NewStatementResponse[]) => {
            setStataments(data);
        });
        setIsLoading(false);

        return () => {
            statementSubscriber.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        dispatch(getNewStatements({
            applicationId: loanSelected?.applicationId,
        }));
    }, [loanSelected]);

    const handleRenderItem = ({ item }: any) => (
        <NewStatementItem item={(item)} />
    );

    const handleRenderSeparator = () => (
        <View style={styles.separator} />
    );


    return (
        <View style={[styles.container, (isWeb && !Boolean(statements.length)) && styles.containerBackground]}>
            {isWeb ? (
                <AppListEmptyState
                    accessibilityLabel={'statementsAppListEmptyStateStatements'}
                    displayEmptyState={Boolean(statements.length)}
                    testID={'statementsAppListEmptyStateStatements'}
                    title='Statements'
                >
                    <View style={{ width: '100%' }}>
                        <ShimmerApp
                            visible={Boolean(!isLoading)}
                            contentStyle={styles.listActitivies}
                            shimmerStyle={styles.listActitivies}
                        >
                            <FlatList
                                data={statements}
                                initialNumToRender={4}
                                ItemSeparatorComponent={handleRenderSeparator}
                                keyExtractor={(_: any, index: number) => index.toString()}
                                renderItem={handleRenderItem}
                                style={styles.list}
                                showsVerticalScrollIndicator={false}
                            />
                        </ShimmerApp>
                    </View>
                </AppListEmptyState >
            ) : (
                <>{statements.length ?
                    <FlatList
                        data={statements}
                        initialNumToRender={4}
                        ItemSeparatorComponent={handleRenderSeparator}
                        keyExtractor={(_: any, index: number) => index.toString()}
                        renderItem={handleRenderItem}
                        style={styles.list}
                        showsVerticalScrollIndicator={false}
                    /> :
                    <View style={styles.emptyStateContainer}>
                        <AppEmptyState
                            accessibilityLabel={'statementsAppEmptyStateStatements'}
                            testID={'statementsAppEmptyStateStatements'}
                            title='Statements'
                        />
                    </View>
                }</>
            )}
        </View >
    );
}