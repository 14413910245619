import appAPI from '@Api/appApi';
import { Commands } from '@Api/config';
import {
    FAQ,
    FAQRequest,
} from '@Profile/interfaces';

export const getFaqAPI = (request: FAQRequest): Promise<FAQ[]> => new Promise(async (resolve, reject) => {
    try {
        const validRequest = {
            ...request,
            category: request.category === 'All' ? '' : request.category,
        };
        const response = await appAPI.get<FAQ[]>(Commands.GET_FAQ, {
            params: validRequest,
        });
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});
