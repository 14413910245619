enum ProfileActionsTypes {
    PROFILE_CLAIM_REQUEST = '@profile/PROFILE_CLAIM_REQUEST',
    PROFILE_MENU_OPTIONS_ERROR = '@profile/PROFILE_MENU_OPTIONS_ERROR',
    PROFILE_MENU_OPTIONS_REQUEST = '@profile/PROFILE_MENU_OPTIONS_REQUEST',
    PROFILE_MENU_OPTIONS_SUCCESS = '@profile/PROFILE_MENU_OPTIONS_SUCCESS',
    PROFILE_USER_INFO_CLEAN = '@profile/PROFILE_USER_INFO_CLEAN',
    PROFILE_USER_INFO_ERROR = '@profile/PROFILE_USER_INFO_ERROR',
    PROFILE_USER_INFO_REQUEST = '@profile/PROFILE_USER_INFO_REQUEST',
    PROFILE_USER_INFO_SUCCESS = '@profile/PROFILE_USER_INFO_SUCCESS',
    PROFILE_USER_UPDATE_PHONE_REQUEST = '@profile/PROFILE_USER_UPDATE_PHONE_REQUEST',
    PROFILE_USER_UPDATE_REQUEST = '@profile/PROFILE_USER_UPDATE_REQUEST',
    PROFILE_USER_ZIP_CODE_REQUEST = '@profile/PROFILE_USER_ZIP_CODE_REQUEST',
};

export default ProfileActionsTypes;
