import React from 'react';

import { Platform } from 'react-native';

import { RefreshingScreen } from '@Auth/screens/RefreshingScreen';
import Languages from '@i18n/index';
import { createStackNavigator } from '@react-navigation/stack';
import { theme } from '@Theme/appTheme';

export type RootStackParamList = {
    RefreshingScreen: {};
};

const Stack = createStackNavigator<RootStackParamList>();

export const RefreshingWebStack = () => (
    <Stack.Navigator
        initialRouteName={'RefreshingScreen'}
        screenOptions={{
            headerLeftContainerStyle: {
                alignSelf: 'center',
            },
            headerStyle: {
                backgroundColor: 'white',
                borderBottomColor: 'transparent',
            },
            headerTintColor: theme.colors.primary,
            headerTitleStyle: {
                alignSelf: 'center',
                marginRight: Platform.OS === 'android' ? 50 : 0,
                textAlign: 'center',
            },
        }}
    >
        <Stack.Screen name='RefreshingScreen' component={RefreshingScreen} options={{
            headerShown: false,
            title: Languages.MyEasyPayApp,
        }} />
    </Stack.Navigator >
);
