import React from 'react';

import {
    Modal,
    ModalBaseProps,
    StatusBar,
    StyleProp,
    StyleSheet,
    TouchableWithoutFeedback,
    View,
    ViewStyle,
} from 'react-native';

import { colors } from '@Theme/appTheme';

interface Props extends ModalBaseProps {
    children: JSX.Element;
    closeModal?: () => void;
    style?: StyleProp<ViewStyle>;
};

export const AppModal = ({
    animationType,
    children,
    closeModal,
    transparent,
    visible = false,
    style = styles.container,
}: Props) => {
    const handleForceClose = () => closeModal && closeModal();

    return (
        <Modal
            animationType={animationType}
            transparent={transparent}
            onRequestClose={handleForceClose}
            visible={visible}
        >
            <StatusBar backgroundColor={visible ? 'rgba(0,0,0,0.51)' : 'transparent'} />
            <TouchableWithoutFeedback
                disabled={closeModal === undefined}
                style={styles.closeContainer}
                onPress={handleForceClose}
            >
                <View style={[
                    styles.container,
                    style && style,
                ]} >
                    {children}
                </View>
            </TouchableWithoutFeedback>
        </Modal >
    );
};

const styles = StyleSheet.create({
    closeContainer: {
        flex: 1,
        height: '100%',
        width: '100%',
    },
    container: {
        alignItems: 'center',
        backgroundColor: colors.blackOpacity,
        flex: 1,
        justifyContent: 'center',
    },
});
