import React from 'react';

import {
    StyleProp,
    StyleSheet,
    Text,
    View,
    ViewStyle,
} from 'react-native';

import { ErrorIconSvg } from '@Assets/index';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Props {
    children: JSX.Element;
    containerStyle?: StyleProp<ViewStyle>;
    enabled?: boolean;
    error?: string;
    isError?: boolean;
    isFocused?: boolean;
    textInputContainerStyle?: StyleProp<ViewStyle>;
    disableErrorIcon?: boolean;
};
export const AppTextInput = ({
    children,
    containerStyle,
    enabled = true,
    error = '',
    isError = false,
    isFocused,
    textInputContainerStyle,
    disableErrorIcon,
}: Props) => (
    <View style={[
        styles.container,
        containerStyle as any,
    ]}>
        <View style={[
            styles.textInputContainer,
            textInputContainerStyle as any,
            isFocused ? styles.borderSelected : styles.border,
            isError ? styles.borderError : styles.marginError,
            !enabled && { backgroundColor: colors.grayDisabled },
        ]}>
            {(isError && !disableErrorIcon) && (
                <View
                    style={{
                        position: 'absolute',
                        width: 50,
                        top: 0,
                        bottom: 0,
                        right: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 999,
                    }}
                >
                    <ErrorIconSvg
                        width={22}
                        height={22}
                    />
                </View>
            )}
            {children}
        </View>
        {isError && (
            <Text
                style={[
                    styles.textError,
                    styles.marginError,
                ]}
                numberOfLines={3}
                ellipsizeMode={'tail'}
            >
                {error}
            </Text>
        )}
    </View>
);

const styles = StyleSheet.create({
    borderSelected: {
        borderWidth: 2,
        borderColor: theme.colors.primary,
    },
    border: {
        borderWidth: 1,
        borderColor: theme.colors.border,
    },
    container: {
    },
    marginError: {
        marginBottom: 15,
    },
    borderError: {
        borderColor: colors.red,
    },
    textError: {
        color: colors.red,
        fontSize: 10,
        marginTop: 2,
        marginHorizontal: 16,
    },
    textInputContainer: {
        backgroundColor: colors.white,
        borderRadius: 5,
        height: 45,
        paddingHorizontal: 16,
    },
});
