import React, {
    useEffect,
    useState,
} from 'react';

import {
    Text,
    View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { AppDropdown } from '@Components/AppDropdown';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import {
    AddCardForm,
    AddCheckingForm,
} from '@MethodsOfPayment/components/index';
import {
    TypeAHC,
    TypeCard,
    TypesMethod,
} from '@MethodsOfPayment/interfaces';
import PaymenthMethodService from '@MethodsOfPayment/services/PaymentOfMethodService';
import {
    putAHCRequestAction,
    putCardRequestAction,
    saveNewAHCRequest,
    saveNewCardRequest,
} from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import { styles } from '@MethodsOfPayment/styles/AddPaymentMethodWebStyle';

import { PaymentMethodResponseBase } from '../interfaces/index';
import { MasterCardIcon, VisaCardIcon } from '@Assets/index';
import { StyleSheet } from 'react-native';
import { fonts } from '@Theme/appTheme';

interface Props {
    paymentMethod?: PaymentMethodResponseBase;
    isVisible: boolean;
    handleCancel?: () => void;
    handleSuccess?: (item?: any) => any;
};

const PickerData = [
    { label: 'Debit Card', value: TypesMethod.DEBITCARD },
    { label: 'Credit Card', value: TypesMethod.CREDITCARD },
    { label: 'Checking Account', value: TypesMethod.ACH },
];

export const AddPaymentMethodScreen = ({
    paymentMethod,
    handleCancel = () => { },
    handleSuccess = (item) => item,
}: Props) => {
    const dispatch = useAppDispatch();
    const { loanSelected } = useAppSelector(({ applicationsReducer }) => applicationsReducer);

    const [methodType, setMethodType] = useState<any>(0);
    const [errorMetthodType, setErrorMethodtype] = useState('');
    const [dataToEdit, setDataToEdit] = useState<TypeCard | TypeAHC | undefined>();

    useEffect(() => {
        if (paymentMethod) {
            const { type } = paymentMethod;
            setMethodType(type);
            if (type === TypesMethod.ACH) {
                setDataToEdit({
                    accountNumber: '************',
                    bankName: paymentMethod.nickname,
                    routingNumber: paymentMethod.routingNumber,
                } as TypeAHC);
            } else {
                setDataToEdit({
                    expirationDate: paymentMethod.expirationDate,
                    nameCard: paymentMethod.note,
                    nickname: paymentMethod.nickname,
                    numberCard: `************${paymentMethod.lastFourDigit}`,
                    type: paymentMethod.type,
                    enableAutoPay: false
                } as TypeCard);
            }
        }
    }, [paymentMethod]);

    useEffect(() => {
        const paymentMethodService = PaymenthMethodService.subscribe((state: any) => {
            const { success } = state;
            if (success) {
                handleSuccess(state);
            }
        });

        return () => {
            paymentMethodService.unsubscribe();
        };
    }, []);

    const handleChangeType = (value: any) => {
        if (value !== Languages.PaymentType) {
            setMethodType(value);
            setErrorMethodtype('');
        }
    };

    const handleSubmitCard = (values: TypeCard) => {
        if (loanSelected) {
            if (dataToEdit) {
                handleSubmitEditCard(values);
            } else {
                handleSubmitSaveCard(values);
            }
        }
    };

    const handleSubmitSaveCard = (values: TypeCard) => {
        if (methodType) {
            const loanId = loanSelected?.loanId || 0;
            LoggerService.logEvent('createCard', {
                type: methodType,
            });
            dispatch(saveNewCardRequest({
                ...values,
                loanId,
                type: methodType,
            }));
            handleCancel();
        } else {
            setErrorMethodtype(Languages.emptyField);
        }
    };

    const handleSubmitEditCard = (values: TypeCard) => {
        LoggerService.logEvent('editCard', {});
        dispatch(putCardRequestAction({
            id: paymentMethod?.id || 0,
            nickname: values.nickname,
            enableAutoPay: values.enableAutoPay,
            expirationDate: values.expirationDate
        }));
        handleCancel();
    };

    const handleSubmitACH = (values: TypeAHC) => {
        if (loanSelected) {
            if (dataToEdit) {
                handleSubmitEditACH(values);
            } else {
                handleSubmitSaveAHC(values);
            }
        }
    };

    const handleSubmitSaveAHC = (values: TypeAHC) => {
        if (methodType) {
            const loanId = loanSelected?.loanId || 0;
            LoggerService.logEvent('createAHC', {});
            dispatch(saveNewAHCRequest({
                ...values,
                loanId,
            }));
            handleCancel();
        } else {
            setErrorMethodtype(Languages.emptyField);
        }
    };

    const handleSubmitEditACH = (values: TypeAHC) => {
        LoggerService.logEvent('editAHC', {});
        dispatch(putAHCRequestAction({
            id: paymentMethod?.id || 0,
            loanId: loanSelected?.loanId || 0,
            nickname: values.bankName,
        }));
        handleCancel();
    };

    const handleClose = () => handleCancel();

    const renderCardOrACH = () => methodType === TypesMethod.ACH
        ? <AddCheckingForm initialData={dataToEdit as TypeAHC} handleSubmit={handleSubmitACH} handleCancel={handleClose} />
        : <AddCardForm initialData={dataToEdit as TypeCard} handleSubmit={handleSubmitCard} handleCancel={handleClose} />;

    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                contentContainerStyle={styles.containerScroll}
                bounces={false}
                enableOnAndroid={false}
            >
                <Text style={styles.header}>{paymentMethod ? Languages.EditPaymentMethod : Languages.AddNewPaymentMethod}</Text>
                <AppDropdown
                    data={PickerData}
                    onChange={handleChangeType}
                    placeholder={Languages.PaymentType}
                    value={methodType}
                    error={errorMetthodType}
                    disabled={!!paymentMethod}
                />

                { ((methodType === TypesMethod.CREDITCARD || methodType === TypesMethod.DEBITCARD) && !dataToEdit) ? (
                    <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row', width: '90%', paddingHorizontal: 10, marginTop: -10, marginBottom: 10}}).item]}>
                        <Text style={[StyleSheet.create({ item: { marginRight: 10, fontFamily: fonts.SFProDisplay }}).item]}>WE ACCEPT</Text>
                        <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row'}}).item]}>
                            <View style={[StyleSheet.create({ item: { marginRight: 5 }}).item]}>
                                <VisaCardIcon />
                            </View>
                            <View>
                                <MasterCardIcon />
                            </View>
                        </View>
                    </View>
                    ) : null}
                <View>
                </View>

                <View style={styles.containerForm}>
                    {renderCardOrACH()}
                </View>
            </KeyboardAwareScrollView>
        </View>
    );
};
