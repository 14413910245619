enum ApplicationsActionsTypes {
    DOWNLOAD_APPLICATIONS_REQUEST = '@applications/DOWNLOAD_APPLICATIONS_REQUEST',
    EMPTY = '@applications/EMPTY',
    GET_APPLICATIONS_REQUEST = '@applications/GET_APPLICATIONS_REQUEST',
    GET_DOCUMENT = '@applications/GET_DOCUMENT',
    GET_DOCUMENT_ARRAY_BUFFER = '@applications/GET_DOCUMENT_ARRAY_BUFFER',
    SAVE_APPLICATION_SELECTED = '@applications/SAVE_APPLICATION_SELECTED',
    SAVE_APPLICATIONS = '@applications/SAVE_APPLICATIONS',
    SET_APPLICATION_SELECTED = '@applications/SET_APPLICATION_SELECTED',
    SET_SELECTABLE_APPLICATIONS = '@applications/SET_SELECTABLE_APPLICATIONS',
};

export default ApplicationsActionsTypes;
