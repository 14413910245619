import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    buttonStyle: {
        borderRadius: 5,
        marginBottom: 20,
        width: 350,
    },
    buttonShimmer: {
        width: 350,
        height: 40,
    },
    containerInputStyle: {
        alignItems: 'center',
        flex: 1,
    },
    flatList: {
        marginHorizontal: 16,
        marginTop: 25,
        overflow: 'visible',
    },
    FlatListShimmer: {
        height: 350,
        width: '100%',
    },
    footerPayment: {
        alignItems: 'center',
        flex: 2,
        height: 100,
        marginTop: 100,
    },
    headerShimmer: {
        height: 20,
        width: '40%',
        top: 5,
        alignItems: 'center',
        alignSelf: 'center',
    },
    itemSeparator: {
        backgroundColor: colors.gray,
        height: 1,
        marginVertical: 10,
    },
    questionTouchable: {
        justifyContent: 'flex-start',
        marginLeft: 16,
    },
    radioShimmer: {
        height: 20,
        width: 20,
        borderRadius: 27.5,
    },
    textAmountPayment: {
        color: colors.grayColor,
        fontFamily: fonts.Roboto,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
        textAlign: 'left',
    },
    textInput: {
        color: colors.grayDarkColor,
        flex: 1,
        fontFamily: fonts.SFProText,
        fontSize: 12,
        fontWeight: '400',
        width: '100%',
        ...Platform.select({
            web: {
                outlineColor: 'transparent',
                outlineStyle: 'none',
                outlineWidth: 0,
            },
        }),
    },
    textTitle: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
        marginRight: 16,
    },
    titlePayment: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '500',
        justifyContent: 'center',
        lineHeight: 28,
        paddingTop: 10,
        textAlign: 'center',
    },
    touchableOpacity: {
        borderColor: theme.colors.primary,
        flex: 10,
        height: 40,
        justifyContent: 'center',
    },
    viewAmount: {
        alignItems: 'flex-end',
        flex: 30,
    },
    viewFlatlist: {
        backgroundColor: colors.white,
        borderRadius: 10,
        flex: 100,
        flexDirection: 'column',
        top: 16,
    },
    viewIsInput: {
        flex: 50,
        height: 15,
        marginRight: 20,
    },
    viewIsInputSecond: {
        flex: 10,
        height: 15,
        marginRight: 20,
    },
    viewPayment: {
        alignItems: 'center',
        flex: 100,
        flexDirection: 'row',
    },
    viewTextTitle: {
        flex: 60,
        flexDirection: 'row',
    },
    textTooltip: {
        color: colors.white,
        fontSize: 15,
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
        textAlign: 'left',
        fontFamily: fonts.SFProText,
    },
    containerTooltip: {
        width: '100%',
        alignItems: 'center',
    },
});
