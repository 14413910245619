import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import React, { useState } from 'react';
import { StyleProp, ViewStyle, TouchableOpacity, View, Text } from 'react-native';
import {
    styles,
} from '@MethodsOfPayment/styles/PaymentMethodsDropZoneStyle';
import { Icon } from '@Components/Icon';
import { KebabMenuIcon } from '@Assets/index';
import { Menu, MenuItem } from 'react-native-material-menu';
import { Loan } from '@Profile/interfaces';
import Languages from '@i18n/index';
import { debounce } from 'lodash';

interface Props {
    index: number;
    item: PaymentMethodResponseBase;
    loanSelected?: Loan;
    onEdit: (item: PaymentMethodResponseBase) => void;
    onInactivate: (item: PaymentMethodResponseBase) => void;
    onSetAsPrimary: (index: number) => void;
    onAutoPayTurnOff: (item: PaymentMethodResponseBase) => void;
    style?: StyleProp<ViewStyle>;
};

const PaymentMethodsDropZoneItem = ({ index, item, loanSelected, onEdit, onInactivate, onSetAsPrimary, onAutoPayTurnOff, style }: Props) => {
    const [showByMenu, setShowByMenu] = useState(-1);

    const {
        canDeleteMethodOfPayment,
        canEditMethodOfPayment,
        canSetPrimaryMethod,
    } = loanSelected?.permissions || {
        canDeleteMethodOfPayment: true,
        canEditMethodOfPayment: true,
        canSetPrimaryMethod: true,
    };

    const handledIsSelected = (index: number) => {
        setShowByMenu(index);
    };

    const hideMenu = debounce(() => setShowByMenu(-1), 200);

    return (
        <View style={[
            styles.item,
            style,
        ]}>
            <View style={styles.itemDataContainer}>
                <Icon name={item.paymentNetwork} />
                <Text style={styles.textTitle}>{item.nickname}</Text>
                <Text style={styles.textSubtitle}>{`* ${item.lastFourDigit}`}</Text>
            </View>
            <Menu
                visible={showByMenu === index}
                anchor={<TouchableOpacity
                    testID={'PaymentMethodsMenu'}
                    accessibilityLabel={'PaymentMethodsMenu'}
                    onPress={debounce(() => handledIsSelected(index), 200)}
                >
                    <KebabMenuIcon />
                </TouchableOpacity>}
                onRequestClose={hideMenu}
                style={styles.menu}
            >
                <MenuItem
                    accessibilityLabel={'PaymentMethodsMenuEdit'}
                    disabled={!canEditMethodOfPayment}
                    onPress={() => {
                        hideMenu();
                        onEdit(item);
                    }}
                    testID={'PaymentMethodsMenuEdit'}
                    textStyle={canEditMethodOfPayment ? styles.menuItem : styles.menuItemDisabled}
                >
                    {Languages.Edit}
                </MenuItem>
                {index !== 999 &&
                    <>
                        <MenuItem
                            accessibilityLabel={'PaymentMethodsMenuInactivate'}
                            disabled={!canDeleteMethodOfPayment}
                            onPress={() => {
                                hideMenu();
                                onInactivate(item);
                            }}
                            testID={'PaymentMethodsMenuInactivate'}
                            textStyle={canDeleteMethodOfPayment ? styles.menuItem : styles.menuItemDisabled}
                        >
                            {Languages.Inactivate}
                        </MenuItem>
                        <MenuItem
                            accessibilityLabel={'PaymentMethodsMenuSetAsPrimary'}
                            disabled={!canSetPrimaryMethod}
                            onPress={() => {
                                hideMenu();
                                onSetAsPrimary(index);
                            }}
                            testID={'PaymentMethodsMenuSetAsPrimary'}
                            textStyle={canSetPrimaryMethod ? styles.menuItem : styles.menuItemDisabled}
                        >
                            {Languages.SetAutoPayOn}
                        </MenuItem>
                    </>
                }
                {
                    index === 999 &&
                    <>
                        <MenuItem
                            accessibilityLabel={'PaymentMethodsMenuTurnAutoPayOff'}
                            onPress={() => {
                                hideMenu();
                                onAutoPayTurnOff(item);
                            }}
                            testID={'PaymentMethodsMenuAutoPayOff'}>
                            {Languages.SetAutoPayOff}
                        </MenuItem>
                    </>
                }
            </Menu>
        </View>
    );
};

export default React.memo(PaymentMethodsDropZoneItem);
