import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { VerificationCodeDialogState } from '@VerificationCodeDialog/interfaces';
import { VerificationCodeDialogRequestAction } from '@VerificationCodeDialog/interfaces/index';
import * as verificationCodeDialogActions
    from '@VerificationCodeDialog/state/verificationCodeDialogActions';

type ActionsType = ActionType<typeof verificationCodeDialogActions>;

export const initialState: VerificationCodeDialogState = {
    _owner: '',
    handleClickEnter: () => { },
    handleClickResend: () => { },
    isVisible: false,
    context: 'Registration',
};

export const verificationCodeDialogReducer = createReducer<VerificationCodeDialogState, ActionsType>(
    initialState,
).handleAction(verificationCodeDialogActions.verificationCodeDialogShow, (
    _: VerificationCodeDialogState,
    { payload }: VerificationCodeDialogRequestAction,
) => ({
    ...payload,
    isVisible: true,
})).handleAction(verificationCodeDialogActions.verificationCodeDialogHide, () => ({
    ...initialState,
}));
