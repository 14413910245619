import { Subject } from 'rxjs';

import { FirstTimeUser } from '@Auth/interfaces';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import { getAppData } from '@Utils/index';

const OnboardingService = () => {
    const subject = new Subject();

    const show = async (email: string) => {
        const canOpen = await isFirstTime(email);
        const timeout = setTimeout(() => {
            subject.next({
                name: 'onboardingScreen',
                open: canOpen,
                email,
            });
            clearTimeout(timeout);
        }, 100);
    };

    const hide = (email: string) => {
        updateFirstTime(email);
        subject.next({
            name: 'onboardingScreen',
            open: false,
            email,
        });
    };

    const subscribe = (process: any) => subject.subscribe(process);

    const isFirstTime = async (email: string): Promise<boolean> => {
        const { getData, storeData } = useStorage();
        const data = await getData({
            key: StorageKeys.FIRST_TIME_USERS,
        });
        let firstTimeUsers: FirstTimeUser[] = [];
        if (data.success) {
            firstTimeUsers = data?.value as FirstTimeUser[];
            if (firstTimeUsers && firstTimeUsers.length > 0) {
                const currentFirstTimeUser = firstTimeUsers
                    .filter((item: FirstTimeUser) => item.email === email)[0];
                if (currentFirstTimeUser) {
                    if (shouldReset(currentFirstTimeUser)) {
                        return true;
                    }
                    return currentFirstTimeUser.isFirstTime;
                }
            }
        }
        await storeData({
            key: StorageKeys.FIRST_TIME_USERS,
            data: [
                ...firstTimeUsers,
                {
                    email,
                    isFirstTime: true,
                    wasReset: true,
                },
            ],
        });
        return true;
    };

    const updateFirstTime = async (email: string) => {
        const { getData, storeData } = useStorage();
        const data = await getData({
            key: StorageKeys.FIRST_TIME_USERS,
        });
        let firstTimeUsers: FirstTimeUser[] = [];
        if (data.success) {
            firstTimeUsers = data?.value as FirstTimeUser[];
            if (firstTimeUsers && firstTimeUsers.length > 0) {
                const currentFirstTimeUser = firstTimeUsers
                    .filter((item: FirstTimeUser) => item.email === email)[0];
                if (currentFirstTimeUser) {
                    currentFirstTimeUser.isFirstTime = false;
                    currentFirstTimeUser.wasReset = true;
                    await storeData({
                        key: StorageKeys.FIRST_TIME_USERS,
                        data: firstTimeUsers,
                    });
                }
            }
        }
    };

    const shouldReset = (firstTimeUser: FirstTimeUser): boolean => {
        if (firstTimeUser.wasReset) {
            return false;
        }
        const appData = getAppData();
        if (appData.store === '1.5.0') {
            return true;
        }
        return false;
    };

    return {
        show,
        hide,
        subscribe,
    };
};

export default OnboardingService();
