import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';

import {
    Entity,
    PaymentActivity,
    PendingPayment,
} from '@Activity/interfaces';
import PaymentActivityService from '@Activity/services/PaymentActivityService';
import { getPaymentActivities, getPaymentPendings } from '@Activity/state/activityActions';
import { styles } from '@ActivityMobile/styles/ActivitiesStyles';
import { ArrowRightIcon } from '@Assets/index';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import { format } from 'date-fns';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import PaymentPendingService from '@Activity/services/PaymentPendingService';
import PaymentPendingUpdateService from '@Activity/services/PaymentPendingUpdateService';
import PaymentPendingCancelService from '@Activity/services/PaymentPendingCancelService';
import { ActivityWebItemScreen } from './ActivityItemScreen.web';
import { AppButton } from '@Components/AppButton';
import { colors, theme } from '@Theme/appTheme';

interface PropsItem {
    item: Entity;
    index: number;
};

interface PropsRenderItem {
    index: number;
    item: PendingPayment;
};

export const ActivityWebScreen = () => {
    useFocusEffect(() => {
        return () => {
            if (viewItem && !!currentItem) {
                setViewItem(false);
                setCurrentItem(undefined);
            }
        }
    })

    const dispatch = useAppDispatch();
    const { width: windowWidth } = useWindowDimensions();
    const [pendings, setPendings] = useState<PendingPayment[]>([]);
    const [entities, setEntities] = useState<Entity[]>([]);
    const [page, setPage] = useState<number>(1);
    const totalPagesRef = useRef(0);
    const navigation = useNavigation();
    const [viewItem, setViewItem] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<Entity | PendingPayment | undefined>();

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        dispatch(getPaymentActivities({
            loanId: loanSelected?.loanId,
            page: 1,
            pageSize: 4,
            source: 'recent',
        }));
    }, [page, loanSelected]);

    useEffect(() => {
        const subscription = PaymentActivityService.subscribe((state: PaymentActivity) => {
            const { entities: newEntities, source, totalPages } = state;
            if (source === 'recent') {
                totalPagesRef.current = totalPages;
                setEntities(newEntities);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [entities]);

    useEffect(() => {
        if (loanSelected) {
            dispatch(getPaymentPendings({
                loanId: loanSelected.loanId,
            }));
        }
    }, [loanSelected]);

    useEffect(() => {
        const pendingSubscribe = PaymentPendingService.subscribe((data: PendingPayment[]) => {
            setPendings(data);
        });

        const updatePendingSubscribe = PaymentPendingUpdateService.subscribe((response: any) => {
            if (response.success && loanSelected) {
                dispatch(getPaymentPendings({
                    loanId: loanSelected.loanId,
                }));
            }
        });
        const cancelPaymentPendingSubscribe = PaymentPendingCancelService.subscribe((response: any) => {
            if (response.success && loanSelected) {
                dispatch(getPaymentPendings({
                    loanId: loanSelected.loanId,
                }));
            }
        });

        return () => {
            pendingSubscribe.unsubscribe();
            updatePendingSubscribe.unsubscribe();
            cancelPaymentPendingSubscribe.unsubscribe();
        };
    }, []);

    const handleViewAll = () => {
        return navigation.navigate('ActivityAll');
    };
    
    const handleStatements = () => {
        return navigation.navigate('StatementsScreen');
    };

    const handleClickItem = (item: Entity | PendingPayment) => {
        if (item) {
            setViewItem(true);
            setCurrentItem(item);
        }
    };

    const resetDetails = () => {
        setViewItem(false);
        setCurrentItem(undefined);
    };

    const handleResponsive = () => {
        return StyleSheet.create({
            mainContainer: {
                flexDirection: 'column',
                alignItems: 'center',
            },
            card: {
                marginHorizontal: 10,
                width: windowWidth < 968 ? '100%' : '49%',
                maxWidth: windowWidth < 968 ? '100%' : 700,
            }
        });
    };

    const handleRenderItem = ({ item, index }: PropsItem) => (
        <TouchableOpacity onPress={() => handleClickItem(item)}>
            <View style={[styles.activityItem, StyleSheet.create({ item: { borderTopWidth: index === 0 ? 0 : 1 }}).item]}>
                <View style={styles.activityItemLeft}>
                    <Text style={styles.activityItemTitle}>{item.title}</Text>
                    <Text style={styles.activityItemMetaData}>{format(new Date(item.dateString), 'MMM dd, yyyy')}</Text>
                    <Text style={styles.activityItemMetaData}>
                        { Languages.PaymentTypeDetails.replace('{type}', item.paymentType || '').replace('{lastFour}', item.paymentLastFourDigit || 'XXXX')}
                    </Text>
                </View>
                <View style={styles.activityItemRight}>
                    <Text style={[styles.activityAmount, StyleSheet.create({ item: { color: item.paymentAmountColor, marginRight: 10 }}).item ]}>{item.paymentAmount}</Text>
                    <ArrowRightIcon />
                </View>
            </View>
        </TouchableOpacity>
    );
    
    const handleRenderPendingItem = ({ item, index }: PropsRenderItem) => (
        <TouchableOpacity onPress={() => handleClickItem(item)}>
            <View style={[styles.activityItem, StyleSheet.create({ item: { borderTopWidth: index === 0 ? 0 : 1 }}).item]}>
                <View style={styles.activityItemLeft}>
                    <Text style={styles.activityItemTitle}>{Languages.Payment}</Text>
                    <Text style={styles.activityItemMetaData}>{format(new Date(item.date), 'MMM dd, yyyy')}</Text>
                    <Text style={styles.activityItemMetaData}>
                        { Languages.PaymentTypeDetails.replace('{type}', item.paymentMethodType || '').replace('{lastFour}', item.paymentMethod.lastFourDigit || 'XXXX')}
                    </Text>
                </View>
                <View style={styles.activityItemRight}>
                        <Text style={[styles.activityAmount, StyleSheet.create({ item: { marginRight: 10 }}).item ]}>{item.amount}</Text>
                        <ArrowRightIcon />
                </View>
            </View>
        </TouchableOpacity>
    );

    const handleEmptyState = () => {
        return (
            <View style={[styles.gridEmptyRow]}>
                <Text style={styles.gridEmptyRowText}>
                    {Languages.YouHaveNoPendingTransactions}
                </Text>
            </View>
        );
    }

    return (viewItem && currentItem) ? (
        <View style={[styles.mainScrollView]}>
            <ActivityWebItemScreen goBack={resetDetails} item={currentItem} />
        </View>
    ) : (
            <ScrollView style={styles.mainScrollView}>
                <View style={[styles.activitiesContainer, handleResponsive().mainContainer]}>
                    <View style={[styles.activityCard, handleResponsive().card]}>
                        <View style={[styles.activityCardTitle, StyleSheet.create({ item: { borderBottomWidth: pendings.length > 0 ? 1 : 0 }}).item]}>
                            <Text style={styles.activityCardTitleText}>{Languages.PendingTransactions}</Text>
                        </View>
                        <View style={styles.activityCardBody}>
                            <>{(pendings && pendings.length > 0) ? <FlatList
                                data={pendings}
                                initialNumToRender={10}
                                // ItemSeparatorComponent={handleRenderItemSeparator}
                                keyExtractor={(_, index: number) => index.toString()}
                                // ListFooterComponent={handleRenderFooter}
                                renderItem={handleRenderPendingItem}
                                showsVerticalScrollIndicator={false}
                                style={styles.itemList}
                            /> : handleEmptyState()}
                            </>
                        </View>
                    </View>
                    <View style={[styles.activityCard, handleResponsive().card]}>
                        <TouchableOpacity
                            accessibilityLabel={`activitiesViewAllRecentTransactionsTouchable`}
                            onPress={handleViewAll}
                            testID={`activitiesViewAllRecentTransactionsTouchable`}
                        >
                        <View style={[styles.activityCardTitle, StyleSheet.create({ item: { borderBottomWidth: entities.length > 0 ? 1 : 0 }}).item]}>
                            <Text style={styles.activityCardTitleText}>{Languages.RecentTransactions}</Text>
                            {
                                entities.length > 0 ? (
                                        <ArrowRightIcon />
                                        ) : (
                                            <></>
                                            )
                                        }
                        </View>
                        </TouchableOpacity>
                        <View style={styles.activityCardBody}>
                            {
                                entities.length > 0 ? (
                                    <>
                                        <FlatList
                                            data={entities}
                                            initialNumToRender={10}
                                            keyExtractor={(_: Entity, index: number) => index.toString()}
                                            removeClippedSubviews={Platform.OS === 'android'}
                                            renderItem={handleRenderItem}
                                            showsVerticalScrollIndicator={false}
                                            // style={styles.list}
                                            onEndReachedThreshold={0.4}
                                        />
                                        <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.white, borderTopWidth: 1, borderTopColor: colors.veryLightGray3 }}).item]}>
                                            <AppButton 
                                                accessibilityLabel={'pendingAppButtonCalendar'}
                                                buttonStyle={[styles.goBackBtn, StyleSheet.create({ item: { marginTop: 0, fontSize: 12 }}).item]}
                                                handleOnChange={() => handleViewAll()}
                                                testID={'pendingAppButtonCalendar'}
                                                textStyle={[styles.goBackBtnTitle, StyleSheet.create({ item: { fontSize: 16 }}).item]}
                                                title={Languages.ViewAllTransactions}></AppButton>
                                            <View style={[StyleSheet.create({ item: { width: 2, height: 16, marginHorizontal: 10, backgroundColor: colors.grayVeryLigth }}).item]}></View>
                                            <AppButton
                                                accessibilityLabel={'pendingAppButtonCalendar'}
                                                buttonStyle={[styles.goBackBtn, StyleSheet.create({ item: { marginTop: 0, fontSize: 12 }}).item]}
                                                handleOnChange={() => handleStatements()}
                                                testID={'pendingAppButtonCalendar'}
                                                textStyle={[styles.goBackBtnTitle, StyleSheet.create({ item: { fontSize: 16 }}).item]}
                                                title={Languages.ViewStatements}></AppButton>
                                        </View>
                                    </>
                                ) : (
                                    <View style={[styles.gridEmptyRow]}>
                                        <Text style={styles.gridEmptyRowText}>
                                            {Languages.YouHaveNoRecentTransactions}
                                        </Text>
                                    </View>
                                )
                            }
                        </View>
                    </View>
                </View>
            </ScrollView>
        );
};
