import * as Yup from 'yup';

import Languages from '@i18n/index';

export const CardSchema = Yup.object().shape({
    numberCard: Yup
        .string()
        .required(Languages.emptyField)
        .matches(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/, 'Enter a valid card number'),
    nickname: Yup
        .string()
        .matches(/^[aA-zZ\s]+$/, Languages.PleaseEnterOnlyLetters),
    expirationDate: Yup
        .string()
        .required(Languages.emptyField)
        .matches(/(0[1-9]|10|11|12)[/.][2-9][0-9]/, 'Enter valid expiration date'),
});

export const CardEditSchema = Yup.object().shape({
    nickname: Yup
        .string()
        .matches(/^[aA-zZ\s]+$/, Languages.PleaseEnterOnlyLetters),
});
