import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    btnsContainer: {
        alignItems: 'flex-end',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
    },
    buttonCancel: {
        backgroundColor: 'transparent',
        borderColor: theme.colors.primary,
        borderWidth: 2,
        flex: 1,
        flexWrap: 'wrap',
        marginRight: 16,
    },
    buttonSuccess: {
        flex: 1,
        flexWrap: 'wrap',
    },
    container: {
        backgroundColor: colors.white,
        flex: 1,
    },
    containerBody: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 30,
    },
    containerButton: {
        width: '70%',
    },
    containerButtons: {
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginTop: 85,
        minWidth: 175,
        paddingRight: 6,
    },
    containerFooterButtons: {
        height: '28%',
        justifyContent: 'flex-end',
        width: '100%',
    },
    containerInputs: {
        flex: 1,
        flexWrap: 'wrap',
        minWidth: 225,
    },
    containerSecondBody: {
        flex: 100,
        flexDirection: 'row',
        justifyContent: 'center',
        height: 45,
        marginBottom: 16,
    },
    contentKeyboardView: {
        alignItems: 'center',
        flex: 1,
    },
    divider: {
        paddingLeft: 6,
        paddingRight: 6,
    },
    generalContainer: {
        flex: 1,
        width: '100%',
    },
    numberInput: {
        flex: 1,
        marginBottom: 8,
        minWidth: 155,
        paddingLeft: 6,
        paddingRight: 20,
    },
    numberSecondInput: {
        flex: 1,
        marginBottom: 8,
        minWidth: 145,
        paddingLeft: 6,
        paddingRight: 20,
        width: '53%',
    },
    otherInput: {
        flex: 1,
        minWidth: 155,
    },
    privacyPolicyContainer: {
        alignItems: 'center',
        backgroundColor: colors.green,
        borderRadius: 4,
        height: 68,
        justifyContent: 'center',
        marginBottom: 24,
        width: '100%',
    },
    privacyPolicyText: {
        color: colors.blue,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 18,
        textAlign: 'center',
    },
    profileInput: {
        marginBottom: 8,
        paddingLeft: 6,
        paddingRight: 6,
        width: '100%',
    },
    shimmerButton: {
        height: 40,
        marginRight: 5,
        width: '45%',
    },
    shimmerInput: {
        height: 40,
        marginRight: 5,
        marginTop: 5,
        width: '90%',
    },
    shimmershortInput: {
        height: 40,
        marginRight: 5,
        marginTop: 5,
        width: '40%',
    },
    shimmershortInputSecond: {
        height: 40,
        marginRight: 5,
        marginTop: 5,
        width: '40%',
    },
    shimmerViewButton: {
        height: 47,
        marginRight: 5,
        marginTop: 5,
        width: 200,
        flexWrap: 'wrap',
    },
    textCancel: {
        color: theme.colors.primary,
    },
    textOtherInput: {
        flex: 50,
        height: 45,
        marginBottom: 8,
    },
    textSuccess: {
        fontSize: 15,
    },
    txtPrivacyPolicy: {
        color: colors.blue,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '700',
        lineHeight: 18,
        marginLeft: 4,
        textAlign: 'center',
    },
    verifyNumber: {
        flex: 50,
        height: 45,
        alignItems: 'center',
        marginBottom: 8,
        backgroundColor: 'transparent',
        borderColor: theme.colors.primary,
        borderWidth: 2,
    },
    verifyNumberText: {
        color: theme.colors.primary,
        fontSize: 17,
    },
    viewBtnContent: {
        alignItems: 'flex-end',
        height: 45,
        width: '75%',
    },
});
