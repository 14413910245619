import React from 'react';

import { Platform } from 'react-native';

import {
    InternalLoginParams,
    LoginParams,
    RegisterBusinessCenterParams,
} from '@Auth/interfaces';
import {
    ForgotPasswordScreen,
    LoginScreen,
    WelcomeScreen,
} from '@Auth/screens';
import { AccountConfirmationScreen } from '@Auth/screens/AccountConfirmationScreen';
import { BusinessCenterValidationScreen } from '@Auth/screens/BusinessCenterValidationScreen';
import { InternalLoginScreen } from '@Auth/screens/InternalLoginScreen';
import { LinkExpireScreen } from '@Auth/screens/LinkExpireScreen';
import { NewPasswordScreen } from '@Auth/screens/NewPasswordScreen';
import { PasswordResetConfirmationScreen } from '@Auth/screens/PasswordResetConfirmationScreen';
import { RegisterScreen } from '@Auth/screens/RegisterScreen';
import { BackToolbarIcon } from '@Components/BackToolbarIcon';
import { Toolbar } from '@Components/Toolbar.web';
import Languages from '@i18n/index';
import { createStackNavigator } from '@react-navigation/stack';
import { theme } from '@Theme/appTheme';

type LinkExpireType = 'Password' | 'Register';

export type RootStackParamList = {
    AccountConfirmationScreen: {
        code?: string;
        email?: string;
    };
    BusinessCenterValidationScreen: {
        token: string;
    };
    ForgotPassword: {};
    LinkExpireScreen: {
        email: string;
        type: LinkExpireType;
    };
    InternalLogin: {
        token: string;
    };
    LoginScreen: {
        loginParams?: LoginParams;
        internalLoginParams?: InternalLoginParams;
        from?: string;
    };
    PasswordResetConfirmationScreen: {
        code?: string;
        email?: string;
    };
    RegisterScreen: {
        disableEmailField?: boolean;
        registerBusinessCenterParams?: RegisterBusinessCenterParams;
    };
    NewPasswordScreen: {
        email?: string;
        verificationCode?: string;
    };
    WelcomeScreen: {};
};

const isWeb = Platform.OS === 'web';

const Stack = createStackNavigator<RootStackParamList>();

export const NoAuthorizeNavigationStack = () => (
    <Stack.Navigator
        initialRouteName={isWeb ? 'LoginScreen' : 'WelcomeScreen'}
        screenOptions={{
            headerLeftContainerStyle: {
                alignSelf: 'center',
            },
            headerStyle: {
                backgroundColor: 'white',
                borderBottomColor: 'transparent',
            },
            headerTintColor: theme.colors.primary,
            headerTitleStyle: {
                alignSelf: 'center',
                marginRight: Platform.OS === 'android' ? 50 : 0,
                textAlign: 'center',
            },
        }}
    >
        <Stack.Screen name='AccountConfirmationScreen' component={AccountConfirmationScreen} options={{
            headerShown: false,
            title: Languages.AccountConfirmation,
        }} />
        <Stack.Screen name='PasswordResetConfirmationScreen' component={PasswordResetConfirmationScreen} options={{
            headerShown: false,
            title: Languages.PasswordResetConfirmation,
        }} />
        <Stack.Screen name='BusinessCenterValidationScreen' component={BusinessCenterValidationScreen} options={{
            headerShown: false,
            title: Languages.BusinessCenterValidation,
        }} />
        <Stack.Screen name='WelcomeScreen' component={WelcomeScreen} options={{
            headerShown: false,
        }} />
        <Stack.Screen name='LoginScreen' component={LoginScreen} options={{
            headerShown: false,
            title: Languages.SignIn,
        }} />
        <Stack.Screen name='ForgotPassword' component={ForgotPasswordScreen} options={{
            ...isWeb && {
                headerLeft: () => (
                    <BackToolbarIcon fill={theme.colors.primary} />
                ),
            },
            headerBackTitleVisible: false,
            headerShown: !isWeb,
            title: Languages.ForgotPassword,
        }} />
        <Stack.Screen name='InternalLogin' component={InternalLoginScreen} options={{
            headerShown: false,
            title: Languages.InternalLogin,
        }} />
        <Stack.Screen name='NewPasswordScreen' component={NewPasswordScreen} options={{
            ...isWeb && {
                headerLeft: () => (
                    <BackToolbarIcon fill={theme.colors.primary} />
                ),
            },
            headerBackTitleVisible: false,
            headerShown: !isWeb,
            title: Languages.NewPassword,
        }} />
        <Stack.Screen name='RegisterScreen' component={RegisterScreen} options={{
            headerShown: false,
            title: Languages.Register,
        }} />
        <Stack.Screen name='LinkExpireScreen' component={LinkExpireScreen} options={{
            headerShown: isWeb,
            headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,
                backgroundColor: theme.colors.primary,
            },
            headerTitle: () => <Toolbar context={'ExpireLink'} />,
            title: '',
        }} />
    </Stack.Navigator >
);
