import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    a: {
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        color: colors.pureBlue,
        textAlign: 'left',
        textAlignVertical: 'top',
    },
    container: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 8,
        flex: 100,
        justifyContent: 'center',
        marginHorizontal: 12,
        padding: 15,
        shadowColor: colors.shadowColor,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,
    },
    p: {
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        color: colors.grayLigth,
        textAlign: 'left',
        textAlignVertical: 'top',
        marginTop: 20,
    },
    textQuestion: {
        fontFamily: fonts.SFProTextMedium,
        fontWeight: '500',
        fontSize: 17,
        lineHeight: 20,
        color: colors.grayColor,
        textAlign: 'left',
        textAlignVertical: 'top',
        flex: 90,
    },
    textanswer: {
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        color: colors.grayLigth,
        textAlign: 'left',
        textAlignVertical: 'top',
        marginTop: 20,
        marginRight: 20,
    },
    touchableQuestion: {
        flex: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    viewQuestions: {
        alignItems: 'center',
        flex: 100,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
