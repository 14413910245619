import { PaymentMethodSetAutoPay } from './../interfaces/index';
import { createAction } from 'typesafe-actions';

import {
    GetPaymentMethodAutoPayRequest,
    PatchPaymentSetPrimaryRequest,
    PaymentMethodAutoPay,
    PaymentMethodsSortRequest,
    PutACHRequest,
    PutCardRequest,
    PutPaymentMethodAutoPayRequest,
    SaveAHCRequest,
    SaveCardRequest,
} from '@MethodsOfPayment/interfaces';
import MethodsOfPaymentActionsTypes from '@MethodsOfPayment/state/MethodsOfPaymentActionsTypes';
import ManageAutoPayActionsTypes from './ManageAutoPayActionsTypes';

export const getPaymentMethodsAction = createAction(
    MethodsOfPaymentActionsTypes.GET_PAYMENT_METHOD_REQUEST,
    (loanId: number) => (loanId)
)();

export const getPaymentMethodsSuccessAction = createAction(
    MethodsOfPaymentActionsTypes.GET_PAYMENT_METHOD_SUCCESS,
)();

export const getPaymentMethodsErrorAction = createAction(
    MethodsOfPaymentActionsTypes.GET_PAYMENT_METHOD_ERROR,
)();

export const saveNewCardRequest = createAction(
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST,
    (request: SaveCardRequest) => (request)
)();

export const saveNewCardAutoPayRequest = createAction(
    ManageAutoPayActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST,
    (request: SaveCardRequest) => (request)
)();

export const saveNewCardSuccess = createAction(
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_SUCCESS,
)();

export const saveNewCardError = createAction(
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_ERROR,
)();

export const saveNewAHCRequest = createAction(
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST,
    (request: SaveAHCRequest) => (request)
)();

export const saveNewAHCAutoPayRequest = createAction(
    ManageAutoPayActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST,
    (request: SaveAHCRequest) => (request)
)();

export const saveNewAHCSuccess = createAction(
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_SUCCESS,
)();

export const saveNewAHCError = createAction(
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_ERROR,
)();

export const putAHCRequestAction = createAction(
    MethodsOfPaymentActionsTypes.PUT_PAYMENT_METHOD_ACH_REQUEST,
    (request: PutACHRequest) => (request),
)();

export const putCardRequestAction = createAction(
    MethodsOfPaymentActionsTypes.PUT_PAYMENTH_METHOD_CARD_REQUEST,
    (request: PutCardRequest) => (request),
)();

export const loadMethodsOfPayment = createAction(
    MethodsOfPaymentActionsTypes.LOAD_PAYMENT,
    (items: any) => (items),
)();

export const setMethodOfPaymentSelected = createAction(
    MethodsOfPaymentActionsTypes.RESQUEST_SELECTED,
    (selected: any) => (selected),
)();

export const unselectAllMethodsOfPayments = createAction(
    MethodsOfPaymentActionsTypes.UNSELECT_ALL
)();

export const clearMethodsOfPayments = createAction(
    MethodsOfPaymentActionsTypes.CLEAR_PAYMENT
)();

export const getPlaidLinkToken = createAction(
    MethodsOfPaymentActionsTypes.GET_PLAID_LINK_TOKEN,
)();

export const deleteCard = createAction(
    MethodsOfPaymentActionsTypes.DELETE_CARD_PAYMENT_REQUEST,
    (cardId: number) => (cardId),
)();

export const deleteCheck = createAction(
    MethodsOfPaymentActionsTypes.DELETE_CHECK_PAYMENT_REQUEST,
    (checkId: number) => (checkId),
)();

export const patchPaymentMethodsSortAction = createAction(
    MethodsOfPaymentActionsTypes.PATCH_PAYMENT_METHODS_SORT,
    (request: PaymentMethodsSortRequest) => (request),
)();

export const patchPaymentSetPrimaryAction = createAction(
    MethodsOfPaymentActionsTypes.PATCH_PAYMENT_SET_PRIMARY_REQUEST,
    (request: PatchPaymentSetPrimaryRequest) => (request),
)();

export const putPaymentMethodAutoPayAction = createAction(
    MethodsOfPaymentActionsTypes.PUT_AUTOPAY,
    (request: PutPaymentMethodAutoPayRequest) => (request),
)();

export const getPaymentMethodAutoPayAction = createAction(
    MethodsOfPaymentActionsTypes.GET_AUTOPAY,
    (request: GetPaymentMethodAutoPayRequest) => (request),
)();

export const setPaymentMethodAutoPayAction = createAction(
    MethodsOfPaymentActionsTypes.SET_AUTOPAY,
    (request: PaymentMethodSetAutoPay) => (request),
)();

export const getPaymentMethodAutoPaySuccessAction = createAction(
    MethodsOfPaymentActionsTypes.GET_AUTOPAY_SUCCESS,
    (response: PaymentMethodAutoPay) => (response),
)();
