import React, { useState } from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import {
    ArrowRightIcon,
    DisplayCloseIcon,
} from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import Languages from '@i18n/index';
import { Loan } from '@Profile/interfaces';
import { styles } from '@Profile/styles/ApplicationsStylesWeb';
import { colors } from '@Theme/appTheme';

interface Props {
    item: Loan;
    index: number;
    onDownloadPdf: (applicationId: number) => void;
};

const ApplicationItem = ({ item, index, onDownloadPdf }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const onViewTap = () => setIsOpen(!isOpen);

    return (
        <View
            key={index}
            style={[isOpen ? styles.cardStatement : styles.cardStatementClose]}>
            <View style={styles.rowContainer}>
                <View style={styles.dataContainer}>
                    <Text style={styles.title}>{Languages.Account}</Text>
                    <Text style={styles.title}>{`${item.applicationId}`}</Text>
                    <TouchableOpacity
                        accessibilityLabel={`applicationsTouchableViewMore${item.applicationId}`}
                        onPress={onViewTap}
                        style={styles.containerData}
                        testID={`applicationsTouchableViewMore${item.applicationId}`}
                    >
                        {isOpen === false ? (
                            <ArrowRightIcon />
                        ) : (
                            <DisplayCloseIcon />
                        )}
                    </TouchableOpacity>
                </View>
                <View style={styles.lineSeparator}></View>
                <View style={styles.dataContainer}>
                    <Text style={styles.paymentTitle}>{Languages.ContractDate}</Text>
                    <View style={styles.containerData}>
                        <Text style={styles.paymentDate}>{item.contractDate}</Text>
                    </View>
                </View>
                <View style={styles.dataContainer}>
                    <Text style={styles.paymentTitle}>{Languages.FCCPExpirationDate}</Text>
                    <View style={styles.containerData}>
                        <Text style={styles.paymentDate}>{item.fccpExpirationDate}</Text>
                    </View>
                </View>
                <View style={[styles.dataContainer, { backgroundColor: colors.veryPaleBlue }]}>
                    <Text style={styles.paymentTitle}>{Languages.FirstContractualPayment}</Text>
                    <View style={styles.containerData}>
                        <Text style={styles.paymentDate}>{`$${item.figures.paymentAmount}`}</Text>
                    </View>
                </View>
                {isOpen && (
                    <>
                        <View style={styles.dataContainer}>
                            <Text style={styles.paymentTitle}>{Languages.PaymentFrequency}</Text>
                            <View style={styles.containerData}>
                                <Text style={styles.paymentDate}>{item.paymentFrequency}</Text>
                            </View>
                        </View>
                        <View style={[styles.dataContainer, { backgroundColor: colors.veryPaleBlue }]}>
                            <Text style={styles.paymentTitle}>{Languages.ContractTerm}</Text>
                            <View style={styles.containerData}>
                                <Text style={styles.paymentDate}>{`${item.contractTerms}`}</Text>
                            </View>
                        </View>
                        <View style={styles.dataContainer}>
                            <Text style={styles.paymentTitle}>{Languages.PaymentRemaining}</Text>
                            <View style={styles.containerData}>
                                <Text style={styles.paymentDate}>{`${item.paymentsRemaining}`}</Text>
                            </View>
                        </View>
                        <View style={[styles.dataContainer, { backgroundColor: colors.veryPaleBlue }]}>
                            <Text style={styles.paymentTitle}>{Languages.PaymentAmount}</Text>
                            <View style={styles.containerData}>
                                <Text style={styles.paymentDate}>{`$${item.figures.paymentAmount}`}</Text>
                            </View>
                        </View>
                        <View style={styles.dataContainer}>
                            <Text style={styles.paymentTitle}>{Languages.InterestRate}</Text>
                            <View style={styles.containerData}>
                                <Text style={styles.paymentDate}>{`${item.interestRate}%`}</Text>
                            </View>
                        </View>
                        <AppButton
                            accessibilityLabel={`applicationsAppButtonViewContract${item.applicationId}`}
                            handleOnChange={() => onDownloadPdf(item.applicationId)}
                            testID={`applicationsAppButtonViewContract${item.applicationId}`}
                            title={Languages.ViewEcontract}
                        />
                    </>
                )}
            </View>
        </View>
    );
};

export default React.memo(ApplicationItem);
