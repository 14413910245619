import { Subject } from 'rxjs';

const DocumentStatementService = () => {
    const subject = new Subject();

    const onSuccess = (response: any) => {
        subject.next(response);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onSuccess,
        subscribe,
    };
};

export default DocumentStatementService();
