import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    addNewMethodButton: {
        backgroundColor: 'transparent',
        borderColor: colors.grayOpacity,
        borderStyle: 'dashed',
        borderWidth: 2,
        height: 45,
        marginHorizontal: 9,
    },
    addNewMethodButtonDisable: {
        borderWidth: 0,
        height: 58,
        marginHorizontal: 0,
        backgroundColor: colors.darkGray,
    },
    amount: {
        color: colors.greenSolid,
        fontFamily: fonts.SFProDisplay,
        fontSize: 50,
        fontWeight: 'bold',
        lineHeight: 60,
        textAlign: 'center',
    },
    container: {
        flex: 100,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-evenly',
        paddingHorizontal: 16,
    },
    containerAutoPay: {
        alignItems: 'center',
        flex: 40,
        flexDirection: 'row',
    },
    containerAutoPayLeft: {
        alignItems: 'flex-end',
        flex: 20,
        marginRight: 26,
    },
    containerBody: {
        flex: 94,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 30,
        gap: 20,
        width: '100%'
    },
    containerButton: {
        backgroundColor: colors.veryLightGray3,
        borderRadius: 5,
        height: 55,
        justifyContent: 'center',
        paddingVertical: 30,
        minWidth: 255,
        width: '65%',
    },
    containerLeft: {
        flex: 32,
        flexDirection: 'column',
        minWidth: 290,
        marginBottom: 24,
    },
    containerPaymentMethods: {
        backgroundColor: 'white',
        borderRadius: 10,
        height: 600,
        paddingHorizontal: 24,
    },
    containerPaymentMethodsBody: {
        flex: 80,
    },
    containerPaymentMethodsHeader: {
        alignItems: 'center',
        flex: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 10
    },
    containerPaymentOptions: {
        height: 458,
    },
    containerRight: {
        flex: 68,
        flexDirection: 'column',
        // minWidth: 385,
        backgroundColor: 'white',
        borderRadius: 10,
        height: 600,
        paddingHorizontal: 24,
    },
    cardShimmerBalanceTitle: {
        height: 30,
        width: '90%',
    },
    cardShimmerBalanceAmount: {
        height: 50,
        width: '90%',
    },
    cardShimmerHeaderLeft: {
        height: 20,
        width: '50%',
    },
    cardShimmerSwitch: {
        height: 20,
        width: 20,
        borderRadius: 27.5,
    },
    cardShimmerAddMethod: {
        height: 50,
        width: '100%',
    },
    cardShimmerAutotext: {
        height: 20,
        width: '50%',
    },
    footerContainer: {
        alignItems: 'center',
        flex: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginVertical: 24,
    },
    headerLeft: {
        flex: 60,
    },
    headerLeftText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        lineHeight: 28.64,
    },
    text: {
        color: colors.grayColor,
        fontFamily: fonts.SFProDisplaySemibold,
        fontSize: 22,
        lineHeight: 26,
        marginBottom: 8,
        textAlign: 'center',
        fontStyle: 'normal',
    },
    textAddNewMethod: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 20.29,
    },
    textAutopay: {
        color: colors.grayColor,
        fontFamily: fonts.Roboto,
        fontSize: 18,
        fontWeight: '500',
        lineHeight: 21,
        fontStyle: 'normal',
    },
    titlePayment: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 31,
        fontWeight: '500',
    },
    viewCurrent: {
        alignItems: 'center',
        backgroundColor: colors.greenBalance,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 126,
        justifyContent: 'center',
        paddingVertical: 16,
    },
    viewTextTitle: {
        alignItems: 'center',
        flex: 5,
        flexDirection: 'row',
        marginTop: 25,
    },
});
