import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    accountFooter: {
        flexDirection: 'column',
        marginTop: 5,
        ...Platform.select({
            web: {
            },
        }),
        alignItems: 'center',
    },
    btnContainerStyle: {
        height: '17%',
    },
    container: {
        backgroundColor: theme.colors.background,
        flex: 1,
        justifyContent: 'center',
    },
    containerAvoiding: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
    containerForm: {
        justifyContent: 'flex-end',
        width: '80%',
    },
    containerGeneral: {
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '85%',
        ...Platform.select({
            web: {
                height: '50%',
            },
        }),
    },
    dontHaveAccount: {
        color: colors.darkGray,
        fontFamily: fonts.SFProText,
        ...Platform.select({
            web: {
                lineHeight: 24,
                fontSize: 13,
                fontWeight: '400',
            },
        }),
    },
    ltoCustomerPortal: {
        color: colors.darkGray,
        fontFamily: fonts.SFProText,
        marginTop: 4,
        fontSize: 18,
        ...Platform.select({
            web: {
                lineHeight: 24,
                fontSize: 18,
                fontWeight: '400',
            },
        }),
    },
    emailStyle:
    {
        fontFamily: fonts.SFProText,
        fontSize: 15,
        lineHeight: 24,
        fontWeight: '400',
        width: 39,
    },
    footerContainer: {
        alignItems: 'center',
    },
    forgotText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontWeight: 'bold',
        marginTop: 0,
        marginBottom: 12,
        fontSize: 15,
        ...Platform.select({
            web: {
                lineHeight: 22,
            },
        }),
    },
    imgContainer: {
        paddingTop: 40,
        alignItems: 'center',
        justifyContent: 'center',
        resizeMode: 'center',
        marginBottom: 50,
    },
    loadingContainer: {
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: colors.blackOpacity,
    },
    passwordStyle: {
        fontFamily: fonts.SFProText,
        fontSize: 15,
        lineHeight: 24,
        fontWeight: '400',
    },
    registerNow: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontWeight: 'bold',
        textDecorationLine: 'none',
        marginTop: 8,
        fontSize: 15,
        ...Platform.select({
            web: {
                lineHeight: 24,
                fontWeight: '400',
            },
        }),
    },
    ltoCustomerPortalLink: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontWeight: 'bold',
        textDecorationLine: 'none',
        marginTop: 4,
        fontSize: 15,
        ...Platform.select({
            web: {
                lineHeight: 24,
                fontWeight: '400',
            },
        }),
    },
    typographyLogo: {
        marginTop: 25,
    },
    versionText: {
        color: colors.grayDarkColor,
        bottom: 0,
        marginBottom: 2,
        marginRight: 5,
        position: 'absolute',
        right: 0,
        fontFamily: fonts.SFProText,
        fontSize: 10,
        fontWeight: '400',
    },
});
