import React from 'react';

import {
    SelectMethodOfPaymentScreen,
} from '@MethodsOfPayment/screens/SelectMethodOfPaymentsScreen';
import { createStackNavigator } from '@react-navigation/stack';

const Stack = createStackNavigator();

interface Props {
    handleCancel: () => void;
    handleSelection: (item: any) => any;
    isSelectionOnly: boolean;
};

export const PaymentMethodSelectionWebStack = ({
    handleCancel,
    handleSelection,
}: Props) => (
    <Stack.Navigator
        screenOptions={() => ({
            headerShown: false,
        })}
    >
        <Stack.Screen
            name='SelectMethodOfPaymentScreen'
            component={SelectMethodOfPaymentScreen}
            initialParams={{
                handleCancel,
                handleSelection,
                isSelectionOnly: true
            }}
        />
    </Stack.Navigator>
);
