import * as React from 'react';
import { Text, View } from 'react-native';
import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import Languages from '@i18n/index';
import { colors, fonts, theme } from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';
import { CheckCircleSvg } from '@Assets/index';

interface Props {
    show: boolean;
    handleClose: () => void;
};

export const ConfirmPaymentUpdatedModal = ({
    show,
    handleClose,
}: Props) => (
    <AppModal
        animationType='fade'
        closeModal={handleClose}
        transparent
        visible={show}
    >
        <View style={[styles.container,]}>
            <View style={{ alignItems: 'center' }}>
                <CheckCircleSvg fill={theme.colors.primary} style={{ margin: 15 }} />
            </View>
            <Text style={styles.description}>
                Your Payment Method for AutoPay was updated successfully.
            </Text>
            <AppButton
                accessibilityLabel={'confirmPaymentButtonAccept'}
                buttonStyle={styles.acceptButton}
                handleOnChange={handleClose}
                testID={'confirmPaymentButtonAccept'}
                title={Languages.Close}
            />
        </View>
    </AppModal>
);

const styles = AppStyleSheet({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        width: '95%',
        height: 200,
        paddingBottom: 15
    },
    description: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 18,
        textAlign: 'center',
        marginBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
    },
    acceptButton: {
        width: '78%',
        marginBottom: 0,
        marginTop: 10
    }
});
