import { createAction } from 'typesafe-actions';

import {
    SendCodeEmailRequest,
    SendCodePhoneNumberRequest,
    UpdatePasswordRequest,
} from '@Auth/interfaces';
import ForgotPasswordActionsTypes from '@Auth/state/forgotPasswordActionsTypes';

export const sendCodePhoneNumber = createAction(
    ForgotPasswordActionsTypes.SEND_CODE_PHONE_REQUEST,
    (request: SendCodePhoneNumberRequest) => (request),
)();

export const sendCodeEmail = createAction(
    ForgotPasswordActionsTypes.SEND_CODE_EMAIL_REQUEST,
    (request: SendCodeEmailRequest) => (request),
)();

export const clearForgotPassword = createAction(
    ForgotPasswordActionsTypes.FORGOT_PASSWORD_CLEAR
)();

export const updatePasswordPhone = createAction(
    ForgotPasswordActionsTypes.UPDATE_PASSWORD_PHONE_REQUEST,
    (request: UpdatePasswordRequest) => (request),
)();

export const updatePasswordEmail = createAction(
    ForgotPasswordActionsTypes.UPDATE_PASSWORD_EMAIL_REQUEST,
    (request: UpdatePasswordRequest) => (request),
)();
