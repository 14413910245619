import React, {
    useEffect,
    useState,
} from 'react';

import {
    ScrollView,
    View,
} from 'react-native';

import { PaymentActivityWidget } from '@Activity/components/PaymentActivityWidget';
import { PaymentAmountSvg } from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { SectionHeaderComponent } from '@Components/SectionHeaderComponent';
import { ShimmerApp } from '@Components/ShimerApp';
import { ActionCardsComponent } from '@Home/components/ActionCardsComponent';
import { BalanceComponent } from '@Home/components/BalanceComponent.web';
import {
    Balance,
    BalanceSubscription,
} from '@Home/interfaces';
import { ActionCardsItem } from '@Home/interfaces/index';
import BalanceService from '@Home/services/BalanceService';
import { getBalanceRequestAction } from '@Home/state/balanceActions';
import { styles } from '@Home/styles/HomeWebStyles';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import { notificationsAction } from '@Notifications/state/notificationsActions';
import { StackScreenProps } from '@react-navigation/stack';
import { ActionCardsItemEnum } from '@Home/enums';

interface Props extends StackScreenProps<RootStackParamList, 'Home'> { }

const cardDefault: ActionCardsItem = {
    id: 0,
    icon: PaymentAmountSvg,
    actionName: `${Languages.NextPaymentAmount}`,
    title: '0.00',
    subtitle: '--/--/--',
    action: 'None',
    endDate: '--/--/--',
    type: 'Pay',
};

export const HomeScreen = ({
    navigation,
}: Props) => {
    const dispatch = useAppDispatch();
    const isMobile = useCheckMobileScreen();
    const [balance, setBalance] = useState<Balance>();
    const [cards, setCards] = useState<ActionCardsItem[]>([]);

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        dispatch(getBalanceRequestAction({
            applicationId: loanSelected?.applicationId,
        }));
        dispatch(notificationsAction());
    }, [loanSelected]);

    useEffect(() => {
        const balanceServiceSubscribe = BalanceService.subscribe((response: BalanceSubscription) => {
            const { balance, actionCards } = response;
            setBalance(balance);
            setCards(actionCards);
        });
        return () => {
            balanceServiceSubscribe.unsubscribe();
        };
    }, []);

    const handleTapPayNow = () => {
        LoggerService.logEvent('payNowCard', {});
        navigation.navigate('PaymentsScreen', {});
    };

    const handleTapActivity = () => {
        if (cards[2].id === ActionCardsItemEnum.AccountDetails) {
            LoggerService.logEvent('accountDetailsCard', {});
            navigation.navigate('ProfileScreen', { goTo: 'ApplicationsScreen' });
        } else if (cards[2].id === ActionCardsItemEnum.RebateProgram) {
            LoggerService.logEvent('programRebateCard', {});
            navigation.navigate('ProgramRebateScreen', { date: cards[1].endDate });
        }
    };

    const goToPayments = () => {
        LoggerService.logEvent('makeAPaymentWidget', {});
        navigation.navigate('PaymentsScreen', {});
    };

    const goToActivity = () => {
        LoggerService.logEvent('seeActivityCard', {});
        navigation.navigate('Activity', {});
    };

    return (
        <View style={styles.body}>
            <ScrollView style={{ flex: 100 }} showsVerticalScrollIndicator={true}>
                <View style={[styles.viewText, isMobile && { flex: 2 }]}>
                    <SectionHeaderComponent
                        title={Languages.Home}
                    />
                </View>
                <View style={styles.viewTop}>
                    <View style={styles.cardBalance}>
                        <BalanceComponent
                            balance={balance}
                        />
                    </View>
                    <View style={styles.viewMobileButton}>
                        <ShimmerApp
                            visible={Boolean(balance?.rebateProgramEndDate)}
                            contentStyle={styles.buttonMakePaymentShimmerMobile}
                            shimmerStyle={styles.buttonMakePaymentShimmerMobile}
                        >
                            <AppButton
                                    accessibilityLabel={'homeScreenAppButtonMakeAPayment'}
                                    buttonStyle={styles.makeAPaymentButton}
                                    disabled={!loanSelected?.permissions.canMakeAPayment}
                                    handleOnChange={goToPayments}
                                    testID={'homeScreenAppButtonMakeAPayment'}
                                    title={Languages.MakeAPayment}
                            />
                        </ShimmerApp>
                    </View>
                    <View style={styles.viewActionCards}>
                        <ActionCardsComponent
                            item={cards.length ? cards[0] : cardDefault}
                            goTo={handleTapPayNow}
                        />
                    </View>
                    <View style={styles.viewActionCards}>
                        <ActionCardsComponent
                            item={cards.length ? cards[1] : cardDefault}
                            goTo={goToPayments}
                        />
                    </View>
                    <View style={styles.viewActionCards}>
                        <ActionCardsComponent
                            item={cards.length ? cards[2] : cardDefault}
                            goTo={handleTapActivity}
                        />
                    </View>
                    <View style={styles.viewActionCards}>
                        <ActionCardsComponent
                            item={cards.length ? cards[3] : cardDefault}
                            goTo={goToActivity}
                        />
                    </View>
                </View>
                <View style={styles.viewBottom}>
                    <View style={styles.cardsPayment}>
                        <PaymentActivityWidget />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};
