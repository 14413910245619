import React from 'react';

import { View } from 'react-native';

import { EasypayLogoSvg } from '@Assets/index';
import { theme } from '@Theme/appTheme';

export const RefreshingScreen = () => (
    <View style={{
        flex: 1,
        backgroundColor: theme.colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <EasypayLogoSvg />
    </View>
);
