import { createAction } from 'typesafe-actions';

import { PageMenu } from '@Home/interfaces';
import MenuActionsTypes from '@Home/state/menuActionsTypes';

export const getListMenuAction = createAction(
    MenuActionsTypes.GET_MENU_LIST_REQUEST,
)();

export const getListMenuActionSuccess = createAction(
    MenuActionsTypes.GET_MENU_LIST_SUCCESS,
    (list: PageMenu[]) => (list),
)();

export const navigateLeftMenu = createAction(
    MenuActionsTypes.NAVIGATE_LEFT_MENU,
    (screen: string) => (screen)
)();

export const clearMenuListAction = createAction(
    MenuActionsTypes.CLEAR_MENU_LIST,
)();
