import 'react-native-gesture-handler';
import './web/main.css';

import React from 'react';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';

import { AppNavigation } from '@Navigation/index';
import { store } from '@Store/index';

const App = () => (
    <Provider store={store}>
        <SafeAreaProvider>
            <AppNavigation />
        </SafeAreaProvider>
    </Provider>
);

export default App;
