import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    globalStyle,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    buttonStyle: {
        paddingHorizontal: 8,
        borderRadius: 5,
        height: 30,
    },
    container: {
        backgroundColor: colors.white,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
    },
    downloadBtn: {
        width: 353,
        height: 55,
    },
    downloadText: {
        fontSize: 14,
        fontFamily: fonts.SFProText,
    },
    containerButton: {
        justifyContent: 'center',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 20
    },
    containerLeft: {
        flex: 1,
        paddingStart: 2,
        maxHeight: 480,
        minWidth: 450,
    },
    containerModal: {
        width: '90%',
        height: 450,
        alignItems: 'center',
    },
    containerModalButton: {
        backgroundColor: colors.softRed,
        marginTop: 16,
        width: '100%',
    },
    containerModalButtonText: {
        color: colors.white,
        fontSize: 17,
        lineHeight: 22,
        fontWeight: '600',
        fontFamily: fonts.SFProText,
    },
    containerModalPdf: {
        width: '100%',
        height: 450,
        borderRadius: 10,
        backgroundColor: colors.white,
        ...globalStyle.dropShadow,
    },
    containerPdf: {
        flex: 1,
        marginTop: 16,
        ...globalStyle.dropShadow,
        ...Platform.select({
            web: {
                marginHorizontal: 0,
                marginBottom: 9,
            },
            default: {
                marginHorizontal: 16,
                marginBottom: 16,
            },
        }),
    },
    containerRight: {
        flex: 70,
        minWidth: 364,
        paddingHorizontal: 24,
        maxHeight: 480,
    },
    containerPdfShimmer: {
        height: '75%',
        width: '100%',
        marginTop: 24,
        ...globalStyle.dropShadow,
        ...Platform.select({
            web: {
                marginHorizontal: 0,
                marginBottom: 9,
            },
            default: {
                marginHorizontal: 16,
                marginBottom: 16,
            },
        }),
    },
    separator: {
        marginBottom: 16,
        ...Platform.select({
            web: {
                marginBottom: 10,
            },
        }),
    },
    list: {
        flex: 1,
        marginTop: 30,
    },
});
