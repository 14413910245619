import { theme } from '@Theme/appTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
        width: '100%',
    },
    contentForm: {
        alignItems: 'center',
        width: '100%',
    },
    inputLarge: {
        width: '100%',
    },
    inputMedium: {
        width: '100%',
        flexGrow: 2,
    },
    containerInputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 5,
        marginTop: 7,
    },
    containerInputCol: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 7,
        width: '100%',
    },
    containerButton: {
        width: '78%',
    },
    creditCard: {
        marginRight: 10.13,
    },

    buttonSave: {
        width: '100%',
    },
    buttonNext: {
        width: '100%',
    },
    buttonCancel: {
        marginTop: 5,
        backgroundColor: 'transparent'
    },
    textCancel: {
        color: theme.colors.primary,
    },
    containerCheckbox: {
        marginBottom: 15,
        flexDirection: 'row',
    },
    containerInitialData: {
        width: '100%'
    }
});
