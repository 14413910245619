import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import { TextInput as Input } from 'react-native';

import {
    EyeHideIconSvg,
    EyeIconSvg,
} from '@Assets/index';
import { RegisterSchema } from '@Auth/config/RegisterSchema';
import { InputType } from '@Auth/interfaces/index';
import { AppButton } from '@Components/AppButton';
import { TextInput } from '@Components/TextInput';
import { useFormik } from '@Hooks/useForm';
import { KeyboardEventObject } from '@Hooks/useKeyboardEvent';
import Languages from '@i18n/index';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Props {
    disableEmailField?: boolean;
    email?: string;
    handleOnCreateAccount: (phoneNumber: string, password: string, confirmPassword: string) => void;
    keyboardEvent?: KeyboardEventObject;
};
interface RegisterInitialState {
    email: string;
    password: string;
    confirmPassword: string;
}

const initialRegisterForm: RegisterInitialState = {
    email: '',
    password: '',
    confirmPassword: '',
};

export const RegisterFormComponent = ({
    disableEmailField = false, email, handleOnCreateAccount, keyboardEvent,
}: Props) => {
    const [isShowPassword, setIsShowPassword] = useState(true);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(true);

    const passwordRef = useRef<Input>(null);
    const confirmPasswordRef = useRef<Input>(null);

    useEffect(() => {
        if (handledToValidateSendForm()) {
            submitForm();
        }
    }, [keyboardEvent]);

    useEffect(() => {
        if (email) {
            setFieldValue('email', email);
        }
    }, [email]);

    const handledToValidateSendForm = () => keyboardEvent?.triggered && !Boolean(shouldBeActiveBtn());

    const handleShowPassword = () => {
        passwordRef.current?.focus();
        setIsShowPassword(!isShowPassword);
    };
    const handleShowConfirmPassword = () => {
        confirmPasswordRef.current?.focus();
        setIsShowConfirmPassword(!isShowConfirmPassword);
    };

    const fillEyeColor = (isFocus: boolean | undefined, error: any, touched: any) => {
        if (Boolean(error) && touched) {
            return colors.red;
        }
        if (isFocus) {
            return theme.colors.primary;
        } else {
            return colors.gray;
        };
    };

    const renderEyeIconPassword = () => (
        <>
            {isShowPassword ? <EyeHideIconSvg
                width={25}
                height={19}
                fill={fillEyeColor(passwordRef.current?.isFocused(), errors.password, touched.password)}
            /> : <EyeIconSvg
                width={25}
                height={15}
                fill={fillEyeColor(passwordRef.current?.isFocused(), errors.password, touched.password)}
            />}
        </>
    );

    const renderEyeIconConfirmPassword = () => (
        <>
            {isShowConfirmPassword ? <EyeHideIconSvg
                width={25}
                height={19}
                fill={fillEyeColor(confirmPasswordRef.current?.isFocused(), errors.confirmPassword, touched.confirmPassword)}
            /> : <EyeIconSvg
                width={25}
                height={15}
                fill={fillEyeColor(confirmPasswordRef.current?.isFocused(), errors.confirmPassword, touched.confirmPassword)}
            />}
        </>
    );

    const handleSubmit = (values: RegisterInitialState) => {
        handleOnCreateAccount(values.email, values.password, values.confirmPassword);
    };

    const form = useFormik(handleSubmit, initialRegisterForm, RegisterSchema);

    const {
        values,
        errors,
        submitForm,
        handleChange,
        handleBlur,
        validateField,
        setFieldTouched,
        setFieldValue,
        touched,
    } = form;

    const shouldBeActiveBtn = () => errors.email || errors.password || errors.confirmPassword;

    const onChangeText = (field: InputType, text: string) => {
        setFieldTouched(field, true);
        validateField(field);
        setFieldValue(field, text);
    };

    return (
        <>
            <TextInput
                accessibilityLabel={'registerFormTextInputEmailAddress'}
                autoCapitalize='none'
                editable={!disableEmailField}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email ? errors.email : ''}
                keyboardType='email-address'
                onBlur={handleBlur('email')}
                onChangeText={handleChange('email')}
                placeholder={Languages.Email}
                testID={'registerFormTextInputEmailAddress'}
                value={values.email}
            />
            <TextInput
                accessibilityLabel={'registerFormTextInputPassword'}
                autoCapitalize='none'
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password ? errors.password : ''}
                myRef={passwordRef}
                onChangeText={(text: string) => onChangeText(InputType.password, text)}
                onClickRight={handleShowPassword}
                placeholder={Languages.CreatePassword}
                RightIcon={() => renderEyeIconPassword()}
                securityEntry={isShowPassword}
                testID={'registerFormTextInputPassword'}
                value={values.password}
            />
            <TextInput
                accessibilityLabel={'registerFormTextInputConfirmPassword'}
                autoCapitalize='none'
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                myRef={confirmPasswordRef}
                onChangeText={(text: string) => onChangeText(InputType.confirmPassword, text)}
                onClickRight={handleShowConfirmPassword}
                placeholder={Languages.ConfirmPassword}
                RightIcon={() => renderEyeIconConfirmPassword()}
                securityEntry={isShowConfirmPassword}
                testID={'registerFormTextInputConfirmPassword'}
                value={values.confirmPassword}
            />

            <AppButton
                accessibilityLabel={'registerFormAppButtonCreateAccount'}
                disabled={Boolean(shouldBeActiveBtn())}
                handleOnChange={submitForm}
                testID={'registerFormAppButtonCreateAccount'}
                title={Languages.CreateAccount}
            />
        </>
    );
};
