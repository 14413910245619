import React from 'react';

import { View } from 'react-native';

import { ShimmerApp } from '@Components/ShimerApp';
import { styles } from '@MethodsOfPayment/styles/PaymentMethodsDropZoneStyle';

export const PaymentMethodsDropShimmer = () => (

    <View style={[
        styles.itemLeftShimmer, styles.itemShimmer, styles.itemRightShimmer, styles.itemRightMarginShimmer,
    ]}>
        <View style={styles.itemDataContainer}>
            <View style={{ flexDirection: 'row' }}>
                <ShimmerApp
                    visible={Boolean(false)}
                    shimmerStyle={styles.radioShimmer}
                />
                <ShimmerApp
                    visible={Boolean(false)}
                    shimmerStyle={styles.secondRadioShimmer}
                />
            </View>
            <ShimmerApp
                visible={Boolean(false)}
                shimmerStyle={styles.itemDataShimme}
            />
            <ShimmerApp
                visible={Boolean(false)}
                shimmerStyle={styles.itemDataShimme}
            />
        </View>
    </View>
);
