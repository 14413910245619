import React, { useEffect } from 'react';

import {
    Platform,
    StyleSheet,
    Text,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    ExpireLink,
    ForgotPasswordExpiredLink,
    VerifyAccountExpiredLink,
} from '@Assets/index';
import ForgotService from '@Auth/services/ForgotService';
import RegisterService, { RegisterServiceResponse } from '@Auth/services/RegisterService';
import { sendCodeEmail } from '@Auth/state/forgotPasswordActions';
import { registerVerificationCodeResendAction } from '@Auth/state/registerActions';
import { AppButton } from '@Components/AppButton';
import { useAppDispatch } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import {
    colors,
    fonts,
    globalStyle,
} from '@Theme/appTheme';
import { switchcase } from '@Utils/index';

interface Props extends StackScreenProps<RootStackParamList, 'LinkExpireScreen'> { }

export const LinkExpireScreen = ({ navigation, route }: Props) => {
    const {
        email,
        type,
    } = route?.params || {};

    const dispatch = useAppDispatch();

    useEffect(() => {
        const forgotPasswordService = ForgotService.subscribe(((state: any) => {
            const { success: isSuccess } = state;
            if (isSuccess) {
                AppAlertService.showSuccess({
                    message: Languages.ResetPasswordSuccess,
                });
                goToLogin();
            }
        }));
        const registerService = RegisterService.subscribe((state: RegisterServiceResponse) => {
            const { action, success: isSuccess, title } = state;
            if (action === 'validation') {
                if (isSuccess) {
                    AppAlertService.showSuccess({
                        message: Languages.PleaseCheckYourInbox,
                    });
                    goToLogin();
                } else {
                    AppAlertService.showError({
                        message: title,
                    });
                }
            }
        });

        return () => {
            forgotPasswordService.unsubscribe();
            registerService.unsubscribe();
        };
    }, []);

    const goToLogin = () => {
        navigation.reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
            ],
        });
    };

    const onHandleRequestNewLink = () => {
        LoggerService.logEvent('linkExpire', {
            type,
            email,
        });
        if (type === 'Password') {
            dispatch(sendCodeEmail({
                email,
            }));
        } else {
            dispatch(registerVerificationCodeResendAction({
                email,
            }));
        }
    };

    const renderImage = () => (
        switchcase({
            ['Password']: () => <ForgotPasswordExpiredLink />,
            ['Register']: () => <VerifyAccountExpiredLink />,
        })(() => <ExpireLink />)(type)()
    );

    const renderMessage = () => (
        switchcase({
            ['Password']: () => <Text style={styles.title}>{Languages.OopsLooksLikeLinkExpireResetPassword}</Text>,
            ['Register']: () => <Text style={styles.title}>{Languages.OopsLooksLikeLinkExpireVerifyAccount}</Text>,
        })(() => <Text style={styles.title}>{Languages.OopsLooksLikeLinkExpire}</Text>)(type)()
    );

    return (
        <View style={styles.container}>
            <View style={styles.imageContainer}>
                {renderImage()}
            </View>
            <View style={styles.dataContainer}>
                <View style={styles.titleContainer}>
                    {renderMessage()}
                </View>
                <View style={styles.buttonContainer}>
                    <AppButton
                        handleOnChange={onHandleRequestNewLink}
                        title={Languages.RequestNewLink}
                        buttonStyle={styles.button}
                        textStyle={styles.buttonText}
                    />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    button: {
        width: 255,
    },
    buttonContainer: {
        flex: 50,
    },
    buttonText: {
        color: colors.white,
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 22,
    },
    container: {
        backgroundColor: colors.white,
        flex: 100,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                ...globalStyle.dropShadow,
                alignSelf: 'center',
                borderRadius: 10,
                flexWrap: 'wrap',
                height: 536,
                marginVertical: 32,
                minWidth: 200,
                width: 932,
            },
        }),
    },
    dataContainer: {
        alignItems: 'center',
        flex: 45,
        justifyContent: 'center',
    },
    imageContainer: {
        alignItems: 'center',
        flex: 55,
        justifyContent: 'flex-end',
        paddingBottom: 62,
        ...Platform.select({
            web: {
                flex: 70,
            },
        }),
    },
    title: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        lineHeight: 28.64,
        textAlign: 'center',
    },
    titleContainer: {
        flex: 50,
        width: 267,
        ...Platform.select({
            web: {
                width: 435,
            },
        }),
    },
});
