import { formatPhoneNumber } from 'react-phone-number-input';
import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import {
    MeunOptionsSuccessAction,
    ProfileState,
    UserInfoSuccessAction,
} from '@Profile/interfaces';
import * as profileActions from '@Profile/state/profileActions';

type ActionsType = ActionType<typeof profileActions>;

const initialState: ProfileState = {
    menuOptions: [],
    userInfo: {
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        aptNumber: '',
        zipCode: '',
        state: '',
        city: '',
        dob: '',
        last4ssn: '',
        phoneNumber: '',
        secondaryPhoneNumber: '',
    },
    isLoading: false,
};

export const profileReducer = createReducer<ProfileState, ActionsType>(
    initialState,
).handleAction(profileActions.getMenuOptionsSuccessAction, (state: ProfileState, { payload }: MeunOptionsSuccessAction) => ({
    ...state,
    menuOptions: payload.menuOptions,
})).handleAction(profileActions.getMenuOptionsErrorAction, (state: ProfileState) => ({
    ...state,
})).handleAction(profileActions.getUserInfoSuccessAction, (state: ProfileState, { payload }: UserInfoSuccessAction) => ({
    ...state,
    userInfo: {
        ...payload,
        email: payload.email ?? '',
        firstName: payload.firstName ?? '',
        lastName: payload.lastName ?? '',
        address: payload.address ?? '',
        aptNumber: payload.aptNumber ?? '',
        zipCode: payload.zipCode ?? '',
        state: payload.state ?? '',
        city: payload.city ?? '',
        dob: payload.dob ?? '',
        last4ssn: payload.last4ssn ?? '',
        phoneNumber: formatPhoneNumber(`+1${payload.phoneNumber}`),
        phoneNumberVerificationCode: '',
        secondaryPhoneNumber: formatPhoneNumber(`+1${payload.secondaryPhoneNumber}`),
    },
})).handleAction(profileActions.getUserInfoErrorAction, (state: ProfileState) => ({
    ...state,
})).handleAction(profileActions.cleanProfileUserInfoAction, (state: ProfileState) => ({
    ...state,
    userInfo: initialState.userInfo,
}));
