import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import Languages from '@i18n/index';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import {
    getMenuOptionsAPI,
    getUserInfoAPI,
    getZipCodeInformationAPI,
    postClaimProfileAPI,
    postPhoneUpdateAPI,
    putUserInfoAPI,
} from '@Profile/api/profileApi';
import {
    GetProfileResponse,
    GetZipCodeActionRequest,
    GetZipCodeResponse,
    PostClaimProfileActionRequest,
    PostPhoneUpdateActionRequest,
    PutProfileActionRequest,
    PutProfileRequest,
    UserInfo,
} from '@Profile/interfaces';
import ClaimProfileService from '@Profile/services/ClaimProfileService';
import UpdateProfileService from '@Profile/services/UpdateProfileService';
import ZipCodeService from '@Profile/services/ZipCodeService';
import {
    getMenuOptionsErrorAction,
    getMenuOptionsSuccessAction,
    getUserInfoErrorAction,
    getUserInfoSuccessAction,
} from '@Profile/state/profileActions';
import ProfileActionsTypes from '@Profile/state/profileActionTypes';
import ProfileActionTypes from '@Profile/state/profileActionTypes';
import {
    clearPhoneNumberFormat,
    formatToISO,
    parseDate,
} from '@Utils/FormatUtils';
import VerificationCodeService from '@VerificationCodeDialog/service/VerificationCodeService';

interface ClaimProfileError {
    title: string;
    subtitle: string | string[];
    titleBottomSection?: string;
    subTitleBottomSection?: string;
};

interface ClaimProfileErrorData {
    [key: number]: ClaimProfileError;
};

const CLAIM_PROFILE_ERRORS: ClaimProfileErrorData = {
    406: {
        title: Languages.AccountNotFound,
        subtitle: [Languages.WeDoNotHaveAnActiveAccount,Languages.WeDoNotHaveAnActiveAccountDetails],
        titleBottomSection: Languages.LeaseToOwnCustomers,
        subTitleBottomSection: Languages.LeaseToOwnCustomersServicePhone,
    },
    408: {
        title: Languages.AccountNotFunded,
        subtitle: Languages.WeAreCurrentlyWorking,
    },
    407: {
        title: Languages.AttemptsExceeded,
        subtitle: Languages.YouHaveExceededTheNumberOfAttempts,
    },
};

export const menuOptionEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ProfileActionsTypes.PROFILE_MENU_OPTIONS_REQUEST),
    mergeMap(() => from(getMenuOptionsAPI())
        .pipe(
            map((response: any) => getMenuOptionsSuccessAction(response)),
            catchError((error: Error) => {
                LoggerService.logError(error);
                return of(getMenuOptionsErrorAction(error));
            }),
        ),
    ),
);

export const downloadUserInfoMergeMap = () => switchMap(() => from(getUserInfoAPI())
    .pipe(
        mergeMap((response: GetProfileResponse) => from(
            new Promise<UserInfo>((resolve, reject) => {
                try {
                    const userInfo: UserInfo = {
                        email: response.contactInformation.email ?? '',
                        firstName: response.name,
                        lastName: response.lastName,
                        address: response.address?.address1 ?? '',
                        aptNumber: response.address?.apartmentNumber ?? '',
                        zipCode: response.address?.zipCode ?? '',
                        state: response.address?.state ?? '',
                        city: response.address?.city ?? '',
                        dob: response.dob ? parseDate(response.dob, 'MM/dd/yyyy') : '',
                        last4ssn: response.last4Ssn,
                        phoneNumber: response.contactInformation?.mainPhone ?? '',
                        secondaryPhoneNumber: response.contactInformation?.secondaryPhone ?? '',
                    };
                    resolve(userInfo);
                } catch (error) {
                    reject(error);
                }
            }),
        )),
    ),
);

export const userInfoEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ProfileActionsTypes.PROFILE_USER_INFO_REQUEST),
    mergeMap(() => from(getUserInfoAPI())
        .pipe(
            mergeMap((response: GetProfileResponse) => {
                const userInfo: UserInfo = {
                    email: response.contactInformation.email ?? '',
                    firstName: response.name,
                    lastName: response.lastName,
                    address: response.address?.address1 ?? '',
                    aptNumber: response.address?.apartmentNumber ?? '',
                    zipCode: response.address?.zipCode ?? '',
                    state: response.address?.state ?? '',
                    city: response.address?.city ?? '',
                    dob: response.dob ? parseDate(response.dob, 'MM/dd/yyyy') : '',
                    last4ssn: response.last4Ssn,
                    phoneNumber: response.contactInformation?.mainPhone ?? '',
                    secondaryPhoneNumber: response.contactInformation?.secondaryPhone ?? '',
                };
                return [
                    getUserInfoSuccessAction(userInfo),
                    loadingDialogHide(),
                ];
            }),
            catchError((error: Error) => {
                LoggerService.logError(error);
                return of(
                    getUserInfoErrorAction(error),
                    loadingDialogHide()
                );
            }),
        ),
    ),
);

export const putUserInfoEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ProfileActionsTypes.PROFILE_USER_UPDATE_REQUEST),
    mergeMap(({ payload }: PutProfileActionRequest) => from(new Promise<PutProfileRequest>((resolve) => {
        const request: PutProfileRequest = {
            address: {
                address1: payload.address,
                apartmentNumber: payload.aptNumber,
                city: payload.city,
                state: payload.state,
                zipCode: payload.zipCode,
            },
            contactInformation: {
                email: payload.email,
                mainPhone: clearPhoneNumberFormat(payload.phoneNumber),
                mainPhoneVerificationCode: payload.phoneNumberVerificationCode,
                secondaryPhone: payload.secondaryPhoneNumber ? clearPhoneNumberFormat(payload.secondaryPhoneNumber) : '',
            },
            dob: payload.dob ? formatToISO(payload.dob) : '',
            last4Ssn: payload.last4ssn,
            lastName: payload.lastName,
            name: payload.firstName,
        };
        resolve(request);
    })).pipe(
        mergeMap((request: PutProfileRequest) => from(putUserInfoAPI(request))
            .pipe(
                mergeMap(() => {
                    const userInfo: UserInfo = {
                        email: request.contactInformation.email ?? '',
                        firstName: request.name,
                        lastName: request.lastName,
                        address: request.address?.address1 ?? '',
                        aptNumber: request.address?.apartmentNumber ?? '',
                        zipCode: request.address?.zipCode ?? '',
                        state: request.address?.state ?? '',
                        city: request.address?.city ?? '',
                        dob: request.dob ? parseDate(request.dob, 'MM/dd/yyyy') : '',
                        last4ssn: request.last4Ssn,
                        phoneNumber: request.contactInformation?.mainPhone ?? '',
                        secondaryPhoneNumber: request.contactInformation?.secondaryPhone ?? '',
                    };
                    UpdateProfileService.onSuccess({
                        success: true,
                        wihtMobileNumber: false,
                    });
                    return [
                        loadingDialogHide(),
                        getUserInfoSuccessAction(userInfo),
                    ];
                }),
                catchError((error: Error) => {
                    AppAlertService.showError({
                        message: error.title,
                    });
                    LoggerService.logError(error);
                    return of(loadingDialogHide());
                })
            ),
        ),
    )),
);

export const phoneUpdateVerificationCodeEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ProfileActionTypes.PROFILE_USER_UPDATE_PHONE_REQUEST),
    delay(250),
    mergeMap(({ payload }: PostPhoneUpdateActionRequest) => from(postPhoneUpdateAPI(payload))
        .pipe(
            map((response: any) => {
                VerificationCodeService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const zipCodeInformationEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ProfileActionTypes.PROFILE_USER_ZIP_CODE_REQUEST),
    switchMap(({ payload }: GetZipCodeActionRequest) => from(getZipCodeInformationAPI(payload))
        .pipe(
            map((response: GetZipCodeResponse) => {
                ZipCodeService.onSuccess({
                    ...response,
                });
                return loadingDialogHide();
            }),
            catchError((_: Error) => {
                ZipCodeService.onSuccess({
                    zipCode: payload.zipCode,
                    city: '',
                    state: '',
                    utcOffset: 0,
                });
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const postClaimProfileEpic = (action$: Observable<any>) => action$.pipe(
    ofType(ProfileActionTypes.PROFILE_CLAIM_REQUEST),
    delay(250),
    switchMap(({ payload }: PostClaimProfileActionRequest) => from(postClaimProfileAPI(payload))
        .pipe(
            map((_: any) => {
                ClaimProfileService.onSuccess({
                    success: true,
                    errorCode: 200,
                });
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                const message = CLAIM_PROFILE_ERRORS[error.code || 0];
                if (message) {
                    ClaimProfileService.onError({
                        success: false,
                        errorCode: error.code ?? 0,
                        ...message,
                    });
                } else {
                    AppAlertService.showError({
                        message: error.title,
                    });
                }
                return of(loadingDialogHide());
            }),
        ),
    ),
);
