import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    btnFooter: {
        height: 30,
        width: 123,
    },
    containerButtons: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 20,
        width: '77%',
    },
    containerFooter: {
        alignItems: 'center',
    },
    containerInfoFooter: {
        alignItems: 'center',
        backgroundColor: colors.green,
        borderRadius: 4,
        height: 110,
        marginTop: 73,
        width: 342,
    },
    containerSearch: {
        height: 45,
        marginHorizontal: '3%',
        marginTop: 20,
    },
    flatList: {
        flexDirection: 'row',
        marginHorizontal: 12,
    },
    listFooter: {
        marginBottom: 50,
    },
    principalView: {
        backgroundColor: colors.white,
        height: 133,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                justifyContent: 'flex-start',
            },
            default: {
                backgroundColor: theme.colors.primary,
            },
        }),

    },
    questionList: {
        marginTop: 8,
    },
    textHelp: {
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '600',
        lineHeight: 29,
        textAlign: 'center',
        color: theme.colors.background,
        ...Platform.select({
            web: {
                fontFamily: fonts.SFProText,
                fontWeight: '500',
                fontSize: 18,

            },
            default: {
                color: colors.white,
            },
        }),
    },
    textMenu: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 22,
        textAlign: 'center',
    },
    textQuestion: {
        color: colors.grayColor,
        flex: 8,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        height: '100%',
        left: 20,
        lineHeight: 20,
        textAlign: 'left',
        textAlignVertical: 'top',
        top: 20,
    },
    touchableQuestion: {
        alignItems: 'flex-end',
        flex: 1,
        marginHorizontal: 8,
        top: 25,
    },
    viewFlatlistItem: {
        marginVertical: 8,
    },
    viewMenu: {
        alignItems: 'center',
        borderColor: theme.colors.primary,
        borderRadius: 5,
        borderWidth: 2,
        height: 30,
        justifyContent: 'center',
        marginBottom: 10,
        marginRight: 10,
        marginTop: 15,
        flex: 1,
        minWidth: 80,
    },
    viewQuestions: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 5,
        flexDirection: 'row',
        height: 80,
        marginHorizontal: 12,
        marginTop: 7,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 13,
    },
});
