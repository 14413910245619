import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // backgroundColor: 'red',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.backgroundWhiteLight,
    },
    containerScroll: {
        alignItems: 'center',
        flex: 1,
        // backgroundColor: 'purple',
        backgroundColor: colors.backgroundWhiteLight,
    },
    containerForm: {
        width: '90%',
        maxWidth: 250,
        justifyContent: 'space-between',
        // paddingHorizontal: 32,
        marginBottom: 10
    },
    buttonCancel: {
        backgroundColor: 'transparent',
    },
    header: {
        fontSize: 24,
        fontWeight: '500',
        fontFamily: fonts.SFProDisplay,
        color: theme.colors.primary,
        paddingTop: 32,
    },
    textCancel: {
        color: theme.colors.primary,
    },
    viewDropdown: {
        backgroundColor: 'red',
        width: '90%',
    },
});
