import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
        marginTop: 20
    },
    containerPrimaryPayment: {
        width: '100%',
        marginBottom: 6,
    },
    contentContainerListPayment: {
        paddingBottom: 20,
    },
    containerListPayment: {
        flex: 1,
        width: '100%',
    },
    footerContainer: {
        alignItems: 'center',
        flex: 1,
    },
    containerPrimaryPaymentTitle: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 6,
    },
    titlePaymentPrimary: {
        marginLeft: 16,
        marginRight: 9,
    },
    separator: {
        borderBottomWidth: 0.7,
        borderColor: colors.gray,
        marginBottom: 25,
        marginTop: 25,
        width: '91%',
        marginLeft: 16,
    },
    textAddNew: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 20,
        marginTop: 10,
    },
    textAddNewDisable: {
        color: colors.darkGray,
    },
    containerButton: {
        alignItems: 'center',
        marginTop: 30,
        width: '20%',
    },
    containerAutoPay: {
        alignItems: 'center',
        flexDirection: 'row',
        height: '6%',
        justifyContent: 'flex-end',
        marginTop: 20,
        paddingHorizontal: 16,
        width: '100%',
        marginBottom: 21,
    },
    textAutopay: {
        color: colors.grayColor,
        fontFamily: fonts.Roboto,
        fontSize: 18,
        fontWeight: '500',
        lineHeight: 21,
        marginRight: 29,
    },
    containerSwipe: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
        width: '100%',
    },
    containerNumberLogoPayment: {
        flex: 1,
        justifyContent: 'center',
    },
    containerNikenameNote: {
        alignItems: 'center',
        flex: 2,
        justifyContent: 'center',
    },
    containerMenuSwipe: {
        flex: 1, alignItems: 'flex-end',
        justifyContent: 'center',
        marginRight: 1,
    },
    containerHeader: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 5,
        height: 80,
        marginTop: 16,
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowOpacity: 0.32,
        shadowRadius: 13,
        width: '91%',
    },
    containerItem: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 5,
        height: 80,
        marginHorizontal: 16,
        overflow: 'hidden',
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 13,
    },
    containerItemMarginBottom: {
        marginBottom: 16,
    },
    containerItemSelected: {
        borderWidth: 2,
        borderColor: theme.colors.primary,
    },
    lastFourDigit: {
        color: theme.colors.primary,
        fontFamily: fonts.Roboto,
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 23,
        marginTop: 14,
    },
    nickname: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
    },
    note: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        marginTop: 15,
        textAlign: 'center',
    },
    contentSwipe: {
        backgroundColor: colors.white,
        height: 80,
        marginBottom: 16,
        paddingHorizontal: 16,
        overflow: 'hidden',
    },
    contentSwipeSelected: {
        backgroundColor: colors.strongCyan50,
    },
    noAutoPayMethod: {
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
    },
    noAutoPayMethodText: {
        padding: 15,
        backgroundColor: colors.greenBalance,
        borderRadius: 10,
        width: '100%',
    }
});
