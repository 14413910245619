import React from 'react';

import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import PdfScreenViewer from '@Pdf/components/PdfScreenViewer';
import { StackScreenProps } from '@react-navigation/stack';

interface Props extends StackScreenProps<RootStackParamList, 'PdfScreen'> { }

export const PdfScreen = ({ route }: Props) => {
    const {
        base64,
    } = route?.params;

    return (<PdfScreenViewer base64={base64 ?? ''} />);
};
