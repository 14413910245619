import React from 'react';

import {
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { ShimmerApp } from '@Components/ShimerApp';
import { ActionCardsItem } from '@Home/interfaces';
import { styles } from '@Home/styles/ActionCardWebStyles';
import { ActionCardsItemEnum } from '@Home/enums';

interface PropItem {
    item: ActionCardsItem;
    goTo: () => void;
};

export const ActionCardsComponent = ({
    item,
    goTo,
}: PropItem) => {
    const isSpecialItem = item.id === ActionCardsItemEnum.AccountDetails;
    const isNextDueDateItem = item.id === ActionCardsItemEnum.NextDueDate;

    const getItemAction = (action: string): string => {
        try {
            return action.replace(/\s/g, '');
        } catch (error) { }
        return '';
    }

    const itemAction = getItemAction(item?.action)

    const mayRenderSubtitle = (value: string | undefined) => value && (
        <View style={{ flex: 80 }}>
            <Text style={styles.date}>{value}</Text>
        </View>
    );

    return (
        <View style={styles.card}>
            <View style={styles.dataContainer}>
                <View style={styles.icon}>
                    <ShimmerApp
                        visible={Boolean(item.id)}
                        shimmerStyle={styles.iconShimmer}
                    >
                        <item.icon width={54} height={54} />
                    </ShimmerApp>
                </View>
                <View style={styles.cardWeb}>
                    <ShimmerApp
                        visible={Boolean(item.id)}
                        contentStyle={{ flex: 10 }}
                        shimmerStyle={styles.actionShimmer}
                    >
                        <Text style={styles.action}>{item.actionName}</Text>
                    </ShimmerApp>
                    <ShimmerApp
                        visible={Boolean(item.id)}
                        contentStyle={styles.amountContainer}
                        shimmerStyle={styles.amountShimmer}
                    >
                        {
                            !isSpecialItem && (
                                <Text
                                    style={isNextDueDateItem ? styles.smallerAmount : styles.amount}
                                    numberOfLines={2}
                                    ellipsizeMode={'tail'}
                                >
                                    {item.title}
                                </Text>
                            )
                        }
                    </ShimmerApp>
                    <>
                        {Boolean(item.id) && mayRenderSubtitle(item?.subtitle)}
                    </>
                </View>
            </View>

            {Boolean(item.id) && (
                <AppButton
                    accessibilityLabel={`actionCardsAppButtonAction${itemAction}`}
                    buttonStyle={styles.cardEnd}
                    disabled={item.disabled}
                    handleOnChange={() => goTo()}
                    testID={`actionCardsAppButtonAction${itemAction}`}
                    textStyle={styles.viewAction}
                    title={item.action}
                />
            )}
        </View >
    );
};
