import React, {
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    Text,
    View,
} from 'react-native';

import { EmtpyAccountsIcon } from '@Assets/index';
import { AppEmptyState } from '@Components/AppEmptyState';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import ApplicationItem from '@Profile/components/ApplicationItem';
import { Loan } from '@Profile/interfaces';
import ApplicationsService from '@Profile/services/applicationsService';
import DocumentService from '@Profile/services/documentService';
import {
    getApplications,
    getDocumentArrayBufferAction,
} from '@Profile/state/applicationsAction';
import { styles } from '@Profile/styles/ApplicationsStylesWeb';
import { colors } from '@Theme/appTheme';

interface PropsItem {
    index: number;
    item: Loan;
};

export const ApplicationsScreen = () => {
    const dispatch = useAppDispatch();
    const [dataLoans, setLoans] = useState([]);
    const [dataLoansPast, setLoansPast] = useState([]);

    const { loans } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        dispatch(getApplications());
    }, [loans]);

    useEffect(() => {
        const applicationsServices = ApplicationsService.subscribe((state: any) => {
            setLoans(state.data[0].data);
            if (state.data[1].data.length > 0) {
                setLoansPast(state.data[1].data);
            } else {
                setLoansPast([]);
            };
        });
        const documentService = DocumentService.subscribe((data: any) => {
            downloadPDF(data);
        });
        return () => {
            applicationsServices.unsubscribe();
            documentService.unsubscribe();
        };
    }, []);

    const handledOnDownloadPdf = (applicationId: number) => {
        dispatch(getDocumentArrayBufferAction({
            applicationId,
        }));
    };

    const downloadPDF = (response: any) => {
        if (response) {
            LoggerService.logEvent('viewContract', {});
            const url = window.URL.createObjectURL(new Blob([response], {
                type: 'application/pdf',
            }));
            window.open(url, '_blank');
        };
    };

    const handleRenderItem = ({ item, index }: PropsItem) => (
        <ApplicationItem
            item={item}
            index={index}
            onDownloadPdf={handledOnDownloadPdf}
        />
    );

    return (
        <View style={styles.container}>
            <View style={styles.firstFlatList}>
                {(dataLoans && dataLoans.length > 0) && (
                    <>
                        <Text style={styles.textCurrentAccount}>{Languages.CurrentAccounts}</Text>
                        <FlatList
                            showsVerticalScrollIndicator={false}
                            data={dataLoans}
                            keyExtractor={(_, index) => index.toString()}
                            renderItem={handleRenderItem}
                            style={{ flex: 1 }}
                        />
                    </>
                )}
            </View>
            <View style={[
                styles.secondFlatList, {
                    backgroundColor: dataLoansPast.length ? 'transparent' : colors.white,
                },
                !dataLoansPast.length && styles.emptyLisPastAccount,
            ]}>
                <Text style={styles.textCurrentAccount}>{Languages.PastAccounts}</Text>
                {dataLoansPast.length ?
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        data={dataLoansPast}
                        keyExtractor={(_, index) => index.toString()}
                        renderItem={handleRenderItem}
                        style={{ flex: 1 }}
                    /> :
                    <AppEmptyState
                        accessibilityLabel={'applicationsAppEmptyStatePastAccounts'}
                        Icon={EmtpyAccountsIcon}
                        subTitle='You Have No'
                        testID={'applicationsAppEmptyStatePastAccounts'}
                        title='Past Account'
                    />
                }
            </View>
        </View>
    );
};

