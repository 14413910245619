import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Text,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import ForgotService from '@Auth/services/ForgotService';
import { sendCodeEmail } from '@Auth/state/forgotPasswordActions';
import { styles } from '@Auth/styles/ForgotStyle';
import { AppButton } from '@Components/AppButton';
import { EmailTextInput } from '@Components/EmailTextInput';
import { useAppDispatch } from '@Hooks/appHooks';
import { KeyboardEventObject } from '@Hooks/useKeyboardEvent';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { validateEmail } from '@Utils/FormatUtils';

interface Props extends StackScreenProps<RootStackParamList, 'ForgotPassword'> {
    handleIsUserNotFound: (notFound: boolean) => void;
    isUserNotFound: boolean;
    keyboardEvent?: KeyboardEventObject;
};

export const ForgotPasswordForm = ({
    handleIsUserNotFound,
    isUserNotFound,
    keyboardEvent,
}: Props) => {
    const dispatch = useAppDispatch();

    const resetPassTextRef = useRef('');
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (handledToValidateSendForm()) {
            sendForgotPassword();
        }
    }, [keyboardEvent]);

    useEffect(() => {
        const forgotPasswordService = ForgotService.subscribe(((state: any) => {
            const { success: isSuccess, title: error, code } = state;
            if (isSuccess) {
                AppAlertService.showSuccess({
                    message: Languages.ResetPasswordSuccess,
                });
                handleIsUserNotFound(false);
            } else if (!isSuccess && error) {
                if (code) {
                    handleIsUserNotFound(true);
                }
            }
        }));

        return () => {
            forgotPasswordService.unsubscribe();
        };
    }, []);

    const handledToValidateSendForm = () => keyboardEvent?.triggered && isValid;

    const validValue = (value: string): any => {
        const { isValid, type } = validateEmail(value);
        setIsValid(isValid);
        return { isValid, type };
    };

    const sendForgotPassword = () => {
        const { isValid } = validValue(resetPassTextRef.current);
        if (!isValid) {
            return;
        }
        LoggerService.logEvent('forgotPassword', {
            email: resetPassTextRef.current,
        });
        dispatch(sendCodeEmail({
            email: resetPassTextRef.current,
        }));
    };

    const onResetPassInputChange = (text: string) => {
        resetPassTextRef.current = text;
        validValue(resetPassTextRef.current);
    };

    return (
        <View style={[styles.containerGeneral, { justifyContent: isUserNotFound ? 'flex-end' : 'center' }]}>
            <Text style={styles.resetText}>{Languages.ResetPassword}</Text>
            <View style={styles.instructionsContainer}>
                <Text style={styles.instructionsText}>{Languages.PleaseEnterYourRegisterd}</Text>
            </View>

            <View style={styles.containerForm}>
                <EmailTextInput
                    placeholder={Languages.Email}
                    handleOnChange={onResetPassInputChange}
                />
                <AppButton
                    title={Languages.Send}
                    disabled={!isValid}
                    handleOnChange={sendForgotPassword}
                />
            </View>
        </View>
    );
};
