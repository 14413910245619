import * as Yup from 'yup';

import Languages from '@i18n/index';

export const AHCSchema = Yup.object().shape({
    routingNumber: Yup
        .string()
        .min(9, Languages.RoutingNumberMinCharacteres)
        .matches(/^[0-9]+$/, Languages.PleaseEnterOnlyNumbers)
        .required(Languages.emptyField),
    accountNumber: Yup
        .string()
        .min(12, Languages.AccountNumberMinCharacters)
        .matches(/^[0-9]+$/, Languages.PleaseEnterOnlyNumbers)
        .required(Languages.emptyField),
    bankName: Yup
        .string()
        .matches(/^[aA-zZ\s]+$/, Languages.PleaseEnterOnlyLetters),
});

export const AHCEditSchema = Yup.object().shape({
    bankName: Yup
        .string()
        .matches(/^[aA-zZ\s]+$/, Languages.PleaseEnterOnlyLetters),
});
