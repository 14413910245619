import React from 'react';

import {
    Image,
    Platform,
} from 'react-native';
import { Color } from 'react-native-svg';

import {
    AccountNotification,
    ActivityNotification,
    ActivitySvg,
    AmericanExpressIcon,
    ApplicationsIcon,
    CheckIcon,
    CreditCardIcon,
    DiscoverLogoImage,
    FAQSvg,
    FinancialFundamentalsIconSvg,
    HomeSvg,
    LogOutIcon,
    MasterCardIcon,
    MasterCardImage,
    PaymentNotification,
    PaymentSvg,
    PersonalInfoIcon,
    ProfileTabIconSvg,
    QuestionActiveIcon,
    SettingIcon,
    VisaCardIcon,
    AutoPayScheduledIcon,
    EdithActiveSvg
} from '@Assets/index';

interface Props {
    name: string;
    fill?: Color;
};

enum IconName {
    accountNotification = 'Account',
    ActivityMenu = 'ActivityMenu',
    activityNotification = 'Activity',
    americanExpress = 'AmericanExpress',
    applications = 'Account Details',
    checking = 'Checking',
    CreditCardIcon = 'CreditCardIcon',
    discover = 'Discover',
    DiscoverImage = 'DiscoverImage',
    FAQ = 'FAQ',
    HelpMenu  = 'HelpMenu',
    HomeMenu = 'HomeMenu',
    information = 'Personal Information',
    logOut = 'Log Out',
    masterCard = 'MasterCard',
    paymentNotification = 'Payment',
    PaymentsMenu = 'PaymentsMenu',
    ProfileMenu = 'ProfileMenu',
    settings = 'Settings',
    visa = 'Visa',
    FinancialFundamentalsMenu = 'FinancialFundamentalsMenu',
    AutoPayScheduled = 'AutoPayScheduled',
    EditActiveSvg = 'EditActiveSvg',
};

export const Icon = ({
    name,
    fill,
}: Props) => {
    const setIcon = () => {
        switch (name) {
        case IconName.information:
            return <PersonalInfoIcon />;
        case IconName.settings:
            return <SettingIcon />;
        case IconName.applications:
            return <ApplicationsIcon />;
        case IconName.logOut:
            return <LogOutIcon />;
        case IconName.visa:
            return <VisaCardIcon />;
        case IconName.masterCard:
            return Platform.OS === 'web' ? <MasterCardIcon /> : <Image source={MasterCardImage} />;
        case IconName.americanExpress:
            return <AmericanExpressIcon />;
        case IconName.discover:
            return <Image source={DiscoverLogoImage} style={{ width: 100, height: 18 }} />;
        case IconName.checking:
            return <CheckIcon />;
        case IconName.accountNotification:
            return <AccountNotification />;
        case IconName.paymentNotification:
            return <PaymentNotification />;
        case IconName.activityNotification:
            return <ActivityNotification />;
        case IconName.FAQ:
            return <FAQSvg />;
        case IconName.HomeMenu:
            return <HomeSvg fill={fill} />;
        case IconName.PaymentsMenu:
            return <PaymentSvg fill={fill} />;
        case IconName.ProfileMenu:
            return <ProfileTabIconSvg fill={fill} />;
        case IconName.ActivityMenu:
            return <ActivitySvg fill={fill} />;
        case IconName.HelpMenu:
            return <QuestionActiveIcon fill={fill} />;
        case IconName.CreditCardIcon:
            return <CreditCardIcon />;
        case IconName.FinancialFundamentalsMenu:
            return <FinancialFundamentalsIconSvg fill={fill} />;
        case IconName.AutoPayScheduled:
            return <AutoPayScheduledIcon fill={fill}/>;
        case IconName.DiscoverImage:
            return <Image source={DiscoverLogoImage} style={{ width: 62, height: 11 }}/>;
        case IconName.EditActiveSvg:
            return <EdithActiveSvg fill={fill} />;
        default:
            return null;
        }
    };
    return (
        <>
            {setIcon()}
        </>
    );
};
