import { colors, fonts, theme } from '@Theme/appTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    manageAutoPayContainer: {
        alignItems: 'center',
        flex: 1,
        padding: 5,
        marginBottom: 10,
        paddingHorizontal: 15
    },
    autopayToggleContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        height: 25,
        justifyContent: 'flex-end',
        marginHorizontal: 16,
        width: '100%',
    },
    toggleText: {
        fontSize: 18,
        fontWeight: '500',
        textAlignVertical: 'center',
        paddingLeft: 5,
        paddingRight: 5
    },
    isAutopayToggleText: {
        fontSize: 10,
        textAlignVertical: 'center'
    },
    selectedPaymentMethodContainer: {
        marginTop: 5
    },
    titleAutoPayIsOff: {
        fontSize: 15,
        fontWeight: '600'
    },
    bodyAutoPayIsOff: {
        fontSize: 14, 
        fontWeight: '400', 
        color: colors.grayColor
    },
    storedPaymentTitle: {
        fontSize: 14,
        fontWeight: '500',
        color: colors.grayColor,
        marginBottom: 10
    },
    submitStoredPaymentContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    cancelButtonContainer: {
        width: '28%',
        height: 35,
        alignItems: 'center',
        borderRadius: 5,
        justifyContent: 'center',
    },
    cancelButtonText: {
        fontSize: 17,
        fontWeight: '700',
        fontFamily: fonts.SFProText,
        color: theme.colors.primary,
    },
    submitButtonText: {
        width: '28%',
        marginBottom: 0,
        height: 35
    },
    title: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 20,
        fontWeight: '700',
        lineHeight: 28,
        textAlign: 'center',
        marginBottom: 10
    },
    containerAutoPayIsOff: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 10,
        height: 70,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10,
        padding: 5,
        paddingLeft: 10
    },
    containerListPayment: {
        width: '100%',
        display: 'flex'
    },
    contentContainerListPayment: {
        paddingBottom: 10,
    },
    separator: {
        borderBottomWidth: 0.7,
        borderColor: colors.gray,
        marginBottom: 15,
        marginTop: 15,
        width: '100%',
    },
    newPaymentMethodContainer: {
        display: 'flex'
    },
    newPaymentMethodRow: {
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 50,
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'center'
    },
    newPaymentMethodIconContainer: {
        width: '15%',
        alignItems: 'center'
    },
    newPaymentMethodTexContainer: {
        width: '70%'
    },
    newPaymentMethodTextLabel: {
        textAlign: 'left',
        alignItems: 'center',
        marginLeft: 30,
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
    },
})