import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    map,
    mergeMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import { getFaqAPI } from '@Profile/api/faqApi';
import {
    FAQ,
    FAQCategory,
    FAQRequestAction,
} from '@Profile/interfaces';
import FaqService from '@Profile/services/FaqService';

import FaqActionsTypes from '../state/faqActionTypes';

export const faqEpic = ($action: Observable<any>) => $action.pipe(
    ofType(FaqActionsTypes.GET_FAQ_REQUEST),
    delay(250),
    mergeMap(({ payload }: FAQRequestAction) => from(getFaqAPI(payload))
        .pipe(
            map((response: FAQ[]) => {
                const faqCategories: FAQCategory[] = response.map((faq: FAQ) => ({
                    name: faq.category,
                } as FAQCategory));

                const uniqueFaqCategories: FAQCategory[] = getUniqueListBy(faqCategories, 'name');
                uniqueFaqCategories.unshift({
                    name: 'All',
                } as FAQCategory);

                FaqService.onSuccess({
                    categories: uniqueFaqCategories,
                    faqs: response,
                });

                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        )
    )
);

export const searchFaqEpic = ($action: Observable<any>) => $action.pipe(
    ofType(FaqActionsTypes.SEARCH_FAQ),
    mergeMap(({ payload }: FAQRequestAction) => from(getFaqAPI(payload))
        .pipe(
            map((response: FAQ[]) => {
                const faqCategories: FAQCategory[] = response.map((faq: FAQ) => ({
                    name: faq.category,
                } as FAQCategory));

                const uniqueFaqCategories: FAQCategory[] = getUniqueListBy(faqCategories, 'name');
                uniqueFaqCategories.unshift({
                    name: 'All',
                } as FAQCategory);

                FaqService.onSuccess({
                    categories: uniqueFaqCategories,
                    faqs: response,
                });

                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        )
    )
);

const getUniqueListBy = (arr: any, key: string): any => [...new Map(arr.map((item: any) => [item[key], item])).values()];
