import axios, { AxiosInstance } from 'axios';

import {
    addAuthBaseURLRequestInterceptor,
    addAuthorizationRequestInterceptor,
    addAuthorizationResponseInterceptor,
} from './index';

const buildAuthAxiosInstance = (): AxiosInstance => {
    const instance = axios.create({
        baseURL: '',
        cancelToken: undefined,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    addAuthBaseURLRequestInterceptor(instance);
    addAuthorizationRequestInterceptor(instance);
    addAuthorizationResponseInterceptor(instance);
    return instance;
};

const AuthApi = buildAuthAxiosInstance();

export default AuthApi;
