import React, { useCallback } from 'react';
import {
    Text,
    TouchableOpacity,
    View,
    Linking
} from 'react-native';
import { isIOS, isAndroid, isMacOs, isWindows } from 'react-device-detect';
import AppAlertService from '@Alert/services/AppAlertService';
import {
    EasypayLogoSvg,
} from '@Assets/index';
import { styles } from '@Auth/styles/AppLinksStyles';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { AppButton } from '@Components/AppButton';

export const AppLinksComponent = () => {
    const handleOnAppLinkTap = useCallback(async () => {
        try {
            if (isMacOs || isIOS) Linking.openURL('https://apps.apple.com/us/app/myeasypay/id1571788423?itsct=apps_box_badge&amp;itscg=30200');
            if (isWindows || isAndroid) Linking.openURL('https://play.google.com/store/apps/details?id=com.myeasypay&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1');
        } catch (error) {
            LoggerService.logError(error);
            AppAlertService.showError({
                message: Languages.DontNowHowToOpenThisURL,
            });
        }
    }, []);

    return (
        <View style={styles.mainBox}>
            <View style={styles.boxAppLink} >
                <View>
                    <TouchableOpacity
                        style={styles.viewRow}
                        onPress={handleOnAppLinkTap}
                    >
                        <View style={styles.icon}>
                            <EasypayLogoSvg
                                width={30}
                                height={24}
                            />
                        </View>
                        <Text style={styles.textBox}>{Languages.AppLinkText}</Text>
                        <AppButton
                            accessibilityLabel={'loginFormAppButtonSignIn'}
                            buttonStyle={{ paddingRight: 12, paddingLeft: 12, height: 25 }}
                            textStyle={{ fontSize: 12 }}
                            handleOnChange={handleOnAppLinkTap}
                            testID={'viewAppLinkButton'}
                            title={Languages.View}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};