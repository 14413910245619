import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { ApplicationsState } from '@Profile/interfaces';
import * as applicationsActions from '@Profile/state/applicationsAction';

type ActionsType = ActionType<typeof applicationsActions>;

const initialState: ApplicationsState = {
    loans: [],
    loanSelected: undefined,
    selectableApplications: [],
};

export const applicationsReducer = createReducer<ApplicationsState, ActionsType>(
    initialState
).handleAction(applicationsActions.saveApplications, (_, { payload }) => ({
    ...initialState,
    ...payload,
})).handleAction(applicationsActions.setApplicationSelected, (state, { payload }) => ({
    ...state,
    loanSelected: payload,
})).handleAction(applicationsActions.setSelectableApplications, (state, { payload }) => ({
    ...state,
    selectableApplications: payload,
}));
