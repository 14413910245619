import React, {
    useEffect,
    useState,
} from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { Subscription } from 'rxjs';

import AppAlertService from '@Alert/services/AppAlertService';
import { registerValidateVerificationCodeAction } from '@Auth/state/registerActions';
import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import { TextCodeInput } from '@Components/TextCodeInput';
import { useAppDispatch } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import { Response } from '@Interfaces/BaseResponse';
import {
    clearPhoneNumberFormat,
    validateNumberOrEmail,
} from '@Utils/FormatUtils';
import ValidateVerficationCodeService
    from '@VerificationCodeDialog/service/ValidateVerficationCodeService';
import VerificationCodeDialogService, {
    VerificationCodeObserver,
} from '@VerificationCodeDialog/service/VerificationCodeDialogService';
import { styles } from '@VerificationCodeDialog/styles/VerificationCodeDialogStyle';

interface Props {
    name: string;
};

let _handleClickEnter = (_code: string) => { };
let _handleClickResend = () => { };
let _owner = '';
let _context = '';
let _code = '';

export const VerificationCodeDialogScreen = ({ name }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [code, setCode] = useState('');

    const dispatch = useAppDispatch();

    useEffect(() => {
        const subscription = VerificationCodeDialogService.subscribe((observer: VerificationCodeObserver) => {
            if (observer.name !== name) {
                return;
            }
            _handleClickEnter = observer.handleClickEnter;
            _handleClickResend = observer.handleClickResend;
            _owner = observer._owner;
            _context = observer.context;
            setIsVisible(observer.open);
        });
        let subscriptionValidate: Subscription;
        if (isVisible) {
            subscriptionValidate = ValidateVerficationCodeService.subscribe((response: Response) => {
                const { success: isSuccess, title } = response;
                if (isSuccess) {
                    doEnterCode();
                } else {
                    AppAlertService.showError({
                        message: title,
                    });
                }
            });
        }
        return () => {
            subscription.unsubscribe();
            if (subscriptionValidate) {
                subscriptionValidate.unsubscribe();
            }
        };
    }, [name, isVisible]);

    const onChangeTextCode = (code: string) => setCode(code);

    const handleOnTapEnter = () => {
        _code = code;
        dispatch(registerValidateVerificationCodeAction({
            context: _context,
            owner: typeValue(_owner),
            value: code,
        }));
    };

    const typeValue = (value: string): string => {
        const { type } = validValue(value);
        if (type === 'PhoneNumber') {
            return _owner = clearPhoneNumberFormat(value);
        }
        return value;
    };

    const validValue = (value: string): any => {
        const { type } = validateNumberOrEmail(value);
        return { type };
    };

    const doEnterCode = () => {
        _handleClickEnter(_code);
        setCode('');
    };

    const handleAppModalRequestClode = () => setIsVisible(false);

    return (
        <AppModal
            animationType='slide'
            closeModal={handleAppModalRequestClode}
            transparent
            visible={isVisible}
        >
            <View style={styles.container}>
                <Text style={styles.title}>
                    {`${Languages.WeJustTextedYou}`}
                </Text>
                <Text style={styles.description} ellipsizeMode='tail' numberOfLines={3}>
                    {`${Languages.DescriptionVerificationCode} ${_owner}`}
                </Text>
                <View style={styles.containerInputButton}>
                    <TextCodeInput onTextChange={onChangeTextCode} />
                    <AppButton
                        title={Languages.Enter}
                        handleOnChange={handleOnTapEnter}
                        disabled={code.length < 4}
                        buttonStyle={styles.enterBtn}
                    />
                </View>
                <View style={styles.footerContainer}>
                    <Text style={styles.textQuestion} >
                        {`${Languages.DidNotGetToCode}`}
                    </Text>
                    <TouchableOpacity onPress={_handleClickResend}>
                        <Text style={styles.textButton}>
                            {`${Languages.Resend}`}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </AppModal>
    );
};
