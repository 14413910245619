import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import { ContactHelpComponent } from '@Components/ContactHelpComponent';
import { TextInput } from '@Components/TextInput';
import { useAppDispatch } from '@Hooks/appHooks';
import { useDebouncedValue } from '@Hooks/useDebouncedValue';
import Languages from '@i18n/index';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import FAQItem from '@Profile/components/FAQItem';
import {
    FAQ,
    FAQCategory,
    FAQCategoryRenderItem,
    FaqServiceResponse,
    QuestionRenderItem,
} from '@Profile/interfaces';
import faqService from '@Profile/services/FaqService';
import {
    getFaqRequest,
    searchFaq,
} from '@Profile/state/faqAction';
import { styles } from '@Profile/styles/FaqScreenStyles';
import { StackScreenProps } from '@react-navigation/stack';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'FaqScreen'> { };

export const FaqScreen = ({ route }: Props) => {
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>('All');
    const [text, setText] = useState('');
    const [categories, setCategories] = useState<FAQCategory[]>();
    const [questions, setQuestions] = useState<FAQ[]>([]);

    const dispatch = useAppDispatch();
    const { debouncedValue } = useDebouncedValue(text, 100);
    const flatRef = useRef<FlatList<any>>();
    const isFirtsTime = useRef<boolean>(true);

    useEffect(() => {
        dispatch(searchFaq({
            category: selectedCategoryName,
            filter: text,
        }));
    }, [selectedCategoryName, text]);

    useEffect(() => {
        dispatch(getFaqRequest());
        const getFaqSubscribe = faqService.subscribe((state: FaqServiceResponse) => {
            if (selectedCategoryName === 'All') {
                setCategories(state.categories);
            }
            setQuestions(state.faqs);
            if (questions && isFirtsTime) {
                if (route.params.from === 'customer') {
                    setTimeout(() => {
                        flatRef?.current?.scrollToEnd({ animated: false });
                    }, 200);
                }
                isFirtsTime.current = false;
            }
        });
        return () => {
            getFaqSubscribe.unsubscribe();
            isFirtsTime.current = false;
        };
    }, []);

    const renderCategoryItem = ({ item }: FAQCategoryRenderItem) => {
        const backgroundColor = item.name === selectedCategoryName ? theme.colors.primary : colors.white;
        const color = item.name === selectedCategoryName ? colors.white : theme.colors.primary;

        return (
            <TouchableOpacity
                accessibilityLabel={`faqTouchableCategory${item.name.replace(/\s/g, '')}`}
                onPress={() => onPressCategory(item)}
                style={[styles.viewMenu, { backgroundColor }]}
                testID={`faqTouchableCategory${item.name.replace(/\s/g, '')}`}
            >
                <Text
                    style={[styles.textMenu, { color, marginHorizontal: 10 }]}
                >{item.name}</Text>
            </TouchableOpacity>
        );
    };

    const onPressCategory = (item: FAQCategory) => {
        setSelectedCategoryName(item.name);
    };

    const renderQuestions = useCallback(
        ({ item, index }: QuestionRenderItem) => (
            <FAQItem item={item} index={index} handleOnPress={() => onPressFAQItem(index)} />
        ),
        [questions],
    );

    const onPressFAQItem = (index: number) => {
        questions[index].selected = !questions[index].selected;
        setQuestions([
            ...questions,
        ]);
    };

    const onChangeTextSearch = (text: string) => setText(text);

    const footer = useMemo(() => (<ContactHelpComponent />), []);

    return (
        <>
            <View style={styles.principalView}>
                <Text style={styles.textHelp}>{Languages.HowCanWeHelpYou}</Text>
                <TextInput
                    accessibilityLabel={'faqTextInputSearch'}
                    containerStyle={styles.containerSearch}
                    onChangeText={onChangeTextSearch}
                    placeholder={Languages.PlaceholderSearch}
                    testID={'faqTextInputSearch'}
                />
            </View>
            {
                !debouncedValue.length && (
                    <View style={styles.flatList}>
                        <FlatList
                            data={categories}
                            renderItem={renderCategoryItem}
                            keyExtractor={(item: FAQCategory, _) => item.name.toString()}
                            showsVerticalScrollIndicator={false}
                            horizontal={true}
                        />
                    </View>
                )
            }
            <FlatList
                ref={flatRef}
                data={questions}
                renderItem={renderQuestions}
                initialNumToRender={10}
                keyExtractor={(item: FAQ, _) => item.question.toString()}
                showsVerticalScrollIndicator={false}
                ItemSeparatorComponent={() => <View style={styles.viewFlatlistItem} />}
                style={styles.questionList}
                ListFooterComponent={footer}
                removeClippedSubviews={Platform.OS === 'android'}
                ListFooterComponentStyle={styles.listFooter}
            />
        </>
    );
};
