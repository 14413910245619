import { Subject } from 'rxjs';

import { Error } from '@Interfaces/BaseResponse';
import { CurrentPaymentService } from '@MakeAPayment/interfaces';

const MakeAPaymentService = () => {
    const subject = new Subject();

    const onSuccess = (response: CurrentPaymentService) => {
        subject.next(response);
    };

    const onError = (error: Error) => {
        subject.next(error);
    };

    const subscribe = (process: any) => subject.subscribe(process);
    return {
        onError,
        onSuccess,
        subscribe,
    };

};

export default MakeAPaymentService();
