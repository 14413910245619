import * as Yup from 'yup';

import Languages from '@i18n/index';

export const LoginSchema = Yup.object().shape({
    email: Yup
        .string()
        .required(Languages.emptyField)
        .email(Languages.emailInValid),
    password: Yup
        .string()
        .required(Languages.emptyField),
});
