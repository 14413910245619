import React, {
    FC,
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    Text,
    View,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import SwipeUpDownModal from 'react-native-swipe-modal-up-down';

import {
    AccountNumberImage,
    RoutingNumberImage,
} from '@Assets/index';
import RoutingNumberModalService from '@MethodsOfPayment/services/RoutingNumberModalService';
import { styles } from '@MethodsOfPayment/styles/AddNewPaymentModalStyle';

interface PropsRenderItem {
    item: AddNewPaymentDefinitionItem;
};
interface AddNewPaymentDefinitionItem {
    id: string;
    icon: FC<SvgProps>;
    name: string;
};
export const AddNewPaymentMethodModal = () => {
    const [options, setOptions] = useState<AddNewPaymentDefinitionItem[]>([]);
    const [hasShowModal, setHasShowModal] = useState(false);
    const [modalShow, setModalShow] = useState('');
    useEffect(() => {
        const subscription = RoutingNumberModalService.subscribe((state: any) => {
            setModalShow(state.name);
            setHasShowModal(state.open);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);
    useEffect(() => {
        if (hasShowModal && modalShow === 'routingModal') {
            setOptions([
                {
                    id: '1',
                    icon: RoutingNumberImage,
                    name: 'Routing Number',
                },
            ]);
        } else {
            setOptions([
                {
                    id: '2',
                    icon: AccountNumberImage,
                    name: 'Account Number',
                },
            ]);
        };
    }, [hasShowModal]);

    const closeModal = () => {
        RoutingNumberModalService.hide();
    };
    const handleRenderItem = ({ item }: PropsRenderItem) => (
        <View>
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 12,
            }}>
                {
                    <item.icon
                        height={123}
                        preserveAspectRatio={'xMidYMid slice'}
                        width={301}
                    />
                }
            </View>
            <Text style={styles.name}>{item.name}</Text>
        </View>
    );
    const handleRenderContentModal = () => (
        <>
            <FlatList
                data={options}
                renderItem={handleRenderItem}
                keyExtractor={(item, _) => item.id.toString()}
                removeClippedSubviews={Platform.OS === 'android'}
                style={styles.flatList}
            />
        </>
    );
    const handleRenderHeaderContent = () => (
        <View style={styles.headerContentStyle}>
            <View style={styles.headerBar} />
            <View style={styles.appTextContainer}>
                <Text style={styles.appText}>{modalShow === 'routingModal' ? 'Routing Number' : 'Account Number'}</Text>
            </View>
        </View>
    );
    return (
        <SwipeUpDownModal
            PressToanimate={true}
            modalVisible={hasShowModal}
            ContentModal={handleRenderContentModal()}
            HeaderStyle={styles.headerStyle}
            ContentModalStyle={styles.contentModel}
            HeaderContent={handleRenderHeaderContent()}
            onClose={closeModal}
        />
    );
};
