import Config from 'react-native-config';

import { ConfigMap } from '@Api/interfaces';
import Languages from '@i18n/index';

interface ApiUrls {
    AUTH_BASE_URL: string;
    APP_BASE_URL: string;
};

export const obtainURLs = () => new Promise<ApiUrls>(async (resolve) => {
    try {
        const response = await fetch('../env.json');
        if (response.ok) {
            const textResponse = await response.text();
            const arrayURLs = textResponse.split('\n');
            resolve({
                AUTH_BASE_URL: arrayURLs[0].split('=')[1],
                APP_BASE_URL: arrayURLs[1].split('=')[1],
            });
        } else {
            resolve({
                AUTH_BASE_URL: Config.AUTH_BASE_URL as string,
                APP_BASE_URL: Config.APP_BASE_URL as string,
            });
        }
    } catch (error) {
        resolve({
            AUTH_BASE_URL: Config.AUTH_BASE_URL as string,
            APP_BASE_URL: Config.APP_BASE_URL as string,
        });
    };
});

export const Commands = {
    AUTHENTICATE_EMAIL: 'api/Authentication/authenticate/email',
    AUTHENTICATE_INTERNAL: 'api/Authentication/authenticate/internal',
    AUTHENTICATE_REFRESH_TOKEN: 'api/Authentication/refresh',
    DELETE_PAYMENT_PENDING: 'api/Payments/pending/cancel/{id}/loan/{loanId}',
    FORGOT_PASSWORD_EMAIL: 'api/VerificationCodes/password-reset/email/request',
    FORGOT_PASSWORD_PHONE_NUMBER: 'api/VerificationCodes/password-reset/phone/request',
    GET_APPLICATIONS: 'api/Accounts/loans',
    GET_AUTOPAY: 'api/PaymentMethods/autopay/{loanId}',
    GET_BALANCE: 'api/Accounts/balance/{applicationId}',
    GET_DOCUMENT_STATEMENT: 'api/Documents/monthly-statement/{id}',
    GET_DOCUMENT: 'api/Documents/contract/{applicationId}',
    GET_DOCUMENTS: 'api/Documents/monthly-statements/{applicationId}',
    GET_FAQ: '/api/Accounts/FAQs',
    GET_PAYMENT_ACTIVITIES: 'api/Accounts/payment-activities/{loanId}/page/{page}/size/{pageSize}',
    GET_PAYMENT_ACTIVITY: 'api/Accounts/payment-activity/{id}',
    GET_PAYMENT_METHODS: 'api/PaymentMethods/loan/{loanId}',
    GET_PENDING_PAYMENTS: 'api/Accounts/pending-payments/{loanId}',
    GET_PROFILE_CLAIM: 'api/Profiles/claim',
    GET_PROFILE: 'api/Profiles',
    GET_ZIP_CODES_INFORMATION: 'api/RegionalInformation/zip-codes/{zipCode}',
    NOTIFICATION_OFFERS: 'api/Offers/repeat-business/{applicationId}',
    PATCH_PAYMENT_METHODS_SORT: 'api/PaymentMethods/sort/loan/{loanId}',
    PATCH_PAYMENT_SET_PRIMARY: 'api/PaymentMethods/set-primary/loan/{loanId}/payment-method/{paymentMethodId}/type/{paymentMethodType}',
    PAYMENT_DELETE_CARD: 'api/PaymentMethods/card/{id}',
    PAYMENT_DELETE_CHECK: 'api/PaymentMethods/check/{id}',
    PAYMENT_SUMMARY: 'api/Accounts/payment-summary',
    POST_CONFIRM_ACCOUNT: 'api/Accounts/confirm',
    POST_PAYMENT_SCHEDULE: 'api/Accounts/payments/schedule',
    POST_PHONE_UPDATE_REQUEST: 'api/VerificationCodes/consumer-profile/phone/update/request',
    POST_REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE: 'api/Accounts/register/email/business-center/validate',
    POST_REGISTER_EMAIL_BUSINESS_CENTER: 'api/Accounts/register/email/business-center',
    POST_REGISTER_EMAIL_VERIFICATION_CODE_RESEND: 'api/Accounts/register/email/verification-code/resend',
    PUT_ACH: 'api/PaymentMethods/check',
    PUT_AUTOPAY: 'api/PaymentMethods/autopay',
    PUT_CARD: 'api/PaymentMethods/card',
    PUT_PAYMENT_PENDING: 'api/Payments/pending',
    PUT_PROFILE: 'api/Profiles/contact-information',
    REGISTER_EMAIL: 'api/Accounts/register/email',
    REGISTER_NEW_PAYMENTH_METHOD_AHC: 'api/PaymentMethods/check',
    REGISTER_NEW_PAYMENTH_METHOD_CARD: 'api/PaymentMethods/card',
    REGISTER_VERIFICATION_CODE: 'api/VerificationCodes/registration/phone/request',
    REGISTER: 'api/Accounts/register/phone',
    UPDATE_PASSWORD_EMAIL: 'api/Accounts/password-reset/update-password/email',
    UPDATE_PASSWORD_PHONE: 'api/Accounts/password-reset/update-password/phone',
    VALIDATE_VERIFICATION_CODE: 'api/VerificationCodes/validate',
    GET_SURVEY_ANSWERED: 'api/survey/mark/{loanId}',
    SET_SURVEY_ANSWERED: 'api/survey/mark',
};

export const MESSAGE_MAP: ConfigMap = {
    [Commands.AUTHENTICATE_EMAIL]: {
        [400]: {
            title: Languages.TheUsernameOrPasswordIsInvalid,
        },
        [401]: {
            title: Languages.TheUsernameOrPasswordIsInvalid,
        },
    },
    [Commands.AUTHENTICATE_REFRESH_TOKEN]: {
        [400]: {
            title: Languages.TheUsernameOrPasswordIsInvalid,
        },
        [401]: {
            title: Languages.TheUsernameOrPasswordIsInvalid,
        },
    },
};
