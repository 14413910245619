import React from 'react';

import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import { useAppDispatch } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoadingService from '@LoadingDialog/service/LoadingService';
import { ApplicationsScreen } from '@Profile/screens/ApplicationsScreen.web';
import { ProfileFaqScreen } from '@Profile/screens/ProfileFaqScreen';
import { ProfileInfoScreen } from '@Profile/screens/ProfileInfoScreen.web';
import { downloadApplications } from '@Profile/state/applicationsAction';
import { getFaqRequest } from '@Profile/state/faqAction';
import { getUserInfoAction } from '@Profile/state/profileActions';
import {
    createMaterialTopTabNavigator,
    MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { switchcase } from '@Utils/index';

interface CustomTopTabProps extends MaterialTopTabBarProps {
    onPressFocusedTab?: (name: string) => void;
}

const TabStack = createMaterialTopTabNavigator();

const CustomTabBar = ({ state, descriptors, navigation, onPressFocusedTab = () => { } }: CustomTopTabProps) => (
    <View style={styles.container}>
        {state.routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const label =
                options.tabBarLabel !== undefined
                    ? options.tabBarLabel
                    : options.title !== undefined
                        ? options.title
                        : route.name;

            const isFocused = state.index === index;

            const onPress = () => {
                const event = navigation.emit({
                    type: 'tabPress',
                    target: route.key,
                    canPreventDefault: true,
                });

                if (!isFocused && !event.defaultPrevented) {
                    navigation.navigate(route.name);
                } else {
                    onPressFocusedTab(route.name);
                }
            };

            const onLongPress = () => {
                navigation.emit({
                    type: 'tabLongPress',
                    target: route.key,
                });
            };

            return (
                <TouchableOpacity
                    accessibilityLabel={`profileTopTabsTouchableTab${label}`}
                    accessibilityState={isFocused ? { selected: true } : {}}
                    key={index}
                    onLongPress={onLongPress}
                    onPress={onPress}
                    style={[styles.button, isFocused && styles.buttonSelected]}
                    testID={`profileTopTabsTouchableTab${label}`}
                >
                    <Text
                        style={[
                            styles.text,
                            isFocused && styles.textSelected,
                        ]}
                        numberOfLines={1}
                    >
                        {label}
                    </Text>
                </TouchableOpacity>
            );
        })}
    </View>
);

export const ProfileTopTabsStack = () => {
    const dispatch = useAppDispatch();

    const handleOnPressFocusedTab = (name: string) => {
        LoadingService.show();
        switchcase({
            ['ApplicationsScreen']: () => dispatch(downloadApplications()),
            ['ProfileInfoScreen']: () => dispatch(getUserInfoAction()),
            ['ProfileFaqScreen']: () => dispatch(getFaqRequest()),
        })(() => LoadingService.hide())(name)();
    };

    return (
        <TabStack.Navigator
            tabBar={(props) => <CustomTabBar {...props} onPressFocusedTab={handleOnPressFocusedTab} />}
        >
            <TabStack.Screen name="ProfileInfoScreen" options={{ title: Languages.PersonalInformation }} component={ProfileInfoScreen} />
            <TabStack.Screen name="ApplicationsScreen" options={{ title: Languages.AccountDetails }} component={ApplicationsScreen} />
            <TabStack.Screen name="ProfileFaqScreen" options={{ title: Languages.FAQ }} component={ProfileFaqScreen} />
        </TabStack.Navigator>
    );
};

const styles = StyleSheet.create({
    button: {
        alignItems: 'center',
        paddingBottom: 17,
        flex: 1,
        justifyContent: 'center',
        borderBottomColor: '#DADADA',
        borderBottomWidth: 1,
    },
    buttonSelected: {
        borderBottomColor: theme.colors.primary,
        borderBottomWidth: 2,
    },
    container: {
        marginVertical: 13,
        flexDirection: 'row',
        height: 40,
    },
    text: {
        color: colors.grayColor,
        fontSize: 17,
        lineHeight: 20.29,
        fontFamily: fonts.SFProText,
        fontWeight: '500',
    },
    textSelected: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontWeight: '600',
        lineHeight: 20.29,
    },
});
