import React, { useEffect } from 'react';

import { View } from 'react-native';

import { EasypayLogoSvg } from '@Assets/index';
import { registerValidateVerificationCodeAction } from '@Auth/state/registerActions';
import { useAppDispatch } from '@Hooks/appHooks';
import { Response } from '@Interfaces/BaseResponse';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { reset } from '@Navigation/RootNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { theme } from '@Theme/appTheme';
import ValidateVerficationCodeService
    from '@VerificationCodeDialog/service/ValidateVerficationCodeService';

interface Props extends StackScreenProps<RootStackParamList, 'PasswordResetConfirmationScreen'> { };

export const PasswordResetConfirmationScreen = ({ route }: Props) => {
    const {
        email,
        code,
    } = route?.params || {};

    const dispatch = useAppDispatch();

    useEffect(() => {
        const subscriptionValidate = ValidateVerficationCodeService.subscribe((response: Response) => {
            const { code: responseCode, success: isSuccess } = response;
            if (isSuccess) {
                goToNextScreen(code, email);
            } else if (responseCode === 102) {
                goToExpireLinkScreen(email);
            } else {
                goToLoginScreen();
            }
        });
        return () => {
            subscriptionValidate.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (code && email) {
            verifyCode(code, email);
        }
    }, [code, email]);

    const verifyCode = (code: string, email: string) => {
        const context = 'EmailPasswordReset';
        dispatch(registerValidateVerificationCodeAction({
            context,
            owner: email,
            value: code,
        }));
    };

    const goToNextScreen = (code?: string, email?: string) => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: { from: 'newPassword' },
                },
                {
                    name: 'NewPasswordScreen',
                    params: {
                        email,
                        verificationCode: code,
                    },
                },
            ],
        });
    };

    const goToExpireLinkScreen = (email?: string) => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
                {
                    name: 'LinkExpireScreen',
                    params: {
                        email,
                        type: 'Password',
                    },
                },
            ],
        });
    };

    const goToLoginScreen = () => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
            ],
        });
    };

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.primary,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <EasypayLogoSvg />
        </View>
    );
};
