import React, {
    useEffect,
    useState,
} from 'react';

import LottieView from 'lottie-react-native';
import {
    BackHandler,
    Text,
    View,
} from 'react-native';

import { LoadingLottie } from '@Assets/index';
import LoadingService from '@LoadingDialog/service/LoadingService';
import { styles } from '@LoadingDialog/styles/LoadingScreenStyle';

interface State {
    name: string;
    open: boolean;
};

interface Props {
    name: string;
};

const handleBackPress = () => true;

export const LoadingDialog = ({ name }: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const subscription = LoadingService.subscribe((state: State) => {
            if (state.name !== name) {
                return;
            }
            setIsVisible(state.open);
        });
        if (isVisible) {
            BackHandler.addEventListener('hardwareBackPress', handleBackPress);
        } else {
            BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
        }

        return () => {
            subscription.unsubscribe();
        };
    }, [name]);

    if (isVisible) {
        return (
            <View
                accessibilityLabel={'loadingScreenView'}
                nativeID={'loadingScreenView'}
                style={styles.mainContainer}
                testID={'loadingScreenView'}
            >
                <View style={styles.container}>
                    <Text style={styles.loadingText}>Loading</Text>
                    <LottieView
                        autoPlay
                        loop
                        source={LoadingLottie}
                        style={styles.lottie}
                    />
                </View>
            </View>
        );
    }
    return null;
};
