import React, {
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';

import { QuestionActiveIcon } from '@Assets/index';
import { Icon } from '@Components/Icon';
import { PageMenu } from '@Home/interfaces';
import {
    getListMenuAction,
    navigateLeftMenu,
} from '@Home/state/menuActions';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import { navigate } from '@Navigation/RootNavigation';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface PropsItem {
    item: PageMenu;
    index: number;
};

export const LeftMenu = () => {
    const dispatch = useAppDispatch();
    const { pages } = useAppSelector(({
        menuReducer,
    }) => ({
        pages: menuReducer.pages,
    }));

    const [isQuestionIcon, setIsQuestionIcon] = useState<boolean>();

    const isMobile = useCheckMobileScreen();
    const { width: screenWidth } = useWindowDimensions();

    useEffect(() => {
        dispatch(getListMenuAction());
    }, []);

    useEffect(() => {
        const page: PageMenu | undefined = pages.find((page: PageMenu) => page.selected);
        if (page) {
            setIsQuestionIcon(page.index === 4 ? true : false);
        }
    }, [pages]);

    const onClickItem = (item: PageMenu) => {
        setIsQuestionIcon(false);
        navigate(item.screen, {});
        dispatch(navigateLeftMenu(item.screen));
    };

    const onClickQuestion = () => {
        setIsQuestionIcon(true);
        navigate('FaqScreen', {});
        dispatch(navigateLeftMenu('FaqScreen'));
    };

    const handleRenderItem = ({ index, item }: PropsItem) => (isMobile || (!isMobile && !item.onlyMobile)) ? (
        <View key={index}>
            <TouchableOpacity
                accessibilityLabel={`leftMenuTouchableItem${item.name}`}
                onPress={() => onClickItem(item)}
                testID={`leftMenuTouchableItem${item.name}`}
                style={[
                    styles.containerItem,
                    isMobile && {
                        width: screenWidth / 5,
                        paddingTop: 15,
                    },
                ]}
            >
                <Icon
                    name={item.Icon}
                    fill={item.selected ? theme.colors.primary : colors.grayLigth}
                />
                <Text
                    style={[
                        item.screen != "FinancialFundamentalsScreen" ? styles.text : styles.textFinancialFundamentals,
                        { color: item.selected ? theme.colors.primary : colors.grayLigth },
                    ]}
                >
                    {item.name}
                </Text>
                {(!isMobile && item.selected) && (
                    <View style={styles.verticalLine}></View>
                )}
            </TouchableOpacity>
        </View>
    ) : null;

    const renderDefault = () => (
        <>
            <FlatList
                data={pages}
                renderItem={handleRenderItem}
                keyExtractor={(item: PageMenu, _) => item.index.toString()}
                showsVerticalScrollIndicator={false}
                scrollEnabled={false}
                style={styles.list}
            />
            <TouchableOpacity
                accessibilityLabel={'leftMenuTouchableItemFAQ'}
                onPress={() => onClickQuestion()}
                style={styles.containerQuestion}
                testID={'leftMenuTouchableItemFAQ'}
            >
                <QuestionActiveIcon
                    fill={isQuestionIcon ? theme.colors.primary : colors.grayLigth} />
                {isQuestionIcon && (
                    <View style={styles.verticalLine}></View>
                )}
            </TouchableOpacity>
        </>
    );

    const rederMobile = () => (
        <FlatList
            data={pages}
            renderItem={handleRenderItem}
            keyExtractor={(item: PageMenu, _) => item.index.toString()}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
            horizontal
            style={styles.list}
        />
    );

    return (
        <View style={[styles.container, isMobile && styles.containerMobile]}>
            {
                isMobile ? rederMobile() : renderDefault()
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    containerHamburger: {
        alignItems: 'center',
        borderBottomColor: colors.grayLigthDark,
        borderBottomWidth: 1,
        height: 66,
        justifyContent: 'center',
        width: 66,
    },
    containerItem: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingTop: 15,
    },
    containerMobile: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    containerQuestion: {
        alignItems: 'center',
        height: 60,
        justifyContent: 'center',
        paddingBottom: 30,
    },
    list: {
        flex: 1,
        width: 78,
    },
    text: {
        color: colors.grayLigth,
        fontSize: 10,
        paddingTop: 5,
        textAlign: 'center',
        paddingBottom: 15
    },
    textFinancialFundamentals: {
        color: colors.grayLigth,
        fontSize: 10,
        marginTop: 5,
        marginBottom: 2,
        textAlign: 'center'
    },
    verticalLine: {
        backgroundColor: theme.colors.primary,
        height: 44,
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 0,
        width: 3,
    },
});
