import React, {
    useEffect,
    useState,
} from 'react';

import {
    Platform,
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import Languages from '@i18n/index';
import { PrimaryMethodModalState } from '@MethodsOfPayment/interfaces';
import PrimaryMethodModalService from '@MethodsOfPayment/services/PrimaryMethodModalService';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

let _text = '';
let _paymentNetwork = '';
let _handleOnTapAcceptButton = () => { };
let _handleOnTapCancelButton = () => { };

export const PrimaryMethodModal = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const subscribe = PrimaryMethodModalService.subscribe((state: PrimaryMethodModalState) => {
            if (state.params) {
                const {
                    lastFourDigits,
                    paymentNetwork,
                    onTapAcceptButton = () => { },
                    onTapCancelButton = () => { },
                } = state.params;
                _text = lastFourDigits;
                _paymentNetwork = paymentNetwork ?? '';
                _handleOnTapAcceptButton = onTapAcceptButton;
                _handleOnTapCancelButton = onTapCancelButton;
            }
            setIsVisible(state.open);
        });
        return () => {
            subscribe.unsubscribe();
        };
    }, []);

    const handleOnTapAcceptButton = () => {
        _handleOnTapAcceptButton();
        PrimaryMethodModalService.hide();
    };

    const handleOnTapCancelButton = () => {
        _handleOnTapCancelButton();
        PrimaryMethodModalService.hide();
    };

    return (
        <AppModal
            animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
            closeModal={handleOnTapCancelButton}
            transparent
            visible={isVisible}
        >
            <View style={styles.container}>
                <Text style={styles.title}>
                    {`${Languages.ContinueQuestion}`}
                </Text>
                <Text style={styles.description}>
                    {` ${_paymentNetwork} ${Languages.EndingIn} ${_text} ${Languages.WillBeTheNewPrimaryMethodOfPayment}`}
                </Text>
                <View style={styles.containerInputButton}>
                    <AppButton
                        accessibilityLabel={'primaryMethodModalAppButtonAccept'}
                        handleOnChange={handleOnTapAcceptButton}
                        testID={'primaryMethodModalAppButtonAccept'}
                        textStyle={styles.textButtonAccept}
                        title={Languages.Accept}
                    />
                    <AppButton
                        accessibilityLabel={'primaryMethodModalAppButtonCancel'}
                        buttonStyle={styles.buttonCancelStyle}
                        handleOnChange={handleOnTapCancelButton}
                        testID={'primaryMethodModalAppButtonCancel'}
                        textStyle={styles.textButtonCancel}
                        title={Languages.Cancel}
                    />
                </View>
            </View>
        </AppModal>
    );
};

const styles = AppStyleSheet({
    container: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 10,
        width: 343,
        paddingVertical: 16,
    },
    containerInputButton: {
        justifyContent: 'space-between',
        marginTop: 25,
        width: '75%',
    },
    description: {
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 17.9,
        marginTop: 10,
        width: 257,
        textAlign: 'center',
    },
    textButtonAccept: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontStyle: 'normal',
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    textButtonCancel: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontStyle: 'normal',
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    buttonCancelStyle: {
        backgroundColor: colors.white,
    },
    title: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextBold,
        fontSize: 17,
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 20.29,
        marginTop: 16,
        textAlign: 'center',
    },
});
