import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import AppIntroSlider from 'react-native-app-intro-slider';

import {
    OnBoardingFinancialFundamentals,
    OnboardingPageOne,
    OnboardingPageThree,
    OnboardingRebate,
} from '@Assets/index';
import Languages from '@i18n/index';
import { OnboardingSlide } from '@OnBoarding/interfaces';
import OnboardingService from '@OnBoarding/service/OnboardingService';
import { styles } from '@OnBoarding/styles/OnboardingScreenStyle';
import { colors } from '@Theme/appTheme';

interface State {
    name: string;
    open: boolean;
    email: string;
};

interface Props {
    name: string;
};

interface SlideItem {
    index: number;
    item: OnboardingSlide;
};

const slides: OnboardingSlide[] = [
    {
        key: 'one',
        title: Languages.TitleCreateProfile,
        text: Languages.DescriptionCreateProfile,
        image: OnboardingPageOne,
        backgroundColor: colors.white,
    },
    {
        key: 'two',
        title: Languages.OnBoardingTitleRebate,
        text: '',
        image: OnboardingRebate,
        backgroundColor: colors.white,
    },
    {
        key: 'three',
        title: Languages.TitleMakingPayment,
        text: Languages.DescriptionMakingPayment,
        image: OnboardingPageThree,
        backgroundColor: colors.white,
    },
    {
        key: 'four',
        title: Languages.FinancialFundamentals,
        text: Languages.FinancialFundamentalsDescription,
        image: OnBoardingFinancialFundamentals,
        backgroundColor: colors.white,
    },
];

export const OnboardingScreen = ({ name }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const email = useRef('');

    useEffect(() => {
        const subscription = OnboardingService.subscribe(async (state: State) => {
            if (state.name !== name) {
                return;
            }
            setIsVisible(state.open);
            email.current = state.email;
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [name]);

    const renderDoneButton = () => (
        <TouchableOpacity
            accessibilityLabel={'onboardingScreenTouchableDone'}
            onPress={done}
            style={styles.viewButton}
            testID={'onboardingScreenTouchableDone'}
        >
            <Text style={styles.textButton} >{Languages.Continue}</Text>
        </TouchableOpacity>
    );

    const handleRenderItem = ({ item }: SlideItem) => (
        <View style={styles.view}>
            <View style={styles.viewImage}>
                <item.image style={{ height: 160 }} />
            </View>
            <Text style={styles.introTitleStyle}>
                {item.title}
            </Text>
            <View style={styles.viewText}>
                <Text style={styles.introTextStyle}>
                    {item.text}
                </Text>
            </View>
        </View>
    );

    const done = () => {
        setIsVisible(false);
        OnboardingService.hide(email.current);
    };

    if (isVisible) {
        return (
            <View style={styles.viewP}>
                <View style={styles.viewAppIntro}>
                    <AppIntroSlider
                        accessibilityLabel={'onboardingScreenAppIntroSliderSlides'}
                        activeDotStyle={styles.activeDot}
                        bottomButton
                        data={slides}
                        dotStyle={styles.dotStyle}
                        nextLabel={'Continue'}
                        renderDoneButton={renderDoneButton}
                        renderItem={handleRenderItem}
                        showNextButton={false}
                        showSkipButton={false}
                        testID={'onboardingScreenAppIntroSliderSlides'}
                    />
                </View>
            </View>
        );
    }
    return null;
};
