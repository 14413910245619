import React, {
    useEffect,
    useRef,
} from 'react';

import {
    ImageBackground,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    EasyPayLogoComplete,
    Mep,
} from '@Assets/index';
import { RegisterFormComponent } from '@Auth/components/RegisterFormComponent';
import { AppLinksComponent } from '@Auth/components/AppLinksComponent';
import RegisterBusinessCenterService, {
    RegisterBusiness,
} from '@Auth/services/RegisterBusinessCenterService';
import RegisterService, { RegisterServiceResponse } from '@Auth/services/RegisterService';
import {
    registerByEmailAction,
    registerByEmailBusinessCenterAction,
} from '@Auth/state/registerActions';
import { styles } from '@Auth/styles/RegisterWebStyles';
import TermsOfUseService from '@Dialogs/services/TermsOfUseService';
import { useAppDispatch } from '@Hooks/appHooks';
import useKeyboardEvent from '@Hooks/useKeyboardEvent';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { t } from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'RegisterScreen'> { }

export const RegisterScreen = ({ navigation, route }: Props) => {
    const dispatch = useAppDispatch();

    const keyboardEvent = useKeyboardEvent('Enter', 'register');
    const passwordRef = useRef<string>('');
    const {
        disableEmailField: isDisableEmailField,
        registerBusinessCenterParams,
    } = route?.params || {};

    useEffect(() => {
        const registerSubscribe = RegisterService.subscribe((state: RegisterServiceResponse) => {
            const { success: isSuccess, title } = state;
            if (isSuccess) {
                AppAlertService.showSuccess({
                    message: Languages.PleaseCheckYourInbox,
                });
            } else {
                AppAlertService.showError({
                    message: title,
                });
            }
            goToLoginScreen();
        });
        const registerBusinessSubscribe = RegisterBusinessCenterService.subscribe((state: RegisterBusiness) => {
            if (state.action === 'registration') {
                goToLoginScreen(true);
            }
        });
        return () => {
            registerBusinessSubscribe.unsubscribe();
            registerSubscribe.unsubscribe();
        };
    }, []);

    const goToLoginScreen = (autoLogin?: boolean) => {
        if (autoLogin) {
            navigation.navigate('LoginScreen', {
                loginParams: {
                    autoLogin: true,
                    password: passwordRef.current,
                    username: registerBusinessCenterParams?.email,
                },
            });
        } else {
            navigation.navigate('LoginScreen', {});
        }
    };

    const handleOnCreateAccount = (email: string, password: string) => {
        passwordRef.current = password;
        LoggerService.logEvent('createAccount', {
            email,
            businessCenter: registerBusinessCenterParams !== undefined,
        });
        if (registerBusinessCenterParams) {
            dispatch(registerByEmailBusinessCenterAction({
                content: registerBusinessCenterParams.content,
                password,
            }));
        } else {
            dispatch(registerByEmailAction({
                email,
                password,
            }));
        }
    };

    const handleOnPress = () => navigation.reset({
        index: 0,
        routes: [
            {
                name: 'LoginScreen',
                params: {},
            },
        ],
    });

    const validateSendKeyboardEvent = () => {
        if (keyboardEvent?.screen && window.location.href.indexOf(keyboardEvent?.screen) > -1) {
            return keyboardEvent;
        }
    };

    const handleOnOpenTermsOfUse = () => TermsOfUseService.showDialog();

    return (
        <ImageBackground
            source={Mep}
            imageStyle={[t.wFull, t.hFull]}
            style={[t.flex1, t.itemsEnd, t.justifyCenter, t.pX7]}
        >
            <View style={[
                { width: 464 },
                t.minW80,
                t.maxW2_5,
                t.maxHFull,
                t.bgWhite,
                t.itemsCenter,
                t.roundedSm,
                t.pY10,
            ]}>
                <View style={[t.pB12]}>
                    <EasyPayLogoComplete />
                </View>
                <Text style={[
                    styles.textAccount,
                    t.text2xl,
                    t.leadingNormal,
                    t.textLeft,
                    t.mB5,
                ]}>{Languages.CreateAnAccount}</Text>
                <View style={[t.w4_5]}>
                    <RegisterFormComponent
                        disableEmailField={isDisableEmailField}
                        email={registerBusinessCenterParams?.email}
                        handleOnCreateAccount={handleOnCreateAccount}
                        keyboardEvent={validateSendKeyboardEvent()}
                    />
                </View>
                <View style={[t.pT5, t.w4_5]}>
                    <TouchableOpacity
                        accessibilityLabel={'registerScreenTouchableTermsOfUse'}
                        onPress={handleOnOpenTermsOfUse}
                        testID={'registerScreenTouchableTermsOfUse'}
                    >
                        <Text style={styles.text}>
                            {`${Languages.ByContinuingIAgreeTo} `}
                            <Text style={styles.link}>{Languages.TermsOfUse}</Text>
                        </Text>
                    </TouchableOpacity>
                    <View style={[t.flexRow, t.mT6, t.itemsCenter, t.justifyCenter]}>
                        <Text style={styles.signIn}>{Languages.HaveAnAccount}</Text>
                        <TouchableOpacity
                            accessibilityLabel={'registerScreenTouchableSignIn'}
                            onPress={handleOnPress}
                            testID={'registerScreenTouchableSignIn'}
                        >
                            <Text style={styles.linkSing}>{Languages.SignIn}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <AppLinksComponent />
        </ImageBackground >
    );
};
