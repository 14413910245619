import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import * as balanceActions from '@Home/state/balanceActions';

type ActionsType = ActionType<typeof balanceActions>;

interface BalanceState { };

export const initialState = { };

export const balanceReducer = createReducer<BalanceState, ActionsType>(
    initialState,
);
