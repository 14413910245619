import { createTheme } from 'react-native-whirlwind';

import { Theme } from '@react-navigation/native';

export const theme: Theme = {
    dark: false,
    colors: {
        primary: '#1BCEAC',
        background: '#FFFFFF',
        card: '#5898E9',
        text: 'white',
        border: '#979797',
        notification: '#0A84FF',
    },
};

export const colors = {
    backgroundWhiteLight: '#E5E5E5',
    black: '#000000',
    blackOpacity: 'rgba(0,0,0,0.51)',
    blackMinOpacity: 'rgba(0,0,0,0.75)',
    blue: '#4387DE',
    blueGradient: ['#4291F4', '#75B0FA', '#0065A4F3'],
    brightCyan: 'rgba(39, 217, 183, 0.25)',
    cyanLimeGreen: '#63CC93',
    darkCyanLimeGreen: '#4EA475',
    darkGray: '#979797',
    darkGrayishCyan: '#9CA0A0',
    gray: '#DADADA',
    grayWhiteColor: '#F2F2F2',
    grayColor: '#656565',
    grayDarkColor: '#4B4B4B',
    grayDisabled: '#D8D8D8',
    grayishCyan: '#C6CECE',
    grayLigth: '#859899',
    grayLigthDark: '#E2E2E2',
    grayOpacity: '#859899',
    grayText: '#A5F8E4',
    grayVeryLigth: '#C4C4C4',
    green: '#E5EEF9',
    greenBalance: '#D3EAE5',
    greenContainer: '#27D9B7',
    greenGradient: [
        'rgba(81, 228, 200, 0.42)',
        'rgba(53, 211, 181, 0.81)',
        'rgba(39, 208, 176, 1)',
        'rgba(24, 195, 163, 1)',
    ],
    greenLightColor: '#A7EFE1',
    greenLigth: '#656565',
    greenLigthContainer: '#E5F9F5',
    greenSolid: '#22B095',
    greenStartDate: '#A4EFE1',
    lightGray: '#D7D7D7',
    lightGrayishBlue: '#E5EEFA',
    limeGreen: '#76E1A7',
    marron: '#E6FFFA',
    notificationSoftRed: '#F37E7E',
    pureBlue: '#007AFF',
    red: '#e12121',
    shadowColor: '#4D9E9E9E',
    shadowSemiBlack: '#9E9E9E',
    slightlyDesaturatedPink: '#CF698D',
    softOrange: '#E67847',
    softOrangeLigth: '#E67847',
    softRed: '#EA6C6C',
    stepGray: '#ECECEC',
    strongCyan50: 'rgba(27, 206, 172, 0.5)',
    veryDarkGrayishBlue30: 'rgba(60, 60, 67, 0.3)',
    veryGray: '#BEBEBE',
    veryLightGray: '#F4F4F4',
    veryLightGray2: '#E3E3E3',
    veryLightGray3: '#E9E9E9',
    veryLightRed: '#FF9797',
    veryPaleBlue: '#F4F9FF',
    verySoftBlue: '#B1D2FB',
    verySoftOrange: '#FDCC98',
    verySoftPink: '#F0B4CA',
    verySoftRed: '#F8A9A9',
    vividBlue: '#0A84FF',
    white: '#FFFFFF',
    whiteLigth: '#FEFEFE',
};

export const fonts = {
    Roboto: 'Roboto-Regular',
    SFProDisplay: 'SFProDisplay-Regular',
    SFProDisplayMedium: 'SFProDisplay-Medium',
    SFProDisplaySemibold: 'SFProDisplay-Semibold',
    SFProRoundedBold: 'SFProRounded-Bold',
    SFProRoundedMedium: 'SFProRounded-Medium',
    SFProText: 'SFProText-Regular',
    SFProTextBold: 'SFProText-Bold',
    SFProTextMedium: 'SFProText-Medium',
    SFProTextSemibold: 'SFProText-Semibold',
};

export const globalStyle = {
    dropShadow: {
        elevation: 10,
        shadowColor: colors.shadowColor,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowOpacity: 0.30,
        shadowRadius: 3,
    },
};

export const t = createTheme({
    colors: {
        primary: theme.colors.primary,
    },
    // 720p (1280 x 720), 1080p (1920 x 1080), 2k (2048 x 1080), 4k (3840 x 2160).
    screens: {
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536,
    },
    fontSizes: {
        '2xs': 8,
        xs: 12,
        sm: 14,
        base: 16,
        lg: 18,
        xl: 20,
        '2xl': 24,
        '3xl': 30,
        '4xl': 36,
        '5xl': 48,
    },
});
