import {
    StyleSheet,
    Platform
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    amount: {
        color: 'white',
        fontFamily: fonts.SFProDisplay,
        fontSize: 40,
        fontWeight: '700',
        lineHeight: 47,
        textAlign: 'center',
    },
    arrow: {
        alignItems: 'center',
        paddingTop: 10,
    },
    btnFooter: {
        height: 30,
        width: 123,
    },
    buttonStyle: {
        marginBottom: 20,
        width: 250,
    },
    cancelButton: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 16,
        fontWeight: '600',
        textAlign: 'center',
    },
    container: {
        alignItems: 'center',
        padding: 5,
        paddingHorizontal: 15
    },
    containerButtons: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 20,
        width: '77%',
    },
    containerFooter: {
        alignItems: 'center',
    },
    containerInfoFooter: {
        alignItems: 'center',
        backgroundColor: colors.green,
        borderRadius: 4,
        height: 110,
        marginTop: 73,
        width: 342,
    },
    containerInput: {
        justifyContent: 'center',
        marginLeft: 7,
    },
    containerPayment: {
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '100%',
        paddingTop: 10
    },
    containerRadioBtn: {
        marginLeft: 18,
        marginRight: 10,
    },
    footerPayment: {
        alignItems: 'center',
        flex: 2,
        height: 100,
        marginTop: 50,
    },
    footerTitle: {
        color: colors.blue,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 20.29,
        marginTop: 20,
    },
    iconPayment: {
        alignItems: 'center',
        flex: 0.2,
        justifyContent: 'center',
        marginBottom: 7,
        ...Platform.select({
            ios: {
                marginBottom: 8,
            },
        }),
    },
    itemSeparator: {
        backgroundColor: colors.gray,
        height: 1,
        marginTop: 0,
        top: 0,
    },
    text: {
        color: colors.grayText,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 20,
        paddingTop: 10,
        textAlign: 'center',
    },
    textAmount: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
        paddingBottom: 4,
        top: 3,
    },
    textAmountPayment: {
        color: colors.grayColor,
        fontFamily: fonts.Roboto,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
        ...Platform.select({
            android: {
                marginBottom: 3,
            },
        }),
    },
    textDashes: {
        flex: 20,
        minWidth: 10,
    },
    textInformation: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 13,
        fontWeight: '400',
        lineHeight: 15,
        paddingBottom: 4,
        textAlign: 'center'
    },
    textInput: {
        color: colors.grayColor,
        fontFamily: fonts.Roboto,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
        width: '100%',
        height: 30,
        borderWidth: 0,
        padding: 0,
        margin: 0,
        marginBottom: 2,
        ...Platform.select({
            ios: {
                marginBottom: 6,
            },
        }),
    },
    containerInputStyle: {
    },
    textStyle: {
        color: colors.white,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 17,
    },
    textTitle: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
        ...Platform.select({
            android: {
                paddingTop: 3
            },
        }),
    },
    titlePayment: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 20,
        fontWeight: '700',
        lineHeight: 28,
        textAlign: 'center',
        marginBottom: 10
    },
    touchableOpacity: {
        borderColor: theme.colors.primary,
        backgroundColor: colors.white,
        height: 35
    },
    viewAmount: {
        alignItems: 'flex-start',
        flex: 0.7,
    },
    viewCurrentClose: {
        backgroundColor: theme.colors.primary,
        borderRadius: 10,
        height: 113,
        width: 343,
    },
    viewCurrentOpen: {
        backgroundColor: theme.colors.primary,
        borderRadius: 10,
        height: 105,
        width: '100%',
    },
    viewFlatlist: {
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
        padding: 3,
        borderRadius: 10,
    },
    viewInfo: {
        textAlign: 'center'
    },
    viewInfoAmount: {
        backgroundColor: '#1BCEAC',
        flex: 0.3,
        padding: 10,
    },
    viewInformation: {
        borderBottomColor: colors.white,
        borderBottomWidth: 1,
        bottom: 10,
        paddingLeft: 6,
        width: '100%',
    },
    viewIsInput: {
        display: 'flex'
    },
    viewPayment: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        height: 40,
    },
    selectedOptionIcon: {
        height: 10,
        width: 10,
    },
    viewPaymentOptions: {
        flexDirection: 'row',
    },
    viewRow: {
        flexDirection: 'row',
    },
    viewTextTitle: {
        justifyContent: 'center',
        marginLeft: 10,
        width: 190,
        display: 'flex',
    },
    dateContainer: {
        justifyContent: 'flex-start',
        marginTop: 15,
        alignItems: 'center',
        flex: 1,
        width: '100%',
    },
    dateContainerInputStyle: {
        display: 'flex',
        height: 45,
    },
    viewPaymenDateTextInput: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    paymenDateTextInput: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        width: '94%',
        height: '100%',
        ...Platform.select({
            android: {
                marginTop: 10
            },
        }),
    },
    datePickerInput: {
        color: colors.white,
    },
    dateForm: {
        alignItems: 'center',
        width: '100%',
    },
    dateLabel: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 13,
        fontWeight: '600',
        backgroundColor: '#E5F9F5',
        padding: 15,
        width: '100%',
        borderRadius: 10,
        marginTop: 10
    },
    pendingPaymentLabel: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProDisplay,
        fontSize: 13,
        fontWeight: '600',
        backgroundColor: "#EA6C6C",
        padding: 15,
        width: '100%',
        borderRadius: 10,
        marginTop: 10
    },
    inputLarge: {
        marginBottom: 5,
        width: '100%',
    },
    titlePaymentMethod: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 20,
        fontWeight: '700',
        lineHeight: 28,
        textAlign: 'center',
        marginVertical: 5
    },
    containerListPayment: {
        width: '100%',
        display: 'flex'
    },
    contentContainerListPayment: {
        paddingBottom: 10,
    },


    // Payment Method card style
    containerItem: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 5,
        height: 55,
        overflow: 'hidden',
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 13,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: 10,
    },
    outlineContainerItem: {
        borderStartWidth: 2,
        borderEndWidth: 2,
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderColor: theme.colors.primary
    },
    containerNumberLogoPayment: {
        paddingLeft: 10,
        justifyContent: 'center',
        height: '100%',
        width: '20%',
        overflow: 'hidden'
    },
    lastFourDigit: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 18,
        fontWeight: '700',
        lineHeight: 23,
        marginTop: 5,
    },
    expirationDateLabel: {
        color: colors.red,
        fontFamily: fonts.Roboto
    },
    containerNikenameNote: {
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    nickname: {
        marginLeft: 10,
        color: colors.grayOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
    },
    note: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        marginTop: 15,
        textAlign: 'center',
    },
    containerSelectedPaymentMethod: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '17%',
        height: '100%'
    },
    containerFourDigits: {
        width: '30%',
        alignItems: "flex-end"
    },
    paymentMethodItem: {
        height: '100%',
        width: '33%',
    },
    buttonSubmit: {
        width: '100%'
    },
    makeAPaymentContainer: {
        flexDirection: 'row',
        marginBottom: 15
    },
    selectedPaymentCheckMark: {
        marginLeft: 13,
        maxHeight: 20,
        maxWidth: 20
    }
});
