import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        backgroundColor: theme.colors.background,
        flex: 100,
        justifyContent: 'space-between',
    },
    imgContainer: {
        alignItems: 'center',
        marginTop: 20,
        justifyContent: 'flex-end',
    },
    typographyLogo: {
        marginTop: 25,
    },
    containerAvoiding: {
        flex: 1,
        paddingLeft: 60,
        paddingRight: 60,
    },
    containerFooter: {
        justifyContent: 'flex-end',
        marginBottom: 50,
        marginTop: 50,
        ...Platform.select({
            web: {
                paddingTop: '1%',
            },
        }),
    },
    containerForm: {
        justifyContent: 'flex-end',
        marginTop: 40,
    },
    containerGeneral: {
        marginTop: 40,
        justifyContent: 'flex-start',
        ...Platform.select({
            web: {
                height: '40%',
                width: '90%',
            },
        }),
    },
    instructionsContainer: {
        alignItems: 'center',
    },
    instructionsText: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
        width: 255,
        textAlign: 'center',
        ...Platform.select({
            web: {
                width: '90%',
                textAlign: 'center',
            },
        }),
    },
    resetText: {
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        lineHeight: 24.64,
        marginBottom: 10,
        textAlign: 'center',
        color: colors.grayDarkColor,
    },
});
