import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import * as FaqActions from '@Profile/state/faqAction';

type ActionsType = ActionType<typeof FaqActions>;

interface FaqInterface { };

export const initialState = {};

export const faqReducer = createReducer<FaqInterface, ActionsType>(
    initialState,
);
