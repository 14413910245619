import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import {
    GetPaymentMethodAutoPaySuccessAction,
    MethodsOfPaymentState,
} from '@MethodsOfPayment/interfaces';
import * as MethodsOfPaymentActions from '@MethodsOfPayment/state/MethodsOfPaymentActions';

type ActionsTypes = ActionType<typeof MethodsOfPaymentActions>;

const initialState: MethodsOfPaymentState = {
    autopay: undefined,
};

export const methodsOfPaymentReducer = createReducer<MethodsOfPaymentState, ActionsTypes>(
    initialState
).handleAction(MethodsOfPaymentActions.getPaymentMethodsSuccessAction,
    () => initialState,
).handleAction(
    MethodsOfPaymentActions.getPaymentMethodsErrorAction,
    () => initialState
).handleAction(
    MethodsOfPaymentActions.saveNewCardError,
    () => initialState
).handleAction(
    MethodsOfPaymentActions.saveNewAHCError,
    () => initialState
).handleAction(
    MethodsOfPaymentActions.getPaymentMethodAutoPaySuccessAction,
    (state: MethodsOfPaymentState, { payload }: GetPaymentMethodAutoPaySuccessAction) => ({
        ...state,
        autopay: payload?.autopay,
    }),
).handleAction(
    MethodsOfPaymentActions.setPaymentMethodAutoPayAction,
    (state: MethodsOfPaymentState, { payload }: any) => ({
        ...state,
        autopay: payload?.autopay,
    }),
);
