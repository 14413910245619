import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: colors.white,
    },
    containerData: {
        alignItems: 'flex-end',
        flex: 30,
    },
    cardStatement: {
        backgroundColor: colors.white,
        borderRadius: 8,
        elevation: 9,
        flexDirection: 'row',
        height: 490,
        justifyContent: 'space-between',
        minWidth: 364,
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,
        marginStart: 3,
        marginEnd: 3,
        marginBottom: 9,
    },
    cardStatementClose: {
        backgroundColor: colors.white,
        borderRadius: 10,
        elevation: 9,
        flexDirection: 'row',
        height: 170,
        justifyContent: 'space-between',
        minHeight: 215,
        minWidth: 364,
        shadowColor: colors.shadowSemiBlack,
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,
        marginStart: 3,
        marginEnd: 3,
        marginBottom: 9,
    },
    dataContainer: {
        alignItems: 'flex-start',
        paddingVertical: 15,
        flexDirection: 'row',
        borderRadius: 10,
    },
    dataContainerToRight: {
        alignItems: 'flex-end',
    },
    firstFlatList: {
        flex: 1,
        minWidth: 364,
        width: 400,
    },
    headerContainer: {
        flex: 100,
        height: 54,
        justifyContent: 'center',
    },
    headerDataContainer: {
        height: 53,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 32,
    },
    headerDivider: {
        height: 1,
        backgroundColor: colors.lightGray,
    },
    headerTitle: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 19,
        textAlign: 'left',
    },
    headerTitleContainer: {
        flex: 33,
    },
    list: {
        flex: 1,
        marginHorizontal: 17,
    },
    lineSeparator: {
        alignItems: 'center',
        backgroundColor: colors.lightGray,
        height: 1,
        width: '100%',
    },
    paymentAmount: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 17,
        lineHeight: 20,
    },
    paymentDate: {
        fontFamily: fonts.SFProText,
        color: colors.grayColor,
        fontSize: 13,
        fontWeight: '400',
        lineHeight: 16,
        alignItems: 'flex-end',
        flex: 1,
        paddingEnd: 15,
    },
    paymentTitle: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 13,
        lineHeight: 16,
        paddingStart: 15,
        fontWeight: '400',
    },
    rowContainer: {
        flex: 100,
        height: 44,
        paddingHorizontal: 32,
        paddingTop: 10,
    },
    rowContainerEven: {
        backgroundColor: colors.veryPaleBlue,
    },
    secondFlatList: {
        flex: 1,
        flexWrap: 'wrap',
        minWidth: 364,
        width: 400,
    },
    emptyLisPastAccount: {
        shadowColor: colors.shadowSemiBlack,
        marginHorizontal: 12,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,
        height: 450,
        borderRadius: 10,
    },
    textCurrentAccount: {
        fontFamily: fonts.SFProText,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 21,
        marginBottom: 8,
        marginTop: 24,
        textAlign: 'left',
        color: colors.grayColor,
        marginLeft: 8,
    },
    title: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 16,
        lineHeight: 19,
        fontWeight: '500',
    },
    titlePastApplication: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '600',
        lineHeight: 28,
        marginTop: 20,
        textAlign: 'center',
    },
});
