import React, { FC } from 'react';

import {
    StyleProp,
    Text,
    TouchableOpacity,
    ViewProps,
    ViewStyle,
} from 'react-native';
import { SvgProps } from 'react-native-svg';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

interface Props extends ViewProps {
    title?: string;
    handleOnChange: () => void;
    buttonStyle?: StyleProp<ViewStyle>;
    textStyle?: any;
    disabled?: boolean;
    Icon: FC<SvgProps>;
};

export const AppButtonIcon = ({
    accessibilityLabel = 'AppButtonIcon',
    buttonStyle,
    handleOnChange,
    testID = 'AppButtonIcon',
    textStyle,
    title,
    disabled,
    Icon,
}: Props) => (
    <TouchableOpacity
        style={[styles.button, buttonStyle]}
        onPress={handleOnChange}
        activeOpacity={0.9}
        accessibilityLabel={accessibilityLabel}
        testID={testID}
        disabled={disabled}
    >
        <Icon />
        <Text style={[styles.text, textStyle]} >{title}</Text>
    </TouchableOpacity>
);

const styles = AppStyleSheet({
    button: {
        alignItems: 'center',
        backgroundColor: theme.colors.primary,
        borderRadius: 5,
        height: 47,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    text: {
        color: colors.white,
        fontSize: 17,
        fontWeight: '600',
        fontFamily: fonts.SFProText,
        marginLeft: 5.81,
    },
});
