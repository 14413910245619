import { Subject } from 'rxjs';

import { PaymentActivity } from '@Activity/interfaces';

const PaymentActivityService = () => {
    const subject = new Subject();

    const onSuccess = (response: PaymentActivity) => {
        subject.next(response);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onSuccess,
        subscribe,
    };
};

export default PaymentActivityService();
