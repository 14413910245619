import { useEffect } from 'react';

import { useFormik  as Formik } from 'formik';

export const useFormik = (onSubmit: any, initialState: any, yupSchema: any) => {
    const form = Formik({
        initialValues: initialState,
        onSubmit,
        validationSchema: yupSchema,
        validateOnChange: true,
        enableReinitialize: true,
        initialErrors: {},
    });
    useEffect(() => {
        form.validateForm();
    }, []);
    return form;
};
