import * as React from 'react';
import { View } from 'react-native';
import { AppModal } from '@Components/AppModal';
import { AppCalendar, PressedDay } from '@Components/AppCalendar';

interface Props {
    show: boolean;
    handleAccept: () => void;
    daySelected: string;
    disabled?: boolean;
    limitDayHour?: number;
    limitDayMinutes?: number;
    maxDate?: Date;
    minDate?: Date;
    onPressDay: (pressed: PressedDay) => void;
    selectToday?: boolean;
};

export const CalendarModal = ({
    show,
    handleAccept,
    daySelected,
    disabled,
    limitDayHour = 0,
    limitDayMinutes = 0,
    maxDate,
    minDate = new Date(),
    onPressDay = () => { },
    selectToday,
}: Props) => {
    const renderCalendar = () => {
        return (
            <View style={{ width: '95%' }}>
                <AppCalendar
                    daySelected={daySelected}
                    limitDayHour={limitDayHour}
                    limitDayMinutes={limitDayMinutes}
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={disabled}
                    onPressDay={onPressDay}
                    selectToday={selectToday} />
            </View>
        );
    };

    return (
        <AppModal
            animationType='fade'
            closeModal={handleAccept}
            transparent
            visible={show}
        >
            {renderCalendar()}
        </AppModal>
    );
}