import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
    persistReducer,
    persistStore,
} from 'redux-persist';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    rootEpic,
    rootReducer,
} from '@Store/modules/root';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
    }
};

const epicMiddleware = createEpicMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(epicMiddleware)),
    );
    epicMiddleware.run(rootEpic);
    return store;
};

const store = configureStore();
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { persistor, store };
