import {
    EntityResponse,
    GetPaymentActivitiesRequest,
    GetPaymentActivitiesResponse,
    GetPaymentActivityRequest,
    GetPendingPaymentsRequest,
    GetStatementRequest,
    PaymentPendingCancelRequest,
    PaymentPendingRequest,
    PaymentPendingResponse,
    PaymentStatementResponse,
} from '@Activity/interfaces';
import AppApi from '@Api/appApi';
import { Commands } from '@Api/config';

export const getPaymentActivitiesAPI = ({
    loanId,
    page,
    pageSize,
}: GetPaymentActivitiesRequest): Promise<GetPaymentActivitiesResponse> => new Promise(async (resolve, reject) => {
    try {
        let command = Commands.GET_PAYMENT_ACTIVITIES.replace('{loanId}', `${loanId}`);
        command = command.replace('{page}', `${page}`);
        command = command.replace('{pageSize}', `${pageSize}`);
        const response = await AppApi.get<GetPaymentActivitiesResponse>(command);
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const getPaymentStatementsAPI = ({ applicationId }: GetStatementRequest): Promise<PaymentStatementResponse[]> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_DOCUMENTS.replace('{applicationId}', `${applicationId}`);
        const response = await AppApi.get<PaymentStatementResponse[]>(command);
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const getPendingPaymentsAPI = ({ loanId }: GetPendingPaymentsRequest): Promise<PaymentPendingResponse[]> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_PENDING_PAYMENTS.replace('{loanId}', `${loanId}`);
        const response = await AppApi.get<PaymentPendingResponse[]>(command);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const getPaymentActivityAPI = ({ id }: GetPaymentActivityRequest): Promise<EntityResponse> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_PAYMENT_ACTIVITY.replace('{id}', `${id}`);
        const response = await AppApi.get<EntityResponse>(command);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const updatePaymentPendingAPI = (paymentPending: PaymentPendingRequest) => new Promise(async (resolve, reject) => {
    try {
        const response = await AppApi.put(Commands.PUT_PAYMENT_PENDING, {
            ...paymentPending,
        });
        resolve(response?.status);
    } catch (error) {
        reject(error);
    }
});

export const deletePaymentPendingAPI = (paymentPending: PaymentPendingCancelRequest) => new Promise(async (resolve, reject) => {
    try {
        let command = Commands.DELETE_PAYMENT_PENDING.replace('{id}', `${paymentPending.id}`);
        command = command.replace('{loanId}', `${paymentPending.loanId}`);
        const response = await AppApi.delete(command);
        resolve(response.status);
    } catch (error) {
        reject(error);
    }
});

export const getDocumentStatementsAPI = ({ id }: any): Promise<any> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_DOCUMENT_STATEMENT.replace('{id}', `${id}`);
        const response = await AppApi.get<any>(command, { responseType: 'blob' });
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});
