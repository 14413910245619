import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    appText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '500',
        textAlign: 'center',
    },
    appTextContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    contentModel: {
        color: colors.grayDarkColor,
        marginTop: '50%',
        ...Platform.select({
            android: {
                marginTop: '65%',
            },
        }),
    },
    flatList: {
        backgroundColor: 'white',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        paddingTop: 80,
    },
    headerBar: {
        backgroundColor: theme.colors.primary,
        borderRadius: 13,
        height: 5,
        marginTop: 10,
        width: 114,
    },
    headerContentStyle: {
        alignItems: 'center',
        flex: 100,
        justifyContent: 'center',
        height: 80,
    },
    headerStyle: {
        justifyContent: 'center',
        marginTop: '50%',
        ...Platform.select({
            android: {
                marginTop: '65%',
            },
        }),
        backgroundColor: 'white',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    itemContainer: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
    name: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        textAlign: 'left',
    },
    title: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    viewIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 12,
    },
    viewText: {
        paddingHorizontal: 30,
    },
});
