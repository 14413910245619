import { parse } from 'date-fns';
import * as Yup from 'yup';

import Languages from '@i18n/index';
import { obtainDifferenceInDays } from '@Utils/FormatUtils';

const isAValidDOB = (value: any) => {
    const formDate = parse(value, 'MM/dd/yyyy', new Date());
    const difference = obtainDifferenceInDays(new Date(), formDate);
    const years = (difference / 365);
    if (years >= 18) {
        return true;
    } else {
        return false;
    }
};

export const ProfileInfoSchema = Yup.object().shape({
    firstName: Yup.
        string()
        .required(Languages.emptyField),
    lastName: Yup.
        string()
        .required(Languages.emptyField),
    email: Yup.
        string()
        .email(Languages.emailInValid)
        .required(Languages.emptyField),
    address: Yup.
        string()
        .required(Languages.emptyField),
    aptNumber: Yup.
        string(),
    zipCode: Yup.
        string()
        .required(Languages.emptyField),
    city: Yup.
        string()
        .required(Languages.emptyField),
    state: Yup.
        string()
        .required(Languages.emptyField),
    dob: Yup.
        string()
        .required(Languages.emptyField)
        .matches(/^((0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2})*$/, 'Enter valid DOB')
        .test('Enter valid DOB', 'Enter valid DOB', (value) => {
            if (isAValidDOB(value)) {
                return true;
            } else {
                return false;
            }
        }),
    last4ssn: Yup.
        string()
        .required(Languages.emptyField)
        .min(4, 'Minimun 4 characters'),
    phoneNumber: Yup.
        string()
        .required(Languages.emptyField)
        .matches(/^\(\d{3}\)\s\d{3}-\d{4}/, 'Enter valid phone number'),
    secondaryPhoneNumber: Yup.
        string()
        .matches(/^\(\d{3}\)\s\d{3}-\d{4}/, 'Enter valid phone number'),
});

export const LocateAccountSchema = Yup.object().shape({
    dob: Yup.
        string()
        .required(Languages.emptyField)
        .matches(/^((0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2})*$/, 'Enter valid DOB')
        .test('Enter valid DOB', 'Enter valid DOB', (value) => {
            if (isAValidDOB(value)) {
                return true;
            } else {
                return false;
            }
        }),
    last4ssn: Yup.
        string()
        .required(Languages.emptyField)
        .min(11, 'Minimun 9 characters'),
});
