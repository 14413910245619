import { Platform } from 'react-native';
import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import ActivityActionTypes from '@Activity/state/activityActionTypes';
import AuthenticateActionsTypes from '@Auth/state/authenticateActionTypes';
import ForgotPasswordActionsTypes from '@Auth/state/forgotPasswordActionsTypes';
import RegisterActionTypes from '@Auth/state/registerActionTypes';
import BalanceActionTypes from '@Home/state/balanceActionTypes';
import LoadingService from '@LoadingDialog/service/LoadingService';
import LoadingDialogActionTypes from '@LoadingDialog/state/loadingDialogActionTypes';
import MakeAPaymentActionsTypes from '@MakeAPayment/state/MakeAPaymentActionsTypes';
import MethodsOfPaymentActionsTypes from '@MethodsOfPayment/state/MethodsOfPaymentActionsTypes';
import ApplicationsActionTypes from '@Profile/state/applicationsActionTypes';
import FaqActionsTypes from '@Profile/state/faqActionTypes';
import ProfileActionsTypes from '@Profile/state/profileActionTypes';
import StatementActions from '@Statements/state/statementActionTypes';

const actionTypeMobile = ofType<any>(
    ActivityActionTypes.CANCEL_PENDING_REQUEST,
    ActivityActionTypes.GET_DOCUMENT_STATEMENT,
    ActivityActionTypes.GET_PAYMENT_ACTIVITIES,
    ActivityActionTypes.GET_PAYMENT_ACTIVITY,
    ActivityActionTypes.GET_PAYMENT_STATEMENTS,
    ActivityActionTypes.UPDATE_PENDING_REQUEST,
    ApplicationsActionTypes.GET_DOCUMENT,
    AuthenticateActionsTypes.AUTHENTICATE_REFRESH_TOKEN,
    AuthenticateActionsTypes.AUTHENTICATE_REQUEST,
    BalanceActionTypes.GET_BALANCE_REQUEST_ACTION,
    FaqActionsTypes.GET_FAQ_REQUEST,
    ForgotPasswordActionsTypes.SEND_CODE_EMAIL_REQUEST,
    ForgotPasswordActionsTypes.SEND_CODE_PHONE_REQUEST,
    ForgotPasswordActionsTypes.UPDATE_PASSWORD_EMAIL_REQUEST,
    ForgotPasswordActionsTypes.UPDATE_PASSWORD_PHONE_REQUEST,
    MakeAPaymentActionsTypes.GET_CURRENT_PAYMENT_REQUEST,
    MakeAPaymentActionsTypes.GET_CURRENT_PAYMENT_REQUEST,
    MakeAPaymentActionsTypes.SAVE_NEW_PAYMENTH_SCHEDULE_REQUEST,
    MethodsOfPaymentActionsTypes.DELETE_CARD_PAYMENT_REQUEST,
    MethodsOfPaymentActionsTypes.DELETE_CHECK_PAYMENT_REQUEST,
    MethodsOfPaymentActionsTypes.GET_AUTOPAY,
    MethodsOfPaymentActionsTypes.GET_PAYMENT_METHOD_REQUEST,
    MethodsOfPaymentActionsTypes.GET_PLAID_LINK_TOKEN,
    MethodsOfPaymentActionsTypes.PUT_AUTOPAY,
    MethodsOfPaymentActionsTypes.PUT_PAYMENT_METHOD_ACH_REQUEST,
    MethodsOfPaymentActionsTypes.PUT_PAYMENTH_METHOD_CARD_REQUEST,
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST,
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST,
    ProfileActionsTypes.PROFILE_CLAIM_REQUEST,
    ProfileActionsTypes.PROFILE_USER_UPDATE_PHONE_REQUEST,
    ProfileActionsTypes.PROFILE_USER_UPDATE_REQUEST,
    RegisterActionTypes.CONFIRM_ACCOUNT_REQUEST,
    RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_REQUEST,
    RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE_REQUEST,
    RegisterActionTypes.REGISTER_EMAIL_REQUEST,
    RegisterActionTypes.REGISTER_REQUEST,
    RegisterActionTypes.REGISTER_VERIFICATION_CODE_REQUEST,
    RegisterActionTypes.REGISTER_VERIFICATION_CODE_RESEND_REQUEST,
    RegisterActionTypes.VALIDATE_VERIFICATION_CODE_REQUEST,
    StatementActions.GET_DOCUMENT_NEW_STATEMENT,
    StatementActions.GET_NEW_STATEMENTS
);

const actionTypeWeb = ofType<any>(
    ActivityActionTypes.CANCEL_PENDING_REQUEST,
    ActivityActionTypes.UPDATE_PENDING_REQUEST,
    ApplicationsActionTypes.GET_DOCUMENT_ARRAY_BUFFER,
    AuthenticateActionsTypes.AUTHENTICATE_INTERNAL_REQUEST,
    AuthenticateActionsTypes.AUTHENTICATE_REFRESH_TOKEN,
    AuthenticateActionsTypes.AUTHENTICATE_REQUEST,
    FaqActionsTypes.GET_FAQ_REQUEST,
    ForgotPasswordActionsTypes.SEND_CODE_EMAIL_REQUEST,
    ForgotPasswordActionsTypes.SEND_CODE_PHONE_REQUEST,
    ForgotPasswordActionsTypes.UPDATE_PASSWORD_EMAIL_REQUEST,
    ForgotPasswordActionsTypes.UPDATE_PASSWORD_PHONE_REQUEST,
    MethodsOfPaymentActionsTypes.DELETE_CARD_PAYMENT_REQUEST,
    MethodsOfPaymentActionsTypes.DELETE_CHECK_PAYMENT_REQUEST,
    MethodsOfPaymentActionsTypes.GET_PLAID_LINK_TOKEN,
    MethodsOfPaymentActionsTypes.PUT_AUTOPAY,
    MethodsOfPaymentActionsTypes.PUT_PAYMENT_METHOD_ACH_REQUEST,
    MethodsOfPaymentActionsTypes.PUT_PAYMENTH_METHOD_CARD_REQUEST,
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST,
    MethodsOfPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST,
    ProfileActionsTypes.PROFILE_CLAIM_REQUEST,
    ProfileActionsTypes.PROFILE_USER_UPDATE_PHONE_REQUEST,
    ProfileActionsTypes.PROFILE_USER_UPDATE_REQUEST,
    RegisterActionTypes.CONFIRM_ACCOUNT_REQUEST,
    RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_REQUEST,
    RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE_REQUEST,
    RegisterActionTypes.REGISTER_EMAIL_REQUEST,
    RegisterActionTypes.REGISTER_REQUEST,
    RegisterActionTypes.REGISTER_VERIFICATION_CODE_REQUEST,
    RegisterActionTypes.REGISTER_VERIFICATION_CODE_RESEND_REQUEST,
    RegisterActionTypes.VALIDATE_VERIFICATION_CODE_REQUEST,
);
const startLoading = (action$: any) => action$.pipe(
    Platform.OS === 'web' ? actionTypeWeb : actionTypeMobile,
    mergeMap(() => {
        LoadingService.show();
        return EMPTY;
    }),
);
const endLoading = (action$: any) => action$.pipe(
    ofType<any>(
        LoadingDialogActionTypes.LOADING_DIALOG_HIDE,
    ),
    mergeMap(() => {
        LoadingService.hide();
        return EMPTY;
    }),
);
export { endLoading, startLoading };
