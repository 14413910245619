import React from 'react';

import { Text } from 'react-native';

import Languages from '@i18n/index';
import { styles } from '@MethodsOfPayment/styles/SelecteMethodOfPaymentStyle';

export const Header = () => (
    <Text style={styles.title} >{Languages.SelectMethodOfPayment}</Text>
);
