import React, { useEffect } from 'react';

import { View } from 'react-native';

import { AppModal } from '@Components/AppModal';

import { PaymentMethodResponseBase } from '../interfaces/index';
import { AddPaymentMethodScreen } from './AddPaymentMethodScreen.web';

interface Props {
    isVisible: boolean;
    handleCancel?: () => void;
    handleSuccess?: (item: any) => any;
    paymentMethod?: PaymentMethodResponseBase;
};

export const AddPaymentMethodDialog = ({
    isVisible,
    handleCancel = () => { },
    handleSuccess = (item?: any) => item,
    paymentMethod,
}: Props) => {
    const [smQuery, setSmQuery] = React.useState<any>({
        matches: window.innerWidth < 768 ? true : false,
    });
    const [mdQuery, setMdQuery] = React.useState<any>({
        matches: (window.innerWidth > 767 && window.innerWidth < 968) ? true : false,
    });
    useEffect(() => {
        let mediaQuery = window.matchMedia('(max-width: 767px)');
        mediaQuery.addEventListener('query', setSmQuery);

        return () => mediaQuery.removeEventListener('query', setSmQuery);
    }, []);
    
    useEffect(() => {
        let mediaQueryMd = window.matchMedia('(max-width: 968px)');
        mediaQueryMd.addEventListener('query', setMdQuery);

        return () => mediaQueryMd.removeEventListener('query', setMdQuery);
    }, []);

    const getModalWidth = () => {
        if (!!smQuery && smQuery.matches) return '90%';
        if (!!mdQuery && mdQuery.matches) return '70%';
        return '50%';
    }   
    return (
        <AppModal
            animationType='fade'
            transparent
            visible={isVisible}
        >
            <View style={{
                borderRadius: 10,
                height: 'auto',
                overflow: 'hidden',
                // minWidth: 512,
                width: getModalWidth(),
            }}>
                <AddPaymentMethodScreen isVisible={isVisible} handleCancel={handleCancel} handleSuccess={handleSuccess} paymentMethod={paymentMethod} />
            </View>
        </AppModal>
    );
}
