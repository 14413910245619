import React from 'react';

import { View } from 'react-native';

import { HomeActivityScreen } from '@Activity/screens/HomeActivityScreen.web';
import { InternalLoginScreen } from '@Auth/screens/InternalLoginScreen';
import { AutoPayScreen } from '@AutoPay/screens/AutoPayScreen';
import { Footerbar } from '@Components/Footerbar.web';
import { LeftMenu } from '@Components/LeftMenu';
import { Toolbar } from '@Components/Toolbar.web';
import { FinancialFundamentalsScreen } from '@Home/screens/FinancialFundamentalsScreen';
import { HomeScreen } from '@Home/screens/HomeScreen';
import { ProgramRebateScreen } from '@Home/screens/ProgramRebateScreen';
import { navigateLeftMenu } from '@Home/state/menuActions';
import { useAppDispatch } from '@Hooks/appHooks';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import Languages from '@i18n/index';
import { DateMakeAPaymentScreen } from '@MakeAPayment/screens/DateMakeAPaymentScreen';
import { MakeAPaymentScreen } from '@MakeAPayment/screens/MakeAPaymentScreen';
import { AddPaymentMethodScreen } from '@MethodsOfPayment/screens/AddPaymentMethodScreen';
import { MethodsOfPaymentsScreen } from '@MethodsOfPayment/screens/MethodsOfPaymentsScreen';
import {
    SelectMethodOfPaymentScreen,
} from '@MethodsOfPayment/screens/SelectMethodOfPaymentsScreen';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import { ProfileTopTabsStack } from '@Navigation/ProfileTopTabsStack';
import { NotificationScreen } from '@Notifications/screens/NotificationScreen';
import { AppNavigationOnboarding } from '@OnBoarding/navigation';
import { PaymentsScreen } from '@Payments/screens/PaymentsScreen.web';
import { ApplicationsScreen } from '@Profile/screens/ApplicationsScreen';
import { FaqScreen } from '@Profile/screens/FaqScreen';
import { ProfileInfoNotFoundedScreen } from '@Profile/screens/ProfileInfoNotFoundedScreen.web';
import { ProfileInfoScreen } from '@Profile/screens/ProfileInfoScreen';
import { ProfileScreen } from '@Profile/screens/ProfileScreen';
import { createStackNavigator } from '@react-navigation/stack';

import { navigate } from './RootNavigation';
import { ActivityWebScreen } from '@ActivityWeb/screens/ActivityScreen.web';
import { ActivityWebAllScreen } from '@ActivityWeb/screens/ActivityAllScreen.web';
import { ActivityStatementsWebScreen } from '@ActivityWeb/screens/ActivityStatements.web';

const Stack = createStackNavigator<RootStackParamList>();

const WebAuthorizedNavigationStack = () => {
    const dispatch = useAppDispatch();

    const handleFocus = (routeName: string) => {
        navigate(routeName, {});
        dispatch(navigateLeftMenu(routeName));
    };

    return (
        <Stack.Navigator
            initialRouteName={'Home'}
            screenOptions={() => ({
                headerShown: false,
            })}
        >
            <Stack.Screen
                component={HomeScreen}
                name='Home'
                options={{ title: Languages.Home }}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='ProgramRebateScreen'
                component={ProgramRebateScreen} />

            <Stack.Screen
                name='FinancialFundamentalsScreen'
                component={FinancialFundamentalsScreen} />

            <Stack.Screen
                name='NotificationScreen'
                component={NotificationScreen}
            />
            <Stack.Screen
                component={PaymentsScreen}
                name='PaymentsScreen'
                options={{ title: Languages.Billing }}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='MethodsOfPaymentsScreen'
                component={MethodsOfPaymentsScreen}
            />
            <Stack.Screen
                name='SelectMethodOfPaymentScreen'
                component={SelectMethodOfPaymentScreen}
            />
            <Stack.Screen
                name='MakeAPaymentScreen'
                component={MakeAPaymentScreen}
            />
            <Stack.Screen
                name='AddPaymentMethodScreen'
                component={AddPaymentMethodScreen}
            />
            <Stack.Screen
                name='AutoPayScreen'
                component={AutoPayScreen}
            />
            <Stack.Screen
                name='DateMakeAPaymentScreen'
                component={DateMakeAPaymentScreen}
            />
            <Stack.Screen
                name='ProfileScreen'
                component={ProfileScreen}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='ProfileInfoScreen'
                component={ProfileInfoScreen}
            />
            <Stack.Screen
                name='ProfileTopTabsStack'
                component={ProfileTopTabsStack}
            />
            <Stack.Screen
                name='ApplicationsScreen'
                component={ApplicationsScreen}
            />
            <Stack.Screen
                name='FaqScreen'
                component={FaqScreen}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='Activity'
                component={ActivityWebScreen}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='StatementsScreen'
                component={ActivityStatementsWebScreen}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='ActivityAll'
                component={ActivityWebAllScreen}
                listeners={({ route }) => ({
                    focus: () => handleFocus(route.name),
                })}
            />
            <Stack.Screen
                name='ProfileInfoNotFoundedScreen'
                component={ProfileInfoNotFoundedScreen}
            />
            <Stack.Screen
                name='InternalLogin'
                component={InternalLoginScreen} options={{
                    headerShown: false,
                    title: Languages.InternalLogin,
                }}
            />
        </Stack.Navigator>
    );
};

export const WebNavigator = () => {
    const isMobile = useCheckMobileScreen();

    return (
        <>
            <View style={{ flex: 1 }}>
                <View style={{ height: 66 }}>
                    <Toolbar />
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    {
                        !isMobile && (
                            <View>
                                <LeftMenu />
                            </View>
                        )
                    }
                    <WebAuthorizedNavigationStack />
                </View>
                {
                    isMobile && (
                        <View>
                            <LeftMenu />
                        </View>
                    )
                }
                <View >
                    <Footerbar />
                </View>
            </View>
            <AppNavigationOnboarding />
        </>
    );
};
