import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { ForgotPassswordState } from '@Auth/interfaces';
import * as forgotPasswordActions from '@Auth/state/forgotPasswordActions';

type ActionsType = ActionType<typeof forgotPasswordActions>;

export const initialState: ForgotPassswordState = {
    isSendCodePhone: false,
    isSendCodeEmail: false,
    error: '',
};

export const forgotPasswordReducer = createReducer<ForgotPassswordState, ActionsType>(
    initialState,
).handleAction(forgotPasswordActions.sendCodePhoneNumber, () => ({
    ...initialState,
})).handleAction(forgotPasswordActions.sendCodeEmail, () => ({
    ...initialState,
})).handleAction(forgotPasswordActions.clearForgotPassword, () => ({
    ...initialState,
})).handleAction(forgotPasswordActions.updatePasswordPhone, () => ({
    ...initialState,
})).handleAction(forgotPasswordActions.updatePasswordEmail, () => ({
    ...initialState,
}));
