import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import {
    MakeAPaymentState,
    ShouldShowPaymentWarningAction,
} from '@MakeAPayment/interfaces';
import * as MakeAPaymentActions from '@MakeAPayment/state/MakeAPaymentActions';

type ActionsTypes = ActionType<typeof MakeAPaymentActions>;

const initialState: MakeAPaymentState = {
    showWarning: false,
    isLeftMenuReset: false,
};

export const makeAPaymentReducer = createReducer<MakeAPaymentState, ActionsTypes>(
    initialState
).handleAction(
    MakeAPaymentActions.getCurrentPaymentSuccessAction,
    () => initialState
).handleAction(
    MakeAPaymentActions.getCurrentPaymentErrorAction,
    () => initialState
).handleAction(
    MakeAPaymentActions.shouldShowPaymentWarningAction,
    (state: MakeAPaymentState, { payload }: ShouldShowPaymentWarningAction) => ({
        ...state,
        showWarning: payload?.show,
    })
).handleAction(
    MakeAPaymentActions.resetLeftMenu,
    (state: MakeAPaymentState, { payload }) => ({
        ...state,
        isLeftMenuReset: payload,
    }),
).handleAction(
    MakeAPaymentActions.clearPaymentStatusAction,
    (state: MakeAPaymentState) => ({
        ...state,
        showWarning: false,
    }),
);
