import React from 'react';

import {
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { EmptyIcon } from '@Assets/index';
import Languages from '@i18n/index';
import {
    colors,
    fonts,
} from '@Theme/appTheme';

interface Props {
    accessibilityLabel?: string;
    Icon?: any;
    subTitle?: string | null;
    testID?: string;
    title: string;
    content?: string;
};

export const AppEmptyState = ({
    accessibilityLabel = 'AppEmptyState',
    Icon = null,
    subTitle = null,
    testID = 'AppEmptyState',
    title,
    content = Languages.NoInformationAvailable,
}: Props) => {
    const textSubTitle = subTitle ? subTitle : Languages.NoPrevious;
    return (
        <View
            accessibilityLabel={accessibilityLabel}
            style={styles.container}
            testID={testID}
        >
            {Icon ? <Icon /> : <EmptyIcon />}
            <Text style={styles.title}>{`${textSubTitle} ${title}`}</Text>
            <Text style={styles.description}>{content}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 550,
    },
    title: {
        color: colors.greenLigth,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 20,
        marginTop: 48,
    },
    description: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 17,
    },
});
