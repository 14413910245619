import {
    Linking,
    Platform,
    StyleSheet,
} from 'react-native';

import { toPST } from '@Utils/FormatUtils';

import AppJSON from '../../app.json';

interface AppData {
    name: string;
    displayName: string;
    version: string;
    store: string;
}

export const has = Object.prototype.hasOwnProperty;

export const executeIfFunction = (f: Function) => (f instanceof Function ? f() : f);

export const switchcaseFunction = (cases: any) => (defaultCase: any) => (key: any) => executeIfFunction(switchcase(cases)(defaultCase)(key));

export const switchcase = (cases: any) => (defaultCase: any) => (key: any) => (has.call(cases, key) ? cases[key] : defaultCase);

export const formatAmount = (amount: number | string) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatAmountPrice = (amount: number | string) => {
    const formated = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (formated.indexOf('-') === 0) {
        return `${formated.substring(0, 1)}$${formated.substring(1, formated.length + 1)}`;
    }
    return `$${formated}`;
};

export const AppStyleSheet = (styles: any) => Platform.OS === 'web' ? styles : StyleSheet.create(styles);

export const makeACall = (phoneNumber: string) => new Promise(async (resolve, reject) => {
    const url = `tel:${phoneNumber}`;
    const isSupported = await Linking.canOpenURL(url);
    if (isSupported) {
        try {
            openUrl(url);
            resolve(true);
        } catch (error) {
            reject(false);
        }
    } else {
        reject(false);
    }
});

export const sendAnEmail = (to: string, subject?: string) => new Promise(async (resolve, reject) => {
    let url = `mailto:${to}`;
    if (subject) {
        url = `${url}?subject=${subject}`;
    }
    const isSupported = await Linking.canOpenURL(url);
    if (isSupported) {
        try {
            openUrl(url);
            resolve(true);
        } catch (error) {
            reject(false);
        }
    } else {
        reject(false);
    }
});

export const linkingPrefixes = [
    'https://*.easypayfinance.com',
    'https://easypayfinance.com',
    'myeasypay',
    'myeasypayapp',
    'app',
    '*easypayfinance.com',
    '*.easypayfinance.com',
    'http://localhost:3000',
    'localhost:3000',
];

export const openUrl = (url: string, target?: string) => {
    if (Platform.OS === 'web') {
        const windowUrl = window.open(url, target || '_self');
        windowUrl?.close();
    } else {
        Linking.openURL(url);
    }
};

export const isWebIOS = (): boolean => {
    const userAgent = window.navigator.userAgent;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    const result = iosPlatforms.filter((platform: string) => {
        if (userAgent.includes(platform)) {
            return true;
        }
        return false;
    });
    return result.length > 0;
};

// @ts-ignore
export const getAppData = (): AppData => Platform.OS === 'web' ? process.env.APP_JSON : AppJSON;

export const linksApp = {
    financialFundamentalsProgram: 'https://easypay-finance.everfi-next.net/welcome/financial-fundamentals',
    privacyPolicy: 'https://www.easypayfinance.com/privacy-policy/',
    termsAndConditionsFCCP: 'https://www.easypayfinance.com/fccp/',
    termsOfUse: 'https://my.easypayfinance.com/terms',
};

export const isTimeToMakeAPayment = (hour: number, minutes: number): boolean => {
    const currentPstTime = toPST(new Date());
    const pivotDate = new Date();

    pivotDate.setDate(pivotDate.getDate());
    pivotDate.setHours(hour, minutes, 0, 0);

    if (currentPstTime > pivotDate) {
        return false;
    }
    return true;
};

export const isTimeToMakeAPaymentByDate = (hour: number, minutes: number, date: Date): boolean => {
    const currentPstTime = toPST(new Date());
    const pivotDate = date;

    pivotDate.setHours(hour, minutes, 0, 0);

    if (pivotDate > currentPstTime) {
        return true;
    }
    return false;
};
