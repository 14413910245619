import axios, { AxiosInstance } from 'axios';

import {
    addAppBaseURLRequestInterceptor,
    addAuthorizationRequestInterceptor,
    addAuthorizationResponseInterceptor,
} from './index';

const buildAppAxiosInstance = (): AxiosInstance => {
    const instance = axios.create({
        baseURL: '',
        cancelToken: undefined,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    addAppBaseURLRequestInterceptor(instance);
    addAuthorizationRequestInterceptor(instance);
    addAuthorizationResponseInterceptor(instance);
    return instance;
};

const AppApi = buildAppAxiosInstance();

export default AppApi;
