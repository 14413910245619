import appAPI from '@Api/appApi';
import { Commands } from '@Api/config';
import { Response } from '@Interfaces/BaseResponse';
import {
    GetPaymentMethodAutoPayRequest,
    GetPlaidTokenResponse,
    PatchPaymentMethodsSortRequest,
    PatchPaymentSetPrimaryRequest,
    PaymentMethodAutoPay,
    PaymentsMethodResponse,
    PutACHRequest,
    PutCardRequest,
    PutPaymentMethodAutoPayRequest,
    SaveAHCRequest,
    SaveCardRequest,
} from '@MethodsOfPayment/interfaces';
import { CheckResponse } from '@MethodsOfPayment/interfaces/index';

export const getPaymentMethodsAPI = async (loanId: number, includeDeactivatedMoP : boolean = false): Promise<PaymentsMethodResponse[]> => new Promise(async (resolve, reject) => {
    const command = Commands.GET_PAYMENT_METHODS.replace('{loanId}', `${loanId}`).concat(includeDeactivatedMoP ? '?includeDeactivatedMoP=true' : '');
    try {
        const response = await appAPI.get<any>(command);
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const savePaymethMethodCardAPI = async (card: SaveCardRequest) => new Promise(async (resolve, reject) => {
    try {
        const response: any = await appAPI.post(Commands.REGISTER_NEW_PAYMENTH_METHOD_CARD, {
            loanId: card.loanId,
            type: card.type,
            nickname: card.nickname,
            cardNumber: card.numberCard,
            expirationDate: card.expirationDate,
            zipCode: '91911',
            note: 'Note',
        });

        resolve({
            ...response.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const putPaymentMethodCardAPI = (request: any): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response: any = await appAPI.put(Commands.PUT_CARD, request.payload);
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const savePaymentMethodACHAPI = async (ahc: SaveAHCRequest): Promise<CheckResponse> => new Promise(async (resolve, reject) => {
    try {
        const response: any = await appAPI.post(Commands.REGISTER_NEW_PAYMENTH_METHOD_AHC, {
            loanId: ahc.loanId,
            routingNumber: ahc.routingNumber,
            nickname: ahc.bankName,
            note: null,
            accountNumber: ahc.accountNumber,
        });
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const putPaymentMethodACHAPI = (request: PutACHRequest): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response: any = await appAPI.put(Commands.PUT_ACH, request);
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const getPlaidLinkTokenAPI = async (): Promise<GetPlaidTokenResponse> => new Promise(async (resolve, reject) => {
    try {
        resolve({
            token: 'link-sandbox-b0d132da-6f9d-448a-8911-045c4d6418f4',
        });
    } catch (error) {
        reject(error);
    }
});

export const deleteCardAPI = async (id: number) => new Promise(async (resolve, reject) => {
    const command = Commands.PAYMENT_DELETE_CARD.replace('{id}', `${id}`);
    try {
        const response = await appAPI.delete<any>(command);
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const deleteCheckAPI = async (id: number) => new Promise(async (resolve, reject) => {
    const command = Commands.PAYMENT_DELETE_CHECK.replace('{id}', `${id}`);
    try {
        const response = await appAPI.delete<any>(command);
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const patchPaymentMethodsSortAPI = async (
    request: PatchPaymentMethodsSortRequest,
): Promise<Response> => new Promise(async (resolve, reject) => {
    const { loanId, paymentMethods } = request;
    const command = Commands.PATCH_PAYMENT_METHODS_SORT.replace('{loanId}', `${loanId}`);
    try {
        const response = await appAPI.patch(command, paymentMethods);
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

export const pathPaymentSetPrimaryAPI = (
    request: PatchPaymentSetPrimaryRequest,
): Promise<Response> => new Promise(async (resolve, reject) => {
    const { loanId, paymentMethodId, paymentMethodType } = request;
    let command = Commands.PATCH_PAYMENT_SET_PRIMARY.replace('{loanId}', `${loanId}`);
    command = command.replace('{paymentMethodId}', `${paymentMethodId}`);
    command = command.replace('{paymentMethodType}', paymentMethodType);
    try {
        const response = await appAPI.patch<Response>(command);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const putPaymentMethodAutoPayAPI = (request: PutPaymentMethodAutoPayRequest): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response = await appAPI.put<Response>(Commands.PUT_AUTOPAY, request);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const getPaymentMethodAutoPayAPI = (
    request: GetPaymentMethodAutoPayRequest
): Promise<PaymentMethodAutoPay> => new Promise(async (resolve, reject) => {
    try {
        const { loanId } = request;
        const command = Commands.GET_AUTOPAY.replace('{loanId}', `${loanId}`);
        const response = await appAPI.get<PaymentMethodAutoPay>(command);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});
