import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { AuthenticateState } from '@Auth/interfaces';
import * as authenticateActions from '@Auth/state/authenticateActions';

type ActionsType = ActionType<typeof authenticateActions>;

export const initialState: AuthenticateState = {};

export const authenticateReducer = createReducer<AuthenticateState, ActionsType>(
    initialState,
).handleAction(authenticateActions.authenticateAction, () => ({})
).handleAction(authenticateActions.authenticateRefreshTokenAction, () => ({}));
