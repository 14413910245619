import React from 'react';

import {
    Platform,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import Languages from '@i18n/index';
import { styles } from '@MethodsOfPayment/styles/SelecteMethodOfPaymentStyle';

interface Props {
    onClickAdd: () => void;
    onClickCancel: () => void;
    onClickContinue: () => void;
    shouldDisableButton: boolean;
};

const isWeb = Platform.OS === 'web';

export const Footer = ({
    onClickAdd,
    onClickCancel,
    onClickContinue,
    shouldDisableButton,

}: Props) => (
    <View style={styles.containerFooter}>
        <View style={styles.containerButton}>
            <AppButton
                accessibilityLabel={'selectMethodOfPaymentTouchableAppButtonAddNewMethod'}
                buttonStyle={styles.addNewMethodBtn}
                handleOnChange={onClickAdd}
                testID={'selectMethodOfPaymentTouchableAppButtonAddNewMethod'}
                textStyle={styles.textAddNewMethod}
                title={Languages.AddNewMethod}
            />
        </View>

        <View style={styles.containerFooterBtns}>
            <AppButton
                accessibilityLabel={'selectMethodOfPaymentTouchableAppButtonContinue'}
                buttonStyle={{ height: 55 }}
                disabled={shouldDisableButton}
                handleOnChange={onClickContinue}
                testID={'selectMethodOfPaymentTouchableAppButtonContinue'}
                textStyle={styles.textBtnContinue}
                title={isWeb ? Languages.Next : Languages.Continue}
            />
            <AppButton
                accessibilityLabel={'selectMethodOfPaymentTouchableAppButtonCancel'}
                buttonStyle={styles.containerBtnCancel}
                handleOnChange={onClickCancel}
                testID={'selectMethodOfPaymentTouchableAppButtonCancel'}
                textStyle={styles.textBtnCancel}
                title={Languages.Cancel}
            />
        </View>
    </View>
);
