import { Subject } from 'rxjs';
import {
    ALERT_CONFIG,
    AppAlertTypes,
} from '@Alert/config';
import {
    AlertMessage,
    AlertRequest,
} from '@Alert/interfaces';
const AppAlertService = () => {
    const subject = new Subject();
    const showError = (alertMessage: AlertMessage) => {
        const alertRequest: AlertRequest = buildAlertRequest(
            AppAlertTypes.ERROR_ALERT,
            alertMessage,
            false,
            true,
        );
        subject.next(alertRequest);
    };
    const showSuccess = (alertMessage: AlertMessage) => {
        const alertRequest: AlertRequest = buildAlertRequest(
            AppAlertTypes.SUCCESS_ALERT,
            alertMessage,
            false,
            true,
        );
        subject.next(alertRequest);
    };
    const showCustom = (alertRequest: AlertRequest) => {
        subject.next(alertRequest);
    };
    const showErrorStatic = (alertMessage: AlertMessage) => {
        const alertRequest: AlertRequest = buildAlertRequest(
            AppAlertTypes.ERROR_ALERT,
            {
                ...alertMessage,
            },
            true,
            true,
        );
        subject.next(alertRequest);
    };
    const hideErrorStatic = () => {
        const alertRequest: AlertRequest = buildAlertRequest(
            AppAlertTypes.ERROR_ALERT,
            {
                message: '',
            },
            true,
            false,
        );
        subject.next(alertRequest);
    };
    const subscribe = (process: any) => subject.subscribe(process);
    const buildAlertRequest = (
        type: AppAlertTypes,
        alertMessage: AlertMessage,
        fixed: boolean = false,
        show: boolean = false,
    ): AlertRequest => {
        const build = ALERT_CONFIG[type];
        return {
            ...build,
            subtitle: alertMessage?.message,
            fixed,
            show,
        };
    };
    return {
        hideErrorStatic,
        showCustom,
        showError,
        showErrorStatic,
        showSuccess,
        subscribe,
    };
};
export default AppAlertService();
