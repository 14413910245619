import { logEvent as logEventAnalytics } from 'firebase/analytics';

import { LoggerUserDataRequest } from '@Logger/interfaces/index';

import FirebaseService from './FirebaseService';

const LoggerService = () => {
    const initialize = () => {
        FirebaseService.initialize();
    };

    const logUserData = async (_: LoggerUserDataRequest) => {

    };

    const log = async (message?: string, ...optionalParams: any[]) => {
        logEventAnalytics(FirebaseService.getAnalytics(), 'log', {
            message,
            params: optionalParams,
        });
    };

    const logError = async (error: any) => {
        logEventAnalytics(FirebaseService.getAnalytics(), 'logError', {
            error: JSON.stringify(error),
            object: error,
        });
    };

    const logEvent = async (event: string, params: any) => {
        logEventAnalytics(FirebaseService.getAnalytics(), event, params);
    };

    const logScreenView = async (screenClass: string, screenName: string) => {
        logEventAnalytics(FirebaseService.getAnalytics(), 'screen_view', {
            firebase_screen: screenName,
            firebase_screen_class: screenClass,
        });
    };

    const crash = async () => { };

    return {
        crash,
        initialize,
        log,
        logError,
        logEvent,
        logScreenView,
        logUserData,
    };
};

export default LoggerService();
