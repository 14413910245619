import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    a: {
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        color: colors.pureBlue,
        textAlign: 'left',
        textAlignVertical: 'top',
    },
    container: {
        backgroundColor: colors.white,
        flex: 100,
        justifyContent: 'center',
        paddingBottom: 16,
        paddingTop: 16,
        flexWrap: 'wrap',
    },
    p: {
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        color: colors.grayLigth,
        textAlign: 'left',
        textAlignVertical: 'top',
        marginTop: 20,
    },
    textQuestion: {
        fontFamily: fonts.SFProText,
        fontWeight: '500',
        fontSize: 15,
        lineHeight: 18,
        color: colors.grayColor,
        textAlign: 'left',
        textAlignVertical: 'top',
        flex: 90,
    },
    textanswer: {
        fontFamily: fonts.SFProText,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 17.9,
        color: colors.grayColor,
        textAlign: 'left',
        textAlignVertical: 'top',
        marginTop: 20,
        marginRight: 20,
        marginHorizontal: 24,
    },
    touchableQuestion: {
        flex: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    viewQuestions: {
        alignItems: 'center',
        flex: 100,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
