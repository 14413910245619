import React, {
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    View,
} from 'react-native';

import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import {
    Footer,
    Header,
    ItemListWeb,
} from '@MethodsOfPayment/components';
import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import MethodsOfPaymentService from '@MethodsOfPayment/services/MethodsOfPaymentService';
import { getPaymentMethodsAction } from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import { styles } from '@MethodsOfPayment/styles/SelectMethodOfPaymentsScreenWebStyle';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import { StackScreenProps } from '@react-navigation/stack';

import { AddPaymentMethodDialog } from './AddPaymentMethodDialog';

interface Props extends StackScreenProps<RootStackParamList, 'SelectMethodOfPaymentScreen'> { }

export const SelectMethodOfPaymentScreen = ({
    navigation,
    route,
}: Props) => {
    const dispatch = useAppDispatch();
    const { loanSelected } = useAppSelector(({ applicationsReducer }) => applicationsReducer);
    const [payments, setPayments] = useState<PaymentMethodResponseBase[]>([]);
    const [isMakeAPayment, setIsMakeAPayment] = useState<boolean>(false);

    useEffect(() => {
        if (loanSelected?.loanId) {
            const {
                loanId,
            } = loanSelected;

            dispatch(getPaymentMethodsAction(loanId));
        }
    }, [loanSelected]);

    useEffect(() => {
        const makeAPaymentSubscribe = MethodsOfPaymentService.subscribe((state: PaymentMethodResponseBase[]) => {
            if (state.length) {
                setPayments(state);
            }
        });
        return () => {
            makeAPaymentSubscribe.unsubscribe();
        };
    }, []);

    const handleChangePaymentMethod = (index: any) => {
        const lastSelected: any = payments.find((item: any) => item.selected);
        if (lastSelected) {
            const indexLastSelected = payments.indexOf(lastSelected);
            payments[indexLastSelected].selected = false;
        }

        payments[index].selected = true;
        setPayments([
            ...payments,
        ]);
    };

    const handleCancel = () => route.params.handleCancel ? route.params.handleCancel() : {};

    const handleSuccess = (item: any) => {
        route.params.handleSelection ? route.params.handleSelection(item) : {}
    };

    const handleClickContinue = () => {
        const payment: any = payments.find((item: PaymentMethodResponseBase) => item.selected);
        if (route.params.isSelectionOnly && route.params.handleSelection) {
            route.params.handleSelection(payment);
        } else {
            navigation.navigate('DateMakeAPaymentScreen', {
                amount: route.params.amount,
                optionSelected: route.params.optionSelected,
                paymentMethodId: payment?.id,
                paymentMethodType: payment?.type,
                isTodayPayoff: route.params.isTodayPayoff,
            });
        }
    };

    const addPaymentMethod = () => setIsMakeAPayment(true);

    const shouldDisable = (): boolean => payments.some((element) => element.selected) === false;

    return (
        <View style={styles.container}>
            <View style={{
                width: '100%',
                height: '100%',
                flex: 1,
            }}>
                <FlatList
                    bounces={false}
                    data={payments}
                    extraData={payments}
                    keyExtractor={(_: any, index: number) => index.toString()}
                    renderItem={({ index, item }: any) => (<ItemListWeb index={index} item={item} onClickItem={handleChangePaymentMethod} />)}
                    ListHeaderComponent={Header}
                    numColumns={2}
                    horizontal={false}
                    showsVerticalScrollIndicator={false}
                    columnWrapperStyle={{ flexWrap: 'wrap', flex: 1, paddingHorizontal: 20, width: '100%' }}
                    ListHeaderComponentStyle={styles.containerHeader}
                    contentContainerStyle={{ width: '100%', display: 'flex', height: '100%' }}
                    ListFooterComponent={() => (
                        <Footer
                            onClickAdd={addPaymentMethod}
                            onClickCancel={handleCancel}
                            onClickContinue={handleClickContinue}
                            shouldDisableButton={shouldDisable()}
                        />
                    )}
                    ListFooterComponentStyle={styles.containerFlatFooter}
                />
            </View>
            <AddPaymentMethodDialog
                isVisible={isMakeAPayment}
                handleCancel={handleCancel}
                handleSuccess={handleSuccess} />

        </View>
    );
};
