enum ActivityActionTypes {
    GET_DOCUMENT_STATEMENT = '@@activity/GET_DOCUMENT_STATEMENT',
    GET_PAYMENT_ACTIVITIES = '@@activity/GET_PAYMENT_ACTIVITIES',
    GET_PAYMENT_ACTIVITY = '@@activity/GET_PAYMENT_ACTIVITY',
    GET_PAYMENT_PENDINGS = '@@activity/GET_PAYMENT_PENDINGS',
    GET_PAYMENT_STATEMENTS = '@@activity/GET_PAYMENT_STATEMENTS',
    SAVE_PAYMENT_ACTIVITY = '@@activity/SAVE_PAYMENT_ACTIVITY',
    CANCEL_PENDING_REQUEST = '@@pendig/CANCEL_PENDING_REQUEST',
    UPDATE_PENDING_REQUEST = '@@pending/UPDATE_PENDING_REQUEST',
};

export default ActivityActionTypes;
