import {
    format,
    parse,
} from 'date-fns';
import {
    differenceInDays,
    format as formatDate,
    parseISO,
} from 'date-fns/esm';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as Yup from 'yup';

import Languages from '@i18n/index';

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
const numberRegx = /[0-9]/gm;
const lettersRegx = /[a-zA-Z]/g;
const specialCharacters = /[|&;$%"<>()+-/\s/]/g;

interface ValidField {
    isValid: boolean;
    isError: boolean;
    message: string;
    type?: string;
};

export const formatToPhoneNumber = (number: string, region: string = 'US'): string => {
    try {
        const formatNumber = phoneUtil.parse(number, region);
        return phoneUtil.formatInOriginalFormat(formatNumber);
    } catch {
        return number;
    }
};

export const clearPhoneNumberFormat = (number: string): string => {
    try {
        const numberPattern = /\d+/g;
        const result = number.match(numberPattern);
        if (result) {
            return result.join('');
        }
    } catch { }
    return '';
};

export const clearAllFormat = (value: string): string => value.replace(specialCharacters, '');

export const removedText = (value: string): string => value.replace(/[^0-9]/, '');

export const containsNumbers = (value: string): boolean => {
    const cleanedValue = value.replace(specialCharacters, '');
    return value ? numberRegx.test(cleanedValue) : false;
};

export const containsLetters = (value: string): boolean => {
    const cleanedValue = value.replace(specialCharacters, '');
    const result = cleanedValue.match(lettersRegx);
    return result !== null && result.length > 0;
};

export const isValidPhoneNumber = (number: string, region: string = 'US'): boolean => {
    try {
        const formatNumber = phoneUtil.parse(number, region);
        return phoneUtil.isValidNumberForRegion(formatNumber);
    } catch {
        return false;
    }
};

export const validateNumber = (value: string): ValidField => {
    const phoneNumber = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
    const number = /[0-9]/gm;

    if (value === '') {
        return {
            isValid: false,
            isError: false,
            message: '',
        };
    }

    if (number.test(value)) {
        if (!phoneNumber.test(value)) {
            return {
                isValid: false,
                isError: true,
                message: Languages.ErrorPhoneNumberInvalid,
            };
        }
    }
    return {
        isValid: true,
        isError: false,
        message: '',
    };
};

export const validateEmail = (value: string): ValidField => {
    const emailSchema = Yup.object().shape({
        email: Yup
            .string()
            .email(),
    });
    if (value === '') {
        return {
            isValid: false,
            isError: false,
            message: '',
        };
    }
    try {
        emailSchema.validateSync({
            email: value,
        });
        return {
            isValid: true,
            isError: false,
            message: '',
        };
    } catch (error) {
        return {
            isValid: false,
            isError: true,
            message: Languages.ErrorEmailInvalid,
        };
    }
    return {
        isValid: true,
        isError: false,
        message: '',
    };
};

export const validateNumberOrEmail = (value: string): ValidField => {
    let type = '';
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneNumber = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
    const number = /[0-9]/gm;

    if (value === '') {
        return {
            isValid: false,
            isError: false,
            message: '',
            type: '',
        };
    }

    if (value.includes('@') || containsLetters(value)) {
        type = 'Email';
        if (!emailPattern.test(value)) {
            return {
                isValid: false,
                isError: true,
                message: Languages.ErrorEmailInvalid,
                type,
            };
        }
    } else if (number.test(value)) {
        type = 'PhoneNumber';
        if (!phoneNumber.test(value)) {
            return {
                isValid: false,
                isError: true,
                message: Languages.ErrorPhoneNumberInvalid,
                type,
            };
        }
    } else {
        return {
            isValid: false,
            isError: true,
            message: Languages.ErrorEmailInvalid,
            type,
        };
    }
    return {
        isValid: true,
        isError: false,
        message: '',
        type,
    };
};

export const parseDate = (date: string, format: string): string => date && formatDate(
    parseISO(date),
    format,
);

/**
 * Function to convert, MM/dd/yyyy to yyyy-MM-dd
 * without time and avoiding format libraries
 * 
 * @param dateString MM/dd/yyyy
 * @returns string (yyyy-MM-dd)
 */
export const formatDOB = (dateString: string): string => {
    const data = dateString.split('/');
    return `${data[2]}-${data[0]}-${data[1]}`;
};

export const formatToISO = (dateString: string) => {
    const date = parse(dateString, 'MM/dd/yyyy', new Date());
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const obtainDifferenceInDays = (dateLeft: string | Date, dateRight: string | Date): number => {
    const leftDate = typeof dateLeft === 'string' ? parseISO(dateLeft) : dateLeft;
    const rightDate = typeof dateRight === 'string' ? parseISO(dateRight) : dateRight;
    return differenceInDays(leftDate, rightDate);
};

export const formatSSN = (ssn: string): string => {
    let formatedValue = ssn.replace(/\D/g, '');
    formatedValue = formatedValue.replace(/^(\d{3})/, '$1-');
    formatedValue = formatedValue.replace(/-(\d{2})/, '-$1-');
    formatedValue = formatedValue.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return formatedValue;
};

export const clearformatSSN = (ssnWithFormat: string): string => {
    if (ssnWithFormat && ssnWithFormat !== '' && ssnWithFormat.includes('-')) {
        return ssnWithFormat.replace(/-/g, '');
    }
    return ssnWithFormat;
};

export const toPST = (date: Date): Date => {
    const localString = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    let parseDate = new Date(localString);
    return parseDate;
};

export const formatCreditCard = (value:string) => {
    const input = value.replace(/\D/g, '');
    let formattedInput = '';
    if (input.startsWith('34') || input.startsWith('37')) {
        formattedInput = input.replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1-$2-$3')
    } else {
        formattedInput = input.replace(/\b(\d{4})(\d{4})(\d{4})(\d{4})\b/, '$1-$2-$3-$4');
    }
    
    return formattedInput;
}
