import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    action: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProDisplay,
        fontSize: 13,
        justifyContent: 'center',
        textAlign: 'left',
    },
    actionShimmer: {
        backgroundColor: 'blue',
        height: 14,
        width: 148,
    },
    amount: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextBold,
        fontSize: 18,
        fontWeight: '700',
        textAlign: 'left',
    },
    smallerAmount: {
        color: colors.grayColor,
        fontFamily: fonts.SFProTextBold,
        fontSize: 18,
        fontWeight: '700',
        textAlign: 'left',
    },
    amountContainer: {
        flex: 10,
        paddingVertical: 3,
    },
    amountShimmer: {
        backgroundColor: 'green',
        height: 33,
        marginTop: 10,
        width: 148,
    },
    dataContainer: {
        flex: 80,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    card: {
        alignItems: 'center',
        flex: 100,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 16,
    },
    cardEnd: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderColor: theme.colors.primary,
        borderRadius: 5,
        borderWidth: 2,
        flex: 20,
        justifyContent: 'center',
        minWidth: 184,
    },
    cardWeb: {
        flex: 75,
        flexDirection: 'column',
        marginLeft: 15,
        width: 125,
    },
    date: {
        color: theme.colors.border,
        fontFamily: fonts.SFProRoundedMedium,
        fontSize: 12,
        fontWeight: '500',
        paddingTop: 1,
        textAlign: 'left',
    },
    icon: {
        alignItems: 'center',
        flex: 25,
    },
    iconShimmer: {
        borderRadius: 27.5,
        height: 54,
        width: 54,
    },
    viewAction: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 17,
        fontWeight: '600',
    },
    viewMobile: {
        flex: 2,
        flexDirection: 'row',
    },
});
