import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    delay,
    map,
    mergeMap,
    switchMap,
    tap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import Languages from '@i18n/index';
import {
    Error,
    Response,
} from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import {
    deleteCardAPI,
    deleteCheckAPI,
    getPaymentMethodAutoPayAPI,
    getPaymentMethodsAPI,
    getPlaidLinkTokenAPI,
    patchPaymentMethodsSortAPI,
    pathPaymentSetPrimaryAPI,
    putPaymentMethodACHAPI,
    putPaymentMethodAutoPayAPI,
    putPaymentMethodCardAPI,
    savePaymentMethodACHAPI,
    savePaymethMethodCardAPI,
} from '@MethodsOfPayment/api/MethodsOfPaymentApi';
import {
    CheckResponse,
    DeletePaymentMethodActionRequest,
    GetPaymentMethodAutoPayActionRequest,
    GetPlaidTokenResponse,
    PatchPaymentSetPrimaryActionRequest,
    PaymentMethodAutoPay,
    PaymentMethodResponseBase,
    PaymentMethodSort,
    PaymentMethodsSortActionRequest,
    PaymentsMethodResponse,
    PutACHActionRequest,
    PutCardRequest,
    PutPaymentMethodAutoPayActionRequest,
    SaveAHCActionRequest,
    SaveCardActionRequest,
} from '@MethodsOfPayment/interfaces';
import MethodsOfPaymentService from '@MethodsOfPayment/services/MethodsOfPaymentService';
import PaymentOfMethodService from '@MethodsOfPayment/services/PaymentOfMethodService';
import PlaidLinkService from '@MethodsOfPayment/services/PlaidLinkService';
import RefreshMethodsOfPaymentService
    from '@MethodsOfPayment/services/RefreshMethodsOfPaymentService';
import {
    getPaymentMethodAutoPaySuccessAction,
    getPaymentMethodsAction,
    loadMethodsOfPayment,
    patchPaymentSetPrimaryAction,
    setMethodOfPaymentSelected,
    setPaymentMethodAutoPayAction,
} from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import MakeAPaymentActionsTypes from '@MethodsOfPayment/state/MethodsOfPaymentActionsTypes';
import MethodsOfPaymentActionsTypes from '@MethodsOfPayment/state/MethodsOfPaymentActionsTypes';
import {
    useAppDispatch,
} from '@Hooks/appHooks';
import ManageAutoPayActionsTypes from '@MethodsOfPayment/state/ManageAutoPayActionsTypes';

export const getPaymentMethodsEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MethodsOfPaymentActionsTypes.GET_PAYMENT_METHOD_REQUEST),
    delay(250),
    mergeMap(({ payload }: any) => from(getPaymentMethodsAPI(payload))
        .pipe(
            mergeMap((response: PaymentsMethodResponse[]) => {
                const filteredArray: PaymentsMethodResponse[] = response.filter((paymentMethod: PaymentsMethodResponse) => paymentMethod != null);
                const paymentMethods: PaymentMethodResponseBase[] = filteredArray.map((paymentMethod: PaymentsMethodResponse): PaymentMethodResponseBase => {
                    if (paymentMethod.type === 'Debit' || paymentMethod.type === 'Credit') {
                        return {
                            active: !paymentMethod.active,
                            bank: '',
                            expirationDate: `${paymentMethod.expirationDate}`,
                            id: paymentMethod.id,
                            isPrimary: paymentMethod.isPrimary,
                            lastFourDigit: paymentMethod.lastFourDigit ?? '',
                            loanId: paymentMethod.loanId,
                            nickname: paymentMethod.nickname,
                            note: paymentMethod.note,
                            paymentNetwork: (paymentMethod.paymentNetwork && paymentMethod.paymentNetwork !== 'Unknown')
                                ? paymentMethod.paymentNetwork : 'CreditCardIcon',
                            routingNumber: '',
                            selected: false,
                            token: paymentMethod.token,
                            type: paymentMethod.type,
                            isShowShimmer: false,
                        };
                    } else {
                        return {
                            active: !paymentMethod.active,
                            bank: paymentMethod.bank ?? '',
                            expirationDate: '',
                            id: paymentMethod.id,
                            isPrimary: paymentMethod.isPrimary,
                            lastFourDigit: paymentMethod.lastFourDigit ?? '',
                            loanId: paymentMethod.loanId,
                            nickname: paymentMethod.nickname,
                            note: paymentMethod.note,
                            paymentNetwork: 'Checking',
                            routingNumber: paymentMethod.routingNumber ?? '',
                            selected: false,
                            token: paymentMethod.token,
                            type: paymentMethod.type,
                            isShowShimmer: false,
                        };
                    }
                });

                MethodsOfPaymentService.onSuccess(paymentMethods);
                return [
                    loadMethodsOfPayment(paymentMethods),
                    loadingDialogHide(),
                ];
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

const setCardAsPimaryMethod =(response: any,{ payload }: SaveCardActionRequest) =>
{
    if(!payload.fromManageAutopayScreen){
        AppAlertService.showSuccess({
            message: Languages.YouHaveAddedSuccessfullyNewMethodOfPayment,
        });
    }

    PaymentOfMethodService.onSuccess(response);
    RefreshMethodsOfPaymentService.onSuccess(payload.loanId);

    if(!payload.enableAutoPay){
        return loadingDialogHide();
    }

    return patchPaymentSetPrimaryAction({
        loanId: payload.loanId,
        paymentMethodId: response?.id,
        paymentMethodType: payload.type ?? '',
    })
}


export const saveCardEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST),
    ofType(ManageAutoPayActionsTypes.SAVE_NEW_PAYMENTH_METHOD_CARD_REQUEST),
    delay(250),
    mergeMap(({ payload }: SaveCardActionRequest) => from(savePaymethMethodCardAPI(payload))
        .pipe(
            mergeMap((response: any) => [
                setCardAsPimaryMethod(response, { payload }),
                setPaymentMethodAutoPayAction({ autopay: true }),
                setMethodOfPaymentSelected({ id: response?.id, value: false, clear: true })
            ]),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

export const putCardEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.PUT_PAYMENTH_METHOD_CARD_REQUEST),
    delay(250),
    switchMap(( payload : PutCardRequest) => from(putPaymentMethodCardAPI(payload))
        .pipe(
            map((response: any) => {
                AppAlertService.showSuccess({
                    message: Languages.YouHaveUpdatedSuccessfullyYourMethodOfPayment,
                });
                PaymentOfMethodService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

const setACHAsiPrimaryPaymentMethod = (response: CheckResponse, { payload }: SaveAHCActionRequest) => {
    if (!payload.fromManageAutopayScreen) {
        AppAlertService.showSuccess({
            message: Languages.YouHaveAddedSuccessfullyNewMethodOfPayment,
        });
    }
    PaymentOfMethodService.onSuccess(response);
    RefreshMethodsOfPaymentService.onSuccess(payload.loanId);
    if(!payload.enableAutoPay)
        return loadingDialogHide();
    return patchPaymentSetPrimaryAction({
        loanId: payload.loanId,
        paymentMethodId: response?.id,
        paymentMethodType: 'Checking',
    });
}

export const saveAHCEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST),
    ofType(ManageAutoPayActionsTypes.SAVE_NEW_PAYMENTH_METHOD_AHC_REQUEST),
    delay(250),
    mergeMap(({ payload }: SaveAHCActionRequest) => from(savePaymentMethodACHAPI(payload))
        .pipe(
            mergeMap((response: CheckResponse) => [
                setACHAsiPrimaryPaymentMethod(response, { payload }),
                setPaymentMethodAutoPayAction({ autopay: true }),
                setMethodOfPaymentSelected({ id: response?.id, value: false, clear: true })
            ]),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

export const putAHCEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.PUT_PAYMENT_METHOD_ACH_REQUEST),
    delay(250),
    switchMap(({ payload }: PutACHActionRequest) => from(putPaymentMethodACHAPI(payload))
        .pipe(
            map((response: any) => {
                AppAlertService.showSuccess({
                    message: Languages.YouHaveUpdatedSuccessfullyYourMethodOfPayment,
                });
                PaymentOfMethodService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            })
        ))
);

export const getPlaidLinkTokenEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.GET_PLAID_LINK_TOKEN),
    delay(250),
    mergeMap(() => from(getPlaidLinkTokenAPI())
        .pipe(
            map((response: GetPlaidTokenResponse) => {
                PlaidLinkService.onSuccess({
                    ...response,
                });
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const deleteCardEpic = (action$: Observable<any>, store: any) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.DELETE_CARD_PAYMENT_REQUEST),
    delay(250),
    mergeMap(({ payload }: DeletePaymentMethodActionRequest) => from(deleteCardAPI(payload))
        .pipe(
            map(() => loadingDialogHide()),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        ).pipe(
            map(() => {
                const {
                    loanId,
                } = store.value.applicationsReducer.loanSelected;
                return getPaymentMethodsAction(loanId);
            }),
        )
    ),
);

export const deleteCheckEpic = (action$: Observable<any>, store: any) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.DELETE_CHECK_PAYMENT_REQUEST),
    delay(250),
    mergeMap(({ payload }: DeletePaymentMethodActionRequest) => from(deleteCheckAPI(payload))
        .pipe(
            map(() => loadingDialogHide()),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        ).pipe(
            map(() => {
                const {
                    loanId,
                } = store.value.applicationsReducer.loanSelected;
                return getPaymentMethodsAction(loanId);
            }),
        )
    )
);

export const patchPaymentMethodsSortEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.PATCH_PAYMENT_METHODS_SORT),
    mergeMap(
        ({ payload }: PaymentMethodsSortActionRequest) => from(new Promise((resolve, reject) => {
            try {
                const { loanId, order, paymentMethods } = payload;
                const sortedData: PaymentMethodSort[] = order.map((id: number) => {
                    const finded = paymentMethods.find((paymentMethod: PaymentMethodResponseBase) => paymentMethod.id === id);
                    return {
                        paymentMethodId: finded?.id,
                        type: finded?.type,
                    } as PaymentMethodSort;
                });
                resolve({
                    loanId,
                    paymentMethods: sortedData,
                });
            } catch (error) {
                reject(error);
            }
        })).pipe(
            mergeMap((response: any) => from(patchPaymentMethodsSortAPI(response))
                .pipe(
                    map((_: Response) => {
                        RefreshMethodsOfPaymentService.onSuccess(payload.paymentMethods[0].id);
                        return loadingDialogHide();
                    }),
                    catchError((error: Error) => {
                        AppAlertService.showError({
                            message: error.title,
                        });
                        return of(loadingDialogHide());
                    }),
                ),
            ),
        ),
    ),
);

export const patchPaymentSetPrimaryEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MakeAPaymentActionsTypes.PATCH_PAYMENT_SET_PRIMARY_REQUEST),
    switchMap(({ payload }: PatchPaymentSetPrimaryActionRequest) => from(pathPaymentSetPrimaryAPI(payload))
        .pipe(
            map((_: Response) => {
                RefreshMethodsOfPaymentService.onSuccess(payload.paymentMethodId);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const putPaymentMethodAutoPayEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MethodsOfPaymentActionsTypes.PUT_AUTOPAY),
    delay(250),
    switchMap(({ payload }: PutPaymentMethodAutoPayActionRequest) => from(putPaymentMethodAutoPayAPI(payload))
        .pipe(
            mergeMap(() => {
                RefreshMethodsOfPaymentService.onSuccess(payload.loanId);
                return [
                    getPaymentMethodAutoPaySuccessAction({
                        autopay: payload.autopay,
                    }),
                    loadingDialogHide(),
                ]
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title,
                });
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const getPaymentMethodAutoPayEpic = (action$: Observable<any>) => action$.pipe(
    ofType(MethodsOfPaymentActionsTypes.GET_AUTOPAY),
    delay(250),
    switchMap(({ payload }: GetPaymentMethodAutoPayActionRequest) => from(getPaymentMethodAutoPayAPI(payload))
        .pipe(
            mergeMap((response: PaymentMethodAutoPay) => [
                getPaymentMethodAutoPaySuccessAction(response),
                loadingDialogHide(),
            ]),
            catchError(() => of(loadingDialogHide())),
        ),
    ),
);
