import { Commands } from "@Api/config";
import appAPI from '@Api/appApi';
import { GetDelightedSurveyAnsweredResponse } from "@Home/interfaces";

export const getSurveyAnsweredAPI = ({ applicationId }: any): Promise<any> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_SURVEY_ANSWERED;
        const response = await appAPI.get<GetDelightedSurveyAnsweredResponse>(command.replace('{loanId}', `${applicationId}`));

        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const setSurveyAnsweredAPI = ({ applicationId }: any): Promise<any> => new Promise(async (resolve, reject) => {
    try {

        const command = Commands.SET_SURVEY_ANSWERED;
        const response = await appAPI.post<GetDelightedSurveyAnsweredResponse>(command, {loanId: applicationId});

        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});