import React, { useState } from 'react';

import {
    compareAsc,
    parse,
} from 'date-fns';
import {
    Platform,
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { CreditCardIcon } from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { Icon } from '@Components/Icon';
import { TextInput } from '@Components/TextInput';
import { CheckBox } from '@Components/CheckBox';
import { useFormik } from '@Hooks/useForm';
import Languages from '@i18n/index';
import {
    CardEditSchema,
    CardSchema,
} from '@MethodsOfPayment/config/CardSchema';
import { TypeCard } from '@MethodsOfPayment/interfaces';
import { styles } from '@MethodsOfPayment/styles/AddCardFormStyle';
import {
    colors,
    theme,
} from '@Theme/appTheme';
import { formatCreditCard, removedText } from '@Utils/FormatUtils';
import { switchcase } from '@Utils/index';

enum Network {
    AMERICANEXPRESS = 3,
    VISA = 4,
    MASTERCARD = 5,
    DISCOVERCARD = 6,
};

enum TypesNetworkCard {
    MASTERCARD = 'MasterCard',
    AMERICANEXPRESS = 'AmericanExpress',
    VISA = 'Visa',
    DISCOVERCARD = 'Discover',
};
interface Props {
    handleSubmit: (values: TypeCard) => void;
    initialData?: TypeCard;
    isAutoPayEnabled?: boolean | undefined;
    handleCancel: () => void;
};

interface Validation {
    error: boolean;
    errorMsg: string;
};

export const AddCardForm = ({
    handleSubmit,
    initialData,
    isAutoPayEnabled,
    handleCancel
}: Props) => {
    const initialStateForm: TypeCard = {
        numberCard: '',
        nickname: '',
        expirationDate: '',
        enableAutoPay: isAutoPayEnabled ?? false
    };

    const form = useFormik(
        handleSubmit,
        initialData ?? initialStateForm,
        initialData ? CardEditSchema : CardSchema,
    );
    const [validate, setValidate] = useState<Validation>({
        error: false,
        errorMsg: '',
    });

    const [validateNumber, setValidateNumber] = useState<Validation>({
        error: false,
        errorMsg: '',
    });

    const [isChecked, setIsChecked] = useState(isAutoPayEnabled ?? false);

    const isWeb = Platform.OS === 'web';

    const [isAmericanExpress, setIsAmericanExpress] = useState(false);

    const [cardNumber, setCardNumber] = useState('');

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        submitForm,
        setFieldValue,
        touched,
    } = form;

    const onChangeNumber = (type: string, value: string) => {
        const formatedValue = removedText(value);
        const formattedInput = formatCreditCard(formatedValue);
        setCardNumber(formattedInput);

        setFieldValue(type, formatedValue);
        const numberInitial = Number(value.substring(0, 1));
        if (numberInitial === 3) {
            setIsAmericanExpress(true);
        } else {
            setIsAmericanExpress(false);
        }

        if (numberInitial === 3 || numberInitial === 6) {
            setValidateNumber({ error: true, errorMsg: 'We are sorry, but American Express and Discover cards are not accepted. Please use a Visa or Mastercard for your transaction.'});
        } else {
            setValidateNumber({ error: false, errorMsg: '' });
        }
    };

    const renderLeftIcon = () => {
        const numberInitial = Number(values.numberCard.substring(0, 1));
        return (
            <View style={styles.creditCard}>
                {switchcase({
                    [Network.AMERICANEXPRESS]: () => <Icon name={TypesNetworkCard.AMERICANEXPRESS} />,
                    [Network.VISA]: () => <Icon name={TypesNetworkCard.VISA} />,
                    [Network.DISCOVERCARD]: () => <Icon name={TypesNetworkCard.DISCOVERCARD} />,
                    [Network.MASTERCARD]: () => <Icon name={TypesNetworkCard.MASTERCARD} />,
                })(() => <CreditCardIcon fill={colors.grayVeryLigth} />)(numberInitial)()}
            </View>
        );
    };

    const shouldBeDisableButtonSave = (): boolean => {
        return Boolean(errors.numberCard
            || errors.expirationDate
            || validate.error
            || validateNumber.error
        );
    };

    const onChangeValueEspecial = (type: string, value: string) => {
        const year = value.substring(3, 5);
        const mount = value.substring(0, 2);

        const dateNow = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const yearStr = dateNow.getFullYear().toString().substring(0, 2);

        const strDate = `${mount}/01/${yearStr}${year}`;
        const date = parse(strDate, 'MM/dd/yyyy', new Date());

        const result = compareAsc(date, dateNow);
        if (result <= 0) {
            setValidate({
                errorMsg: 'Enter valid expiration date',
                error: true,
            });
        } else {
            setValidate({
                errorMsg: '',
                error: false,
            });
        }

        setFieldValue(type, value);
    };

    const messageErrorExpirationDate = () => {
        if (touched.expirationDate || validate.error) {
            if (errors.expirationDate) {
                return errors.expirationDate;
            } else {
                return validate.errorMsg;
            }
        }
        return '';
    };

    const handleCheckboxChange = (newState: boolean) => {
        setIsChecked(newState);
        setFieldValue('enableAutoPay', newState);
    };

    const handleNumberValidationError = () => {
        if (validateNumber.error) return validateNumber.errorMsg;
        return touched.numberCard && errors.numberCard;
    }

    return (
        <View style={styles.container}>
            <View style={styles.contentForm}>
                <TextInput
                    accessibilityLabel={'addCardFormTextInputCardNumber'}
                    containerStyle={styles.inputLarge}
                    editable={!initialData}
                    error={!initialData && ((touched.numberCard && !validateNumber.error) && (!!validateNumber.error || !!errors.numberCard))}
                    helperText={handleNumberValidationError()}
                    keyboardType='numeric'
                    LeftIcon={renderLeftIcon}
                    maxCharacters={isAmericanExpress ? 17 : 19}
                    onBlur={handleBlur('numberCard')}
                    onChangeText={(value) => onChangeNumber('numberCard', value)}
                    placeholder={Languages.CardNumber}
                    returnKeyType='done'
                    testID={'addCardFormTextInputCardNumber'}
                    value={initialData ? values.numberCard : cardNumber}
                />
                <View style={[StyleSheet.create({ item: {}}).item]}>
                    <Text style={[StyleSheet.create({ item: { color: colors.red, fontSize: 10, marginHorizontal: 12, marginBottom: 15, marginTop: -13 }}).item]}>
                        {validateNumber.errorMsg}
                    </Text>
                </View>
                {!initialData &&
                    <View style={styles.containerInitialData}>
                        <View style={styles.containerInputRow}>
                            <TextInput
                                accessibilityLabel={'addCardFormTextInputExpirationDate'}
                                containerStyle={styles.inputMedium}
                                editable={!initialData}
                                error={touched.expirationDate && (Boolean(errors.expirationDate) || validate.error)}
                                helperText={messageErrorExpirationDate()}
                                keyboardType='numeric'
                                maxCharacters={5}
                                onBlur={handleBlur('expirationDate')}
                                onChangeText={(value) => onChangeValueEspecial('expirationDate', value)}
                                options={{
                                    format: 'MM/YY',
                                }}
                                placeholder={Languages.ExpirationDateCardFormat}
                                returnKeyType='done'
                                testID={'addCardFormTextInputExpirationDate'}
                                type='Mask'
                                value={values.expirationDate}
                            />
                        </View>
                        <View style={styles.containerCheckbox}>
                            <CheckBox
                                title={Languages.EnableAutoPayCheckbox}
                                selected={isChecked}
                                onChange={handleCheckboxChange}
                            />
                        </View>
                    </View>
                }

                {initialData &&
                    <TextInput
                        accessibilityLabel={'addCardFormTextInputExpirationDate'}
                        containerStyle={styles.inputLarge}
                        error={touched.expirationDate && (Boolean(errors.expirationDate) || validate.error)}
                        helperText={messageErrorExpirationDate()}
                        keyboardType='numeric'
                        maxCharacters={5}
                        onBlur={handleBlur('expirationDate')}
                        onChangeText={(value) => onChangeValueEspecial('expirationDate', value)}
                        options={{
                            format: 'MM/YY',
                        }}
                        placeholder={Languages.ExpirationDateCardFormat}
                        returnKeyType='done'
                        testID={'addCardFormTextInputExpirationDate'}
                        type='Mask'
                        value={values.expirationDate}
                    />

                }
                <AppButton
                    accessibilityLabel={'addCardFormAppButtonSave'}
                    buttonStyle={isWeb ? styles.buttonNext : [styles.buttonSave, { backgroundColor: shouldBeDisableButtonSave() ? colors.darkGray : theme.colors.primary }]}
                    disabled={shouldBeDisableButtonSave()}
                    handleOnChange={submitForm}
                    testID={'addCardFormAppButtonSave'}
                    title={Languages.Save}
                />
                <AppButton
                    accessibilityLabel={'addCardFormButtonCancel'}
                    buttonStyle={styles.buttonCancel}
                    handleOnChange={handleCancel}
                    testID={'addCardFormButtonCancel'}
                    textStyle={styles.textCancel}
                    title={Languages.Cancel}
                />
            </View>
        </View>
    );
};
