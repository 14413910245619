import { Subject } from 'rxjs';

const TermsOfUseService = () => {
    const subject = new Subject();

    const showDialog = () => {
        subject.next({
            show: true,
        });
    };

    const hideDialog = () => {
        subject.next({
            show: false,
        });
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        hideDialog,
        showDialog,
        subscribe,
    };

};

export default TermsOfUseService();
