import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Text,
    TextInput as Input,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    EyeHideIconSvg,
    EyeIconSvg,
} from '@Assets/index';
import { NewPasswordSchema } from '@Auth/config/NewPasswordSchema';
import { InputType } from '@Auth/interfaces/index';
import UpdatePasswordService from '@Auth/services/UpdatePasswordService';
import { updatePasswordEmail } from '@Auth/state/forgotPasswordActions';
import { styles } from '@Auth/styles/ForgotStyle';
import { AppButton } from '@Components/AppButton';
import { TextInput } from '@Components/TextInput';
import { useAppDispatch } from '@Hooks/appHooks';
import { useFormik } from '@Hooks/useForm';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'NewPasswordScreen'> { };
interface NewPasswordInitialState {
    email: string;
    password: string;
    confirmPassword: string;
}

const initialRegisterForm: NewPasswordInitialState = {
    email: '',
    password: '',
    confirmPassword: '',
};

export const NewPasswordForm = ({
    navigation,
    route,
}: Props) => {
    const dispatch = useAppDispatch();
    const { email, verificationCode } = route.params;
    const newPassTextRef = useRef<Input>(null);
    const confirmNewPassTextRef = useRef<Input>(null);
    const [isShowPassword, setIsShowPassword] = useState(true);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(true);

    const handleShowPassword = () => {
        newPassTextRef.current?.focus();
        setIsShowPassword(!isShowPassword);
    };
    const handleShowConfirmPassword = () => {
        confirmNewPassTextRef.current?.focus();
        setIsShowConfirmPassword(!isShowConfirmPassword);
    };

    const fillEyeColor = (isFocus: boolean | undefined, error: any, touched: any) => {
        if (Boolean(error) && touched) {
            return colors.red;
        }
        if (isFocus) {
            return theme.colors.primary;
        } else {
            return colors.gray;
        };
    };

    const renderEyeIconPassword = () => (
        <>
            {isShowPassword ? <EyeHideIconSvg
                width={25}
                height={19}
                fill={fillEyeColor(newPassTextRef.current?.isFocused(), errors.password, touched.password)}
            /> : <EyeIconSvg
                width={25}
                height={15}
                fill={fillEyeColor(newPassTextRef.current?.isFocused(), errors.password, touched.password)}
            />}
        </>
    );

    const renderEyeIconConfirmPassword = () => (
        <>
            {isShowConfirmPassword ? <EyeHideIconSvg
                width={25}
                height={19}
                fill={fillEyeColor(confirmNewPassTextRef.current?.isFocused(), errors.confirmPassword, touched.confirmPassword)}
            /> : <EyeIconSvg
                width={25}
                height={15}
                fill={fillEyeColor(confirmNewPassTextRef.current?.isFocused(), errors.confirmPassword, touched.confirmPassword)}
            />}
        </>
    );

    useEffect(() => {
        if (email && email?.length) {
            initialRegisterForm.email = email;
        }

        if (email && email.length && form.values) {
            form.setFieldValue('email', email);
        }
    }, [email]);

    const onSubmit = (values: NewPasswordInitialState) => {
        if (email && verificationCode) {
            LoggerService.logEvent('updatePassword', {});
            dispatch(updatePasswordEmail({
                requester: values.email,
                verificationCode,
                password: values.password,
            }));
        }
    };

    const form = useFormik(onSubmit, initialRegisterForm, NewPasswordSchema);

    const {
        errors,
        submitForm,
        setFieldTouched,
        setFieldValue,
        touched,
        validateField,
        values,
    } = form;

    const shouldBeActiveBtn = () => errors.password || errors.confirmPassword;

    const onChangeText = (field: InputType, text: string) => {
        setFieldTouched(field, true);
        validateField(field);
        setFieldValue(field, text);
    };

    useEffect(() => {
        const updatePasswordService = UpdatePasswordService.subscribe(((state: any) => {
            const { success: isSuccess } = state;
            if (isSuccess) {
                AppAlertService.showSuccess({
                    message: Languages.YourPasswordHasBeenUpdatedSuccessfully,
                });
                navigation.navigate('LoginScreen', {
                    loginParams: {
                        autoLogin: true,
                        username: values.email,
                        password: values.password,
                    },
                });
            }
        }));

        return () => {
            updatePasswordService.unsubscribe();
        };
    }, [values]);

    return (
        <View style={styles.containerGeneral}>
            <Text style={styles.resetText}>{Languages.ResetPassword}</Text>
            <View style={styles.instructionsContainer}>
                <Text style={styles.instructionsText}>{Languages.PleaseEnterAndConfirm}</Text>
            </View>

            <View style={styles.containerForm}>
                <TextInput
                    placeholder={Languages.EmailAddress}
                    value={values.email}
                    keyboardType='email-address'
                    editable={false}
                    autoCapitalize='none'
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ''}
                />
                <TextInput
                    placeholder={Languages.NewPassword}
                    value={values.password}
                    onChangeText={(text: string) => onChangeText(InputType.password, text)}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ''}
                    RightIcon={() => renderEyeIconPassword()}
                    autoCapitalize='none'
                    securityEntry={isShowPassword}
                    myRef={newPassTextRef}
                    onClickRight={handleShowPassword}
                />
                <TextInput
                    placeholder={Languages.ConfirmNewPassword}
                    value={values.confirmPassword}
                    onChangeText={(text: string) => onChangeText(InputType.confirmPassword, text)}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                    RightIcon={() => renderEyeIconConfirmPassword()}
                    autoCapitalize='none'
                    securityEntry={isShowConfirmPassword}
                    myRef={confirmNewPassTextRef}
                    onClickRight={handleShowConfirmPassword}
                />
                <AppButton
                    title={Languages.UpdatePassword}
                    disabled={Boolean(shouldBeActiveBtn())}
                    handleOnChange={submitForm}
                />
            </View>
        </View>
    );
};
