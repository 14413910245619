import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 31,
        fontWeight: '500',
        lineHeight: 37,
        marginBottom: 30,
        marginLeft: 17,
        marginTop: 25,
    },
    containerRow: {
        backgroundColor: 'white',
    },
    leftContainer: {
        width: '30%',
        height: 500,
        marginRight: 24,
    },
    rigthContainer: {
        backgroundColor: colors.white,
        borderRadius: 10,
        flexDirection: 'row',
        width: '100%',
        paddingRight: 52,
        marginLeft: 17,
        height: '100%',
    },
    containerImageRebate: {
        marginTop: 75,
    },
    continerHeader: {
        alignItems: 'center',
        backgroundColor: colors.greenBalance,
        borderRadius: 10,
        flexDirection: 'row',
        height: 166,
        justifyContent: 'space-around',
        marginLeft: 17,
        width: '100%',
        flex: 1,
    },
    containerEndDate: {
        alignItems: 'center',
        backgroundColor: colors.greenBalance,
        borderRadius: 10,
        flexDirection: 'row',
        minHeight: 106,
        justifyContent: 'space-around',
        marginLeft: 17,
        width: '100%',
        flex: 1,
        marginTop: 47,
    },
    contentPromotionRebate: {
        marginTop: 35,
    },
    textPromotionRebate: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '600',
    },
    descriptionRebatePromotion: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 12,
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 15,
    },
    containerGeneralDate: {
        alignItems: 'center',
        flexDirection: 'row',
        height: 39,
        justifyContent: 'space-between',
        marginTop: 8,
        width: '90%',
    },
    containerDate: {
        backgroundColor: 'white',
        borderRadius: 4,
        flexDirection: 'row',
        height: 40,
        justifyContent: 'space-between',
        width: '84%',
    },
    containerRowDate: {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '27%',
    },
    containerStartDate: {
        alignItems: 'center',
        width: '100%',
    },
    txtTitleDate: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 10,
        fontWeight: '400',
        lineHeight: 13.93,
    },
    txtDateValue: {
        color: colors.grayColor,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '700',
        lineHeight: 23,
    },
    containerTextDateValues: {
        alignItems: 'flex-start',
    },
    containerRowDateLines: {
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-between',
        width: '46%',
    },
    borderLine: {
        borderColor: colors.gray,
        borderLeftWidth: 1,
        height: '70%',
        width: '1%',
    },
    textStartDate: {
        color: colors.grayColor,
        fontFamily: fonts.SFProDisplay,
        fontSize: 22,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 26,
        marginTop: 7,
    },
    titleRigth: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontWeight: '500',
        fontSize: 24,
        lineHeight: 28,
        marginLeft: 8,
    },
    textQuestionHowWorks: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProDisplay,
        fontSize: 18,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 22,
        textAlign: 'center',
    },
    textSimpleQuality: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        textAlign: 'center',
    },
    containerHowWorks: {
        width: '70%',
    },
    containerInterestRebateOne: {
        width: '100%',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        marginTop: 16,
    },
    containerInterestRebateTwo: {
        width: '100%',
        alignItems: 'center',
    },
    textBoldCustom: {
        color: theme.colors.primary,
        fontWeight: '700',
    },
    containerStepOne: {
        flex: 2,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 28,
    },
    textStepOne: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginLeft: 28,
        textAlign: 'right',
        marginRight: 15,
    },
    containerIconCalendar: {
        bottom: 10,
        width: '80%',
        height: '80%',
    },
    containerStepTwo: {
        alignItems: 'center',
        backgroundColor: colors.stepGray,
        borderRadius: 10,
        flexDirection: 'row',
        height: 96,
        justifyContent: 'space-evenly',
        marginTop: 19,
        width: '100%',
    },
    containerStepTwoText: {
        flex: 1,
        alignItems: 'flex-start',
    },
    containerStepTwoIcon: {
        flex: 2,
        alignItems: 'flex-end',
    },
    textStepTwo: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginLeft: 12,
        textAlign: 'left',
    },
    textStepThree: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginLeft: 12,
    },
    containerStepthree: {
        flex: 2,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 37,
        marginTop: 16,
    },
    containerStepthreeText: {
        flex: 2,
    },
    containerStepthreeIcon: {
        flex: 1.5,
        alignItems: 'flex-end',
    },
    containerStepOneText: {
        flex: 2,
    },
    containerWalletIcon: {
        flex: 1.5,
        alignItems: 'flex-end',
    },
    textQuestionInteresting: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProDisplay,
        fontSize: 18,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 32,
        textAlign: 'center',
        width: 293,
    },
    textDescriptionRebate: {
        color: colors.grayLigth,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 16,
        width: 330,
    },
    containerImage: {
        height: '100%',
        marginBottom: 30,
        marginTop: 31,
        width: '100%',
        alignItems: 'center',
    },
    imageMoney: {
        height: '100%',
        width: '100%',
    },
    faqButton: {
        marginTop: 8,
        width: '100%',
    },
    faqButtonText: {
        fontSize: 17,
        fontFamily: fonts.SFProText,
        color: colors.white,
        fontWeight: '600',
    },
    faqContainer: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        marginBottom: 16,
        width: '100%',
    },
    faqContainerTitle: {
        fontSize: 15,
        fontFamily: fonts.SFProText,
        color: colors.grayLigth,
        fontWeight: '600',
    },
});
