import { PaymentStatementResponse } from '@Activity/interfaces';
import { getDocumentStatement, getPaymentStatements } from '@Activity/state/activityActions';
import { CloseIcon, EmptyActivityIcon, DownloadIcon, ViewIcon, ArrowLeftIcon } from '@Assets/index';
import { AppEmptyState } from '@Components/AppEmptyState';
import { useAppDispatch, useAppSelector } from '@Hooks/appHooks';
import React, {
    useEffect,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import { styles } from '@ActivityWeb/styles/ActivitiesStyles';
import Languages from '@i18n/index';
import { format } from 'date-fns';
import PaymentStatementsService from '@Activity/services/PaymentStatementService';
import { AppButton } from '@Components/AppButton';
import DocumentStatementsService from '@Activity/services/DocumentStatementsService';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import PdfScreenViewer from '@Pdf/components/PdfScreenViewer';
import { AppModal } from '@Components/AppModal';
import LoadingService from '@LoadingDialog/service/LoadingService';
import LoggerService from '@Logger/services/LoggerService';
import axios from 'axios';
import { parseDate } from '@Utils/FormatUtils';
import { PaymentStatementDocumentItemShimmer } from '@Activity/components/PaymentStatementDocumentItemShimmer';
import { useNavigation } from '@react-navigation/native';

export const ActivityStatementsWebScreen = () => {
    const dispatch = useAppDispatch();
    const { width: windowWidth } = useWindowDimensions();
    const [statements, setStataments] = useState<PaymentStatementResponse[]>([]);
    const [isLoadingStatements, setIsLoadingStatements] = useState(false);
    const [documentPdf, setDocumentPdf] = useState<string | undefined>(undefined);
    const [currentDocument, setCurrentDocument] = useState<any | undefined>(undefined);
    const navigation = useNavigation();
    const [shouldShowDocument, setShouldShowDocument] = useState(false);
    const isMobile = useCheckMobileScreen();
    const shouldShowPdfDialog = isMobile && window.location.href.indexOf('statements') > -1;
    const [downloadDocument, setDownloadDocument] = useState<boolean>(false);


    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useEffect(() => {
        setDocumentPdf(undefined);
        setCurrentDocument(undefined);
        if (!loanSelected) {
            return;
        }
        dispatch(getPaymentStatements({
            applicationId: loanSelected?.applicationId,
        }));
        setIsLoadingStatements(true);
    }, [loanSelected]);

    const handledOnPressItem = (item: any, id: number) => {
        if (isMobile) {
            LoadingService.show();
        }
        setDocumentPdf(undefined);
        setCurrentDocument(item);
        dispatch(getDocumentStatement({
            id,
        }));
    };

    const triggerDownload = (item: any) => {
        LoadingService.show();
        setDownloadDocument(true);
        dispatch(getDocumentStatement({
            id: item.id,
        }));
    }

    const downloadPDF = (doc: any) => {
        LoggerService.logEvent('downloadPdf', {});
        const date = parseDate(new Date().toISOString(), 'yyyy_MM_dd_sss');
        axios({
            url: `data:application/pdf;base64,${doc}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${date}.pdf`);
            document.body.appendChild(link);
            link.click();
            setDownloadDocument(false);
            LoadingService.hide();
        });
    };

    const resetDetails = () => {
        setCurrentDocument(undefined);
        setDocumentPdf(undefined);
    };

    const handleOnCloseModal = () => setShouldShowDocument(false);

    useEffect(() => {
        const statementSubscriber = PaymentStatementsService.subscribe((data: PaymentStatementResponse[]) => {
            setStataments(data);
            setIsLoadingStatements(false);
        });
        const documentStatementSubscriber = DocumentStatementsService.subscribe((data: string) => {
            setDocumentPdf(data);
            if (downloadDocument) {
                downloadPDF(data);
            }
            if (isMobile) {
                setShouldShowDocument(true);
            }
        });
        return () => {
            statementSubscriber.unsubscribe();
            documentStatementSubscriber.unsubscribe();
        };
    }, [loanSelected, documentPdf, downloadDocument]);

    useEffect(() => {
        if (isMobile && shouldShowDocument) {
            <AppModal
                animationType='fade'
                transparent
                visible={shouldShowDocument}
            >
                <View style={[styles.containerModal]}>
                    <View style={[styles.containerModalPdf]}>
                        <PdfScreenViewer
                            base64={documentPdf}
                            alignButton={true}
                        />
                    </View>
                    <AppButton
                        accessibilityLabel={'statementsAppButtonClose'}
                        buttonStyle={[styles.containerModalButton]}
                        handleOnChange={handleOnCloseModal}
                        testID={'statementsAppButtonClose'}
                        textStyle={[styles.containerModalButtonText]}
                        title={Languages.Close}
                    />
                </View>
            </AppModal>;
        } else {
            <View style={[]}>
                <PdfScreenViewer base64={documentPdf} />
            </View>;
        }
    }, [shouldShowPdfDialog]);


    const handleResponsive = () => {
        return StyleSheet.create({
            mainContainer: {
                flexDirection: windowWidth < 968 ? 'column' : 'row',
            },
            card: {
                marginHorizontal: 10,
                width: windowWidth < 968 ? '100%' : '49%',
                maxWidth: windowWidth < 968 ? '100%' : 700,
            }
        });
    };

    const handleRenderWebDocument = () => {
        return (
            <View style={[styles.activityCard, handleResponsive().card, StyleSheet.create({ item: { marginTop: 20, height: 500, overflow: 'hidden' }}).item]}>
                <View style={styles.activityCardTitle}>
                    <Text style={styles.activityCardTitleText}>
                        {`${Languages.MonthlyStatement} - ${format(new Date(currentDocument.timestamp), 'MMMM yyyy')}`}
                    </Text>
                    <TouchableOpacity
                        accessibilityLabel={`activitiesStatementViewCloseTouchable`}
                        onPress={resetDetails}
                        testID={`activitiesStatementViewCloseTouchable`}
                    >
                        <CloseIcon />
                    </TouchableOpacity>
                </View>
                <View style={[styles.activityCardBody, StyleSheet.create({ item: { marginTop: 20, height: 500, overflow: 'hidden' }}).item]}>
                    <PdfScreenViewer base64={documentPdf} />
                </View>
            </View>
        );
    };

    const handleRenderMobileDocument = () => {
        return (
            <AppModal
                animationType='fade'
                transparent
                visible={shouldShowDocument}
            >
                <View style={[styles.containerModal]}>
                    <View style={[styles.containerModalPdf]}>
                        <PdfScreenViewer
                            base64={documentPdf}
                            alignButton={true}
                        />
                    </View>
                    <AppButton
                        accessibilityLabel={'statementsAppButtonClose'}
                        buttonStyle={[styles.containerModalButton]}
                        handleOnChange={handleOnCloseModal}
                        testID={'statementsAppButtonClose'}
                        textStyle={[styles.containerModalButtonText]}
                        title={Languages.Close}
                    />
                </View>
            </AppModal>
        );
    };

    const handleRenderDocument = () => {
        return shouldShowPdfDialog ? handleRenderMobileDocument() : handleRenderWebDocument();
    }

    const handleRenderItem = ({ item, index }: any) => (
        <View style={styles.activityItemAmount}>
            <View style={[styles.activityItem, StyleSheet.create({ item: { borderTopWidth: index === 0 ? 0 : 1 }}).item]}>
                <View style={styles.activityItemLeft}>
                    <Text style={styles.activityItemTitle}>{Languages.MonthlyStatement}</Text>
                    <Text style={styles.activityItemMetaData}>{format(new Date(item.timestamp), 'MMM dd, yyyy')}</Text>
                    <Text style={styles.activityItemMetaData}>
                    </Text>
                </View>
                <View style={[styles.activityItemRight, StyleSheet.create({ item: {}}).item]}>
                    <TouchableOpacity
                        accessibilityLabel={`activitiesStatementViewCloseTouchable`}
                        onPress={() => triggerDownload(item)}
                        testID={`activitiesStatementViewCloseTouchable`}
                        style={[styles.buttonIcon]}
                    >
                        <DownloadIcon />
                        <Text style={[styles.buttonIconText]}>
                            {Languages.Download}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );

    return (<>{statements.length > 0 ?

        <View style={[styles.activitiesContainer, styles.allContainer]}>
            <View style={[styles.activityCard, handleResponsive().card]}>
                <View style={[styles.activityCardTitle]}>
                    <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row'}}).item]}>
                        <TouchableOpacity onPress={() => navigation.navigate('Activity')}>
                            <ArrowLeftIcon />
                        </TouchableOpacity>
                        <Text style={[styles.activityCardTitleText, StyleSheet.create({ item: { marginLeft: 10 }}).item]}>{Languages.Statements}</Text>
                    </View>
                </View>
                <View style={[styles.activityCardBody]}>
                    <FlatList
                        data={statements}
                        initialNumToRender={4}
                        keyExtractor={(_: any, index: number) => index.toString()}
                        removeClippedSubviews={Platform.OS === 'android'}
                        renderItem={handleRenderItem}
                        showsVerticalScrollIndicator={true}
                        style={[styles.itemList, StyleSheet.create({ item: { maxHeight: 500 }}).item]}
                        onEndReachedThreshold={0.4}
                    />
                </View>
            </View>
            {currentDocument ? handleRenderDocument() : null}
        </View>
    :
        <View style={styles.emptyStateContainer}>
            { isLoadingStatements ? 
                <PaymentStatementDocumentItemShimmer /> : 
                <AppEmptyState
                    accessibilityLabel={'paymentActivityAppEmptyStateStatements'}
                    Icon={EmptyActivityIcon}
                    subTitle={Languages.NoPrevious}
                    testID={'paymentActivityAppEmptyStateStatements'}
                    title={Languages.Statements}
                    content=''
                />
            }
        </View>
    }</>
);
};
