import React from 'react';

import {
    Platform,
    StyleProp,
    StyleSheet,
    Text,
    View,
    ViewStyle,
} from 'react-native';
import RNPickerSelect from 'react-native-picker-select';

import { ArrowDownIcon } from '@Assets/index';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

interface Props {
    data: any[];
    disabled: boolean;
    error?: string;
    onChange: (value: any) => void;
    pickerContainer?: StyleProp<ViewStyle>;
    placeholder: string;
    value?: any;
};

export const AppDropdown = ({
    data,
    disabled,
    error,
    onChange,
    placeholder,
    value,
}: Props) => {
    const renderIcon = () => (
        <View style={styles.iconContainer}>
            <ArrowDownIcon />
        </View>
    );
    const isWeb = Platform.OS === 'web';

    return (
        <View style={isWeb ? styles.containerWeb : styles.container}>
            <RNPickerSelect
                accessibilityLabel={'appDropdownRNPickerSelect'}
                disabled={disabled}
                fixAndroidTouchableBug
                Icon={renderIcon}
                items={data}
                onValueChange={onChange}
                value={value}
                placeholder={{
                    key: 'unique',
                    inputLabel: placeholder,
                    label: placeholder,
                    color: colors.darkGray,
                }}
                style={{
                    inputAndroid: {
                        color: colors.grayDarkColor,
                    },
                    inputWeb: {
                        height: 45,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: theme.colors.border,
                        color: colors.darkGray,
                        fontFamily: fonts.SFProText,
                        paddingLeft: 8,
                        WebkitAppearance: 'none',
                        MozAppearance: 'none',
                        appearance: 'none',
                        backgroundColor: disabled ? colors.grayDisabled : colors.whiteLigth
                    },
                    inputIOS: {
                        color: colors.grayDarkColor,
                    },
                    viewContainer: {
                        ...styles.pickerStyle,
                        borderColor: error?.length ? colors.red : colors.darkGray,
                        marginBottom: error?.length ? 0 : 20,
                        justifyContent: 'center',
                        backgroundColor: disabled ? colors.grayDisabled : colors.whiteLigth
                    },
                    chevronDown: {
                        display: 'none',
                    },
                    chevronUp: {
                        display: 'none',
                    },
                }}
                testID={'appDropdownRNPickerSelect'}
            />
            <Text style={[styles.textError, { display: error?.length ? 'flex' : 'none' }]}>{error}</Text>
        </View>
    );
};

export const styles = StyleSheet.create({
    pickerStyle: {
        height: 45,
        paddingHorizontal: '5%',
        ...Platform.select({
            android: {
                paddingHorizontal: 5,
            },
        }),
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 5
    },
    iconContainer: {
        marginTop: 4,
        marginRight: 16,
        ...Platform.select({
            android: {
                marginRight: 16,
            },
        }),
    },
    container: {
        width: '90%',
        marginTop: 8,
    },
    containerWeb: {
        width: '90%',
        paddingTop: 16,
    },
    textError: {
        color: colors.red,
        fontSize: 10,
        marginTop: 2,
        marginHorizontal: 16,
        marginBottom: 15,
    },
});
