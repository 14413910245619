import { Subject } from 'rxjs';

export interface ClaimProfile {
    errorCode: number;
    subtitle?: string | string[];
    success: boolean;
    title?: string;
    titleBottomSection?: string;
    subTitleBottomSection?: string;
}

const ClaimProfileService = () => {
    const subject = new Subject();

    const onSuccess = (response: ClaimProfile) => {
        subject.next(response);
    };

    const onError = (response: ClaimProfile) => {
        subject.next(response);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onError,
        onSuccess,
        subscribe,
    };

};

export default ClaimProfileService();
