import React, {
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Row,
} from 'react-bootstrap';
import {
    ScrollView,
    Text,
    View,
} from 'react-native';

import {
    CalenderIRPRightIcon,
    DateIcon,
    DocumentSign,
    HandPhoneMoney,
    ImageRebate,
    IRPIcon,
} from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { styles } from '@Home/styles/ProgramRebateScreenWebStyle';
import Languages from '@i18n/index';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { linksApp } from '@Utils/index';

interface Props extends StackScreenProps<RootStackParamList, 'ProgramRebateScreen'> { }

interface DateProgramRebate {
    day: string;
    month: string;
    year: string;
}

export const ProgramRebateScreen = ({
    route,
    navigation,
}: Props) => {
    const [date, setDate] = useState<DateProgramRebate>({
        day: '',
        month: '',
        year: '',
    });

    const TextBold = (data: any) => <Text  {...data} style={styles.textBoldCustom}>{data.children}</Text>;

    useEffect(() => {
        if (route.params.date) {
            const divideDate = route.params.date.split('/');
            const formatDate: DateProgramRebate = {
                day: divideDate[0].split(',')[0],
                month: divideDate[1],
                year: divideDate[2],
            };
            setDate(formatDate);
        }
    }, []);

    const handleTapTermsAndConditions = () => window.open(linksApp.termsAndConditionsFCCP, '_blank');

    return (
        <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
            <Text style={styles.title}>{Languages.Home}</Text>
            <Row>
                <Col xs={11} sm={11} md={11} lg={4} xl={4} style={{ maxHeight: 350, marginBottom: 20 }}>
                    <View style={styles.continerHeader}>
                        <View style={styles.contentPromotionRebate}>
                            <Text style={styles.textPromotionRebate}>
                                {Languages.DaysRebatePromotion}
                            </Text>
                        </View>
                        <View style={styles.containerImageRebate}>
                            <ImageRebate />
                        </View>
                    </View>
                    <View style={styles.containerEndDate}>
                        <View style={styles.containerStartDate}>
                            <Text style={styles.textStartDate} >{Languages.EndDate}</Text>

                            <View style={styles.containerGeneralDate}>
                                <DateIcon />
                                <View style={styles.containerDate}>
                                    <View style={styles.containerRowDate}>
                                        <View style={styles.containerTextDateValues}>
                                            <Text style={styles.txtTitleDate}>{Languages.Day}</Text>
                                            <Text style={styles.txtDateValue} >{date.day}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.containerRowDateLines}>
                                        <View style={styles.borderLine} />
                                        <View style={styles.containerTextDateValues}>
                                            <Text style={styles.txtTitleDate}>{Languages.Month}</Text>
                                            <Text style={styles.txtDateValue} >{date.month}</Text>
                                        </View>
                                        <View style={styles.borderLine} />
                                    </View>
                                    <View style={styles.containerRowDate}>
                                        <View style={styles.containerTextDateValues}>
                                            <Text style={styles.txtTitleDate}>{Languages.Year}</Text>
                                            <Text style={styles.txtDateValue} >{date.year}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Col>
                <Col
                    xs={11}
                    sm={11}
                    md={11}
                    lg={7}
                    xl={7}
                    style={{
                        backgroundColor: 'white',
                        marginLeft: 24,
                        borderRadius: 10,
                        marginBottom: 50,
                    }} >
                    <Row>
                        <Col xs={12} sm={12} lg={6}>
                            <View style={styles.containerInterestRebateOne}>
                                <View style={styles.containerHowWorks}>
                                    <Text style={styles.textQuestionHowWorks}>{Languages.HowDoesItWork}</Text>
                                    <Text style={styles.textSimpleQuality}>
                                        {Languages.SimpleToQualifyYouHaveTo}
                                    </Text>
                                </View>
                            </View>
                            <Row className='flex-row align-items-center justify-content-between'>
                                <Col>
                                    <View style={styles.containerStepOne}>
                                        <View style={styles.containerStepOneText}>
                                            <Text style={styles.textStepThree}>
                                                {Languages.PaytheTotalAmountFinanced}
                                            </Text>
                                        </View>
                                        <View style={styles.containerWalletIcon}>
                                            <HandPhoneMoney />
                                        </View>
                                    </View>
                                </Col>
                            </Row>
                            <Row className='flex-row align-items-center justify-content-between'>
                                <Col>
                                    <View style={styles.containerStepTwo}>
                                        <View style={styles.containerStepTwoText}>
                                            <CalenderIRPRightIcon />
                                        </View>
                                        <View style={styles.containerStepTwoIcon}>
                                            <Text style={styles.textStepOne}>
                                                {Languages.HaveNoPaymentOver30DaysPastDue}
                                            </Text>
                                        </View>
                                    </View>
                                </Col>
                            </Row>
                            <Row className='flex-row align-items-center justify-content-between'>
                                <Col>
                                    <View style={styles.containerStepthree}>
                                        <View style={styles.containerStepthreeText}>
                                            <Text style={styles.textStepTwo}>
                                                {Languages.NotOtherwiseBeIn}
                                            </Text>
                                        </View>
                                        <View style={styles.containerStepthreeIcon}>
                                            <DocumentSign />
                                        </View>
                                    </View>
                                </Col>
                            </Row>
                            <Row className='flex-row align-items-center justify-content-between'>
                                <Col>
                                    <View style={styles.faqContainer}>
                                        <Text style={styles.faqContainerTitle}>
                                            {`${Languages.ToReadOur} `}
                                            <TextBold>{Languages.FAQs}</TextBold>
                                            {` ${Languages.OnThe} `}
                                            <TextBold>{Languages.FCCP}</TextBold>
                                        </Text>
                                        <AppButton
                                            accessibilityLabel={'programRebateAppButtonFAQ'}
                                            buttonStyle={styles.faqButton}
                                            handleOnChange={() => navigation.navigate('FaqScreen', {})}
                                            testID={'programRebateAppButtonFAQ'}
                                            textStyle={styles.faqButtonText}
                                            title={Languages.ClickHere}
                                        />
                                    </View>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <View style={styles.containerInterestRebateTwo}>
                                <Text style={styles.textQuestionInteresting}> {Languages.IsInterestAccruing}</Text>
                                <Text style={styles.textDescriptionRebate}>
                                    {Languages.DescriptionRebate}
                                    <TextBold
                                        accessibilityLabel={'programRebateTextTermsAndConditions'}
                                        onPress={handleTapTermsAndConditions}
                                        testID={'programRebateTextTermsAndConditions'}
                                    >{` ${Languages.TermsAndConditions}`}</TextBold>
                                </Text>
                                <View style={styles.containerImage}>
                                    <IRPIcon />
                                </View>
                            </View>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ScrollView>
    );
};

