import { createAction } from 'typesafe-actions';

import {
    PostConfirmAccountRequest,
    PostRegisterEmailBusinessCenterRequest,
    PostRegisterEmailBusinessCenterValidateRequest,
    PostRegisterVerificationCodeResendRequest,
    RegisterByEmailRequest,
    RegisterRequest,
    ValidateVerificationCodeRequest,
    VerificationCodeRequest,
} from '@Auth/interfaces';
import RegisterActionTypes from '@Auth/state/registerActionTypes';

export const registerAction = createAction(
    RegisterActionTypes.REGISTER_REQUEST,
    (request: RegisterRequest) => (request),
)();

export const registerByEmailAction = createAction(
    RegisterActionTypes.REGISTER_EMAIL_REQUEST,
    (request: RegisterByEmailRequest) => (request),
)();

export const registerValidateVerificationCodeAction = createAction(
    RegisterActionTypes.VALIDATE_VERIFICATION_CODE_REQUEST,
    (request: ValidateVerificationCodeRequest) => (request),
)();

export const registerError = createAction(
    RegisterActionTypes.REGISTER_ERROR,
)();

export const registerSuccess = createAction(
    RegisterActionTypes.REGISTER_SUCCESS,
)();

export const registerVerificationCodeAction = createAction(
    RegisterActionTypes.REGISTER_VERIFICATION_CODE_REQUEST,
    (request: VerificationCodeRequest) => (request),
)();

export const confirmAccountAction = createAction(
    RegisterActionTypes.CONFIRM_ACCOUNT_REQUEST,
    (request: PostConfirmAccountRequest) => (request),
)();

export const registerByEmailBusinessCenterAction = createAction(
    RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_REQUEST,
    (request: PostRegisterEmailBusinessCenterRequest) => (request),
)();

export const registerByEmailBusinessCenterValidateAction = createAction(
    RegisterActionTypes.REGISTER_EMAIL_BUSINESS_CENTER_VALIDATE_REQUEST,
    (request: PostRegisterEmailBusinessCenterValidateRequest) => (request),
)();

export const registerVerificationCodeResendAction = createAction(
    RegisterActionTypes.REGISTER_VERIFICATION_CODE_RESEND_REQUEST,
    (request: PostRegisterVerificationCodeResendRequest) => (request),
)();
