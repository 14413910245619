enum ForgotPasswordActionsTypes {
    FORGOT_PASSWORD_CLEAR = '@forgotPassword/CLEAR',
    SEND_CODE_EMAIL_ERROR = '@forgotPassword/SEND_CODE_EMAIL_ERROR',
    SEND_CODE_EMAIL_REQUEST = '@forgotPassword/SEND_CODE_EMAIL_REQUEST',
    SEND_CODE_EMAIL_SUCCESS = '@forgotPassword/SEND_CODE_EMAIL_SUCCESS',
    SEND_CODE_PHONE_ERROR = '@forgotPassword/SEND_CODE_PHONE_ERROR',
    SEND_CODE_PHONE_REQUEST = '@forgotPassword/SEND_CODE_PHONE_REQUEST',
    SEND_CODE_PHONE_SUCCESS = '@forgotPassword/SEND_CODE_PHONE_SUCCESS',
    UPDATE_PASSWORD_EMAIL_REQUEST = '@forgotPassword/UPDATE_PASSWORD_EMAIL_REQUEST',
    UPDATE_PASSWORD_PHONE_REQUEST = '@forgotPassword/UPDATE_PASSWORD_PHONE_REQUEST',
};

export default ForgotPasswordActionsTypes;
