import { Subject } from 'rxjs';

import { Error } from '@Interfaces/BaseResponse';

export interface AuthService {
    isFounded?: boolean;
    isSessionExpired?: boolean;
    success: boolean;
};

const AuthenticateService = () => {
    const subject = new Subject();

    const onSuccess = (response: AuthService) => {
        subject.next(response);
    };

    const onError = (error: Error) => {
        subject.next(error);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onError,
        onSuccess,
        subscribe,
    };

};

export default AuthenticateService();
