import { CheckCircleSvg } from "@Assets/index";
import { styles } from "@MakeAPayment/styles/MakeAPaymentStyles";
import { PaymentMethodResponseBase } from "@MethodsOfPayment/interfaces";
import { theme } from "@Theme/appTheme";
import React from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { Icon } from '@Components/Icon';

interface Props {
    item: PaymentMethodResponseBase;
    isSelected: boolean;
    handleonPress: (item: PaymentMethodResponseBase) => void;
}

export const PaymentMethodItem = ({
    item,
    isSelected,
    handleonPress
}: Props) => {
    const iconName = item?.paymentNetwork == 'Discover' ? 'DiscoverImage' : item?.paymentNetwork;
    return (
        <TouchableOpacity
            style={[styles.containerItem, isSelected && styles.outlineContainerItem]}
            disabled={false}
            accessibilityLabel={`paymentMethodCardTouchableDrag${item.id}`}
            testID={`paymentMethodCardTouchableDrag${item.id}`}
            onPress={() => handleonPress(item)}
        >
            <View style={[styles.paymentMethodItem, styles.containerNumberLogoPayment]}>
                <Icon name={iconName} />
            </View>
            <View style={[styles.paymentMethodItem, styles.containerNikenameNote]}>
                <Text style={styles.nickname}>{item?.nickname}</Text>
            </View>
            <View style={[styles.paymentMethodItem, styles.containerFourDigits]}>
                <Text style={styles.lastFourDigit}>{`* ${item?.lastFourDigit}`}</Text>
                <Text style={styles.expirationDateLabel}>{item?.expirationDate}</Text>
            </View>
            <View style={[styles.containerSelectedPaymentMethod]}>
                {
                    isSelected ? <CheckCircleSvg fill={theme.colors.primary} style={styles.selectedPaymentCheckMark} /> : null
                }
            </View>
        </TouchableOpacity>
    );
};