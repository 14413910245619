import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { PaymentActivityDetail } from '@Activity/interfaces/index';
import * as activityActions from '@Activity/state/activityActions';

type ActionsType = ActionType<typeof activityActions>;

interface ActivityState {
    paymentActivityDetails: {
        [key: number]: PaymentActivityDetail[];
    };
    status?: string;
};

export const initialState = {
    paymentActivityDetails: {},
};

export const activityReducer = createReducer<ActivityState, ActionsType>(
    initialState,
).handleAction(activityActions.savePaymentActivityAction, (state, { payload }) => ({
    ...state,
    paymentActivityDetails: {
        ...state.paymentActivityDetails,
        [payload.id]: payload.details,
    },
    status: payload.status ?? '',
}));
