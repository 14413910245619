import React, { useState } from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { ApplicationsModal } from '@ApplicationsModal/screens/ApplicationsModal.web';
import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import Languages from '@i18n/index';

import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';
import { useAppSelector } from '@Hooks/appHooks';
import { useEffect } from 'react';
import { SelectableApplication } from '@Profile/interfaces';
import { DisplayCloseIcon } from '@Assets/index';

export const SwitchAccountsComponent = () => {
    const isMobile = useCheckMobileScreen();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState<null | SelectableApplication>(null);

    const hideMenu = () => setIsVisible(false);

    const showMenu = () => {
        if (selectableApplications && selectableApplications.length > 1) {
            setIsVisible(true);
        }
    };

    const { selectableApplications } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    
    useEffect(() => {
        const selectedAccount = selectableApplications.find(app => app.isSelected);
        setSelectedAccount(selectedAccount ? selectedAccount : null);
    }, [selectableApplications])

    return (
        <>
            <View style={styles.accOptionsContainer}>
                <AppButton
                    accessibilityLabel={'switchAccountsAppButtonShowMenu'}
                    buttonStyle={[
                        styles.button,
                        isMobile && { minWidth: 150 },
                    ]}
                    handleOnChange={showMenu}
                    testID={'switchAccountsAppButtonShowMenu'}
                    textStyle={styles.buttonText}
                    title={<View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px' }}>
                        <Text style={{ fontFamily: fonts.SFProDisplaySemibold, textTransform: 'uppercase' }}>{Languages.AccountOnly} {selectedAccount?.applicationId}</Text>
                        { (selectableApplications && selectableApplications.length > 1) ? <DisplayCloseIcon style={{marginLeft: '5px'}}></DisplayCloseIcon> : null }
                    </View>}
                />
            </View>
            <AppModal
                animationType='fade'
                transparent
                visible={isVisible}
                closeModal={hideMenu}
            >
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                        height: 400,
                        top: '10%',
                        right: '1%',
                        position: 'absolute',
                    }}
                >
                    <ApplicationsModal
                        forceToClose={hideMenu}
                    />
                </ScrollView>
            </AppModal >
        </>
    );
};

const styles = StyleSheet.create({
    button: {
        backgroundColor: colors.white,
        borderColor: theme.colors.primary,
        borderWidth: 2,
        flex: 1,
        minWidth: 203,
        paddingVertical: 2,
    },
    buttonText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProTextSemibold,
        lineHeight: 22,
    },
    accOptionsColor: {
        color: theme.colors.border,
        gap: '3px'
    },
    menuContainer: {
        ...globalStyle.dropShadow,
        borderRadius: 10,
        width: 350,
    },
    accOptionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    }
});
