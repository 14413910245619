import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import * as MethodsOfPaymentActions from '@MethodsOfPayment/state/MethodsOfPaymentActions';

type ActionsTypes = ActionType<typeof MethodsOfPaymentActions>;
type Initial = {
    [id: string]: PaymentMethodResponseBase;
};

const initialState: Initial = {};

export const methodsOfPaymentDictReducer = createReducer<Initial, ActionsTypes>(
    initialState
).handleAction(
    MethodsOfPaymentActions.loadMethodsOfPayment,
    (_: any, { payload }) => payload.reduce((acc: any, curr: any) => ({
        ...acc,
        [curr.id]: curr,
    }), {}),
).handleAction(
    MethodsOfPaymentActions.clearMethodsOfPayments,
    () => initialState,
).handleAction(
    MethodsOfPaymentActions.unselectAllMethodsOfPayments,
    (state: any) => {
        const newState: any = {};
        Object.keys(state).forEach(key => newState[key] = {...state[key], selected: false});
            return {
                ...newState,
            };
    },
).handleAction(
    MethodsOfPaymentActions.setMethodOfPaymentSelected,
    (state: any, { payload }) => {
        const { id, value, clear } = payload;

        if (clear) {
            const newState: any = {};

            Object.keys((key: string) => {
                newState[key] = {
                    ...state[key],
                    selected: false,
                }
            })
            return {
                ...newState,
            };
        }
        const subject = {
            ...state[id],
            selected: value,
        };

        const updatedState = {
            ...state,
            [subject.id]: subject,
        };

        return updatedState;
    }
);
