import {
    useEffect,
    useState,
} from 'react';

import { fromEvent } from 'rxjs';
import {
    filter,
    map,
} from 'rxjs/operators';

type EventCodeTypes = 'Enter';

export interface KeyboardEventObject {
    event?: string;
    triggered?: boolean;
    screen?: string;
};

const useKeyboardEvent = (eventCode: EventCodeTypes, screen: string): KeyboardEventObject => {
    const [keyboardEvent, setKeyboardEvent] = useState<KeyboardEventObject>();

    useEffect(() => {
        const keyup = fromEvent<KeyboardEvent>(document, 'keyup').pipe(
            map(evt => evt.code),
            filter(code => code === eventCode),
        ).subscribe((value: string) => setKeyboardEvent({
            event: value,
            triggered: value === eventCode,
            screen,
        }));
        return () => {
            keyup.unsubscribe();
        };
    }, []);
    return {
        ...keyboardEvent,
    };
};

export default useKeyboardEvent;
