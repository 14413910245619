import { createAction } from 'typesafe-actions';

import {
    GetPaymentStatusRequest,
    MakeAPaymentRequest,
    SaveAPaymentRequest,
    ShouldShowPaymentWarning,
} from '@MakeAPayment/interfaces';
import MakeAPaymentActionsTypes from '@MakeAPayment/state/MakeAPaymentActionsTypes';

export const getCurrentPaymentAction = createAction(
    MakeAPaymentActionsTypes.GET_CURRENT_PAYMENT_REQUEST,
    (request: MakeAPaymentRequest) => (request),
)();

export const getCurrentPaymentSuccessAction = createAction(
    MakeAPaymentActionsTypes.GET_CURRENT_PAYMENT_SUCCESS,
)();

export const getCurrentPaymentErrorAction = createAction(
    MakeAPaymentActionsTypes.GET_CURRENT_PAYMENT_ERROR,
)();

export const saveAPaymentScheduleRequestAction = createAction(
    MakeAPaymentActionsTypes.SAVE_NEW_PAYMENTH_SCHEDULE_REQUEST,
    (request: SaveAPaymentRequest) => (request)
)();

export const shouldShowPaymentWarningAction = createAction(
    MakeAPaymentActionsTypes.SHOULD_SHOW_PAYMENT_WARNING,
    (response: ShouldShowPaymentWarning) => (response),
)();

export const getPaymentStatusAction = createAction(
    MakeAPaymentActionsTypes.GET_PAYMENT_STATUS_REQUEST,
    (request: GetPaymentStatusRequest) => (request),
)();

export const resetLeftMenu = createAction(
    MakeAPaymentActionsTypes.RESET_LEFT_MENU,
    (request: boolean) => (request),
)();

export const clearPaymentStatusAction = createAction(
    MakeAPaymentActionsTypes.CLEAR_PAYMENT_STATUS_REQUEST,
)();
