import React, {
    useEffect,
    useState,
} from 'react';

import {
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
    FlatList,
    Platform,
} from 'react-native';

import {
    Entity,
    PaymentActivity,
} from '@Activity/interfaces';
import PaymentActivityService from '@Activity/services/PaymentActivityService';
import { getPaymentActivities } from '@Activity/state/activityActions';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { useNavigation } from '@react-navigation/native';
import {
    colors,
    globalStyle,
} from '@Theme/appTheme';
import { styles as cardStyles } from '@ActivityWeb/styles/ActivitiesStyles';

import { ArrowRightIcon } from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { format } from 'date-fns';

export const PaymentActivityWidget = () => {
    const dispatch = useAppDispatch();
    const navigation = useNavigation();
    const [entities, setEntities] = useState<Entity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(6);

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    const handleResponsive = () => {
        return StyleSheet.create({
            mainContainer: {
                flexDirection: 'column',
                alignItems: 'center',
            },
            card: {
                width: '100%',
                marginVertical: 30
            }
        });
    };

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        setIsLoading(true);
        dispatch(getPaymentActivities({
            loanId: loanSelected?.loanId,
            page: 1,
            pageSize,
            source: 'widget',
        }));
    }, [loanSelected, pageSize]);

    useEffect(() => {
        const subscription = PaymentActivityService.subscribe((state: PaymentActivity) => {
            const { entities: newEntities, source } = state;
            if (source === 'widget') {
                setEntities(newEntities.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1));
                if (newEntities.length < 6 && pageSize !== 12) {
                    setPageSize(pageSize * 2);
                }
            }
            setIsLoading(false);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [entities]);

    const handleOnViewMore = () => {
        LoggerService.logEvent('viewMorePaymentActivityWidget', {});
        navigation.navigate('Activity');
    };

    const handleViewAll = () => {
        return navigation.navigate('ActivityAll');
    };

    const handleRenderItem = ({ item, index }: any) => (
        <View>
            <View style={[cardStyles.activityItem, StyleSheet.create({ item: { borderTopWidth: index === 0 ? 0 : 1 }}).item]}>
                <View style={cardStyles.activityItemLeft}>
                    <Text style={cardStyles.activityItemTitle}>{item.title}</Text>
                    <Text style={cardStyles.activityItemMetaData}>{format(new Date(item.dateString), 'MMM dd, yyyy')}</Text>
                    <Text style={cardStyles.activityItemMetaData}>
                        { Languages.PaymentTypeDetails.replace('{type}', item.paymentType || '').replace('{lastFour}', item.paymentLastFourDigit || 'XXXX')}
                    </Text>
                </View>
                <View style={cardStyles.activityItemRight}>
                    <Text style={[cardStyles.activityAmount, StyleSheet.create({ item: { color: item.paymentAmountColor, marginRight: 10 }}).item ]}>{item.paymentAmount}</Text>
                </View>
            </View>
        </View>
    );

    return (
        <View style={[cardStyles.activityCard, handleResponsive().card]}>
            <TouchableOpacity
                accessibilityLabel={`activitiesViewAllRecentTransactionsTouchable`}
                onPress={handleViewAll}
                testID={`activitiesViewAllRecentTransactionsTouchable`}
            >
            <View style={[cardStyles.activityCardTitle, StyleSheet.create({ item: { borderBottomWidth: entities.length > 0 ? 1 : 0 }}).item]}>
                <Text style={cardStyles.activityCardTitleText}>{Languages.RecentTransactions}</Text>
                {
                    entities.length > 0 ? (
                            <ArrowRightIcon />
                            ) : (
                                <></>
                                )
                            }
            </View>
            </TouchableOpacity>
            <View style={cardStyles.activityCardBody}>
                {
                    entities.length > 0 ? (
                        <>
                            <FlatList
                                data={entities}
                                initialNumToRender={10}
                                keyExtractor={(_: Entity, index: number) => index.toString()}
                                removeClippedSubviews={Platform.OS === 'android'}
                                renderItem={handleRenderItem}
                                showsVerticalScrollIndicator={false}
                                // style={styles.list}
                                onEndReachedThreshold={0.4}
                            />
                            <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.white, borderTopWidth: 1, borderTopColor: colors.veryLightGray3 }}).item]}>
                                <AppButton 
                                    accessibilityLabel={'pendingAppButtonCalendar'}
                                    buttonStyle={[cardStyles.goBackBtn, StyleSheet.create({ item: { marginTop: 0, fontSize: 12 }}).item]}
                                    handleOnChange={() => handleViewAll()}
                                    testID={'pendingAppButtonCalendar'}
                                    textStyle={[cardStyles.goBackBtnTitle, StyleSheet.create({ item: { fontSize: 16 }}).item]}
                                    title={Languages.ViewAllTransactions}></AppButton>
                            </View>
                        </>
                    ) : (
                        <View style={[cardStyles.gridEmptyRow]}>
                            <Text style={cardStyles.gridEmptyRowText}>
                                {Languages.YouHaveNoRecentTransactions}
                            </Text>
                        </View>
                    )
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    bodyContainer: {
        ...globalStyle.dropShadow,
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 350,
        flex: 1,
    },
    headerShimmer: {
        width: '100%',
        height: 50,
    },
    listActitivies: {
        height: 350,
        width: '100%',
    },
});
