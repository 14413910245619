import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { RegisterState } from '@Auth/interfaces';
import * as registerActions from '@Auth/state/registerActions';

type ActionsType = ActionType<typeof registerActions>;

export const initialState: RegisterState = {};

export const registerReducer = createReducer<RegisterState, ActionsType>(
    initialState,
).handleAction(registerActions.registerAction, () => ({})
).handleAction(registerActions.registerVerificationCodeAction, () => ({}));
