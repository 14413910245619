import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { Menu } from 'react-native-material-menu';

import {
    DisplayCloseSvg,
    EasyPayLogoCompleteWhiteSvg,
    LogOutIcon,
    ProfileTabIconSvg,
} from '@Assets/index';
import { authenticateLogoutAction } from '@Auth/state/authenticateActions';
import { NotificationToolbarIcon } from '@Components/NotificationToolbarIcon';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';
import Languages from '@i18n/index';
import LoadingService from '@LoadingDialog/service/LoadingService';
import LoggerService from '@Logger/services/LoggerService';
import { navigate } from '@Navigation/RootNavigation';
import { NotificationScreen } from '@Notifications/screens/NotificationScreen.web';
import { getUserInfoAction } from '@Profile/state/profileActions';
import {
    colors,
    fonts,
    globalStyle,
    t,
    theme,
} from '@Theme/appTheme';

interface Props {
    context?: 'ClaimAccount' | 'OnBoarding' | 'default' | 'ExpireLink';
};

export const Toolbar = ({ context = 'default' }: Props) => {
    const dispatch = useAppDispatch();
    const isMobile = useCheckMobileScreen();
    const [isVisible, setIsVisible] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);

    const hideMenu = () => setIsVisible(false);

    const showMenu = () => setIsVisible(true);

    const hideNotificationCenter = () => setIsNotificationVisible(false);

    const showNotificationCenter = () => setIsNotificationVisible(true);

    const onClickName = () => {
        navigate('ProfileScreen', {});
    };

    const { userInfo } = useAppSelector(
        ({ profileReducer }) => profileReducer,
    );

    const onLogoPress = () => {
        navigate('Home', {});
    }

    useEffect(() => {
        dispatch(getUserInfoAction());
    }, []);

    const renderTooltip = () => (
        <Menu
            visible={isNotificationVisible}
            anchor={<NotificationToolbarIcon onPress={showNotificationCenter} />}
            onRequestClose={hideNotificationCenter}
            style={styles.containerTooltip}
        >
            <NotificationScreen />
        </Menu>
    );

    const doLogOut = () => {
        LoadingService.show();
        LoggerService.logEvent('logOut', {
            from: 'toolbar',
        });
        dispatch(authenticateLogoutAction());
    };

    const renderLogout = useMemo(() => (
        <Menu
            visible={isVisible}
            anchor={(
                <TouchableOpacity
                    accessibilityLabel={'toolbarTouchableMenu'}
                    onPress={showMenu}
                    style={styles.arrow}
                    testID={'toolbarTouchableMenu'}
                >
                    <DisplayCloseSvg />
                </TouchableOpacity>
            )}
            onRequestClose={() => hideMenu()}
            style={styles.menuContainer}
        >
            <TouchableOpacity
                accessibilityLabel={'toolbarTouchableLogout'}
                onPress={doLogOut}
                style={styles.containerLogOut}
                testID={'toolbarTouchableLogout'}
            >
                <Text style={styles.textNumber}>{Languages.LogOut}</Text>
                <LogOutIcon
                    fill={theme.colors.primary}
                    width={25.67}
                    height={25}
                />
            </TouchableOpacity>
        </Menu>
    ), [isVisible]);

    const renderLeftSide = useMemo(() => (
        (
            <View style={[styles.containerIcon]}>
                <TouchableOpacity
                accessibilityLabel={'toolbarTouchableLogo'}
                onPress={onLogoPress}
                testID={'toolbarTouchableLogo'}
            >
                {(context === 'default' || context === 'ClaimAccount') && (
                    <EasyPayLogoCompleteWhiteSvg height={45} width={190}  />
                )}
            </TouchableOpacity>
            </View>
        )
    ), [isMobile, context]);

    const renderMiddleSide = useMemo(() => (
        <View style={styles.containerTitle}>
            {(context === 'default' || context === 'ClaimAccount') ? (
                <Text style={styles.text}>{Languages.MyEasyPayApp}</Text>
            ) : (
                <EasyPayLogoCompleteWhiteSvg height={40}  />
            )}
        </View>
    ), [context]);

    return (
        <View style={[styles.container, t.flex1, t.flexRow]}>
            {renderLeftSide}
            <View style={styles.containerMenu}>
                {context === 'default' && (
                    <>
                        <View style={styles.notificationIcon}>
                            {renderTooltip()}
                        </View>
                        <View style={styles.profileContainer}>
                            <TouchableOpacity
                                accessibilityLabel={'toolbarIconTouchableProfile'}
                                onPress={onClickName}
                                style={styles.profileDataContainer}
                                testID={'toolbarIconTouchableProfile'}
                            >
                                <ProfileTabIconSvg fill={colors.white} />
                                {
                                    !isMobile && (
                                        <Text
                                            style={styles.nameText}
                                            numberOfLines={1}
                                            ellipsizeMode={'middle'}
                                        >{`${userInfo.firstName} ${userInfo.lastName}`}</Text>
                                    )
                                }
                            </TouchableOpacity>
                            {renderLogout}
                        </View>
                    </>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    arrow: {
        alignItems: 'center',
        flex: 10,
        height: '100%',
        justifyContent: 'center',
        minWidth: 20,
    },
    container: {
        alignItems: 'center',
        backgroundColor: theme.colors.primary,
        height: 66,
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    containerIcon: {
        alignItems: 'flex-start',
        flex: 10,
        height: '100%',
        justifyContent: 'center',
        minWidth: 181,
    },
    containerLogOut: {
        flexDirection: 'row',
        marginBottom: 15,
        marginHorizontal: 20,
        marginTop: 15,
    },
    containerMenu: {
        alignItems: 'center',
        flex: 20,
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        minWidth: 140,
    },
    containerTitle: {
        alignItems: 'center',
        flex: 70,
        height: '100%',
        justifyContent: 'center',
        minWidth: 155,
    },
    containerTooltip: {
        ...globalStyle.dropShadow,
        backgroundColor: colors.white,
        borderRadius: 10,
        width: 339,
    },
    lineWhite: {
        backgroundColor: colors.white,
        flex: 0.1,
        height: 38,
    },
    menuContainer: {
        ...globalStyle.dropShadow,
        borderRadius: 10,
        width: 350,
    },
    nameText: {
        color: colors.white,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16.71,
        marginLeft: 10,
    },
    notificationIcon: {
        alignItems: 'center',
        flex: 30,
        height: '100%',
        justifyContent: 'center',
        minWidth: 50,
    },
    profileContainer: {
        alignItems: 'center',
        flex: 70,
        flexDirection: 'row',
        height: '100%',
        gap: 10,
        justifyContent: 'center',
    },
    profileDataContainer: {
        alignItems: 'center',
        flex: 90,
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
        justifyContent: 'flex-end',
        minWidth: 20,
    },
    text: {
        color: colors.white,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 23.87,
    },
    textNumber: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 24,
        textAlign: 'left',
        ...Platform.select({
            web: {
                flex: 10,
            },
            default: {
                flex: 113,
            },
        }),
    },
});
