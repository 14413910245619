import React, {
    useEffect,
    useState,
} from 'react';

import { Switch } from 'react-native';

import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Props {
    accessibilityLabel?: string;
    disabled?: boolean;
    isOn: boolean;
    onChange: (value: boolean) => void;
    testID?: string;
};
export const AppSwitch = ({
    accessibilityLabel = 'appSwitch',
    disabled,
    isOn,
    onChange,
    testID = 'appSwitch',
}: Props) => {
    const [isEnabled, setIsEnabled] = useState(isOn);

    useEffect(() => {
        setIsEnabled(isOn);
    }, [isOn]);

    const toggleSwitch = () => onChange(!isEnabled);

    return (
        <Switch
            trackColor={{ false: colors.grayLigthDark, true: colors.greenLightColor }}
            thumbColor={isEnabled ? theme.colors.primary : colors.darkGray}
            onValueChange={toggleSwitch}
            value={isEnabled}
            disabled={disabled}
            accessibilityLabel={accessibilityLabel}
            testID={testID}
        />
    );
};
