import '../../../web/css/pdfviewer.css';

import React from 'react';

import axios from 'axios';
import PDFViewer from 'mgr-pdf-viewer-react';
import {
    ScrollView,
    View,
} from 'react-native';

import { styles } from '@Activity/styles/StatementsScreenWebStyles';
import { AppButton } from '@Components/AppButton';
import { ShimmerApp } from '@Components/ShimerApp';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { parseDate } from '@Utils/FormatUtils';

interface Props {
    alignButton?: boolean;
    base64: string | undefined;
};

const PdfScreenViewer = ({ base64, alignButton }: Props) => {
    const date = parseDate(new Date().toISOString(), 'yyyy_MM_dd_sss');

    const downloadPDF = () => {
        LoggerService.logEvent('downloadPdf', {});
        axios({
            url: `data:application/pdf;base64,${base64}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${date}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <>
            {base64 && base64.length > 0 ? (
                <>
                    <View style={[
                        styles.containerButton,
                        alignButton && { justifyContent: 'center' },
                    ]}>
                        <AppButton
                            accessibilityLabel={'pdfViewerAppButtonDownload'}
                            buttonStyle={styles.buttonStyle}
                            handleOnChange={() => downloadPDF()}
                            testID={'pdfViewerAppButtonDownload'}
                            textStyle={styles.downloadText}
                            title={Languages.DownloadStatement}
                        />
                    </View>
                    <ScrollView
                        style={styles.containerPdf}
                        showsVerticalScrollIndicator={false}>
                        {base64.length > 0 && (
                            <PDFViewer
                                scale={1.2}
                                css='pdfViewer'
                                hideNavbar={true}
                                document={{ url: `data:application/pdf;base64,${base64}` }}
                            />
                        )}
                    </ScrollView>
                </>
            ) : (
                <>
                    <View style={[
                        styles.containerButton,
                        alignButton && { justifyContent: 'center' },
                    ]}>
                        <ShimmerApp
                            visible={Boolean(false)}
                            shimmerStyle={styles.buttonStyle}
                        />
                    </View>
                    <ShimmerApp
                        visible={Boolean(false)}
                        shimmerStyle={styles.containerPdfShimmer}
                    />
                </>
            )}
        </>
    );
};

export default React.memo(PdfScreenViewer);
