import {
    Platform,
    StyleSheet,
} from 'react-native';

export const styles = StyleSheet.create({
    containerFooter: {
        justifyContent: 'flex-end',
        marginTop: 50,
        ...Platform.select({
            web: {
                paddingTop: '1%',
            },
        }),
    },
});
