import React, { useState } from 'react';

import {
    ImageBackground,
    View,
} from 'react-native';

import {
    EasyPayLogoComplete,
    Mep,
} from '@Assets/index';
import { ForgotPasswordFooterComponent } from '@Auth/components/ForgotPasswordFooterComponent';
import { AppLinksComponent } from '@Auth/components/AppLinksComponent';
import { ForgotPasswordForm } from '@Auth/components/ForgotPasswordForm';
import { styles } from '@Auth/styles/ForgotPassworScreenWebStyle';
import useKeyboardEvent from '@Hooks/useKeyboardEvent';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { t } from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'ForgotPassword'> { };

export const ForgotPasswordScreen = ({
    navigation,
    route,
}: Props) => {
    const keyboardEvent = useKeyboardEvent('Enter', 'forgotpassword');

    const [isUserNotFound, setIsUserNotFound] = useState<boolean>(false);

    const handleUserNotFound = (notFound: boolean) => setIsUserNotFound(notFound);

    const validateSendKeyboardEvent = () => {
        if (keyboardEvent?.screen && window.location.href.indexOf(keyboardEvent?.screen) > -1) {
            return keyboardEvent;
        }
    };

    return (
        <ImageBackground
            source={Mep}
            imageStyle={[t.wFull, t.hFull]}
            style={[t.flex1, t.itemsEnd, t.justifyCenter, t.pX7]}
        >
            <View style={[
                { width: 464 },
                t.minW80,
                t.maxW2_5,
                t.maxHFull,
                t.bgWhite,
                t.itemsCenter,
                t.roundedSm,
                t.pY10,
            ]}>
                <View style={[t.mB12]}>
                    <EasyPayLogoComplete />
                </View>
                <ForgotPasswordForm
                    navigation={navigation}
                    route={route}
                    isUserNotFound={isUserNotFound}
                    handleIsUserNotFound={handleUserNotFound}
                    keyboardEvent={validateSendKeyboardEvent()}
                />

                {isUserNotFound &&
                    <View style={styles.containerFooter}>
                        <ForgotPasswordFooterComponent />
                    </View>
                }
            </View>
            <AppLinksComponent />
        </ImageBackground>
    );
};
