import { Subject } from 'rxjs';

import {
    Error,
    Response,
} from '@Interfaces/BaseResponse';

type ACTION = 'registration' | 'validation';
export interface RegisterServiceResponse extends Response {
    action: ACTION;
};

const RegisterService = () => {
    const subject = new Subject();

    const onSuccess = (response: Response) => {
        subject.next({
            ...response,
            action: 'registration',
            success: true,
        } as RegisterServiceResponse);
    };

    const onValidation = (response: Response) => {
        subject.next({
            ...response,
            action: 'validation',
            success: true,
        } as RegisterServiceResponse);
    };

    const onError = (error: Error) => {
        subject.next(error);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onError,
        onSuccess,
        onValidation,
        subscribe,
    };

};

export default RegisterService();
