import AuthApi from '@Api/authApi';
import { Commands } from '@Api/config';
import {
    AuthenticateRefreshTokenRequest,
    AuthenticateRequest,
    AuthenticateResponse,
} from '@Auth/interfaces';
import LoggerService from '@Logger/services/LoggerService';
/**
 * Use reject to send an error @param {AuthenticateRequest}
 * 
 * @returns {Promise}
 */
export const authenticate = ({ email, password }: AuthenticateRequest): Promise<AuthenticateResponse> => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<AuthenticateResponse>(Commands.AUTHENTICATE_EMAIL, {
            email,
            password,
        });
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        LoggerService.logError(error);
        reject(error);
    }
});

export const refreshToken = ({ refreshToken }: AuthenticateRefreshTokenRequest): Promise<AuthenticateResponse> => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.post<AuthenticateResponse>(Commands.AUTHENTICATE_REFRESH_TOKEN, {
            value: refreshToken,
        });
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        LoggerService.logError(error);
        reject(error);
    }
});

export const internalLogin = (token: string): Promise<any> => new Promise(async (resolve, rejected) => {
    try {
        const response = await AuthApi.post(Commands.AUTHENTICATE_INTERNAL, {
            content: decodeURI(token),
        });
        resolve({
            refreshToken: response.data.refreshToken,
            accessToken: response.data.accessToken,
            success: true,
        });
    } catch (error: any) {
        rejected(error);
    }
});
export const logout = () => new Promise(async (resolve) => {
    resolve(true);
});
