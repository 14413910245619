import React, { useCallback } from 'react';

import {
    StyleSheet,
    Text,
    View,
} from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    EmailIcon,
    PhoneIcon,
} from '@Assets/index';
import { AppButtonIcon } from '@Components/AppButtonIcon';
import { useAppSelector } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import {
    colors,
    fonts,
} from '@Theme/appTheme';
import { clearPhoneNumberFormat } from '@Utils/FormatUtils';
import {
    makeACall,
    sendAnEmail,
} from '@Utils/index';

export const ContactHelpComponent = () => {
    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    const handleOnPhoneTap = useCallback(async () => {
        try {
            LoggerService.logEvent('contactHelp', {
                by: 'phone',
            });
            const phoneNumber = clearPhoneNumberFormat(Languages.ContactPhoneNumber);
            const result = await makeACall(phoneNumber);
            if (!result) {
                AppAlertService.showError({
                    message: `${Languages.DontNowHowToOpenThisURL}: ${phoneNumber}`,
                });
            }
        } catch (error) {
            LoggerService.logError(error);
            AppAlertService.showError({
                message: Languages.DontNowHowToOpenThisURL,
            });
        }
    }, []);

    const handleOnEmailTap = useCallback(async () => {
        try {
            LoggerService.logEvent('contactHelp', {
                by: 'email',
            });
            const email = Languages.ContactEmail;
            const result = await sendAnEmail(email, `${Languages.AccountOnly} #${loanSelected?.applicationId}`);
            if (!result) {
                AppAlertService.showError({
                    message: `${Languages.DontNowHowToOpenThisURL}: ${email}`,
                });
            }
        } catch (error) {
            LoggerService.logError(error);
            AppAlertService.showError({
                message: Languages.DontNowHowToOpenThisURL,
            });
        }
    }, [loanSelected]);

    return (
        <View style={styles.containerFooter}>
            <View style={styles.containerInfoFooter}>
                <Text style={styles.footerTitle}>{Languages.NeedHelContactUs}</Text>
                <View style={styles.containerButtons}>
                    <AppButtonIcon
                        accessibilityLabel={'contactHelpAppButtonIconPhone'}
                        buttonStyle={styles.btnFooter}
                        handleOnChange={handleOnPhoneTap}
                        Icon={PhoneIcon}
                        testID={'contactHelpAppButtonIconPhone'}
                        title={Languages.Call}
                    />
                    <AppButtonIcon
                        accessibilityLabel={'contactHelpAppButtonIconEmail'}
                        buttonStyle={styles.btnFooter}
                        handleOnChange={handleOnEmailTap}
                        Icon={EmailIcon}
                        testID={'contactHelpAppButtonIconEmail'}
                        title={Languages.Email}
                    />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    btnFooter: {
        height: 30,
        width: 123,
    },
    containerButtons: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 20,
        width: '77%',
    },
    containerFooter: {
        alignItems: 'center',
    },
    containerInfoFooter: {
        alignItems: 'center',
        backgroundColor: colors.green,
        borderRadius: 4,
        height: 110,
        marginTop: 73,
        width: 342,
    },
    footerTitle: {
        color: colors.blue,
        fontFamily: fonts.SFProText,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 20,
        marginTop: 20,
    },
});
