import React, {
    useRef,
    useState,
} from 'react';

import {
    Platform,
    StyleSheet,
    Text,
    TextInput,
    TouchableWithoutFeedback,
    View,
} from 'react-native';

import Languages from '@i18n/index';
import {
    colors,
    fonts,
} from '@Theme/appTheme';

interface Props {
    onTextChange: (value: string) => void;
};

export const TextCodeInput = ({ onTextChange = () => { } }: Props) => {
    const [textCode, setTextCode] = useState('');
    const textInputRef = useRef<TextInput>(null);
    const onChangeText = (value: string) => {
        if (isValidNumber(value)) {
            setTextCode(value);
            onTextChange(value);
        } else {
            setTextCode(value.slice(0, -1));
        }
    };
    const obtainNumberByPosition = (position: number): string => textCode && textCode.substring(position - 1, position);
    const requestOrReleaseFocus = () => {
        const currentTextInput = textInputRef?.current;
        if (currentTextInput?.isFocused()) {
            currentTextInput.blur();
        } else {
            currentTextInput?.focus();
        }
    };
    const isValidNumber = (value: string): boolean => {
        const result = value.match(/[^0-9]/g);
        if (result === null) {
            return true;
        }
        return false;
    };
    return (
        <View>
            <Text style={styles.title}>{Languages.VerificationCode}</Text>
            <TextInput
                ref={textInputRef}
                keyboardType={'numeric'}
                numberOfLines={1}
                maxLength={4}
                value={textCode}
                style={styles.txtInputStyle}
                onChangeText={onChangeText}
                returnKeyType='done'
            />
            <TouchableWithoutFeedback
                style={styles.codeContainer}
                onPress={requestOrReleaseFocus}
            >
                <View style={styles.codeContainer}>
                    <View style={styles.codeInputContainer}>
                        <Text style={styles.codeInput}>
                            {obtainNumberByPosition(1)}
                        </Text>
                    </View>
                    <View style={styles.codeInputContainer}>
                        <Text style={styles.codeInput}>
                            {obtainNumberByPosition(2)}
                        </Text>
                    </View>
                    <View style={styles.codeInputContainer}>
                        <Text style={styles.codeInput}>
                            {obtainNumberByPosition(3)}
                        </Text>
                    </View>
                    <View style={styles.codeInputContainer}>
                        <Text style={styles.codeInput}>
                            {obtainNumberByPosition(4)}
                        </Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </View>
    );
};

const styles = StyleSheet.create({
    codeContainer: {
        alignContent: 'center',
        flexDirection: 'row',
        height: 59,
        justifyContent: 'space-between',
    },
    codeInput: {
        fontFamily: fonts.Roboto,
        fontSize: 35,
        textAlign: 'center',
        width: 55,
    },
    codeInputContainer: {
        backgroundColor: colors.veryLightGray2,
        borderRadius: 3.5,
        justifyContent: 'center',
        width: 55,
    },
    title: {
        alignSelf: 'center',
        color: colors.grayColor,
        fontFamily: fonts.Roboto,
        fontSize: 16,
        fontWeight: '500',
        marginBottom: 11,
    },
    txtInputStyle: {
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        ...Platform.select({
            web: {
                outlineColor: 'transparent',
                outlineStyle: 'none',
                outlineWidth: 0,
            },
        }),
    },
});
