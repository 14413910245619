import React, { useCallback } from 'react';

import { debounce } from 'lodash';
import {
    Col,
    Row,
} from 'react-bootstrap';
import {
    Linking,
    ScrollView,
    Text,
    View,
} from 'react-native';

import {
    FinancialBooks,
    FinancialGraph,
    FinancialPig,
    ImageFinancial,
} from '@Assets/index';
import { AppButton } from '@Components/AppButton';
import { styles } from '@Home/styles/FinancialFundamentalsScreenWebStyles';
import { useAppSelector } from '@Hooks/appHooks';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { linksApp } from '@Utils/index';

export const FinancialFundamentalsScreen = () => {
    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    const startNowFinancial = useCallback(debounce(async () => {
        const { loanId } = loanSelected || {};
        const { getData } = useStorage();
        const data = await getData({
            key: StorageKeys.LOGIN_METADATA,
        });
        if (data.success) {
            const { phone } = data.value;
            LoggerService.logEvent('startNowFinancial', {
                email: phone,
                loanId,
            });
        }
        Linking.openURL(linksApp.financialFundamentalsProgram);
    }, 250), []);

    return (
        <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
            <Text style={styles.title}>{Languages.FinancialFundamentals}</Text>
            <Row>
                <Col xl={4} xs={12} sm={12} md={12} lg={4}>
                    <View style={styles.containerHeader}>
                        <View style={styles.containerHeaderData}>
                            <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={2}
                                style={styles.titleHeader}
                            >
                                {Languages.FinancialFundamentals}
                            </Text>
                        </View>
                        <View style={styles.containerHeaderImage}>
                            <ImageFinancial
                                width={'100%'} />
                        </View>
                    </View>
                    <View style={styles.containerDescription}>
                        <Text style={styles.description}>{Languages.ClickBelowToBeginFinancial}</Text>
                    </View>
                    <AppButton
                        title={Languages.StartNow}
                        handleOnChange={startNowFinancial}
                        buttonStyle={styles.button}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <View style={styles.containerRight}>
                        <Text style={styles.titleBody}>{Languages.FinancialFundamentalsProgram}</Text>
                        <Row>
                            <Col xs={12} sm={12} lg={6}>
                                <View style={styles.containerBodyLeft}>
                                    <View style={styles.financialPigImage}>
                                        <FinancialPig />
                                    </View>
                                    <View style={styles.containerBodyData}>
                                        <Text style={styles.subtitleBody}>
                                            {Languages.FinancialMakeChoicesStart}
                                            <Text style={styles.subtitleBodyGreen}>
                                                {` ${Languages.FinancialMakeChoicesBold} `}
                                                <Text style={styles.subtitleBody}>
                                                    {Languages.FinancialMakeChoicesEnd}
                                                </Text>
                                            </Text>
                                        </Text>
                                    </View>
                                    <View style={styles.financialGraphImage}>
                                        <FinancialGraph
                                            width={'80%'} />
                                    </View>
                                </View>
                            </Col>
                            <Col xs={12} sm={12} lg={6}>
                                <View style={styles.containerBodyRight}>
                                    <View style={styles.containerBodyData}>
                                        <Text style={styles.subtitleBody}>
                                            {Languages.EasyPayFinanceIsCommitted}
                                        </Text>
                                    </View>
                                    <View style={styles.financialBooksImage}>
                                        <FinancialBooks
                                            width={'90%'} />
                                    </View>
                                    <View style={styles.containerBodyData}>
                                        <Text style={styles.subtitleBody}>
                                            {Languages.WeAreProudToIntroduceStart}
                                            <Text style={styles.subtitleBodyGreen}>
                                                {` ${Languages.WeAreProudToIntroduceBold} `}
                                                <Text style={styles.subtitleBody}>
                                                    {Languages.WeAreProudToIntroduceEnd}
                                                </Text>
                                            </Text>
                                        </Text>
                                    </View>
                                </View>
                            </Col>
                        </Row>
                    </View>
                </Col>
            </Row>
        </ScrollView>
    );
};
