import AppApi from '@Api/appApi';
import AuthApi from '@Api/authApi';
import { Commands } from '@Api/config';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import { Response } from '@Interfaces/BaseResponse';
import {
    GetProfileResponse,
    GetZipCodeRequest,
    GetZipCodeResponse,
    PostClaimProfileRequest,
    PostPhoneUpdateRequest,
    PutProfileRequest,
} from '@Profile/interfaces';

const compareArrays = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

const { getData, storeData } = useStorage();

export const getMenuOptionsAPI = async () => new Promise(async (resolve) => {
    const dataMenuItems = [
        { title: 'Personal Information' },
        { title: 'Account Details' },
        { title: 'FAQ' }
    ];

    const storageData = await getData({ key: StorageKeys.PROFILE_MENU });

    if (storageData.success && compareArrays(storageData.value, dataMenuItems)) {
        resolve({
            menuOptions: storageData.value,
        });
    } else {
        await storeData({
            data: dataMenuItems,
            key: StorageKeys.PROFILE_MENU,
        });
        resolve({
            menuOptions: dataMenuItems,
        });
    }
});

export const getUserInfoAPI = (): Promise<GetProfileResponse> => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.get<GetProfileResponse>(Commands.GET_PROFILE);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const putUserInfoAPI = (request: PutProfileRequest) => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.put(Commands.PUT_PROFILE, request);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const postPhoneUpdateAPI = (request: PostPhoneUpdateRequest) => new Promise(async (resolve, reject) => {
    try {
        const response = await AuthApi.post(Commands.POST_PHONE_UPDATE_REQUEST, request);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const getZipCodeInformationAPI = (request: GetZipCodeRequest): Promise<GetZipCodeResponse> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_ZIP_CODES_INFORMATION.replace('{zipCode}', request.zipCode);
        const response = await AppApi.get(command);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const postClaimProfileAPI = (request: PostClaimProfileRequest): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        await AuthApi.post(Commands.GET_PROFILE_CLAIM, request);
        resolve({
            success: true,
            code: 200,
            title: '',
        });
    } catch (error) {
        reject(error);
    }
});
