import * as React from 'react';

import {
    StyleProp,
    View,
    ViewStyle,
} from 'react-native';

import {
    RadioActiveIcon,
    RadioInactiveIcon,
} from '@Assets/index';
import { AppStyleSheet } from '@Utils/index';
import { colors } from '@Theme/appTheme';

interface Props {
    selected: boolean;
    containerStyle?: StyleProp<ViewStyle>;
}

export const RadioButton = ({
    selected,
    containerStyle,
}: Props) => (
    <View style={containerStyle}>
        <RadioInactiveIcon fill={colors.white} />
        {selected && <View style={styles.containerActive}>
            <RadioActiveIcon />
        </View>}
    </View>
);

const styles = AppStyleSheet({
    containerActive: {
        position: 'absolute',
        top: 3,
        left: 3,
    },
});
