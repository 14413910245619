import React from 'react';

import {
    ImageBackground,
    View,
} from 'react-native';

import {
    EasyPayLogoComplete,
    Mep,
} from '@Assets/index';
import { ForgotPasswordFooterComponent } from '@Auth/components/ForgotPasswordFooterComponent';
import { NewPasswordForm } from '@Auth/components/NewPasswordForm';
import { styles } from '@Auth/styles/NewPasswordScreenWebStyle';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { t } from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'NewPasswordScreen'> { };

export const NewPasswordScreen = ({
    navigation,
    route,
}: Props) => (
    <ImageBackground
        source={Mep}
        imageStyle={[t.wFull, t.hFull]}
        style={[t.flex1, t.itemsEnd, t.justifyCenter, t.pX7]}
    >
        <View style={[
            { width: 464 },
            t.minW80,
            t.maxW2_5,
            t.maxHFull,
            t.bgWhite,
            t.itemsCenter,
            t.roundedSm,
            t.pY10,
        ]}>
            <View style={[t.mB12]}>
                <EasyPayLogoComplete />
            </View>

            <View style={[t.w4_5]}>
                <NewPasswordForm
                    navigation={navigation}
                    route={route}
                />
            </View>
            <View style={styles.containerFooter}>
                <ForgotPasswordFooterComponent />
            </View>
        </View>
    </ImageBackground>
);
