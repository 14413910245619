import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    authenticate,
    internalLogin,
    logout,
    refreshToken,
} from '@Auth/api/authenticateApi';
import {
    AuthenticateInternalActionRequest,
    AuthenticateRefreshTokenRequestAction,
    AuthenticateRequestAction,
    AuthenticateResponse,
} from '@Auth/interfaces/index';
import AuthenticateInternalService from '@Auth/services/AuthenticateInternalService';
import AuthenticateService from '@Auth/services/AuthenticateService';
import AuthenticateActionsTypes from '@Auth/state/authenticateActionTypes';
import {
    clearMenuListAction,
    navigateLeftMenu,
} from '@Home/state/menuActions';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import Languages from '@i18n/index';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import OnboardingService from '@OnBoarding/service/OnboardingService';
import { downloadApplicationsMergeMap } from '@Profile/epic/applicationsEpics';
import { downloadUserInfoMergeMap } from '@Profile/epic/profileEpics';
import { UserInfo } from '@Profile/interfaces/index';
import { saveApplications } from '@Profile/state/applicationsAction';
import { getUserInfoSuccessAction } from '@Profile/state/profileActions';
import { persistor } from '@Store/index';
import { Platform } from 'react-native';

export const authenticateEpic = (action$: Observable<any>, $state: any) => {
    let userInfo: UserInfo;
    return action$.pipe(
        ofType(AuthenticateActionsTypes.AUTHENTICATE_REQUEST),
        switchMap(({ payload }: AuthenticateRequestAction) => from(authenticate({
            ...payload,
        })).pipe(
            switchMap((response: AuthenticateResponse) => from(
                new Promise<AuthenticateResponse>(async (resolve, reject) => {
                    try {
                        await shouldCleanApplicationSelected(payload.email);
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                }),
            )),
        ).pipe(
            switchMap((response: AuthenticateResponse) => from(
                new Promise<AuthenticateResponse>(async (resolve, reject) => {
                    try {
                        const { storeData } = useStorage();
                        await storeData({
                            data: {
                                ...response,
                                phone: payload.email,
                            },
                            key: StorageKeys.LOGIN_METADATA,
                        });
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                }),
            )),
        ).pipe(
            downloadUserInfoMergeMap(),
        ).pipe(
            map((response: UserInfo) => userInfo = response),
        ).pipe(
            downloadApplicationsMergeMap($state),
        ).pipe(
            switchMap((response: any) => {
                LoggerService.logUserData({
                    userId: payload.email,
                });
                setTimeout(() => {
                    AuthenticateService.onSuccess({
                        success: true,
                        isFounded: userInfo && userInfo.dob !== '' && userInfo.last4ssn !== '',
                    });
                    OnboardingService.show(payload.email);
                }, 100);
                return [
                    saveApplications(response),
                    getUserInfoSuccessAction(userInfo),
                    loadingDialogHide(),
                ];
            }),
            catchError((error: Error) => {
                const errorMessage = error?.status === 401
                    ? Languages.TheUsernameOrPasswordIsInvalid
                    : (error.title || Languages.AnErrorHasOcurred);
                AppAlertService.showError({
                    message: errorMessage,
                });
                AuthenticateService.onError(error);
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        )),
    ).pipe(
        catchError((error: Error) => {
            const errorMessage = error?.status === 401
                ? Languages.TheUsernameOrPasswordIsInvalid
                : (error.title || Languages.AnErrorHasOcurred);
            AppAlertService.showError({
                message: errorMessage,
            });
            AuthenticateService.onError(error);
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    );
};

export const authenticateRefreshTokenEpic = (action$: Observable<any>) => action$.pipe(
    ofType(AuthenticateActionsTypes.AUTHENTICATE_REFRESH_TOKEN),
    switchMap(({ payload }: AuthenticateRefreshTokenRequestAction) => from(refreshToken({
        ...payload,
    })).pipe(
        switchMap((response: AuthenticateResponse) => from(
            new Promise(async (resolve, reject) => {
                try {
                    const { getData, storeData } = useStorage();
                    const currentLoginMetadata = await getData({
                        key: StorageKeys.LOGIN_METADATA,
                    });
                    await storeData({
                        data: {
                            ...response,
                            phone: currentLoginMetadata.value.phone,
                        },
                        key: StorageKeys.LOGIN_METADATA,
                    });
                    resolve({
                        ...response,
                        phone: currentLoginMetadata.value.phone,
                    });
                } catch (error) {
                    reject(error);
                }
            }),
        )),
    ).pipe(
        switchMap((response: any) => {
            AuthenticateService.onSuccess({
                success: true,
                isFounded: true,
            });
            OnboardingService.show(response.phone);
            return [
                loadingDialogHide(),
            ];
        }),
        catchError((error: Error) => {
            const errorMessage = error?.status === 401
                ? Languages.TheUsernameOrPasswordIsInvalid
                : (error.title || Languages.AnErrorHasOcurred);
            AppAlertService.showError({
                message: errorMessage,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
).pipe(
    catchError((error: Error) => {
        const errorMessage = error?.status === 401
            ? Languages.TheUsernameOrPasswordIsInvalid
            : (error.title || Languages.AnErrorHasOcurred);
        AppAlertService.showError({
            message: errorMessage,
        });
        LoggerService.logError(error);
        return of(loadingDialogHide());
    }),
);

export const logoutEpic = (action$: Observable<any>) => action$.pipe(
    ofType(AuthenticateActionsTypes.AUTHENTICATE_LOGOUT),
    mergeMap(() => from(logout())
        .pipe(
            mergeMap(() => {
                AuthenticateService.onSuccess({
                    success: false,
                });
                if (Platform.OS === 'web') {
                    clearLoginData();
                }
                return [
                    navigateLeftMenu('Home'),
                    clearMenuListAction(),
                    loadingDialogHide(),
                ];
            })
        )),
);

const clearLoginData = async () => {
    const { getData, storeData } = useStorage();
    const data = await getData({
        key: StorageKeys.LOGIN_METADATA,
    });
    if (data.success) {
        await storeData({
            key: StorageKeys.LOGIN_METADATA,
            data: {
                ...data.value,
                refreshToken: '',
            },
        });
    }
};

export const authenticateInternalEpic = (action$: Observable<any>, $state: any) => {
    let userInfo: UserInfo;
    return action$.pipe(
        ofType(AuthenticateActionsTypes.AUTHENTICATE_INTERNAL_REQUEST),
        switchMap(({ payload }: AuthenticateInternalActionRequest) => from(internalLogin(payload.token))
            .pipe(
                switchMap((response: AuthenticateResponse) => from(
                    new Promise<AuthenticateResponse>(async (resolve, reject) => {
                        try {
                            const { storeData } = useStorage();
                            await storeData({
                                data: {
                                    ...response,
                                },
                                key: StorageKeys.LOGIN_METADATA,
                            });
                            resolve(response);
                        } catch (error) {
                            reject(error);
                        }
                    }),
                ).pipe(
                    downloadUserInfoMergeMap(),
                ).pipe(
                    map((response: UserInfo) => userInfo = response),
                ).pipe(
                    downloadApplicationsMergeMap($state),
                ).pipe(
                    switchMap((response: any) => {
                        setTimeout(() => {
                            AuthenticateService.onSuccess({
                                success: true,
                                isFounded: userInfo && userInfo.dob !== '' && userInfo.last4ssn !== '',
                            });
                        }, 100);
                        return [
                            saveApplications(response),
                            getUserInfoSuccessAction(userInfo),
                            loadingDialogHide(),
                        ];
                    }),
                    catchError((error: Error) => {
                        const errorMessage = error?.status === 401
                            ? Languages.TheUsernameOrPasswordIsInvalid
                            : (error.title || Languages.AnErrorHasOcurred);
                        AppAlertService.showError({
                            message: errorMessage,
                        });
                        LoggerService.logError(error);
                        return of(loadingDialogHide());
                    }),
                )),
                catchError((error: Error) => {
                    AuthenticateInternalService.onError({
                        title: error.title,
                        code: error.code,
                        success: false,
                    });
                    const errorMessage = error?.status === 401
                        ? Languages.TheUsernameOrPasswordIsInvalid
                        : (error.title || Languages.AnErrorHasOcurred);
                    AppAlertService.showError({
                        message: errorMessage,
                    });
                    LoggerService.logError(error);
                    return of(loadingDialogHide());
                }),
            )
        ));
};

const shouldCleanApplicationSelected = (phone: string) => new Promise(async (resolve) => {
    const { getData } = useStorage();
    const data = await getData({
        key: StorageKeys.LOGIN_METADATA,
    });
    if (data.success) {
        const { phone: currentPhone } = data.value;
        if (phone !== currentPhone) {
            persistor.purge()
                .then(() => persistor.flush())
                .then(() => resolve(true));
        } else {
            resolve(true);
        }
    } else {
        resolve(true);
    }
});
