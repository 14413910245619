import React, {
    useEffect,
    useState,
} from 'react';

import { format } from 'date-fns';
import {
    ScrollView,
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { AppCalendar } from '@Components/AppCalendar';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { CongratulationsModal } from '@MakeAPayment/components/CongratulationsModal';
import {
    MakeAPaymentOptionType,
    PaymentSchedule,
} from '@MakeAPayment/interfaces/index';
import SaveAPaymentService from '@MakeAPayment/services/SaveAPaymentService';
import {
    clearPaymentStatusAction,
    getPaymentStatusAction,
    saveAPaymentScheduleRequestAction,
} from '@MakeAPayment/state/MakeAPaymentActions';
import { styles } from '@MakeAPayment/styles/DateMakeAPaymentStyleWeb';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import {
    ParamListBase,
    useNavigation,
} from '@react-navigation/native';
import {
    StackNavigationProp,
    StackScreenProps,
} from '@react-navigation/stack';
import { useScript } from '@Hooks/useScript';
import { getSurveyAnsweredRequestAction } from '../../home/state/delightedSurveyActions';
import SurveyService from '@Home/services/SurveyService';
import { setSurveyAnsweredRequestAction } from '@Home/state/delightedSurveyActions';
import { ConfirmSubmitPaymentModal } from '@MakeAPayment/components/ConfirmSubmitPaymentModal';
declare const delightedStars5: any;

interface DelightedSurvey {
    survey: (obj: any) => any
}

interface Props extends StackScreenProps<RootStackParamList, 'DateMakeAPaymentScreen'> { }

export const DateMakeAPaymentScreen = ({ route }: Props) => {
    const status = useScript('/delighted-survey.js', {
        removeOnUnmount: true
    });
    const [delighted, setDelighted] = useState<undefined | DelightedSurvey>(undefined);
    const {
        amount,
        dueDate,
        optionSelected,
        paymentMethodId,
        paymentMethodType,
        isTodayPayoff,
    } = route.params;

    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const dispatch = useAppDispatch();

    const [dateSelected, setDateSelected] = useState('');
    const [isSelectedToday, setIsSelectedToday] = useState(true);
    const [isDaySelected, setIsDaySelected] = useState(false);
    const [canShowWarning, setCanShowWarning] = useState<boolean>(false);
    const [paymentAmountLabel, setPaymentAmountLabel] = useState('');
    const [paymentDateLabel, setPaymentDateLabel] = useState('');
    const [showConfirmSubmitPaymentModal, setShowConfirmSubmitPaymentModal] = useState<boolean>(false);

    const { userInfo } = useAppSelector(
        ({ profileReducer }) => profileReducer,
    );

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    const { showWarning: willShowWarning } = useAppSelector(
        ({ makeAPaymentReducer }) => makeAPaymentReducer,
    );

    useEffect(() => {
        if (typeof delightedStars5 !== 'undefined') {
            setDelighted(delightedStars5);
        }
    }, [status])

    useEffect(() => {
        dispatch(clearPaymentStatusAction());
        const saveAPaymentService = SaveAPaymentService.subscribe(() => {
            setIsDaySelected(true);
        });

        return () => {
            saveAPaymentService.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (MakeAPaymentOptionType.TODAYS_PAYOFF === optionSelected?.id) {
            setDateSelected(format(new Date(), 'yyyy-MM-dd'));
            dispatch(getPaymentStatusAction({
                dateSelected: new Date(),
                loanId: loanSelected?.loanId || 0,
            }));
        }
    }, [optionSelected]);

    useEffect(() => {
        setCanShowWarning(willShowWarning);
    }, [willShowWarning]);

    useEffect(() => {
        const pAmountLabel = (amount / 100).toString();
        setPaymentAmountLabel(pAmountLabel);
    }, []);

    const saveAPaymentSchedule = (values: PaymentSchedule) => {
        if (loanSelected?.loanId) {
            dispatch(saveAPaymentScheduleRequestAction({
                ...values,
                isTodayPayoff,
                loanId: loanSelected?.loanId,
            }));
            LoggerService.logEvent('ScheduledPayment', {
                ...values,
                loanId: loanSelected?.loanId,
            });
        };
    };

    const handleSubmitSaveAPaymentSchedule = () => {
        const object = {
            paymentAmount: amount,
            runDate: dateSelected,
            paymentMethodId,
            paymentMethodType,
            isTodayPayoff,
        };
        saveAPaymentSchedule(object);
    };

    const handleAccept = () => {
        if (loanSelected && loanSelected.loanId) {
            dispatch(getSurveyAnsweredRequestAction({ applicationId: loanSelected.loanId }));
            const surveyServiceSubscription = SurveyService.subscribe((response: any) => {
                if (!response.surveyExists && delighted) {
                    delighted.survey({
                        forceDisplay: true,
                        email: `${userInfo.email}`,
                        name: `${userInfo.firstName || ''} ${userInfo.lastName || ''}`,
                        properties: {
                            loanId: loanSelected.loanId,
                        },
                        onHide: () => {
                            dispatch(setSurveyAnsweredRequestAction({ applicationId: loanSelected.loanId }));
                        }
                    });
                }

                surveyServiceSubscription.unsubscribe();
                navigation.reset({
                    index: 0,
                    routes: [
                        {
                            name: 'Home',
                        },
                    ],
                });
            });
        }
    };

    const obtainMaxDate = () => {
        if (MakeAPaymentOptionType.TODAYS_PAYOFF === optionSelected?.id) {
            return new Date();
        }
        return new Date(new Date().setMonth(new Date().getMonth() + 3));
    };

    const renderCalendar = () => (
        <AppCalendar
            selectToday={isSelectedToday}
            maxDate={obtainMaxDate()}
            daySelected={dateSelected}
            limitDayHour={MakeAPaymentOptionType.TODAYS_PAYOFF === optionSelected?.id ? 0 : 13}
            limitDayMinutes={MakeAPaymentOptionType.TODAYS_PAYOFF === optionSelected?.id ? 0 : 30}
            disabled={MakeAPaymentOptionType.TODAYS_PAYOFF === optionSelected?.id}
            onPressDay={(date) => {
                setDateSelected(date.formatDate);
                setPaymentDateLabel(format(date.date, "MMM dd, yyyy"));
                setIsSelectedToday(false);
                dispatch(getPaymentStatusAction({
                    dateSelected: date.date,
                    loanId: loanSelected?.loanId || 0,
                }));
            }}
        />
    );

    const renderPaymentWarning = () => canShowWarning && (
        <View style={styles.containerWarning}>
            <Text style={styles.textWarning}>{Languages.WarningPayment}</Text>
        </View>
    );

    const obtainPaymentDateDescription = () => (
        MakeAPaymentOptionType.TODAYS_PAYOFF === optionSelected?.id
            ? Languages.SelectAPaymentDateDescriptionCalendar
            : Languages.SelectAPaymentDateMinimunPastDueDescriptionCalendar
    );

    const shouldDisableButton = (): boolean => dateSelected === '';

    const cancelButton = () => navigation.navigate('SelectMethodOfPaymentScreen', {});

    return (
        <ScrollView
            style={{ flex: 1 }}
        >
            <View style={styles.containerDate}>
                <Text style={styles.textSelectDate}>{Languages.SelectAPaymentDate}</Text>
                {dueDate && (
                    <Text style={styles.textNextPayment}>{`${Languages.YourDueDate}: ${dueDate}`}</Text>
                )}
                <View style={styles.containerCalendar}>
                    {renderCalendar()}
                </View>
                <View style={styles.styleCalendar}>
                    <View style={styles.containerDescription}>
                        <Text style={styles.textDateDescription}>{obtainPaymentDateDescription()}</Text>
                    </View>
                    {renderPaymentWarning()}
                    <View style={styles.containerMakeAPaymentButton}>
                        <View style={styles.makeAPaymentButton}>
                            <AppButton
                                accessibilityLabel={'dateMakeAPaymentAppButtonMakeAPayment'}
                                disabled={shouldDisableButton()}
                                handleOnChange={() => setShowConfirmSubmitPaymentModal(true)}
                                testID={'dateMakeAPaymentAppButtonMakeAPayment'}
                                title={Languages.Submit}
                            />
                        </View>
                        <View style={styles.containerButton}>
                            <AppButton
                                accessibilityLabel={'dateMakeAPaymentAppButtonCancel'}
                                buttonStyle={styles.buttonCancel}
                                handleOnChange={cancelButton}
                                testID={'dateMakeAPaymentAppButtonCancel'}
                                textStyle={styles.textCancel}
                                title={Languages.GoBack}
                            />
                        </View>
                    </View>
                </View>
                <CongratulationsModal
                    show={isDaySelected}
                    handleAccept={handleAccept}
                />
                <ConfirmSubmitPaymentModal
                    show={showConfirmSubmitPaymentModal}
                    paymentAmount={paymentAmountLabel}
                    paymentDate={paymentDateLabel}
                    handleAccept={() => {
                        handleSubmitSaveAPaymentSchedule();
                        setShowConfirmSubmitPaymentModal(false);
                    }}
                    handleCancel={() => setShowConfirmSubmitPaymentModal(false)}
                />
            </View>
        </ScrollView>
    );
};

