import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import {
    cancelPaymentPendingEpic,
    getDocumentStamentsEpic,
    getPaymentActivities,
    getPaymentActivityEpic,
    getPaymentsPendingEpic,
    getPaymentStamentsEpic,
    updatePaymentPendingEpic,
} from '@Activity/epics/activityEpic';
import { activityReducer } from '@Activity/state/activityReducer';
import {
    authenticateEpic,
    authenticateInternalEpic,
    authenticateRefreshTokenEpic,
    logoutEpic,
} from '@Auth/epic/authenticateEpic';
import {
    sentCodeEmailEpic,
    sentCodePhoneNumberEpic,
    updatePasswordEmailEpic,
    updatePasswordPhoneEpic,
} from '@Auth/epic/forgotPasswordEpic';
import {
    confirmAccountEpic,
    registerByEmailBusinessCenterEpic,
    registerByEmailBusinessCenterValidateEpic,
    registerByEmailEpic,
    registerEpic,
    registerValidateVerificationCodeEpic,
    registerVerificationCodeEpic,
    registerVerificationCodeResendEpic,
} from '@Auth/epic/registerEpic';
import { authenticateReducer } from '@Auth/state/authenticateReducer';
import { forgotPasswordReducer } from '@Auth/state/forgotPasswordReducer';
import { registerReducer } from '@Auth/state/registerReducer';
import { getBalanceEpic } from '@Home/epics/balanceEpics';
import { getSurveyAnsweredEpic, setSurveyAnsweredEpic } from '@Home/epics/delightedSurveyEpics';
import { getMenuListEpic } from '@Home/epics/menuEpics';
import { balanceReducer } from '@Home/state/balanceReducer';
import { menuReducer } from '@Home/state/menuReducer';
import {
    endLoading,
    startLoading,
} from '@LoadingDialog/epics/LoadingEpics';
import { loadingDialogReducer } from '@LoadingDialog/state/loadingDialogReducer';
import {
    getPaymentEpic,
    saveAPaymentEpic,
    shouldShowPaymentWarningEpic,
} from '@MakeAPayment/epic/MakeAPaymentEpic';
import { makeAPaymentReducer } from '@MakeAPayment/state/MakeAPaymentReducer';
import {
    deleteCardEpic,
    deleteCheckEpic,
    getPaymentMethodAutoPayEpic,
    getPaymentMethodsEpic,
    getPlaidLinkTokenEpic,
    patchPaymentMethodsSortEpic,
    patchPaymentSetPrimaryEpic,
    putAHCEpic,
    putCardEpic,
    putPaymentMethodAutoPayEpic,
    saveAHCEpic,
    saveCardEpic,
} from '@MethodsOfPayment/epic/MethodsOfPaymentEpic';
import { methodsOfPaymentDictReducer } from '@MethodsOfPayment/state/MethodOfPaymentDictReducer';
import { methodsOfPaymentReducer } from '@MethodsOfPayment/state/MethodsOfPaymentReducer';
import {
    clearNotificationBadgeEpic,
    getOffersEpic,
    notificationsEpic,
} from '@Notifications/epic/notificationsEpic';
import { notificationsReducer } from '@Notifications/state/notificationsReducer';
import {
    applicationsEpic,
    downloadApplicationsEpic,
    getDocumentArrayBufferEpic,
    getDocumentEpic,
    saveLoanSelectedEpic,
} from '@Profile/epic/applicationsEpics';
import {
    faqEpic,
    searchFaqEpic,
} from '@Profile/epic/faqEpic';
import {
    menuOptionEpic,
    phoneUpdateVerificationCodeEpic,
    postClaimProfileEpic,
    putUserInfoEpic,
    userInfoEpic,
    zipCodeInformationEpic,
} from '@Profile/epic/profileEpics';
import { applicationsReducer } from '@Profile/state/applicationsReducer';
import { faqReducer } from '@Profile/state/faqReducer';
import { profileReducer } from '@Profile/state/profileReducer';
import {
    verificationCodeDialogReducer,
} from '@VerificationCodeDialog/state/verificationCodeDialogReducer';
import { delightedSurveyReducer } from '@Home/state/delightedSurveyReducer';
import { newStatementReducer } from '../../statements/state/statementReducer';
import { getDocumentNewStamentsEpic, getNewStamentsEpic } from '../../statements/epics/statementsEpic';

export const rootEpic = combineEpics(
    // epics
    applicationsEpic,
    authenticateEpic,
    authenticateInternalEpic,
    authenticateRefreshTokenEpic,
    cancelPaymentPendingEpic,
    clearNotificationBadgeEpic,
    confirmAccountEpic,
    deleteCardEpic,
    deleteCheckEpic,
    downloadApplicationsEpic,
    endLoading,
    faqEpic,
    getBalanceEpic,
    getSurveyAnsweredEpic,
    setSurveyAnsweredEpic,
    getDocumentArrayBufferEpic,
    getDocumentEpic,
    getDocumentStamentsEpic,
    getMenuListEpic,
    getNewStamentsEpic,
    getDocumentNewStamentsEpic,
    getOffersEpic,
    getPaymentActivities,
    getPaymentActivityEpic,
    getPaymentEpic,
    getPaymentMethodAutoPayEpic,
    getPaymentMethodsEpic,
    getPaymentsPendingEpic,
    getPaymentStamentsEpic,
    getPlaidLinkTokenEpic,
    logoutEpic,
    menuOptionEpic,
    notificationsEpic,
    patchPaymentMethodsSortEpic,
    patchPaymentSetPrimaryEpic,
    phoneUpdateVerificationCodeEpic,
    postClaimProfileEpic,
    putAHCEpic,
    putCardEpic,
    putPaymentMethodAutoPayEpic,
    putUserInfoEpic,
    registerByEmailBusinessCenterEpic,
    registerByEmailBusinessCenterValidateEpic,
    registerByEmailEpic,
    registerEpic,
    registerValidateVerificationCodeEpic,
    registerVerificationCodeEpic,
    registerVerificationCodeResendEpic,
    saveAHCEpic,
    saveAPaymentEpic,
    saveCardEpic,
    saveLoanSelectedEpic,
    searchFaqEpic,
    sentCodeEmailEpic,
    sentCodePhoneNumberEpic,
    shouldShowPaymentWarningEpic,
    startLoading,
    updatePasswordEmailEpic,
    updatePasswordPhoneEpic,
    updatePaymentPendingEpic,
    userInfoEpic,
    zipCodeInformationEpic,
);

export const rootReducer = combineReducers({
    activityReducer,
    applicationsReducer,
    authenticateReducer,
    balanceReducer,
    delightedSurveyReducer,
    faqReducer,
    forgotPasswordReducer,
    loadingDialogReducer,
    makeAPaymentReducer,
    menuReducer,
    methodsOfPaymentDictReducer,
    methodsOfPaymentReducer,
    newStatementReducer,
    notificationsReducer,
    profileReducer,
    registerReducer,
    verificationCodeDialogReducer,
});
