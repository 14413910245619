import React from 'react';

import {
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { SwitchAccountsComponent } from '@Components/SwitchAccountsComponent';
import {
    fonts,
    theme,
} from '@Theme/appTheme';

interface Props {
    title: string;
};

export const SectionHeaderComponent = ({ title }: Props) => (
    <>
        <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.componentContainer}>
            <SwitchAccountsComponent />
        </View>
    </>
);

const styles = StyleSheet.create({
    componentContainer: {
        alignItems: 'flex-end',
        flex: 1,
    },
    title: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 31,
        fontWeight: '500',
    },
    titleContainer: {
        alignItems: 'flex-start',
        flex: 1,
        minWidth: 100,
        maxWidth: 140,
    },
});
