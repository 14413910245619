import { createAction } from 'typesafe-actions';
import StatementsActionTypes from './statementActionTypes';
import { GetNewStatementDocumentRequest, GetNewStatementRequest } from '../interfaces';

export const getDocumentNewStatement = createAction(
    StatementsActionTypes.GET_DOCUMENT_NEW_STATEMENT,
    (request: GetNewStatementDocumentRequest) => (request)
)();


export const getNewStatements = createAction(
    StatementsActionTypes.GET_NEW_STATEMENTS,
    (request: GetNewStatementRequest) => (request),
)();