import React from 'react';

import { MakeAPaymentOption } from '@MakeAPayment/interfaces';
import { DateMakeAPaymentScreen } from '@MakeAPayment/screens/DateMakeAPaymentScreen';
import {
    SelectMethodOfPaymentScreen,
} from '@MethodsOfPayment/screens/SelectMethodOfPaymentsScreen';
import { createStackNavigator } from '@react-navigation/stack';

const Stack = createStackNavigator();

interface Props {
    amount?: number;
    dueDate?: string;
    handleCancel: () => void;
    optionSelected?: MakeAPaymentOption;
    isTodayPayoff?: boolean;
};

export const PaymentWebStack = ({
    amount,
    dueDate,
    handleCancel,
    optionSelected,
    isTodayPayoff,
}: Props) => (
    <Stack.Navigator
        screenOptions={() => ({
            headerShown: false,
        })}
    >
        <Stack.Screen
            name='SelectMethodOfPaymentScreen'
            component={SelectMethodOfPaymentScreen}
            initialParams={{
                amount,
                handleCancel,
                optionSelected,
                isTodayPayoff,
            }}
        />
        <Stack.Screen
            name='DateMakeAPaymentScreen'
            component={DateMakeAPaymentScreen}
            initialParams={{
                dueDate,
            }}
        />
    </Stack.Navigator>
);
