import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import {
    AccountLargeIcon,
    CalendarIconSvg,
    EverfiIcon,
    MegafonoSvg,
    PaymentAmountSvg,
    SchedulePayment,
} from '@Assets/index';
import { getBalanceAPI } from '@Home/api/balanceApi';
import {
    ActionCardsItem,
    Balance,
    BalanceSubscription,
    GetBalanceRequestAction,
    GetBalanceResponse,
} from '@Home/interfaces/index';
import BalanceService from '@Home/services/BalanceService';
import BalanceActionTypes from '@Home/state/balanceActionTypes';
import Languages from '@i18n/index';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import { Loan } from '@Profile/interfaces';
import { parseDate } from '@Utils/FormatUtils';
import { formatAmount } from '@Utils/index';
import { format } from 'date-fns';

export const getBalanceEpic = ($action: Observable<any>, $state: any) => $action.pipe(
    ofType(BalanceActionTypes.GET_BALANCE_REQUEST_ACTION),
    mergeMap(({ payload }: GetBalanceRequestAction) => from(getBalanceAPI({
        ...payload,
    })).pipe(
        map((response: GetBalanceResponse) => {
            let balance: Balance = {
                current: formatAmount(response.balance?.units.toFixed(2) ?? '0.00'),
                latestTransactionAmount: formatAmount(response.latestTransactionAmount?.units.toFixed(2) ?? '0.00'),
                latestTransactionDate: parseDate(response.latestTransactionDate, 'MM/dd/yyyy'),
                nextPaymentAmount: formatAmount(response.nextPaymentAmount?.units.toFixed(2) ?? '0.00'),
                nextPaymentAmountDate: parseDate(response.nextPaymentDate, 'MM/dd/yyyy'),
                nextPendingPaymentDate: parseDate(response.nextPendingPaymentDate, 'dd/MMMM/yyyy'),
                nextPaymentDate: parseDate(response.nextPaymentDate, 'MM/dd/yyyy'),
                rebateProgramEndDate: parseDate(response.rebateProgramEndDate, 'dd/MMMM/yyyy'),
                totalLoan: formatAmount(response.totalLoanAmount?.units.toFixed(2) ?? '0.00'),
                fccpDaysLeft: response.daysLeft
            };

            

            let cardToBeShown : ActionCardsItem = {
                id: 4,
                icon: AccountLargeIcon,
                actionName: 'Account Details',
                title: 'Account Details',
                subtitle: '',
                action: 'Account Details',
                endDate: '',
                type: 'Account Details',
            };

            if(response.daysLeft > 0 ){
                cardToBeShown = {
                    id: 2,
                    icon: MegafonoSvg,
                    actionName: `${Languages.RebateProgramEndDateDays}`,
                    subtitle: '',
                    title: `${response.daysLeft} days`,
                    action: `${Languages.LearnMore}`,
                    endDate: `${balance?.rebateProgramEndDate ?? '--/--/--'}`,
                    type: 'Rebate',
                }
            }

            if(response.daysLeft == 1 ){
                cardToBeShown.subtitle = `${Languages.RebateProgramEndDateDaysDetails}`;
                cardToBeShown.title = '' ;
            }

            const hasNoPreviousTransactions = !balance?.latestTransactionDate || balance.latestTransactionDate === null;

            const { value } = $state;
            const { applicationsReducer } = value;
            const loanSelected: Loan = applicationsReducer.loanSelected;

            const actionCards: ActionCardsItem[] = [
                {
                    id: 7,
                    icon: SchedulePayment,
                    actionName: `${Languages.NextDueDate}`,
                    title: `${format(new Date(balance?.nextPaymentDate), 'MMM dd, yyyy') ?? '--/--/--'}`,
                    subtitle: '',
                    action: `${Languages.PayNow}`,
                    endDate: `${balance?.rebateProgramEndDate ?? '--/--/--'}`,
                    type: 'Pay',
                    disabled: !loanSelected.permissions.canMakeAPayment,
                },
                {
                    id: 1,
                    icon: PaymentAmountSvg,
                    actionName: `${Languages.NextPaymentAmount}`,
                    title: `$${balance?.nextPaymentAmount ?? '0.00'}`,
                    subtitle: '',
                    action: `${Languages.Billing}`,
                    endDate: `${balance?.rebateProgramEndDate ?? '--/--/--'}`,
                    type: 'Payments',
                    disabled: !loanSelected.permissions.canMakeAPayment,
                },
                cardToBeShown
                ,
                {
                    id: 3,
                    icon: CalendarIconSvg,
                    actionName: `${Languages.LatestTransaction}`,
                    title: `$${balance?.latestTransactionAmount ?? '0.00'}`,
                    subtitle: `${format(new Date(balance?.latestTransactionDate), 'MMM dd, yyyy') ?? '--/--/--'}`,
                    action: `${Languages.SeeActivity}`,
                    endDate: `${balance?.rebateProgramEndDate ?? '--/--/--'}`,
                    type: 'Activity',
                    ...hasNoPreviousTransactions && {
                        id: 5,
                        icon: CalendarIconSvg,
                        actionName: `${Languages.LatestTransaction}`,
                        title: Languages.NoActivity,
                        subtitle: '',
                        action: `${Languages.SeeActivity}`,
                        endDate: '',
                        type: 'Activity',
                    },
                }
            ];

            const result: BalanceSubscription = {
                balance,
                actionCards,
            };

            BalanceService.onSuccess(result);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            return of(loadingDialogHide());
        })
    ))
);
