import { createAction } from 'typesafe-actions';

import {
    GetOffersRequest,
    SaveNotificationRequest,
} from '@Notifications/interfaces';
import NotificationsActionsTypes from '@Notifications/state/notificationsActionsTypes';

export const notificationsAction = createAction(
    NotificationsActionsTypes.GET_NOTIFICATIONS_REQUEST,
)();

export const saveNotificationsAction = createAction(
    NotificationsActionsTypes.SAVE_NOTIFICATIONS_REQUEST,
    (request: SaveNotificationRequest) => (request),
)();

export const clearNotificationBadge = createAction(
    NotificationsActionsTypes.CLEAR_NOTIFICATIONS_BADGE,
)();

export const getNotificationOffersRequestAction = createAction(
    NotificationsActionsTypes.GET_NOTIFICATIONS_OFFERS,
    (request: GetOffersRequest) => (request),
)();
