import LoggerService from '@Logger/services/LoggerService';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface StorageResponse {
    success: boolean;
    error?: string;
    value?: any;
};

interface StorageRequest {
    data?: any;
    key: string;
};

export enum StorageKeys {
    BIOMETRICS = '@biometrics_key',
    BIOMETRICS_DIALOG_WAS_DISPLAYED = '@biometricsDialogWasDisplayed',
    FIRST_TIME_USERS = '@fistTimeUsers',
    LOAN_SELECTED = '@LOAN_SELECTED',
    LOGIN_METADATA = '@loginMetadata',
    PROFILE_MENU = '@profileMenu',
    PROFILE_USER_INFO = '@profileUserInfo',
    REVIEWED_APP = '@reviewedApp',
};

export const useStorage = () => {
    const storeData = async (request: StorageRequest): Promise<StorageResponse> => {
        try {
            const { data, key } = request;
            await AsyncStorage.setItem(key, JSON.stringify(data));
            return {
                success: true,
                value: data,
            };
        } catch (error) {
            return {
                success: false,
                error: `${error}`,
            };
        }
    };

    const getData = async (request: StorageRequest): Promise<StorageResponse> => {
        try {
            const { key } = request;
            const jsonValue = await AsyncStorage.getItem(key);
            const paseValue = jsonValue !== null ? JSON.parse(jsonValue) : '';
            return {
                success: paseValue !== null && paseValue !== '',
                value: paseValue,
            };
        } catch (error) {
            LoggerService.log('>>>>> useStorage getData request error', request, error);
            return {
                success: false,
                error: `${error}`,
            };
        }
    };

    const clearAll = async () => {
        try {
            await AsyncStorage.clear();
        } catch (e) {
            // clear error
        }
    };

    return {
        clearAll,
        getData,
        storeData,
    };
};
