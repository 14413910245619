import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    LogBox,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { Subscription } from 'rxjs';

import {
    AddNewIcon,
    QuestionActiveIcon,
} from '@Assets/index';
import { AppSwitch } from '@Components/AppSwitch';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import { PaymentMethodCard } from '@MethodsOfPayment/components/PaymentMethodCard';
import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import { PrimaryMethodInfoModal } from '@MethodsOfPayment/modals/PrimaryMethodInfoModal';
import { PrimaryMethodModal } from '@MethodsOfPayment/modals/PrimaryMethodModal';
import MethodsOfPaymentService from '@MethodsOfPayment/services/MethodsOfPaymentService';
import PaymentOfMethodService from '@MethodsOfPayment/services/PaymentOfMethodService';
import PrimaryMethodInfoModalService
    from '@MethodsOfPayment/services/PrimaryMethodInfoModalService';
import { getPaymentMethodsAction } from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import { styles } from '@MethodsOfPayment/styles/MethodsOfPaymentStyle';
import {
    colors,
    theme,
} from '@Theme/appTheme';

import { AddPaymentMethodDialog } from './AddPaymentMethodDialog';

LogBox.ignoreLogs(['Animated']);

const renderItemHOC = () => (data: any) => (
    <PaymentMethodCard
        data={data}
        handleDelete={() => { }}
        handleEdit={() => { }}
    />
);

export const MethodsOfPaymentsScreen = () => {
    const dispatch = useAppDispatch();
    const { loanSelected } = useAppSelector(({ applicationsReducer }) => applicationsReducer);

    let MethodsSubs: Subscription = new Subscription();

    const paymentMethodsRef = useRef<PaymentMethodResponseBase[]>([]);

    const [payments, setPayments] = useState<number[]>([]);
    const [isAutoPay, setIsAutoPay] = useState(true);

    const renderItem = useCallback(renderItemHOC(), []);
    const [isMakeAPayment, setIsMakeAPayment] = useState<boolean>(false);


    useEffect(() => {
        if (loanSelected?.loanId) {
            const {
                loanId,
            } = loanSelected;
            dispatch(getPaymentMethodsAction(loanId));
        }
    }, []);

    useEffect(() => {
        MethodsSubs = MethodsOfPaymentService.subscribe((state: any) => {
            if (state.length || state.success) {
                const paymentMethods: any[] = state.map((x: PaymentMethodResponseBase) => x);
                paymentMethodsRef.current = paymentMethods;
                const paymentIds = paymentMethods.map((x) => x.id);
                setPayments(paymentIds);
            }
        });

        const PaymentsMethodsSubs = PaymentOfMethodService.subscribe((state: any) => {
            const { success } = state;
            if (success) {
                if (loanSelected?.loanId) {
                    const {
                        loanId,
                    } = loanSelected;

                    dispatch(getPaymentMethodsAction(loanId));
                }
            }
        });
        return () => {
            MethodsSubs.unsubscribe();
            PaymentsMethodsSubs.unsubscribe();
        };
    }, []);

    const handleAddNewPayment = () => setIsMakeAPayment(true);

    const handleOnTapPrimaryMethod = () => {
        requestAnimationFrame(() => {
            PrimaryMethodInfoModalService.show();
        });
    };

    const footerAddNew = () => (
        <View style={styles.footerContainer}>
            <TouchableOpacity
                style={styles.containerButton}
                disabled={!loanSelected?.permissions.canAddMethodOfPayment}
                onPress={handleAddNewPayment}
            >
                <AddNewIcon
                    fill={loanSelected?.permissions.canAddMethodOfPayment
                        ? theme.colors.primary
                        : colors.darkGray}
                />
                <Text style={[
                    styles.textAddNew,
                    !loanSelected?.permissions.canAddMethodOfPayment && styles.textAddNewDisable,
                ]}>{Languages.AddNew}</Text>
            </TouchableOpacity>
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={styles.containerAutoPay}>
                <Text style={styles.textAutopay}>{Languages.AutoPay}</Text>
                <AppSwitch
                    onChange={(isOn: boolean) => setIsAutoPay(isOn)}
                    isOn={isAutoPay}
                />
            </View>

            <View style={styles.containerPrimaryPayment}>
                <View style={styles.containerPrimaryPaymentTitle}>
                    <Text style={styles.titlePaymentPrimary}>{Languages.PrimaryMethodOfPayment}</Text>
                    <TouchableOpacity onPress={handleOnTapPrimaryMethod} >
                        <QuestionActiveIcon fill={theme.colors.primary} />
                    </TouchableOpacity>
                    <PrimaryMethodInfoModal />
                </View>
            </View>

            <View style={styles.containerListPayment}>
                <FlatList
                    data={payments}
                    scrollEnabled={true}
                    extraData={payments}
                    renderItem={renderItem}
                    keyExtractor={(item: any) => item.toString()}
                    contentContainerStyle={styles.contentContainerListPayment}
                    ListFooterComponent={footerAddNew}
                />
            </View>
            <AddPaymentMethodDialog isVisible={isMakeAPayment} />
            <PrimaryMethodModal />
        </View>
    );
};
