import {
    colors,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

export const styles = AppStyleSheet({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.background,
    },
    containerGeneral: {
        width: '85%',
        height: '80%',
        paddingTop: '16%',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    imgContainer: {
        width: '50%',
        height: '26%',
        alignItems: 'center',
        justifyContent: 'center',
        resizeMode: 'center',
    },
    imgLogo: {
        width: '100%',
        height: '100%',
        resizeMode: 'center',
    },
    buttonsContainer: {
        width: '80%',
        height: '55%',
        justifyContent: 'flex-end',
    },
    btnRegisterStyle: {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: theme.colors.primary,
    },
    marginBottomSignIn: { marginBottom: 15 },
    textBtnStyle: {
        color: colors.white,
        fontSize: 17,
        fontWeight: 'bold',
    },
    textBtnStyleRegister: {
        color: theme.colors.primary,
        fontSize: 17,
        fontWeight: 'bold',
    },
    typographyLogo: {
        marginTop: 25,
    },
});
