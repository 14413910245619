import React, {
    useEffect,
    useState,
} from 'react';

import {
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { AppModal } from '@Components/AppModal';
import TermsOfUseService from '@Dialogs/services/TermsOfUseService';
import {
    fonts,
    globalStyle,
} from '@Theme/appTheme';
import WebView from 'react-native-webview';

const isWeb = Platform.OS === 'web';
const touParagraphs = [
    `
    By submitting an application to the Seller (the Merchant) or Third-Party Loan Provider, as applicable, you agree to these Terms and Conditions. You promise you are at
    least 18 years old and the information you supplied is true and correct. You authorize Seller or Third-Party Loan Provider, as applicable, and its agents, servicers, and
    assigns, including Duvera Billing Services, LLC (dba EasyPay Finance), PO Box 2549 Carlsbad, CA 92018-2549, and any EasyPay Finance subsidiary or affiliate (collectively,
    “Provider”), to make all inquiries deemed necessary to verify the accuracy of the information you provided in the application and to access your credit report for the
    purpose of reviewing your loan or credit agreement (the “Agreement”), taking collection action on the Agreement, or any other legitimate purpose. You authorize
    EasyPay Finance to communicate to Seller or Third-Party Loan Provider, as applicable, whether you meet its credit standards and, if so, on what terms. You fully release
    all parties from claims of damage that may arise out of or relate to any such inquiries. You understand that your application will be denied if any of your application
    information cannot be verified. Upon your request, we will inform you whether or not a consumer credit report was ordered, and if so, the name and address of the
    consumer reporting agency that furnished the report.`,
    `
    AUTHORIZATION TO OBTAIN CREDIT REPORTS IN CONNECTION WITH CREDIT TRANSACTIONS YOU DO NOT INITIATE — In addition to the credit reports you
    authorize above in connection with the credit requested in this Application, you also authorize any consumer reporting agency to provide your credit report
    to Provider from time to time in the future for the purpose of determining your eligibility for future credit offers that we believe may be of interest to you.
    This authorization allows Provider to obtain your credit report in connection with these offers without further notice or consent or receipt of any specific
    request for credit from you. This authorization is effective during and after the term of this Agreement.`,
    `
    You agree that Provider may contact you in writing, by e-mail, or using prerecorded/artificial voice messages, text messages, and automatic dialing/sending systems, to
    the full extent allowed by law. You also agree that Provider and its assignees may contact you in these and other ways at any address or telephone number you provide,
    even if the telephone number is a cell phone number or the contact results in a charge to you.`,
    `
    IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT — To help the government fight the funding of terrorism and money laundering
    activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for
    you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your
    driver’s license or other identifying documents.`
]

export const TermsOfUseDialog = () => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const subscription = TermsOfUseService.subscribe((state: any) => {
            setIsShown(state.show);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <AppModal
            animationType='slide'
            transparent
            visible={isShown}
        >
            <View style={styles.container}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title} numberOfLines={2}>Welcome to EasyPay Finance</Text>
                    <AppButton
                        title={'X'}
                        buttonStyle={styles.button}
                        handleOnChange={() => setIsShown(false)}
                    />
                </View>
                <View style={styles.dataContainer}>
                    <ScrollView
                        bounces={false}
                        scrollEnabled={true}
                        style={{ width: '100%' }}
                        contentContainerStyle={{ flexGrow: 1 }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                    >
                        {
                            isWeb ?
                                (
                                    <View>
                                        <Text style={styles.data}>{touParagraphs[0]}</Text>
                                        <Text style={[styles.data, { fontWeight: 'bold' }]}>{touParagraphs[1]}</Text>
                                        <Text style={styles.data}>{touParagraphs[2]}</Text>
                                        <Text style={styles.data}>{touParagraphs[3]}</Text>
                                    </View>
                                ) :
                                (
                                    <WebView originWhitelist={['*']} source={{
                                        html: `<div style="font-size: 3.2em;text-align:justify;line-break: normal;">
                                            ${touParagraphs[0]}
                                            <br /><br />
                                            <b>${touParagraphs[1]}</b> 
                                            <br /><br />
                                            ${touParagraphs[2]}
                                            <br /><br />
                                            ${touParagraphs[3]}
                                        </div>`
                                    }} />
                                )
                        }
                    </ScrollView>
                </View>
            </View>
        </AppModal>
    );
};

const styles = StyleSheet.create({
    button: {
        width: 30,
        height: 30,
        minWidth: 20,
        borderRadius: 100,
    },
    container: {
        flex: 100,
        backgroundColor: 'white',
        borderRadius: 10,
        margin: 16,
        padding: 16,
        ...globalStyle.dropShadow,
    },
    data: {
        textAlign: 'justify',
        fontSize: 15,
        fontFamily: fonts.SFProDisplay,
        lineHeight: 21
    },
    dataContainer: {
        flex: 90,
        flexDirection: 'row'
    },
    title: {
        flex: 1,
        fontSize: 24,
        fontFamily: fonts.SFProDisplaySemibold,
        lineHeight: 33,
    },
    titleContainer: {
        flex: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
});
