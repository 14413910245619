import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import { NotificationsState } from '@Notifications/interfaces';
import * as notificationsActions from '@Notifications/state/notificationsActions';

type ActionsTypes = ActionType<typeof notificationsActions>;

const initialState: NotificationsState = {
    [0]: undefined,
};

export const notificationsReducer = createReducer<NotificationsState, ActionsTypes>(
    initialState
).handleAction(notificationsActions.saveNotificationsAction, (state, { payload }) => ({
    ...state,
    [payload.applicationId]: {
        ...payload.data,
    },
}));
