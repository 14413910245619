import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import * as surveyActions from '@Home/state/delightedSurveyActions';

type ActionsTypes = ActionType<typeof surveyActions>;

interface DelightedSurveyState {
    answered: boolean;
};

export const initialState = {
    answered: false
};

export const delightedSurveyReducer = createReducer<DelightedSurveyState, ActionsTypes>(
    initialState,
).handleAction(
    surveyActions.getSurveyAnsweredRequestAction,
    () => initialState
).handleAction(
    surveyActions.getSurveyAnsweredRequestActionSuccess,
    (state, action) => ({
        ...state,
        answered: action.payload.success
    })
).handleAction(
    surveyActions.setSurveyAnsweredRequestAction,
    () => ({
        answered: true
    }),
);

