import { createAction } from 'typesafe-actions';

import { VerificationCodeDialogRequest } from '@VerificationCodeDialog/interfaces';
import VerificationCodeDialogActionTypes
    from '@VerificationCodeDialog/state/verificationCodeDialogActionTypes';

export const verificationCodeDialogShow = createAction(
    VerificationCodeDialogActionTypes.VERIFICATION_CODE_DIALOG_SHOW,
    (request: VerificationCodeDialogRequest) => (request),
)();

export const verificationCodeDialogHide = createAction(
    VerificationCodeDialogActionTypes.VERIFICATION_CODE_DIALOG_HIDE,
)();
