import React, { useEffect } from 'react';

import { View } from 'react-native';

import { EasypayLogoSvg } from '@Assets/index';
import RegisterBusinessCenterService, {
    RegisterBusiness,
} from '@Auth/services/RegisterBusinessCenterService';
import { registerByEmailBusinessCenterValidateAction } from '@Auth/state/registerActions';
import { useAppDispatch } from '@Hooks/appHooks';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { reset } from '@Navigation/RootNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { theme } from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'BusinessCenterValidationScreen'> { };

export const BusinessCenterValidationScreen = ({ route }: Props) => {
    const dispatch = useAppDispatch();
    const {
        token,
    } = route?.params || {};

    useEffect(() => {
        const subscription = RegisterBusinessCenterService.subscribe((state: RegisterBusiness) => {
            const { action, success: isSuccess, email } = state;
            if (action === 'validation') {
                if (isSuccess) {
                    goToNextScreen(token, email);
                } else {
                    goToLogin();
                }
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        dispatch(registerByEmailBusinessCenterValidateAction({
            content: token,
        }));
    }, [token]);

    const goToNextScreen = (token?: string, email?: string) => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
                {
                    name: 'RegisterScreen',
                    params: {
                        disableEmailField: true,
                        registerBusinessCenterParams: {
                            content: token,
                            email,
                        },
                    },
                },
            ],
        });
    };

    const goToLogin = () => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
            ],
        });
    };

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.primary,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <EasypayLogoSvg />
        </View>
    );
};
