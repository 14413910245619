import { createAction } from 'typesafe-actions';

import LoadingDialogActionTypes from '@LoadingDialog/state/loadingDialogActionTypes';

export const loadingDialogShow = createAction(
    LoadingDialogActionTypes.LOADING_DIALOG_SHOW,
)();

export const loadingDialogHide = createAction(
    LoadingDialogActionTypes.LOADING_DIALOG_HIDE,
)();
