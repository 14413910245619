import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import {
    LogOutIcon,
    ProfileIcon,
} from '@Assets/index';
import { authenticateLogoutAction } from '@Auth/state/authenticateActions';
import { SectionHeaderComponent } from '@Components/SectionHeaderComponent';
import { ShimmerApp } from '@Components/ShimerApp';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoadingService from '@LoadingDialog/service/LoadingService';
import LoggerService from '@Logger/services/LoggerService';
import { ProfileTopTabsStack } from '@Navigation/ProfileTopTabsStack';
import { getUserInfoAction } from '@Profile/state/profileActions';
import { styles } from '@Profile/styles/ProfileScreenWebStyles';
import { theme } from '@Theme/appTheme';

export const ProfileScreen = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const isComponentMountedRef = useRef(true);

    const { userInfo } = useAppSelector(
        ({ profileReducer }) => profileReducer,
    );

    const greet = () => isComponentMountedRef.current && setIsLoading(false);

    useEffect(() => {
        if (!isComponentMountedRef.current) {
            return;
        }
        setTimeout(greet, 1000);
        dispatch(getUserInfoAction());
        return () => {
            isComponentMountedRef.current = false;
        };
    }, []);

    const doLogOut = () => {
        LoadingService.show();
        LoggerService.logEvent('logOut', {
            from: 'profile',
        });
        dispatch(authenticateLogoutAction());
    };

    const header = () => (
        <View style={styles.containerHeader}>
            <View style={styles.profileIconContainer}>
                <ShimmerApp
                    visible={Boolean(!isLoading)}
                    shimmerStyle={styles.shimmerIcon}
                >
                    <ProfileIcon fill={theme.colors.primary} />
                </ShimmerApp>
            </View>
            <ShimmerApp
                visible={Boolean(!isLoading)}
                shimmerStyle={styles.shimmerText}
            >
                <Text style={styles.name}>{`${userInfo.firstName} ${userInfo.lastName}`}</Text>
            </ShimmerApp>
            <ShimmerApp
                visible={Boolean(!isLoading)}
                shimmerStyle={styles.shimmerTextEmail}
            >
                <Text style={styles.email}>{userInfo.email}</Text>
            </ShimmerApp>
            <TouchableOpacity
                accessibilityLabel={'profileScreenTouchableLogOut'}
                onPress={doLogOut}
                style={styles.logOutIconContainer}
                testID={'profileScreenTouchableLogOut'}
            >
                <ShimmerApp
                    visible={Boolean(!isLoading)}
                    shimmerStyle={styles.shimmerIconLogOut}
                >
                    <LogOutIcon
                        fill={theme.colors.primary}
                        width={16.67}
                        height={25}
                    />
                </ShimmerApp>
                <ShimmerApp
                    visible={Boolean(!isLoading)}
                    shimmerStyle={styles.shimmerTextLogOut}
                >
                    <Text style={styles.logOut}>{Languages.LogOut}</Text>
                </ShimmerApp>
            </TouchableOpacity>
        </View>
    );

    return (
        <View style={styles.container}>
            <ScrollView style={{ flex: 100 }} showsVerticalScrollIndicator={false}>
                <View style={styles.viewTextTitle}>
                    <SectionHeaderComponent
                        title={Languages.Profile}
                    />
                </View>
                <View style={styles.containerBody}>
                    <View style={styles.containerLeft}>
                        {header()}
                    </View>
                    <View style={styles.containerRight}>
                        <ProfileTopTabsStack />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};
