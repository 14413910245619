import { ArrowRightIcon, CheckIcon, ManageAutoPayIcon, ManageCreditCardIcon } from "@Assets/index";
import { styles } from "@MakeAPayment/styles/ManageAutoPayStyle";
import { RootStackParamList } from "@Navigation/BottomTabNavigator";
import { theme } from "@Theme/appTheme";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { View, Text, FlatList, TouchableOpacity } from "react-native";
import Languages from '@i18n/index';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import { getPaymentMethodAutoPayAction, getPaymentMethodsAction, patchPaymentSetPrimaryAction, putPaymentMethodAutoPayAction, setMethodOfPaymentSelected, setPaymentMethodAutoPayAction } from "@MethodsOfPayment/state/MethodsOfPaymentActions";
import MethodsOfPaymentService from "@MethodsOfPayment/services/MethodsOfPaymentService";
import { PaymentMethodResponseBase, TypesMethod } from "@MethodsOfPayment/interfaces";
import { PaymentMethodItem } from "@MakeAPayment/components/PaymentMethodItem";
import { AppSwitch } from "@Components/AppSwitch";
import { AppAccordion } from "@Components/AppAccordion";
import { AppButton } from "@Components/AppButton";
import { ScrollView } from "react-native-gesture-handler";
import { ConfirmSubmitAutopayMethod } from "@MakeAPayment/components/ConfirmSubmitAutopayMethod";
import { ConfirmPaymentUpdatedModal } from "@MakeAPayment/components/ConfirmPaymentUpdatedModal";
import { useIsFocused } from "@react-navigation/native";
import { ConfirmDisableAutopayModal } from "@MakeAPayment/components/ConfirmDisableAutopayModal";

interface Props extends StackScreenProps<RootStackParamList, 'ManageAutoPayScreen'> { }

export const ManageAutoPayScreen = ({ navigation }: Props) => {
    const dispatch = useAppDispatch();
    const { autopay: hasAutopay } = useAppSelector(({ methodsOfPaymentReducer }) => methodsOfPaymentReducer);
    const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
    const [previusAutoPayMethod, setPreviusAutoPayMethod] = useState<PaymentMethodResponseBase | undefined>();
    const [autoPayMethod, setAutoPayMethod] = useState<PaymentMethodResponseBase | undefined>();
    const [autoPayMethods, setAutoPayMethods] = useState<PaymentMethodResponseBase[] | undefined>();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodResponseBase | undefined>();
    const { loanSelected } = useAppSelector(({ applicationsReducer }) => applicationsReducer);
    const [isPaymentMethodSectionExpanded, setIsPaymentMethodSectionExpanded] = useState<boolean>(false);
    const [isNewPaymentMethodSectionExpanded, setIsNewPaymentMethodSectionExpanded] = useState<boolean>(false);
    const [showConfirmSubmitAutopayMethod, setShowConfirmSubmitAutopayMethod] = useState<boolean>(false);
    const [showConfirmPaymentUpdatedModal, setShowConfirmPaymentUpdatedModal] = useState<boolean>(false);
    const [showConfirmDisableAutopayModal, setShowConfirmDisableAutopayModal] = useState<boolean>(false);

    const isFocused = useIsFocused();

    const handleOnChangeAutopay = () => {
        if (!autoPayMethod) {
            return;
        }

        const currentAutoPay = !isAutoPay;
        setIsAutoPay(previousState => !previousState);

        if (loanSelected) {
            dispatch(putPaymentMethodAutoPayAction({
                loanId: loanSelected.loanId,
                autopay: currentAutoPay,
            }));
            dispatch(setPaymentMethodAutoPayAction({ autopay: currentAutoPay }));
            setAutoPayMethod(undefined);
            handleOnCancel();
        }
        setShowConfirmDisableAutopayModal(false);
    };

    useEffect(() => {
        if (loanSelected?.loanId) {
            const {
                loanId,
            } = loanSelected;
            dispatch(getPaymentMethodsAction(loanId));
            dispatch(getPaymentMethodAutoPayAction({ loanId }));
        }
    }, [loanSelected]);

    useEffect(() => {
        setIsAutoPay(hasAutopay || false);
    }, [hasAutopay]);

    useEffect(() => {
        const methodsSubs = MethodsOfPaymentService.subscribe((state: any) => {
            if (state.length || state.success) {
                const paymentMethods: PaymentMethodResponseBase[] = state.map((x: PaymentMethodResponseBase) => x).sort((a: PaymentMethodResponseBase, b: PaymentMethodResponseBase) => a.isPrimary ? -1 : 1);
                const currentPaymentMethod = paymentMethods.find(x => x.isPrimary);
                if (previusAutoPayMethod && previusAutoPayMethod?.id != currentPaymentMethod?.id) {
                    setPreviusAutoPayMethod(undefined);
                    setShowConfirmPaymentUpdatedModal(true);
                }
                setAutoPayMethod(currentPaymentMethod);
                setAutoPayMethods(paymentMethods.filter(x => !x.isPrimary))
            }
        });
        return () => {
            methodsSubs.unsubscribe();
        };
    }, [autoPayMethod]);

    useEffect(() => {
        isFocused && refreshPaymentMethods();
    }, [isFocused]);

    const handleAddNewCheckingPayment = () => navigation.navigate('AddPaymentMethodScreen', { paymentType: TypesMethod.ACH });
    const handleAddNewCreditPayment = () => navigation.navigate('AddPaymentMethodScreen', { paymentType: TypesMethod.CREDITCARD });

    const handleOnSubmit = () => {
        if (selectedPaymentMethod) {
            dispatch(patchPaymentSetPrimaryAction({
                loanId: loanSelected?.loanId || 0,
                paymentMethodId: selectedPaymentMethod?.id,
                paymentMethodType: selectedPaymentMethod?.type,
            }));
            dispatch(setPaymentMethodAutoPayAction({ autopay: true }));
            dispatch(setMethodOfPaymentSelected({ id: selectedPaymentMethod.id, value: false, clear: true }));
            setPreviusAutoPayMethod(autoPayMethod);
            setAutoPayMethod(selectedPaymentMethod);
            refreshPaymentMethods();
            setShowConfirmSubmitAutopayMethod(false);
            handleOnCancel();
        }
    }

    const handleAppSwitch = () => {
        if (isAutoPay) {
            setShowConfirmDisableAutopayModal(true);
        } else {
            setIsPaymentMethodSectionExpanded(true);
        }
    };

    const refreshPaymentMethods = () => {
        handleOnCancel();
        const loanId = loanSelected?.loanId || 0;
        dispatch(getPaymentMethodsAction(loanId));
        dispatch(getPaymentMethodAutoPayAction({ loanId }));
    }

    const handleOnCancel = () => {
        setSelectedPaymentMethod(undefined);
        setIsPaymentMethodSectionExpanded(false);
        setIsNewPaymentMethodSectionExpanded(false)
    }

    return (
        <ScrollView>
            <View style={styles.manageAutoPayContainer}>
                <View style={styles.autopayToggleContainer}>
                    <ManageAutoPayIcon />
                    <Text style={styles.toggleText}>{Languages.AutoPay}</Text>
                    <Text style={styles.isAutopayToggleText}>{isAutoPay ? Languages.ON : Languages.OFF}</Text>
                    <AppSwitch
                        accessibilityLabel={'manageAutoPayScreenAppSwitchAutoPay'}
                        disabled={!loanSelected?.permissions.canSetAutoPay}
                        isOn={isAutoPay}
                        onChange={handleAppSwitch}
                        testID={'manageAutoPayScreenAppSwitchAutoPay'}
                    />
                </View>
                <View style={styles.selectedPaymentMethodContainer}>
                    <Text style={styles.title}>{Languages.SelectedPaymentMethod}</Text>
                    {isAutoPay && autoPayMethod ?
                        (
                            <PaymentMethodItem
                                item={autoPayMethod}
                                isSelected={true}
                                handleonPress={(item) => item} />
                        ) :
                        (
                            <View style={styles.containerAutoPayIsOff}>
                                <Text style={styles.titleAutoPayIsOff}>{Languages.AutoPayIsOff}</Text>
                                <Text style={styles.bodyAutoPayIsOff}>{Languages.AutoPayTurnedOffDescription}</Text>
                            </View>
                        )}
                </View>
                <AppAccordion
                    title={Languages.TitleChangeAutoPayPaymentMethod}
                    isExpanded={isPaymentMethodSectionExpanded}
                    handleOnPress={() => {
                        setIsPaymentMethodSectionExpanded(value => !value);
                    }}
                >
                    <View style={styles.containerListPayment}>
                        <View style={styles.separator} />
                        <Text style={styles.storedPaymentTitle}>
                            <Text style={{ color: 'red' }}>*</Text> {Languages.SelectFromStoredPaymentMethods}</Text>
                        <FlatList
                            data={autoPayMethods}
                            scrollEnabled={true}
                            extraData={autoPayMethods}
                            contentContainerStyle={styles.contentContainerListPayment}
                            renderItem={data => PaymentMethodItem({
                                item: data.item,
                                isSelected: selectedPaymentMethod?.id == data.item.id,
                                handleonPress(item) {
                                    setSelectedPaymentMethod(item);
                                },
                            })}
                            keyExtractor={(item: any, index: number) => item.id + index.toString()}
                        />
                        <View style={styles.submitStoredPaymentContainer}>
                            <TouchableOpacity style={styles.cancelButtonContainer} onPress={handleOnCancel}>
                                <Text style={styles.cancelButtonText}>
                                    {Languages.Cancel}
                                </Text>
                            </TouchableOpacity>
                            <AppButton
                                accessibilityLabel={'congratulationsModalAppButtonAccept'}
                                buttonStyle={styles.submitButtonText}
                                handleOnChange={() => setShowConfirmSubmitAutopayMethod(true)}
                                testID={'congratulationsModalAppButtonAccept'}
                                title={Languages.Submit}
                                disabled={!selectedPaymentMethod}
                            />
                        </View>
                        <View style={styles.separator} />
                    </View>
                </AppAccordion >
                {
                    (isPaymentMethodSectionExpanded) && (
                        <AppAccordion
                            title={Languages.AddNewPaymentMethod}
                            isExpanded={isNewPaymentMethodSectionExpanded}
                            handleOnPress={() => setIsNewPaymentMethodSectionExpanded(value => !value)}
                        >
                            <View style={styles.newPaymentMethodContainer}>
                                <View style={styles.separator} />
                                <TouchableOpacity style={styles.newPaymentMethodRow} onPress={handleAddNewCheckingPayment}>
                                    <View style={styles.newPaymentMethodIconContainer}>
                                        <CheckIcon fill={theme.colors.primary}></CheckIcon>
                                    </View>
                                    <View style={styles.newPaymentMethodTexContainer}>
                                        <Text style={styles.newPaymentMethodTextLabel}>{Languages.AddBankAccount}</Text>
                                    </View>
                                    <View style={styles.newPaymentMethodIconContainer}>
                                        <ArrowRightIcon></ArrowRightIcon>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.newPaymentMethodRow} onPress={handleAddNewCreditPayment}>
                                    <View style={styles.newPaymentMethodIconContainer}>
                                        <ManageCreditCardIcon />
                                    </View>
                                    <View style={styles.newPaymentMethodTexContainer}>
                                        <Text style={styles.newPaymentMethodTextLabel}>{Languages.AddCreditCard}</Text>
                                    </View>
                                    <View style={styles.newPaymentMethodIconContainer}>
                                        <ArrowRightIcon></ArrowRightIcon>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </AppAccordion>
                    )
                }
                <ConfirmSubmitAutopayMethod
                    show={showConfirmSubmitAutopayMethod}
                    cardType={selectedPaymentMethod?.paymentNetwork ?? ''}
                    lastFourDigits={selectedPaymentMethod?.lastFourDigit ?? ''}
                    handleAccept={handleOnSubmit}
                    handleCancel={() => setShowConfirmSubmitAutopayMethod(false)}
                />
                <ConfirmPaymentUpdatedModal
                    show={showConfirmPaymentUpdatedModal}
                    handleClose={() => setShowConfirmPaymentUpdatedModal(false)}
                />
                <ConfirmDisableAutopayModal
                    show={showConfirmDisableAutopayModal}
                    handleAccept={handleOnChangeAutopay}
                    handleCancel={() => { setShowConfirmDisableAutopayModal(false); }}
                />
            </View >
        </ScrollView>
    );
}