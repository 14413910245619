import {
    Analytics,
    getAnalytics,
} from 'firebase/analytics';
import {
    FirebaseApp,
    initializeApp,
} from 'firebase/app';

class FirebaseService {
    static app: FirebaseApp;
    static analytics: Analytics;

    static initialize() {
        const firebaseConfig = {
            apiKey: 'AIzaSyAQ4eSN7ZL-iu6T3dDZmuG1yvUoYwOismo',
            authDomain: 'myeasypay.firebaseapp.com',
            databaseURL: 'https://myeasypay-default-rtdb.firebaseio.com',
            projectId: 'myeasypay',
            storageBucket: 'myeasypay.appspot.com',
            messagingSenderId: '332803541050',
            appId: `1:332803541050:web:${process.env.NODE_ENV === 'development' ? '1ef1501a0b9f0d428b0d03' : 'a5ee8f9e96fe413b8b0d03'}`,
            measurementId: process.env.NODE_ENV === 'development' ? 'G-RQBDRJDHBL' : 'G-2MHT4NKTP5',
        };

        // Initialize Firebase
        this.app = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(FirebaseService.app);
    };

    static getAnalytics(): Analytics {
        return this.analytics;
    };

};

export default FirebaseService;
