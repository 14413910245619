import {
    Response,
    UnitsResponse,
} from '@Interfaces/BaseResponse';
import { PaymentsMethodResponse } from '@MethodsOfPayment/interfaces';

export interface PaymentActivity {
    page: number;
    totalPages: number;
    entities: Entity[];
    source: 'widget' | 'screen' | 'recent';
};

export enum EntityStatus {
    NonCashAdjustment = 'Non Cash Adjustment',
    Payment = 'Payment',
    Pending = 'Pending',
    ReturnedPayment = 'Returned Payment',
    WaiveFees = 'Waive Fees',
    GoodPayment = 'Good Payment',
    BouncedPayment = 'Bounced Payment',
};

export interface Entity {
    dateReceived: string;
    dateString: string;
    id: number;
    paymentAmount: string;
    paymentAmountColor: string;
    status: string;
    title: string;
    selected?: boolean;
    paymentType: string | undefined;
    paymentLastFourDigit:string | undefined;
    timestamp: string;
};

export interface GetStatementRequest {
    applicationId: number;
};
export interface PaymentActivityDetail {
    active?: boolean;
    title: string;
    amount: string;
};

export interface GetPaymentActivitiesRequest {
    loanId: number;
    page: number;
    pageSize: number;
    source: 'widget' | 'screen' | 'recent';
};

export interface GetPaymentActivitiesRequestAction {
    payload: GetPaymentActivitiesRequest;
};

export interface GetPaymentActivitiesResponse extends Response {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    entities: EntityResponse[];
};

export interface EntityResponse {
    id: number;
    loanId: number;
    dateReceived: string;
    transactionType: string;
    amountReceived: UnitsResponse;
    lateFee: UnitsResponse;
    nsfFee: UnitsResponse;
    altFee: UnitsResponse;
    interestCharge: UnitsResponse;
    principalApplied: UnitsResponse;
    currentBalance: UnitsResponse;
    timestamp: string;
    paidTo: string;
    mopId: number;
    paymentMethod:PaymentsMethodResponse;
}

export interface PaymentStatementResponse {
    id: number;
    timestamp: string;
    title: string;
    selected: boolean;
};

export interface PaymentStatement {
    id: number;
    timestamp: string;
    title: string;
    selected: boolean;
};

export interface GetPendingPaymentsRequest {
    loanId: number;
};

export interface GetPendinfPaymentsActionRequest {
    payload: GetPendingPaymentsRequest;
};

export interface GetStatementRequest {
    applicationId: number;
};

export interface GetStatementRequestAction {
    payload: GetStatementRequest;
};

export interface GetStatementDocumentRequest {
    id: number;
};

export interface PaymentPendingResponse {
    id: number;
    loanId: number;
    postDate: string;
    runDate: string;
    amount: UnitsResponse;
    status: string;
    isTodayPayoff: boolean;
    paymentMethod: PaymentsMethodResponse;
};

export interface PendingPayment {
    amount: string;
    date: string;
    details?: PendingPaymentDetail[];
    id: number;
    loanId: number;
    status: string;
    amounWitoutFormat: number;
    paymentMethodId: number;
    paymentMethodType: string;
    selected?: boolean;
    isTodayPayoff?: boolean;
    paymentMethod: any;
};

export interface PendingPaymentDetail {
    title: string;
    data: string;
};

export interface PendingPaymentDetail {
    title: string;
    data: string;
};

export interface PendingPaymentDetail {
    title: string;
    data: string;
};

export interface GetPaymentActivityRequest {
    id: number;
    entityStatus?: string;
};

export interface GetPaymentActivityActionRequest {
    payload: GetPaymentActivityRequest;
};

export interface PaymentActivityDetailResponse {
    id: number;
    details: PaymentActivityDetail[];
    status?: string;
};

export interface PaymentPendingRequest {
    id: number;
    amount: number;
    loanId: number;
    paymentmethodId: number;
    paymentmethodType: string;
    runDate: Date;
};

export interface PaymentPendingRequestAction {
    payload: PaymentPendingRequest;
}

export interface PaymentPendingCancelRequest {
    id: number;
    loanId: number;
}

export interface PaymentPendingCancelRequestAction {
    payload: PaymentPendingCancelRequest;
}

export interface StatusConfig {
    text?: string;
    pillColor?: string;
    textColor?: string;
};