import AppAPI from '@Api/appApi';
import { Commands } from '@Api/config';
import {
    GetPaymentResponse,
    MakeAPaymentRequest,
    SaveAPaymentRequest,
} from '@MakeAPayment/interfaces/index';

export const currentPaymentMonthly = ({ loanId }: MakeAPaymentRequest): Promise<GetPaymentResponse> => new Promise(async (resolve, reject) => {
    try {
        const response = await AppAPI.get(`${Commands.PAYMENT_SUMMARY}/${loanId}`);
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});

export const saveAPaymenScheduleAPI = async (schedule: SaveAPaymentRequest) => new Promise(async (resolve, reject) => {
    try {
        const response: any = await AppAPI.post(Commands.POST_PAYMENT_SCHEDULE, {
            loanId: schedule.loanId,
            paymentAmount: schedule.paymentAmount,
            runDate: schedule.runDate,
            paymentMethodId: schedule.paymentMethodId,
            paymentMethodType: schedule.paymentMethodType,
            isTodayPayoff: schedule.isTodayPayoff,
        });
        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});
