import React from 'react';

import {
    Text,
    View,
} from 'react-native';

import { ShimmerApp } from '@Components/ShimerApp';
import { Balance } from '@Home/interfaces';
import { styles } from '@Home/styles/BalanceWebStyles';
import Languages from '@i18n/index';

interface Props {
    balance: Balance | undefined;
};

export const BalanceComponent = ({
    balance
}: Props) => (
    <View style={styles.container}>
        <View style={styles.currentContainer}>
            <ShimmerApp
                visible={Boolean(balance?.current)}
                contentStyle={styles.cardShimmerBalanceTitle}
                shimmerStyle={styles.cardShimmerBalanceTitle}
            >
                <Text style={styles.current}>{(balance?.fccpDaysLeft ?? 0) > 0 ? Languages.CurrentPromotionalBalance : Languages.CurrentBalance}</Text>
            </ShimmerApp>
        </View>
        <View style={styles.containerAmount}>
            <ShimmerApp
                visible={Boolean(balance?.current)}
                contentStyle={styles.cardShimmerBalanceAmount}
                shimmerStyle={styles.cardShimmerBalanceAmount}
            >
                <Text style={styles.amount}>{`$${balance?.current ?? '0.00'}`}</Text>
            </ShimmerApp>
        </View>
    </View>
);
