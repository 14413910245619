import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import {
    getNotificationsAPI,
    getOffersAPI,
} from '@Notifications/api/notificationsApi';
import {
    ClarNotificationBadgeResponse,
    GetNotificationOffersResponse,
    GetOffersRequestAction,
    Notification,
    NotificationEpicResponse,
    NotificationsData,
    SaveNotificationRequest,
} from '@Notifications/interfaces/index';
import NotificationsOffersService from '@Notifications/services/NotificationsOffersService';
import { saveNotificationsAction } from '@Notifications/state/notificationsActions';
import NotificationsActionsTypes from '@Notifications/state/notificationsActionsTypes';

export const notificationsEpic = (action$: Observable<any>, $state: any) => action$.pipe(
    ofType(NotificationsActionsTypes.GET_NOTIFICATIONS_REQUEST),
    switchMap(() => from(new Promise<NotificationEpicResponse>(async (resolve, reject) => {
        try {
            const { value } = $state;
            const { applicationsReducer } = value;
            const { loanSelected } = applicationsReducer;
            const response = await getNotificationsAPI({
                applicationId: loanSelected.applicationId,
            });
            resolve({
                applicationId: loanSelected.applicationId,
                data: response.data,
            });
        } catch (error) {
            reject(error);
        }
    })).pipe(
        mergeMap((response: NotificationEpicResponse) => {
            const { applicationId, data } = response;

            const dataToSave = {
                applicationId,
            } as SaveNotificationRequest;

            const { value } = $state;
            const { notificationsReducer } = value;
            const currentData = notificationsReducer[applicationId] as NotificationsData;
            if (currentData) {
                const currentNotifications = currentData.notifications;
                if (currentNotifications && currentNotifications.length > 0) {
                    const hasRead = currentNotifications[0].wasRead;
                    data[0].wasRead = hasRead;
                    dataToSave.data = {
                        notifications: data,
                        total: data.length,
                        badgeText: hasRead ? '' : ' ',
                    };
                }
            } else {
                dataToSave.data = {
                    notifications: data,
                    total: data.length,
                    badgeText: ' ',
                };
            }
            return [
                saveNotificationsAction(dataToSave),
                loadingDialogHide(),
            ];
        }),
        catchError((error: Error) => {
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const clearNotificationBadgeEpic = (action$: Observable<any>, $state: any) => action$.pipe(
    ofType(NotificationsActionsTypes.CLEAR_NOTIFICATIONS_BADGE),
    mergeMap(() => from<Promise<ClarNotificationBadgeResponse>>(new Promise((resolve) => {
        const { value } = $state;
        const { notificationsReducer, applicationsReducer } = value;
        const { loanSelected } = applicationsReducer;
        resolve({
            applicationId: loanSelected.applicationId,
            data: notificationsReducer[loanSelected.applicationId],
        });
    })).pipe(
        map((response: ClarNotificationBadgeResponse) => {
            const { applicationId, data } = response;
            const { notifications } = data || {
                notifications: [],
            };
            notifications.forEach((notification: Notification) => notification.wasRead = true);

            const dataToSave = {
                applicationId,
                data: {
                    notifications,
                    total: 0,
                    badgeText: '',
                },
            } as SaveNotificationRequest;

            return saveNotificationsAction(dataToSave);
        }),
    )),
);

export const getOffersEpic = ($action: Observable<any>) => $action.pipe(
    ofType(NotificationsActionsTypes.GET_NOTIFICATIONS_OFFERS),
    switchMap(({ payload }: GetOffersRequestAction) => from(getOffersAPI({
        ...payload,
    })).pipe(
        map((response: GetNotificationOffersResponse) => {
            NotificationsOffersService.onSuccess({
                id: response.id,
                applicationId: response.applicationId,
                url: response.url,
            });
            return loadingDialogHide();
        }),
        catchError(() => of(loadingDialogHide()))
    ))
);
