import '../../web/css/tooltipStyle.css';

import React from 'react';

import {
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';
import { StyleProp, useWindowDimensions } from 'react-native';

interface Props {
    children: JSX.Element | null;
    customStyle?: StyleProp<any>;
    Icon: any;
    iconColor: string;
    place: Placement;
    show?: boolean;
    showShadow?: boolean;
    trigger?: OverlayTriggerType;
};

export const AppTooltip = ({
    children,
    customStyle,
    Icon,
    iconColor,
    place,
    show,
    showShadow,
    trigger,
}: Props) => {
    const { width: windowWidth } = useWindowDimensions();
    const renderTooltip = (props: any) => (
        <Tooltip
            {...props}
            className={showShadow && 'tooltip-inner-shadow'}
            style={{ ...props.style, zIndex: 10000, ... windowWidth <= 500 ? { width: 'calc(100% - 10px)'} : {}, ...customStyle ? customStyle : { backgroundColor: 'rgba(101, 101, 101, 1)' } }}
        >
            {children}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            delay={{ show: 0, hide: 0 }}
            overlay={renderTooltip}
            placement={place}
            show={show}
            trigger={trigger}
        >
            <div>
                <Icon fill={iconColor} />
            </div>
        </OverlayTrigger>
    );
};
