import { Subject } from 'rxjs';

import { VerificationCodeDialogRequest } from '@VerificationCodeDialog/interfaces';

export interface VerificationCodeObserver extends VerificationCodeDialogRequest {
    name: string;
    open: boolean;
};

const VerificationCodeDialogService = () => {
    const subject = new Subject();

    const show = (params: VerificationCodeDialogRequest) => {
        subject.next({
            open: true,
            name: 'verificationCodeDialog',
            ...params,
        });
    };

    const hide = () => {
        subject.next({
            open: false,
            name: 'verificationCodeDialog',
        });
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        hide,
        show,
        subscribe,
    };
};

export default VerificationCodeDialogService();
