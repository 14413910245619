import React from 'react';

import { View } from 'react-native';

import { ShimmerApp } from '@Components/ShimerApp';
import { styles } from '@MakeAPayment/styles/PaymentOptionsWebStyles.ts';

export const PaymentOptionsShimmerItem = () => (
    <View style={styles.viewPayment} >
        <ShimmerApp
            visible={Boolean(false)}
            shimmerStyle={styles.viewIsInput}
        />
        <ShimmerApp
            visible={Boolean(false)}
            shimmerStyle={styles.radioShimmer}
        />
        <ShimmerApp
            visible={Boolean(false)}
            shimmerStyle={styles.viewIsInputSecond}
        />
    </View >
);
