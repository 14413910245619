import React from 'react';

import { Platform } from 'react-native';

import Languages from '@i18n/index';
import { ProfileInfoNotFoundedScreen } from '@Profile/screens/ProfileInfoNotFoundedScreen';
import { createStackNavigator } from '@react-navigation/stack';
import {
    colors,
    theme,
} from '@Theme/appTheme';

import { Toolbar } from '../components/Toolbar.web';

const Stack = createStackNavigator();

export const NotFoundedNavigationStack = () => (
    <Stack.Navigator
        screenOptions={() => (Platform.OS === 'web' ? {
            headerTitle: () => <Toolbar context='ClaimAccount' />,
            headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,
                backgroundColor: theme.colors.primary,
            },
            headerBackTitleVisible: false,
            headerTintColor: colors.white,
        } : {
            headerRightContainerStyle: {
                marginRight: 20,
                alignSelf: 'center',
            },
            headerTitleAlign: 'center',
            headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,
                backgroundColor: theme.colors.primary,
            },
            headerBackTitleVisible: false,
            headerTintColor: colors.white,
        })}
    >
        <Stack.Screen
            name='ProfileInfoScreen'
            options={{
                title: Languages.PersonalInformation,
            }}
            component={ProfileInfoNotFoundedScreen}
        />
    </Stack.Navigator>
);
