import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import {
    deletePaymentPendingAPI,
    getDocumentStatementsAPI,
    getPaymentActivitiesAPI,
    getPaymentActivityAPI,
    getPaymentStatementsAPI,
    getPendingPaymentsAPI,
    updatePaymentPendingAPI,
} from '@Activity/api/activityApi';
import {
    Entity,
    EntityResponse,
    GetPaymentActivitiesRequestAction,
    GetPaymentActivitiesResponse,
    GetPaymentActivityActionRequest,
    GetPendinfPaymentsActionRequest,
    GetStatementRequestAction,
    PaymentActivityDetail,
    PaymentPendingCancelRequestAction,
    PaymentPendingRequestAction,
    PaymentPendingResponse,
    PaymentStatementResponse,
    PendingPayment,
} from '@Activity/interfaces';
import DocumentStatementService from '@Activity/services/DocumentStatementsService';
import PaymentActivityService from '@Activity/services/PaymentActivityService';
import PaymentPendingCancelService from '@Activity/services/PaymentPendingCancelService';
import PaymentPendingService from '@Activity/services/PaymentPendingService';
import PaymentPendingUpdateService from '@Activity/services/PaymentPendingUpdateService';
import PaymentStamentService from '@Activity/services/PaymentStatementService';
import { savePaymentActivityAction } from '@Activity/state/activityActions';
import ActivityActionTypes from '@Activity/state/activityActionTypes';
import AppAlertService from '@Alert/services/AppAlertService';
import Languages from '@i18n/index';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import { PaymentsMethodResponse } from '@MethodsOfPayment/interfaces';
import { colors } from '@Theme/appTheme';
import { parseDate } from '@Utils/FormatUtils';
import { formatAmountPrice } from '@Utils/index';
import { getPaymentMethodsAPI } from '@MethodsOfPayment/api/MethodsOfPaymentApi';

export const getPaymentActivities = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.GET_PAYMENT_ACTIVITIES),
    switchMap(({ payload }: GetPaymentActivitiesRequestAction) => from(getPaymentActivitiesAPI({
        ...payload,
    })).pipe(
        map((activitiesResponse: GetPaymentActivitiesResponse) => {
            const { entities, page, totalPages } = activitiesResponse;
            const filteredEntities = entities.filter((entityResponse: EntityResponse) => entityResponse.transactionType.toLowerCase() !== 'cancelled');
            const mappedEntities: Entity[] = filteredEntities.map((paymentActivity: EntityResponse, index: number): Entity => ({
                id: paymentActivity.id,
                title: (paymentActivity.transactionType === 'Returned Payment'
                    ? Languages.Return : Languages.Payment
                ), 
                paymentAmount: formatAmountPrice(paymentActivity?.amountReceived?.units.toFixed(2) ?? '0.00'),
                paymentAmountColor: (Math.sign(paymentActivity?.amountReceived?.units) === -1
                    ? colors.softRed : colors.grayColor
                ),
                dateString: parseDate(paymentActivity.dateReceived, 'MM/dd/yyyy'),
                dateReceived: paymentActivity.dateReceived,
                status: paymentActivity.transactionType,
                selected: index === 0 && page === 1,
                paymentLastFourDigit : paymentActivity.paymentMethod.lastFourDigit,
                paymentType : paymentActivity.paymentMethod.type == "Checking" ? "Account" : paymentActivity.paymentMethod.paymentNetwork,
                timestamp: paymentActivity.timestamp,
            }));

            PaymentActivityService.onSuccess({
                page,
                totalPages,
                entities: mappedEntities,
                source: payload.source,
            });

            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error?.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const getPaymentActivityEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.GET_PAYMENT_ACTIVITY),
    switchMap(({ payload }: GetPaymentActivityActionRequest) => from(getPaymentActivityAPI(payload))
        .pipe(
            mergeMap((response: EntityResponse) => {
                const details: PaymentActivityDetail[] = [
                    {
                        active: true,
                        title: Languages.AmountReceived,
                        amount: formatAmountPrice(response?.amountReceived?.units.toFixed(2) ?? '0.00'),
                    },
                    {
                        active: true,
                        title: Languages.Principal,
                        amount: formatAmountPrice(response?.principalApplied?.units.toFixed(2) ?? '0.00'),
                    },
                    {
                        active: true,
                        title: Languages.Interest,
                        amount: formatAmountPrice(response?.interestCharge?.units.toFixed(2) ?? '0.00'),
                    },
                    {
                        active: true,
                        title: Languages.LateFee,
                        amount: formatAmountPrice(response?.lateFee?.units.toFixed(2) ?? '0.00'),
                    },
                    {
                        active: true,
                        title: Languages.NSFFee,
                        amount: formatAmountPrice(response?.nsfFee?.units.toFixed(2) ?? '0.00'),
                    },
                    {
                        active: false,
                        title: Languages.Balance,
                        amount: formatAmountPrice(response?.currentBalance?.units.toFixed(2) ?? '0.00'),
                    },
                    {
                        active: false,
                        title: Languages.AltFee,
                        amount: formatAmountPrice(response?.altFee?.units.toFixed(2) ?? '0.00'),
                    },
                ];

                return [
                    loadingDialogHide(),
                    savePaymentActivityAction({
                        id: response.id,
                        details: details.filter(d => !!d.active),
                        status: payload.entityStatus ?? '',
                    }),
                ];
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error?.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const getPaymentStamentsEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.GET_PAYMENT_STATEMENTS),
    switchMap(({ payload }: GetStatementRequestAction) => from(getPaymentStatementsAPI({
        ...payload,
    })).pipe(
        map((response: PaymentStatementResponse[]) => {
            const mapRespopnse: PaymentStatementResponse[] = response.map((item: PaymentStatementResponse, index: number) => ({
                ...item,
                timestamp: parseDate(item.timestamp, 'MM/dd/yyyy'),
                selected: index === 0,
            }));

            PaymentStamentService.onSuccess(mapRespopnse);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error?.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const getPaymentsPendingEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.GET_PAYMENT_PENDINGS),
    switchMap(({ payload }: GetPendinfPaymentsActionRequest) => from(getPendingPaymentsAPI(payload))
        .pipe(
            map((response: PaymentPendingResponse[]) => {
                const mappedResponse: PendingPayment[] = response.map<PendingPayment>((item: PaymentPendingResponse, index: number) => ({
                    id: item.id,
                    loanId: item.loanId,
                    date: parseDate(item.runDate, 'MMM dd,yyyy'),
                    amount: formatAmountPrice(item.amount.units.toFixed(2)),
                    status: item.status,
                    paymentMethodId: item.paymentMethod.id,
                    paymentMethodType: item.paymentMethod.type,
                    amounWitoutFormat: item.amount.units,
                    isTodayPayoff: item.isTodayPayoff,
                    paymentMethod: item.paymentMethod,
                    details: [
                        {
                            title: Languages.PaymentAmount,
                            data: formatAmountPrice(item.amount.units.toFixed(2) ?? '0.00'),
                        },
                        {
                            title: Languages.MethodOfPayment,
                            data: obtainMethodOfPayment(item.paymentMethod),
                        },
                        {
                            title: Languages.PaymentDate,
                            data: parseDate(item.runDate, 'MM dd, yyyy'),
                        },
                    ],
                    selected: index === 0,
                }));
                PaymentPendingService.onSuccess(mappedResponse);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error?.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const getDocumentStamentsEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.GET_DOCUMENT_STATEMENT),
    switchMap(({ payload }: any) => from(getDocumentStatementsAPI({
        ...payload,
    })).pipe(
        mergeMap((response: Blob) => from(new Promise<any>((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onloadend = () => {
                    const dataResult = reader.result as string;
                    const data = dataResult.split(',')[1];
                    resolve(data);
                };
            } catch (error) {
                reject({
                    title: 'File reader error',
                    code: 666,
                } as Error);
            }
        })).pipe(
            map((response: any) => {
                DocumentStatementService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title || Languages.AnErrorHasOcurred,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        )),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title || Languages.AnErrorHasOcurred,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const updatePaymentPendingEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.UPDATE_PENDING_REQUEST),
    switchMap(({ payload }: PaymentPendingRequestAction) => from(updatePaymentPendingAPI(payload))
        .pipe(
            map((response: any) => {
                PaymentPendingUpdateService.onSuccess({
                    status: response,
                    success: true,
                });
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error?.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        )
    )
);

export const cancelPaymentPendingEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ActivityActionTypes.CANCEL_PENDING_REQUEST),
    switchMap(({ payload }: PaymentPendingCancelRequestAction) => from(deletePaymentPendingAPI(payload))
        .pipe(
            map((response: any) => {
                PaymentPendingCancelService.onSuccess({
                    status: response,
                    success: true,
                });
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error?.title,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        )
    )
);

const obtainMethodOfPayment = (paymentMethod: PaymentsMethodResponse): string => {
    if (paymentMethod) {
        const formatPaymentNetwork = paymentMethod.paymentNetwork || '';
        const formatLastFourDigits = paymentMethod.lastFourDigit ? `*${paymentMethod.lastFourDigit}` : '';
        return `${formatPaymentNetwork} ${formatLastFourDigits}`;
    }
    return '';
};
