import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    FlatList,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';

import {
    Entity,
    PaymentActivity,
    PendingPayment,
} from '@Activity/interfaces';
import PaymentActivityService from '@Activity/services/PaymentActivityService';
import { getPaymentActivities } from '@Activity/state/activityActions';
import { styles } from '@ActivityWeb/styles/ActivitiesStyles';
import { ArrowRightIcon, EmptyActivityIcon, ArrowLeftIcon } from '@Assets/index';
import { AppEmptyState } from '@Components/AppEmptyState';
import Languages from '@i18n/index';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import { format } from 'date-fns';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { ActivityWebItemScreen } from './ActivityItemScreen.web';
import useCheckMobileScreen from '@Hooks/useCheckMobileScreen';

interface PropsItem {
    item: Entity;
    index: number;
};

export const ActivityWebAllScreen = () => {
    const dispatch = useAppDispatch();
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const [entities, setEntities] = useState<Entity[]>([]);
    const [page, setPage] = useState<number>(1);
    const totalPagesRef = useRef(0);
    const navigation = useNavigation();
    const [viewItem, setViewItem] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<Entity | PendingPayment | undefined>();
    const isMobile = useCheckMobileScreen();

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );

    useFocusEffect(() => {
        return () => {
            if (viewItem && !!currentItem) {
                setViewItem(false);
                setCurrentItem(undefined);
            }
        }
    })

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        dispatch(getPaymentActivities({
            loanId: loanSelected?.loanId,
            page,
            pageSize: 10,
            source: 'screen',
        }));
    }, [loanSelected, page]);

    useEffect(() => {
        const subscription = PaymentActivityService.subscribe((state: PaymentActivity) => {
            const { entities: newEntities, source, totalPages } = state;
            if (source === 'screen') {
                totalPagesRef.current = totalPages;
                setEntities([...entities, ...newEntities]);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [entities]);

    const calculateContainerHeight = () => {
        const headerHeight = 70;
        const bottomNavHeight = 70;
        const cardHeader = 50;
        let subHeight = headerHeight + cardHeader + 30;

        if (isMobile) {
            subHeight = subHeight + bottomNavHeight;
        }

        return windowHeight - subHeight;
    }

    const handleRenderItem = ({ item, index }: PropsItem) => (
        <TouchableOpacity style={styles.activityItemAmount} onPress={() => handleClickItem(item)}>
            <View style={[styles.activityItem, StyleSheet.create({ item: { borderTopWidth: index === 0 ? 0 : 1 }}).item]}>
                <View style={styles.activityItemLeft}>
                    <Text style={styles.activityItemTitle}>{item.title}</Text>
                    <Text style={styles.activityItemMetaData}>{format(new Date(item.dateString), 'MMM dd, yyyy')}</Text>
                    <Text style={styles.activityItemMetaData}>
                        { Languages.PaymentTypeDetails.replace('{type}', item.paymentType || '').replace('{lastFour}', item.paymentLastFourDigit || 'XXXX')}
                    </Text>
                </View>
                <View style={styles.activityItemRight}>
                    <Text style={[styles.activityAmount, StyleSheet.create({ item: { color: item.paymentAmountColor, marginRight: 10 }}).item ]}>{item.paymentAmount}</Text>
                    <ArrowRightIcon />
                </View>
            </View>
        </TouchableOpacity>
    );

    const handleOnEndReached = () => {
        if (page < totalPagesRef.current) {
            setPage(page + 1);
        }
    };

    const handleClickItem = (item: Entity | PendingPayment) => {
        if (item) {
            setViewItem(true);
            setCurrentItem(item);
        }
    };

    const resetDetails = () => {
        setViewItem(false);
        setCurrentItem(undefined);
    };

    const handleResponsive = () => {
        return StyleSheet.create({
            mainContainer: {
                flexDirection: windowWidth < 968 ? 'column' : 'row',
            },
            card: {
                marginHorizontal: 10,
                width: windowWidth < 968 ? '100%' : '49%',
                maxWidth: windowWidth < 968 ? '100%' : 700,
            }
        });
    };

    return (viewItem && !!currentItem) ? (
            <View style={[styles.mainScrollView]}>
                <ActivityWebItemScreen goBack={resetDetails} item={currentItem} />
            </View>
        ) : 
        (<>{entities.length ?
            <ScrollView style={styles.mainScrollView}>
                <View style={[styles.activitiesContainer, styles.allContainer, StyleSheet.create({ item: { height: 200  }}).item]}>
                    <View style={[styles.activityCard, handleResponsive().card]}>
                        <View style={[styles.activityCardTitle]}>
                            <View style={[StyleSheet.create({ item: { display: 'flex', flexDirection: 'row'}}).item]}>
                                <TouchableOpacity onPress={() => navigation.navigate('Activity')}>
                                    <ArrowLeftIcon />
                                </TouchableOpacity>
                                <Text style={[styles.activityCardTitleText, StyleSheet.create({ item: { marginLeft: 10 }}).item]}>{Languages.Transactions}</Text>
                            </View>
                        </View>
                        <View style={[styles.activityCardBody]}>
                            <FlatList
                                data={entities}
                                initialNumToRender={10}
                                keyExtractor={(_: Entity, index: number) => index.toString()}
                                removeClippedSubviews={Platform.OS === 'android'}
                                onEndReached={handleOnEndReached}
                                renderItem={handleRenderItem}
                                showsVerticalScrollIndicator={true}
                                style={[styles.itemList, StyleSheet.create({ item: { height: calculateContainerHeight() }}).item]}
                                onEndReachedThreshold={0.4}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>
        :
            <View style={styles.emptyStateContainer}>
                <AppEmptyState
                    accessibilityLabel={'paymentActivityAppEmptyStateActvity'}
                    Icon={EmptyActivityIcon}
                    subTitle='No Recent'
                    testID={'paymentActivityAppEmptyStateActvity'}
                    title='Transactions'
                    content=''
                />
            </View>
        }</>
    );
};
