import React, { ReactElement } from 'react';

import { Platform } from 'react-native';

import {
    ActivitySvg,
    FinancialFundamentalsIconSvg,
    HomeSvg,
    PaymentSvg,
    ProfileTabIconSvg,
} from '@Assets/index';
import Languages from '@i18n/index';
import { MakeAPaymentOption } from '@MakeAPayment/interfaces';
import { PaymentMethodResponseBase, TypesMethod } from '@MethodsOfPayment/interfaces';
import { ActivityStack } from '@Navigation/ActivityStack';
import { DashboardStack } from '@Navigation/DashboardStack';
import { PaymentStack } from '@Navigation/PaymentStack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RouteProp } from '@react-navigation/core';
import { StackActions } from '@react-navigation/native';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { FinancialFundamentalsStack } from './FinancialFundamentalsStack';
import { Text } from 'react-native';
import { Entity } from '@Activity/interfaces';

export type RootStackParamList = {
    Activity: {
        screen?: string;
    };
    ActivityMobileItemScreen: {
        item: Entity;
        index: number;
    },
    ActivityAll: {},
    AddPaymentMethodScreen: {
        paymentMethod?: PaymentMethodResponseBase;
        paymentType?: TypesMethod;
    };
    ApplicationsScreen: {
        action?: string;
    };
    AutoPayScreen: {};
    BottomTabNavigator: {};
    CustomerService: { from?: string };
    Dashboard: {};
    DateMakeAPaymentScreen: {
        amount: number;
        dueDate?: string;
        optionSelected?: MakeAPaymentOption;
        paymentMethodId: number;
        paymentMethodType: string;
        isTodayPayoff: boolean;
    };
    FaqScreen: { from?: string };
    FinancialFundamentalsScreen: {};
    Home: {};
    InternalLogin: {
        token: string;
    };
    MakeAPaymentScreen: {
        action?: string;
    };
    NewStatementsScreen: {
        action?: string;
    },
    ManageAutoPayScreen: {
        action?: string;
    };
    MethodsOfPaymentsScreen: {};
    NotificationScreen: {};
    NotificationsStack: {};
    NotificationToolbarIcon: {};
    Payments: {
        screen?: string;
        goTo?: string;
    };
    PaymentsActivity: {};
    PaymentsScreen: {
        goTo?: string;
    };
    PdfScreen: {
        link?: string;
        base64?: string;
    };
    Profile: {
        goTo?: string;
    };
    ProfileInfoScreen: {
        editable?: boolean;
    };
    ProfileScreen: {
        goTo?: string;
    };
    ProfileStack: {};
    ProfileTopTabsStack: {};
    ProgramRebateScreen: { date: string };
    ProfileInfoNotFoundedScreen: {
        editable?: boolean;
    };
    SelectMethodOfPaymentScreen: {
        amount: number;
        dueDate?: string;
        handleCancel?: () => void;
        handleSelection?: (item: any) => any;
        optionSelected?: MakeAPaymentOption;
        isTodayPayoff: boolean;
        isSelectionOnly?: boolean;
    };
    StatementsScreen: {};
    Statements: {};
    FinancialFundamentals: {
        goTo?: string;
    };
};

export const BottomTabNavigator = () => <Tabs />;

const iconTabs: any = {
    Dashboard: (focused: boolean, size: number): ReactElement => (
        <HomeSvg
            fill={focused ? theme.colors.primary : colors.grayLigth}
            width={size}
            height={size}
        />
    ),
    Payments: (focused: boolean, size: number): ReactElement => (
        <PaymentSvg
            fill={focused ? theme.colors.primary : colors.grayLigth}
            width={size}
            height={size}
        />
    ),
    Activity: (focused: boolean, size: number): ReactElement => (
        <ActivitySvg
            fill={focused ? theme.colors.primary : colors.grayLigth}
            width={size}
            height={size}
        />
    ),
    FinancialFundamentals: (focused: boolean, size: number): ReactElement => (
        <FinancialFundamentalsIconSvg
            fill={focused ? theme.colors.primary : colors.grayLigth}
            width={size}
            height={size}
        />
    ),
    Profile: (focused: boolean, size: number): ReactElement => (
        <ProfileTabIconSvg
            fill={focused ? theme.colors.primary : colors.grayLigth}
            width={size}
            height={size}
        />
    ),
};

const handleIcon = (
    route: RouteProp<Record<string, object | undefined>, string>,
    focused: boolean,
    size: number,
) => iconTabs[route.name](focused, size);

const BottomTab = createBottomTabNavigator<RootStackParamList>();

const handleBlur = (navigation: any) => {
    const state = navigation.getState();

    state.routes.forEach((route: any, tabIndex: any) => {
        if (route.name === 'Payments' || route.name === 'Profile') {
            route.params = undefined;
        }
        if (state?.index !== tabIndex && route.state?.index > 0) {
            navigation.dispatch(StackActions.popToTop());
        }
    });
};

const Tabs = () => (
    <BottomTab.Navigator
        sceneContainerStyle={{
            backgroundColor: colors.veryLightGray,
        }}
        tabBarOptions={{
            activeTintColor: theme.colors.primary,
            tabStyle: {
                marginTop: 10,
                height: 49,
                ...Platform.select({
                    android: {
                        alignSelf: 'center',
                    },
                }),
            },
            style: {
                height: 60,
                ...Platform.select({
                    ios: {
                        marginBottom: 10,
                    },
                }),
            },
            labelStyle: {
                fontSize: 10,
                fontFamily: fonts.SFProTextMedium,
                textAlign: 'center',
                fontWeight: '500',
                width: 60,
                marginTop: 8,
                flex: 2
            },
        }}
        screenOptions={({ route }: any) => ({
            unmountOnBlur: true,
            tabBarIcon: ({ focused, size }: any) => (
                handleIcon(route, focused, size)
            ),
        })}
    >
        <BottomTab.Screen
            name="Dashboard"
            options={{
                title: Languages.Home,
            }}
            component={DashboardStack}
            listeners={({ navigation }) => ({
                blur: () => handleBlur(navigation),
            })}
        />
        <BottomTab.Screen
            name="Payments"
            options={{ title: Languages.Billing }}
            initialParams={{}}
            component={PaymentStack}
            listeners={({ navigation }) => ({
                blur: () => handleBlur(navigation),
            })}
        />
        <BottomTab.Screen
            name="Activity"
            options={{ title: Languages.Activity }}
            component={ActivityStack}
            listeners={({ navigation }) => ({
                blur: () => handleBlur(navigation),
            })}
        />
        <BottomTab.Screen
            name="FinancialFundamentals"
            options={
                {
                    title: Languages.FinancialFundamentals,
                    tabBarLabel(props) {
                        return <Text
                            style={
                                {
                                    marginTop: 8,
                                    width: 70,
                                    fontSize: 10,
                                    flex: 2,
                                    fontFamily: fonts.SFProTextMedium,
                                    textAlign: 'center',
                                    fontWeight: '500',
                                    color: props.focused ? theme.colors.primary : colors.grayLigth
                                }
                            }>Financial{'\n'}Fundamentals</Text>
                    },
                }}
            component={FinancialFundamentalsStack}
            listeners={({ navigation }) => ({
                blur: () => handleBlur(navigation),
            })}
        ></BottomTab.Screen>
    </BottomTab.Navigator>
);
