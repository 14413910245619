import {
    colors,
    fonts,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

export const styles = AppStyleSheet({
    title: {
        color: colors.grayDarkColor,
        flex: 113,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 18,
        fontWeight: '500',
        lineHeight: 24,
    },
    subtitle: {
        color: colors.grayLigth,
        flex: 90,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 27,
    },
    viewRow: {
        flex: 90,
        justifyContent: 'center',
        marginHorizontal: 60,
        paddingTop: 24,
    },
});
