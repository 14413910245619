import { Subject } from 'rxjs';

import { Error } from '@Interfaces/BaseResponse';

type ACTION = 'registration' | 'validation';

export interface RegisterBusiness {
    action: ACTION;
    success: boolean;
    email: string;
};

const RegisterBusinessCenterService = () => {
    const subject = new Subject();

    const registration = () => {
        subject.next({
            action: 'registration',
            success: true,
        } as RegisterBusiness);
    };

    const validation = (email: string) => {
        subject.next({
            action: 'validation',
            success: true,
            email,
        } as RegisterBusiness);
    };

    const onError = (_: Error) => {
        subject.next({
            action: 'validation',
            success: false,
        } as RegisterBusiness);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onError,
        registration,
        subscribe,
        validation,
    };

};

export default RegisterBusinessCenterService();
