import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

export const styles = AppStyleSheet({
    appText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        textAlign: 'center',
    },
    appTextContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    contentModel: {
        color: colors.grayDarkColor,
        marginTop: '40%',
    },
    flatList: {
        flex: 1,
        backgroundColor: 'white',
        paddingTop: 80,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    headerBar: {
        backgroundColor: theme.colors.primary,
        borderRadius: 13,
        height: 5,
        marginTop: 10,
        width: 114,
    },
    headerContentStyle: {
        flex: 100,
        alignItems: 'center',
        height: 100,
    },
    headerStyle: {
        justifyContent: 'center',
        marginTop: '41%',
    },
});
