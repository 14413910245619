import axios, {
    AxiosInstance,
    AxiosRequestConfig,
} from 'axios';

import {
    Commands,
    obtainURLs,
} from '@Api/config/index';
import { AuthenticateResponse } from '@Auth/interfaces';
import AuthenticateService from '@Auth/services/AuthenticateService';
import {
    StorageKeys,
    useStorage,
} from '@Hooks/useStorage';
import Languages from '@i18n/index';
import { Error } from '@Interfaces/BaseResponse';
import LoggerService from '@Logger/services/LoggerService';

export const TIMEOUT_REQUEST: number = 8000;

export const addAuthBaseURLRequestInterceptor = (instance: AxiosInstance): AxiosInstance => {
    instance.interceptors.request.use((config) => new Promise(async (resolve, reject) => {
        try {
            const url = await obtainURLs();
            config.baseURL = url.AUTH_BASE_URL;
            resolve(config);
        } catch (error) {
            LoggerService.log('addBaseURLRequestInterceptor config error', config, error);
            reject(error);
        }
    }), (error) => Promise.reject(error));
    return instance;
};

export const addAppBaseURLRequestInterceptor = (instance: AxiosInstance): AxiosInstance => {
    instance.interceptors.request.use((config) => new Promise(async (resolve, reject) => {
        try {
            const url = await obtainURLs();
            config.baseURL = url.APP_BASE_URL;
            resolve(config);
        } catch (error) {
            LoggerService.log('addBaseURLRequestInterceptor config error', config, error);
            reject(error);
        }
    }), (error) => Promise.reject(error));
    return instance;
};

export const addAuthorizationRequestInterceptor = (instance: AxiosInstance): AxiosInstance => {
    instance.interceptors.request.use((config) => new Promise(async (resolve, reject) => {
        try {
            const { getData } = useStorage();
            const result = await getData({
                key: StorageKeys.LOGIN_METADATA,
            });
            if (result.success) {
                const authResponse: AuthenticateResponse = result.value;
                if (authResponse.accessToken !== '') {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${authResponse.accessToken}`,
                    };
                }
            }
            addCancelToken(config);
            resolve(config);
        } catch (error) {
            LoggerService.log('addAuthorizationRequestInterceptor config error', config, error);
            reject(error);
        }
    }), (error) => Promise.reject(error));
    return instance;
};

export const addAuthorizationResponseInterceptor = (instance: AxiosInstance): AxiosInstance => {
    instance.interceptors.response.use(
        (response) => (response),
        (error) => {
            LoggerService.log('addAuthorizationResponseInterceptor response', JSON.stringify(error.response));
            if (!axios.isCancel(error) && error?.response?.status === 401) {
                const isAuthEmail = error?.config?.url && error.config.url === Commands.AUTHENTICATE_EMAIL;
                if (!isAuthEmail) {
                    AuthenticateService.onSuccess({
                        success: false,
                        isSessionExpired: true,
                    });
                }
                let errorTitle = Languages.SessionExpiredPleaseSignInAgain;
                if (isAuthEmail) {
                    errorTitle = Languages.TheUsernameOrPasswordIsInvalid;
                }
                return Promise.reject({
                    code: 401,
                    success: false,
                    title: errorTitle,
                });
            }
            return Promise.reject(formatErrorResponse(error));
        },
    );
    return instance;
};

const addCancelToken = (config: AxiosRequestConfig) => {
    config.cancelToken = new axios.CancelToken((cancelRequest) => {
        try {
            const cancelTokenTimeout = setTimeout(
                () => {
                    cancelRequest(config.url);
                    clearTimeout(cancelTokenTimeout);
                },
                TIMEOUT_REQUEST,
            );
        } catch (error) {
            LoggerService.log('>>>>> addCancelToken cancelRequest config.url error', config.url, error);
        }
    });
};

export const formatErrorResponse = (error: any): Error => {
    LoggerService.logError(JSON.stringify(error.response));
    const title = Languages.AnErrorHasOcurred;
    const { response } = error;
    if (response) {
        const { data } = response;
        if (data) {
            if (Array.isArray(data)) {
                return {
                    title: data[0]?.description,
                    code: data[0]?.code,
                    success: false,
                };
            }
            if (data.errors) {
                const objectValues: any[] = Object.values(data.errors);
                if (objectValues && objectValues.length > 0) {
                    const values: any[] = objectValues[0];
                    if (values && values.length > 0) {
                        return {
                            title: values[0],
                            code: data.status,
                            success: false,
                        };
                    }
                }
            }
            return {
                title: data.title,
                code: data.status,
                success: false,
            };
        }
    }
    return {
        code: 999,
        title,
        success: false,
        status: error?.response?.status,
    };
};
