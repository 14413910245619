import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        alignItems: 'center',
        flex: 1,
    },
    containerBackground: {
        ...globalStyle.dropShadow,
        backgroundColor: colors.white,
    },
    containerDescription: {
        alignItems: 'center',
        backgroundColor: colors.greenLigthContainer,
        borderRadius: 10,
        height: 64,
        justifyContent: 'center',
        width: '91%',
    },
    separator: {
        marginBottom: 16,
        ...Platform.select({
            web: {
                marginBottom: 10,
            },
        }),
    },
    textDescription: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProRoundedMedium,
        fontSize: 15,
        fontWeight: '600',
        lineHeight: 18,
        textAlign: 'center',
        width: 226,
    },
    list: {
        flex: 1,
        paddingBottom: 16,
        width: '100%',
    },
    listActitivies: {
        height: 350,
        width: '100%',
    },
    emptyStateContainer: {
        alignItems: 'center',
        width: '90%',
        height: 350,
        backgroundColor: 'white',
        marginHorizontal: 16,
        elevation: 10,
        borderRadius: 10,
        shadowColor: colors.shadowColor,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowOpacity: 0.30,
        shadowRadius: 10,
    },
});
