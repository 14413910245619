import React, { useEffect } from 'react';

import {
    StyleSheet,
    Text,
    View,
} from 'react-native';

import { useAppDispatch } from '@Hooks/appHooks';
import Languages from '@i18n/index';
import { getUserInfoAction } from '@Profile/state/profileActions';
import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';

import { ProfileInfoScreen } from './ProfileInfoScreen.web';

export const ProfileInfoNotFoundedScreen = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserInfoAction());
    }, []);

    return (
        <View style={styles.container}>
            <View style={{ flex: 10, justifyContent: 'center' }}>
                <Text style={styles.title}>{Languages.LocateYourAccount}</Text>
            </View>
            <View style={[
                styles.profileContainer,
                globalStyle.dropShadow,
            ]}>
                <Text style={styles.titleContainer}>{Languages.PersonalInformation}</Text>
                <View style={styles.lineContainer}>
                    <View style={styles.lineOver} />
                    <View style={styles.line} />
                </View>
                <ProfileInfoScreen isClaimProcess={true} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 100,
        flexWrap: 'wrap',
        justifyContent: 'center',
        minWidth: 200,
        paddingHorizontal: 16,
        paddingBottom: 16,
    },
    profileContainer: {
        backgroundColor: colors.white,
        borderRadius: 10,
        flex: 90,
        flexWrap: 'wrap',
        height: 573,
        alignSelf: 'center',
        justifyContent: 'center',
        maxWidth: 792,
        minWidth: 430,
        paddingHorizontal: 16,
    },
    title: {
        flex: 1,
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 31,
        fontWeight: '500',
        lineHeight: 36.99,
        marginHorizontal: 6,
        marginVertical: 16,
    },
    titleContainer: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 17,
        fontWeight: '600',
        lineHeight: 20.29,
        marginHorizontal: 6,
        marginVertical: 16,
    },
    lineContainer: {
        marginHorizontal: 6,
        height: 2,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    line: {
        flex: 1,
        maxWidth: 792,
        height: 1,
        backgroundColor: colors.gray,
    },
    lineOver: {
        width: 174,
        height: 3,
        backgroundColor: theme.colors.primary,
    },
});
