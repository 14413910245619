import { Subject } from 'rxjs';

import { ProfileUpdatedState } from '@Profile/interfaces';

const UpdateProfileService = () => {
    const subject = new Subject();

    const onSuccess = (response: ProfileUpdatedState) => {
        subject.next({
            ...response,
            success: true,
        });
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onSuccess,
        subscribe,
    };

};

export default UpdateProfileService();
