import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import * as statementActions from 'statements/state/statementActions';

type ActionsType = ActionType<typeof statementActions>;

interface StatementState {
    status?: string;
};

export const initialState = {
};

export const newStatementReducer = createReducer<StatementState, ActionsType>(
    initialState,
);
