import React from 'react';

import {
    StyleProp,
    StyleSheet,
    Text,
    TouchableOpacity,
    ViewProps,
    ViewStyle,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

interface Props extends ViewProps {
    accessibilityLabel?: string;
    buttonStyle?: StyleProp<ViewStyle>;
    disabled?: boolean | null | undefined;
    disabledStyle?: StyleProp<ViewStyle>;
    handleOnChange: () => void;
    testID?: string;
    textStyle?: any;
    title?: any;
};

export const AppButton = ({
    accessibilityLabel = 'AppButton',
    buttonStyle,
    disabled,
    disabledStyle = { backgroundColor: colors.darkGray, borderColor: theme.colors.border },
    handleOnChange,
    testID = 'AppButton',
    textStyle,
    title,
}: Props) => (
    <TouchableOpacity
        style={[
            styles.button,
            buttonStyle,
            disabled && disabledStyle,
        ]}
        onPress={handleOnChange}
        activeOpacity={0.9}
        accessibilityLabel={accessibilityLabel}
        testID={testID}
        disabled={disabled}
    >
        <Text
            style={[
                styles.text,
                textStyle,
                disabled && {
                    color: colors.white,
                },
            ]}
            numberOfLines={1}
        >{title}</Text>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    button: {
        alignItems: 'center',
        backgroundColor: theme.colors.primary,
        borderRadius: 5,
        height: 47,
        justifyContent: 'center',
    },
    text: {
        color: colors.white,
        fontSize: 17,
        fontWeight: '600',
        fontFamily: fonts.SFProText,
    },
});
