import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    icon: {
        alignItems: 'center',
        flex: 10,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                marginLeft: 10,
            },
        }),
    },
    textAmount: {
        color: colors.grayLigth,
        flex: 90,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
    },
    textNumber: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 24,
        ...Platform.select({
            web: {
                flex: 10,
            },
            default: {
                flex: 113,
            },
        }),
    },
    touchableSPressed: {
        backgroundColor: colors.brightCyan,
    },
    viewFlat: {
        flex: 100,
        height: 88,
        flexDirection: 'row',
        paddingVertical: 20,
        justifyContent: 'space-between',
        ...Platform.select({
            web: {
                paddingHorizontal: 20,
            },
            default: {
                paddingHorizontal: 39,
            },
        }),
    },
    viewRow: {
        alignItems: 'flex-start',
        flex: 90,
        justifyContent: 'center',
    },
});
