import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    appText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        textAlign: 'center',
    },
    appTextContainer: {
        height: 30,
        marginTop: 10,
        marginBottom: 5,
        justifyContent: 'center',
    },
    contentModalStyle: {
        flex: 90,
        backgroundColor: colors.white,
        ...Platform.select({
            default: {
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
            },
            web: {
                borderRadius: 15,
            },
        }),
    },
    contentModel: {
        color: colors.grayDarkColor,
        marginTop: '110%',
    },
    containerLogOut: {
        flexDirection: 'row',
        marginBottom: 15,
        marginTop: 15,
        marginHorizontal: 20,
    },
    flatList: {
        flex: 100,
    },
    flatListContainer: {
        flex: 75,
    },
    headerBar: {
        backgroundColor: theme.colors.primary,
        borderRadius: 13,
        height: 5,
        marginTop: 10,
        width: 114,
    },
    headerContentModalStyle: {
        flex: 25,
        alignItems: 'center',
    },
    headerContentStyle: {
        flex: 20,
        alignItems: 'center',
        height: 60,
    },

    headerStyle: {
        justifyContent: 'center',
        marginTop: '110%',
    },
    itemSeparator: {
        backgroundColor: colors.gray,
        height: 1,
        marginHorizontal: 16,
    },
    textNumber: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProTextMedium,
        fontSize: 17,
        fontWeight: '500',
        lineHeight: 24,
        textAlign: 'left',
        ...Platform.select({
            web: {
                flex: 10,
            },
            default: {
                flex: 113,
            },
        }),
    },
});
