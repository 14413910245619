enum AuthenticateActionsTypes {
    AUTHENTICATE_ERROR = '@@auth/AUTHENTICATE_ERROR',
    AUTHENTICATE_LOGOUT = '@@auth/AUTHENTICATE_LOGOUT',
    AUTHENTICATE_LOGOUT_SUCCESS = '@@auth/AUTHENTICATE_LOGOUT_SUCCESS',
    AUTHENTICATE_REFRESH_TOKEN = '@@auth/AUTHENTICATE_REFRESH_TOKEN',
    AUTHENTICATE_REQUEST = '@@auth/AUTHENTICATE_REQUEST',
    AUTHENTICATE_SUCCESS = '@@auth/AUTHENTICATE_SUCCESS',
    AUTHENTICATE_INTERNAL_REQUEST = '@@auth/AUTHENTICATE_INTERNAL_REQUEST',
};

export default AuthenticateActionsTypes;
