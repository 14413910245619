import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    appText: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        textAlign: 'center',
    },
    appTextContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    contentModel: {
        color: colors.grayDarkColor,
        marginTop: '80%',
        ...Platform.select({
            android: {
                marginTop: '100%',
            },
        }),
    },
    flatList: {
        flex: 1,
        backgroundColor: 'white',
        paddingTop: 80,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    headerBar: {
        backgroundColor: theme.colors.primary,
        borderRadius: 13,
        height: 5,
        marginTop: 10,
        width: 114,
    },
    headerContentStyle: {
        flex: 100,
        alignItems: 'center',
        height: 100,
    },
    headerStyle: {
        justifyContent: 'center',
        marginTop: '80%',
        ...Platform.select({
            android: {
                marginTop: '100%',
            },
        }),
    },
    name: {
        fontFamily: fonts.SFProText,
        fontSize: 15,
        lineHeight: 24,
        color: theme.colors.primary,
        paddingLeft: 47,
        fontWeight: '600',
        ...Platform.select({
            android: {
                fontFamily: fonts.SFProTextBold,
            },
        }),
    },
});
