import appAPI from '@Api/appApi';
import { Commands } from '@Api/config';
import {
    GetBalanceRequest,
    GetBalanceResponse,
} from '@Home/interfaces/index';

export const getBalanceAPI = ({ applicationId }: GetBalanceRequest): Promise<GetBalanceResponse> => new Promise(async (resolve, reject) => {
    try {
        const command = Commands.GET_BALANCE.replace('{applicationId}', `${applicationId}`);
        const response = await appAPI.get<GetBalanceResponse>(command);

        resolve({
            ...response?.data,
            success: true,
        });
    } catch (error) {
        reject(error);
    }
});
