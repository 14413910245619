import { PaymentPendingResponse } from '@Activity/interfaces';
import { UnitsResponse } from '@Interfaces/BaseResponse';
import { PaymentMethodAutoPay } from '@MethodsOfPayment/interfaces';

export enum MakeAPaymentOptionType {
    MINIMUM_PAYMENT = 1,
    PAST_DUE = 2,
    TODAYS_PAYOFF = 3,
    OTHER_AMOUNT = 4,
};

export interface MakeAPaymentState {
    showWarning: boolean;
    isLeftMenuReset: boolean;
};

export interface MakeAPaymentRequest {
    loanId?: number;
};

export interface SaveAPaymentRequest extends PaymentSchedule {
    loanId: number;
};

export interface MakeAPaymentRequestAction {
    payload: MakeAPaymentRequest;
};

export interface SaveAPaymentRequestAction {
    payload: SaveAPaymentRequest;
};

export interface MakeAPaymentOption {
    amount: string;
    enabled: boolean;
    id: MakeAPaymentOptionType;
    isInput: boolean;
    isShowShimmer?: boolean;
    selected: boolean;
    title: string;
    tooltipDescription?: string;
};

export interface PaymentSchedule {
    paymentAmount: number;
    runDate: string;
    paymentMethodId: number;
    paymentMethodType: string;
    isTodayPayoff: boolean;
};

export interface getPaymentRequestAction {
    loanId: GetPaymentResponse;
};

export interface GetPaymentResponse {
    pastDueAmount: UnitsResponse;
    paymentAmount: UnitsResponse;
    payOffTodayAmount: UnitsResponse;
    dueDate: string;
};

export interface CurrentPaymentService {
    currentMonthlyPayment: string;
    dueDate?: string;
    minimumPayment: string;
    pastDue: string;
    payOffToday: string;
};

export interface GetPaymentStatusRequest {
    loanId: number;
    dateSelected: Date;
};

export interface GetPaymentStatusActionRequest {
    payload: GetPaymentStatusRequest;
};

export interface ShouldShowPaymentWarning {
    show: boolean;
};

export interface ShouldShowPaymentWarningAction {
    payload: ShouldShowPaymentWarning;
};

export interface GetPaymentStatus {
    autopayResponse: PaymentMethodAutoPay | undefined;
    pendingPaymentsResponse: PaymentPendingResponse[] | undefined;
};
