import React, { ReactElement } from 'react';

import {
    StyleProp,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';

interface Props {
    renderIcon: () => ReactElement;
    onPress: () => void;
    style?: StyleProp<ViewStyle>;
    accessibilityLabel: string;
    testID: string;
};

export const ToolbarIcon = ({
    renderIcon,
    onPress,
    style: customStyle,
    accessibilityLabel = 'toolbarIconTouchableIcon',
    testID = 'toolbarIconTouchableIcon',
}: Props) => {
    const handleOnPress = () => onPress();

    return (
        <TouchableOpacity
            onPress={handleOnPress}
            accessibilityLabel={accessibilityLabel}
            testID={testID}
            style={[
                customStyle,
                {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            ]}
        >
            {renderIcon()}
        </TouchableOpacity>
    );
};
