import React, { useEffect } from 'react';

import { View } from 'react-native';

import { EasypayLogoSvg } from '@Assets/index';
import AuthenticateInternalService from '@Auth/services/AuthenticateInternalService';
import AuthenticateService from '@Auth/services/AuthenticateService';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { reset } from '@Navigation/RootNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { theme } from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'InternalLogin'> { };

export const InternalLoginScreen = ({ route }: Props) => {
    const {
        token,
    } = route?.params || {};

    useEffect(() => {
        AuthenticateService.onSuccess({
            success: false,
            isSessionExpired: true,
        });
        goToSignIn(token);
    }, [token]);

    useEffect(() => {
        const observer = AuthenticateInternalService.subscribe((state: any) => {
            const { success } = state;
            if (!success) {
                goToSignIn();
            }
        });

        return () => {
            observer.unsubscribe();
        };
    }, []);

    const goToSignIn = (token?: string) => {
        if (token) {
            reset({
                index: 0,
                routes: [
                    {
                        name: 'LoginScreen',
                        params: {
                            internalLoginParams: {
                                token,
                            },
                        },
                    },
                ],
            });
        } else {
            reset({
                index: 0,
                routes: [
                    {
                        name: 'LoginScreen',
                    },
                ],
            });
        }
    };

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.primary,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <EasypayLogoSvg />
        </View>
    );
};
