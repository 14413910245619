import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    buttonCancel: {
        backgroundColor: 'transparent',
    },
    containerButton: {
        width: '70%',
    },
    containerCalendar: {
        marginTop: 8,
        width: '100%',
    },
    containerDate: {
        alignItems: 'center',
        flex: 1,
    },
    containerDescription: {
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.greenLigthContainer,
        borderRadius: 10,
        height: 60,
        justifyContent: 'center',
        marginTop: 16,
        // width: 470,
    },
    containerMakeAPaymentButton: {
        alignItems: 'center',
        marginTop: 16,
        width: '100%',
    },
    containerWarning: {
        alignItems: 'center',
        backgroundColor: colors.softRed,
        borderRadius: 10,
        height: 64,
        justifyContent: 'center',
        marginTop: 16,
        // width: 470,
    },
    makeAPaymentButton: {
        height: 55,
        width: '100%',
    },
    styleCalendar: {
        alignItems: 'center',
        marginTop: 10,
        width: '100%',
        paddingHorizontal: 20,
    },
    textCancel: {
        color: colors.darkGray,
    },
    textDateDescription: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProText,
        fontSize: 14,
        fontWeight: '500',
        // width: 400,
        lineHeight: 12,
        paddingHorizontal: 16
    },
    textNextPayment: {
        color: colors.darkGray,
        fontFamily: fonts.SFProText,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 16,
    },
    textSelectDate: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 24,
        fontWeight: '500',
        lineHeight: 29,
        marginTop: 15,
        textAlign: 'center',
    },
    textWarning: {
        color: colors.blackOpacity,
        fontFamily: fonts.SFProText,
        fontSize: 14,
        fontWeight: '500',
        // width: 400,
        lineHeight: 12,
        paddingHorizontal: 16
    },
});
