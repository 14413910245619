import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    activeDot: {
        backgroundColor: theme.colors.primary,
    },
    dotStyle: {
        backgroundColor: colors.gray,
    },
    introTextStyle: {
        color: colors.grayLigth,
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        paddingTop: 20,
        paddingVertical: 30,
        textAlign: 'center',
    },
    introTitleStyle: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 24,
        fontWeight: '500',
        // marginTop: 22,
        textAlign: 'center',
    },
    textButton: {
        alignItems: 'center',
        color: colors.white,
        flexDirection: 'column',
        fontFamily: fonts.SFProText,
        fontSize: 17,
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    },
    viewButton: {
        alignSelf: 'center',
        backgroundColor: colors.greenContainer,
        borderRadius: 5,
        flex: 1,
        height: 45,
        justifyContent: 'center',
        overflow: 'hidden',
        paddingBottom: 1,
        width: 255,
    },
    viewImage: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        display: 'flex',
        flex: 1,
    },
    viewText: {
        height: 240,
        paddingHorizontal: 40,
    },
    viewP: {
        backgroundColor: colors.white,
        height: '100%',
        width: '100%',
        zIndex: 999,
    },
    viewAppIntro: {
        flex: 1,
        overflow: 'hidden',
        zIndex: 0,
    },
    view: {
        alignItems: 'center',
        backgroundColor: colors.white,
        flex: 1,
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden',
    },
});
