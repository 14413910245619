import * as Yup from 'yup';

import Languages from '@i18n/index';

export const NewPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Enter valid email')
        .required(Languages.emptyField),
    password: Yup.string()
        .required(Languages.emptyField)
        .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, Languages.PasswordRule),
    confirmPassword: Yup.string()
        .required(Languages.emptyField)
        .oneOf([Yup.ref('password'), null], Languages.PasswordDoesNotMatch),
});
