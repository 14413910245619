import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    KeyboardAvoidingView,
    Platform,
    Text,
    TextInput as Input,
    TouchableOpacity,
    View,
    Linking
} from 'react-native';

import {
    EyeHideIconSvg,
    EyeIconSvg,
} from '@Assets/index';
import { LoginSchema } from '@Auth/config/LoginSchema';
import { InputType } from '@Auth/interfaces/index';
import { clearForgotPassword } from '@Auth/state/forgotPasswordActions';
import { styles } from '@Auth/styles/LoginStyles';
import { AppButton } from '@Components/AppButton';
import { TextInput } from '@Components/TextInput';
import { useAppDispatch } from '@Hooks/appHooks';
import { useFormik } from '@Hooks/useForm';
import { KeyboardEventObject } from '@Hooks/useKeyboardEvent';
import Languages from '@i18n/index';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import {
    colors,
    theme,
} from '@Theme/appTheme';

interface Login {
    email: string;
    password: string;
}

const initialLogin: Login = {
    email: '',
    password: '',
};

interface Props extends StackScreenProps<RootStackParamList, 'LoginScreen'> {
    doLogin: (email: string, password: string) => void;
    keyboardEvent?: KeyboardEventObject;
};

export const LoginForm = ({
    navigation,
    doLogin,
    keyboardEvent,
}: Props) => {
    const dispatch = useAppDispatch();

    const verticalOffset = Platform.OS === 'ios' ? 30 : 0;
    const [isShowPassword, setIsShowPassword] = useState(true);

    const passwordRef = useRef<Input>(null);

    useEffect(() => {
        if (handledToValidateSendForm()) {
            submitForm();
        }
    }, [keyboardEvent]);

    const handleOpenRegister = () => navigation.navigate('RegisterScreen', {});

    const handleOpenLTOSite = () => Linking.openURL('https://www.easypayleasing.acct-admin.com/');

    const handledToValidateSendForm = () => keyboardEvent?.triggered && !Boolean(shouldBeActiveBtn());

    const handleOpenForgotPassword = () => {
        dispatch(clearForgotPassword());
        navigation.navigate('ForgotPassword', {});
    };

    const handleSubmitLogin = (values: Login) => {
        doLogin(values.email, values.password);
    };

    const handleShowPassword = () => {
        passwordRef.current?.focus();
        setIsShowPassword(!isShowPassword);
    };

    const fillEyeColor = (isFocus: boolean | undefined, error: any, touched: any) => {

        if (Boolean(error) && touched) {
            return colors.red;
        }

        if (isFocus) {
            return theme.colors.primary;
        } else {
            return colors.gray;
        };
    };
    const shouldBeActiveBtn = () => errors.email || errors.password;

    const renderEyeIconPassword = () => (
        < >
            {isShowPassword ? <EyeHideIconSvg
                width={20}
                height={19}
                fill={fillEyeColor(passwordRef.current?.isFocused(), errors.password, touched.password)}
            /> : <EyeIconSvg
                width={22}
                height={15}
                fill={fillEyeColor(passwordRef.current?.isFocused(), errors.password, touched.password)}
            />}
        </>

    );

    const form = useFormik(handleSubmitLogin, initialLogin, LoginSchema);

    const onChangeText = (field: InputType, text: string) => {
        setFieldTouched(field, true);
        validateField(field);
        setFieldValue(field, text);
    };

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        submitForm,
        setFieldTouched,
        setFieldValue,
        validateField,
    } = form;

    return (
        <>
            <KeyboardAvoidingView
                style={styles.containerForm}
                behavior={'padding'}
                keyboardVerticalOffset={verticalOffset}
            >
                <TextInput
                    placeholder={Languages.Email}
                    value={values.email}
                    keyboardType='email-address'
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    autoCapitalize='none'
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ''}
                    accessibilityLabel='loginFormTextInputEmail'
                    testID='loginFormTextInputEmail'
                    style={styles.emailStyle}
                />
                <TextInput
                    placeholder={Languages.Password}
                    value={values.password}
                    onChangeText={(text: string) => onChangeText(InputType.password, text)}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ''}
                    RightIcon={() => renderEyeIconPassword()}
                    autoCapitalize='none'
                    securityEntry={isShowPassword}
                    myRef={passwordRef}
                    accessibilityLabel='loginFormTextInputPassword'
                    testID='loginFormTextInputPassword'
                    onClickRight={handleShowPassword}
                    style={styles.passwordStyle}
                />
                <TouchableOpacity
                    accessibilityLabel={'loginFormTouchableForgotPassword'}
                    onPress={handleOpenForgotPassword}
                    testID={'loginFormTouchableForgotPassword'}
                >
                    <Text style={styles.forgotText}>{Languages.ForgotPasswordQuestion}</Text>
                </TouchableOpacity>
                <AppButton
                    accessibilityLabel={'loginFormAppButtonSignIn'}
                    buttonStyle={{ marginBottom: 10 }}
                    disabled={Boolean(shouldBeActiveBtn())}
                    handleOnChange={submitForm}
                    testID={'loginFormAppButtonSignIn'}
                    title={Languages.SignIn}
                />
            </KeyboardAvoidingView>
            <View style={styles.footerContainer}>
            <View style={styles.accountFooter}>
                    <Text style={styles.dontHaveAccount}>{Languages.DontHaveAnAccountQuestion}</Text>
                    <TouchableOpacity
                        accessibilityLabel={'loginFormTouchableRegisterNow'}
                        onPress={handleOpenRegister}
                        testID={'loginFormTouchableRegisterNow'}
                    >
                        <Text style={styles.registerNow}>{Languages.RegisterNow}</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.accountFooter}>
                    <Text style={styles.ltoCustomerPortal}>{Languages.LeaseToOwnCustomers}</Text>
                    <TouchableOpacity
                        accessibilityLabel={'loginFormTouchableLTOCustomerPortal'}
                        onPress={handleOpenLTOSite}
                        testID={'loginFormTouchableLTOCustomerPortal'}
                    >
                        <Text style={styles.ltoCustomerPortalLink}>{Languages.LeaseToOwnCustomersPortal}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </>
    );
};
