import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    ScrollView,
    Text,
    View,
} from 'react-native';

import { AppButton } from '@Components/AppButton';
import { AppModalAlert } from '@Components/AppModalAlert';
import { AppSwitch } from '@Components/AppSwitch';
import { SectionHeaderComponent } from '@Components/SectionHeaderComponent';
import { ShimmerApp } from '@Components/ShimerApp';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import Languages from '@i18n/index';
import LoggerService from '@Logger/services/LoggerService';
import { CurrentPaymentService } from '@MakeAPayment/interfaces/index';
import { PaymentOptionsScreen } from '@MakeAPayment/screens/PaymentOptionsScreen.web';
import MakeAPaymentService from '@MakeAPayment/services/MakeAPaymentService';
import { getCurrentPaymentAction } from '@MakeAPayment/state/MakeAPaymentActions';
import { PaymentMethodsDropZone } from '@MethodsOfPayment/components/PaymentMethodsDropZone';
import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import { PrimaryMethodModal } from '@MethodsOfPayment/modals/PrimaryMethodModal';
import { AddPaymentMethodDialog } from '@MethodsOfPayment/screens/AddPaymentMethodDialog';
import {
    getPaymentMethodAutoPayAction,
    patchPaymentSetPrimaryAction,
    putPaymentMethodAutoPayAction,
} from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import PaymentDefinitionsModalService
    from '@PaymentDefinitionsModal/services/PaymentDefinitionsModalService';
import { styles } from '@Payments/styles/PaymentsWebStyles';
import { StackScreenProps } from '@react-navigation/stack';
import { setPaymentMethodAutoPayAction } from '../../methodsOfPayment/state/MethodsOfPaymentActions';
import { PaymentProcessDialogScreen } from '@MakeAPayment/screens/PaymentProcessDialogScreen';
import { useWindowDimensions } from 'react-native';

interface Props extends StackScreenProps<RootStackParamList, 'PaymentsScreen'> { }

export const PaymentsScreen = ({
    navigation,
}: Props) => {
    const dispatch = useAppDispatch();

    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );
    const { autopay: hasAutopay } = useAppSelector(({ methodsOfPaymentReducer }) => methodsOfPaymentReducer);

    const [payment, setPayment] = useState<CurrentPaymentService>();
    const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
    const [isMakeAPayment, setIsMakeAPayment] = useState<boolean>(false);
    const [isAutoPayAlert, setIsAutoPayAlert] = useState<boolean>(false);
    const selectedToEditPaymentMethodRef = useRef<PaymentMethodResponseBase>();
    const [isSelectMethodOfPayment, setIsSelectMethodOfPayment] = useState<boolean>(false);
    const { width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        if (!loanSelected) {
            return;
        }
        const {
            loanId,
        } = loanSelected;
        dispatch(getCurrentPaymentAction({ loanId }));
        dispatch(getPaymentMethodAutoPayAction({ loanId }));
    }, [loanSelected]);

    useEffect(() => {
        setIsAutoPay(hasAutopay || false);
    }, [hasAutopay]);

    useEffect(() => {
        const getAPaymentSubscribe = MakeAPaymentService.subscribe((state: CurrentPaymentService) => {
            setPayment(state);
        });

        return () => {
            getAPaymentSubscribe.unsubscribe();
        };
    }, []);

    const handleOnTapInfoPaymentOptions = () => {
        requestAnimationFrame(() => {
            PaymentDefinitionsModalService.show();
        });
    };

    const handleOnChangeAutopay = (fromSwitch?: boolean) => {
        setIsAutoPayAlert(true);
        setIsAutoPay(!isAutoPay);
    };

    const addPaymentMethod = () => setIsMakeAPayment(true);

    const handleCancel = () => {
        setIsMakeAPayment(false);
        selectedToEditPaymentMethodRef.current = undefined;
    };

    const handleCancelSelectMethod = () => {
        setIsAutoPay(false);
        setIsSelectMethodOfPayment(false);
    };

    const handleSelectAutoPayPaymentMethod = (item: PaymentMethodResponseBase) => {
        if (loanSelected) {
            dispatch(patchPaymentSetPrimaryAction({
                loanId: loanSelected?.loanId || 0,
                paymentMethodId: item.id,
                paymentMethodType: item.type,
            }));
            setIsSelectMethodOfPayment(false);
        }
    }

    const handleAcceptAutoPay = (fromSwitch?: boolean) => {
        setIsAutoPayAlert(false);
        if (loanSelected) {
            LoggerService.logEvent('autopay', {
                on: isAutoPay,
            });
            if (fromSwitch && isAutoPay) {
                setIsSelectMethodOfPayment(true);
            } else {
                dispatchAutoPayChange();
            }
        }
    };

    const dispatchAutoPayChange = () => {
        if (loanSelected) {
            dispatch(putPaymentMethodAutoPayAction({
                loanId: loanSelected.loanId,
                autopay: isAutoPay,
            }));
            dispatch(setPaymentMethodAutoPayAction({ autopay: isAutoPay }));
        }
    }

    const handleOnEditPaymentMethod = (paymentMethod: PaymentMethodResponseBase) => {
        selectedToEditPaymentMethodRef.current = paymentMethod;
        setIsMakeAPayment(true);
    };

    const autoPayAlert = useMemo(() => (
        <AppModalAlert
            accessibilityLabel={'methodsOfPaymentAppModalAutoPay'}
            handleAccept={handleAcceptAutoPay}
            handleCancel={() => {
                setIsAutoPayAlert(false);
                setIsAutoPay(hasAutopay || false);
            }}
            show={isAutoPayAlert}
            subtitle={isAutoPay ? Languages.EnableAutoPay : Languages.DisableAutoPay}
            testID={'methodsOfPaymentAppModalAutoPay'}
            title={Languages.DoYouWantToContinue}
        />
    ), [isAutoPayAlert]);

    return (
        <View style={styles.container}>
            <ScrollView style={{ flex: 100 }} showsVerticalScrollIndicator={false}>
                <View style={styles.viewTextTitle}>
                    <SectionHeaderComponent
                        title={Languages.Billing}
                    />
                </View>
                <View style={styles.containerBody}>
                    <View style={{...styles.containerLeft,  ...windowWidth < 900 ? { width: '100%' } : {}, marginBottom: 10}}>
                        <View style={styles.viewCurrent}>
                            <ShimmerApp
                                visible={Boolean(payment?.currentMonthlyPayment)}
                                shimmerStyle={styles.cardShimmerBalanceTitle}
                            >
                                <Text style={styles.text}>{Languages.CurrentPaymentAmount}</Text>
                            </ShimmerApp>
                            <ShimmerApp
                                visible={Boolean(payment?.currentMonthlyPayment)}
                                shimmerStyle={styles.cardShimmerBalanceAmount}
                            >
                                <Text style={styles.amount}>{`$${payment?.currentMonthlyPayment ?? '0.00'}`}</Text>
                            </ShimmerApp>
                        </View>
                        <View style={styles.containerPaymentOptions}>
                            <PaymentOptionsScreen
                                payment={payment}
                                onTapInfoPaymentOptions={handleOnTapInfoPaymentOptions}
                            />
                        </View>
                    </View>
                    <View style={styles.containerRight}>
                        <View style={styles.containerPaymentMethodsHeader}>
                            <View style={styles.headerLeft}>
                                <ShimmerApp
                                    visible={Boolean(payment?.currentMonthlyPayment)}
                                    shimmerStyle={styles.cardShimmerHeaderLeft}
                                >
                                    <Text style={styles.headerLeftText}>{Languages.PaymentMethods}</Text>
                                </ShimmerApp>
                            </View>
                            <View style={styles.containerAutoPay}>
                                <View style={styles.containerAutoPayLeft}>
                                    <ShimmerApp
                                        visible={Boolean(payment?.currentMonthlyPayment)}
                                        shimmerStyle={styles.cardShimmerAutotext}
                                    >
                                        <Text style={styles.textAutopay}>{Languages.AutoPay}</Text>
                                    </ShimmerApp>
                                </View>
                                <ShimmerApp
                                    visible={Boolean(payment?.currentMonthlyPayment)}
                                    shimmerStyle={styles.cardShimmerSwitch}
                                >
                                    <AppSwitch
                                        accessibilityLabel={'methodsOfPaymentsAppSwitchAutoPay'}
                                        disabled={!loanSelected?.permissions.canSetAutoPay}
                                        isOn={isAutoPay}
                                        onChange={() => handleOnChangeAutopay(true)}
                                        testID={'methodsOfPaymentsAppSwitchAutoPay'}
                                    />
                                </ShimmerApp>
                            </View>
                        </View>
                        <View style={styles.containerPaymentMethodsBody}>
                            <PaymentMethodsDropZone
                                loan={loanSelected}
                                isAutoPay={isAutoPay}
                                onEdit={handleOnEditPaymentMethod}
                                onAutoPayOff={() => handleOnChangeAutopay(false)}
                            />
                        </View>
                        <View style={styles.footerContainer}>
                            <View
                                style={styles.containerButton}
                                nativeID={'PaymentsScreenAddNewMethodAppButton'}
                            >
                                <ShimmerApp
                                    visible={Boolean(payment?.currentMonthlyPayment)}
                                    shimmerStyle={styles.cardShimmerAddMethod}
                                >
                                    <AppButton
                                        accessibilityLabel={'PaymentsScreenAddNewMethodAppButton'}
                                        buttonStyle={styles.addNewMethodButton}
                                        disabled={!loanSelected?.permissions.canAddMethodOfPayment}
                                        disabledStyle={styles.addNewMethodButtonDisable}
                                        handleOnChange={addPaymentMethod}
                                        testID={'PaymentsScreenAddNewMethodAppButton'}
                                        textStyle={styles.textAddNewMethod}
                                        title={Languages.AddNewMethod}
                                    />
                                </ShimmerApp>
                            </View>
                            <AddPaymentMethodDialog
                                isVisible={isMakeAPayment}
                                handleCancel={handleCancel}
                                handleSuccess={handleCancel}
                                paymentMethod={selectedToEditPaymentMethodRef.current}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>
            <PrimaryMethodModal />
            {autoPayAlert}
            <PaymentProcessDialogScreen
                isSelectionOnly={true}
                handleCancel={handleCancelSelectMethod}
                handleSelection={handleSelectAutoPayPaymentMethod}
                isVisible={isSelectMethodOfPayment}
            />
        </View >
    );
};
