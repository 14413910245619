import { ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { Error } from '@Interfaces/BaseResponse';
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { getDocumentNewStatementsAPI, getNewStatementsAPI } from '../api/statementsApi';
import { parseDate } from '@Utils/FormatUtils';
import NewStamentService from '../services/NewStatementService';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import AppAlertService from '@Alert/services/AppAlertService';
import LoggerService from '@Logger/services/LoggerService';
import { GetNewStatementRequestAction, NewStatementResponse } from '../interfaces';
import StatementsActionTypes from '../state/statementActionTypes';
import DocumentStatementService from '@Activity/services/DocumentStatementsService';
import Languages from '@i18n/index';

export const getNewStamentsEpic = ($action: Observable<any>) => $action.pipe(
    ofType(StatementsActionTypes.GET_NEW_STATEMENTS),
    switchMap(({ payload }: GetNewStatementRequestAction) => from(getNewStatementsAPI({
        ...payload,
    })).pipe(
        map((response: NewStatementResponse[]) => {
            const mapRespopnse: NewStatementResponse[] = response.map((item: NewStatementResponse, index: number) => ({
                ...item,
                timestamp: parseDate(item.timestamp, 'MM/dd/yyyy'),
                selected: index === 0,
            }));

            NewStamentService.onSuccess(mapRespopnse);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error?.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

export const getDocumentNewStamentsEpic = ($action: Observable<any>) => $action.pipe(
    ofType(StatementsActionTypes.GET_DOCUMENT_NEW_STATEMENT),
    switchMap(({ payload }: any) => from(getDocumentNewStatementsAPI({
        ...payload,
    })).pipe(
        mergeMap((response: Blob) => from(new Promise<any>((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onloadend = () => {
                    const dataResult = reader.result as string;
                    const data = dataResult.split(',')[1];
                    resolve(data);
                };
            } catch (error) {
                reject({
                    title: 'File reader error',
                    code: 666,
                } as Error);
            }
        })).pipe(
            map((response: any) => {
                DocumentStatementService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title || Languages.AnErrorHasOcurred,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        )),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title || Languages.AnErrorHasOcurred,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);