import React, { useEffect } from 'react';

import { View } from 'react-native';

import AppAlertService from '@Alert/services/AppAlertService';
import { EasypayLogoSvg } from '@Assets/index';
import RegisterService, { RegisterServiceResponse } from '@Auth/services/RegisterService';
import { confirmAccountAction } from '@Auth/state/registerActions';
import { useAppDispatch } from '@Hooks/appHooks';
import { RootStackParamList } from '@Navigation/NoAuthorizeNavigation';
import { reset } from '@Navigation/RootNavigation';
import { StackScreenProps } from '@react-navigation/stack';
import { theme } from '@Theme/appTheme';

interface Props extends StackScreenProps<RootStackParamList, 'AccountConfirmationScreen'> { };

export const AccountConfirmationScreen = ({ route }: Props) => {
    const dispatch = useAppDispatch();
    const {
        email,
        code,
    } = route?.params || {};

    useEffect(() => {
        const registerServiceSubscription = RegisterService.subscribe((state: RegisterServiceResponse) => {
            const { code: responseCode, success: isSuccess, title } = state;
            if (isSuccess) {
                goToLoginScreen();
            } else if (responseCode === 102) {
                goToExpireLinkScreen();
            } else {
                AppAlertService.showError({
                    message: title,
                });
                goToLoginScreen();
            }
        });
        return () => {
            registerServiceSubscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (code && email) {
            dispatch(confirmAccountAction({
                email,
                verificationCode: code,
            }));
        }
    }, [code, email]);

    const goToExpireLinkScreen = () => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
                {
                    name: 'LinkExpireScreen',
                    params: {
                        email,
                        type: 'Register',
                    },
                },
            ],
        });
    };

    const goToLoginScreen = () => {
        reset({
            index: 0,
            routes: [
                {
                    name: 'LoginScreen',
                    params: {},
                },
            ],
        });
    };

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.primary,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <EasypayLogoSvg />
        </View>
    );
};
