import React from 'react';

import {
    Text,
    View,
} from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';
import { AppStyleSheet } from '@Utils/index';

interface Props {
    children: JSX.Element;
    text: string | undefined;
};

export const BadgeComponent = ({ children, text }: Props) => (
    <View>
        {children}
        {
            text && (
                <View
                    accessibilityLabel={'badgeComponentContainer'}
                    nativeID={'badgeComponentContainer'}
                    style={styles.container}
                    testID={'badgeComponentContainer'}
                >
                    <View style={styles.badge}>
                        <Text
                            accessibilityLabel={'badgeComponentText'}
                            nativeID={'badgeComponentText'}
                            style={styles.text}
                            testID={'badgeComponentText'}
                        >{text}</Text>
                    </View>
                </View>
            )
        }
    </View>
);

const styles = AppStyleSheet({
    container: {
        position: 'absolute',
        top: -5,
        right: -5,
    },
    badge: {
        flex: 1,
        backgroundColor: colors.notificationSoftRed,
        borderRadius: 100,
        minWidth: 16,
        maxWidth: 22,
        minHeight: 16,
        maxHeight: 22,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: colors.white,
        fontSize: 11,
        fontFamily: fonts.Roboto,
    },
});
