import { Subject } from 'rxjs';

const ForgotService = () => {
    const subject = new Subject();

    const sentCode = (request: string) => {
        subject.next(request);
    };

    const onSuccess = (response: Response) => {
        subject.next({
            ...response,
            success: true,
        });
    };

    const onError = (response: Response) => {
        subject.next(response);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onError,
        onSuccess,
        sentCode,
        subscribe,
    };

};

export default ForgotService();
