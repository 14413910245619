import { createAction } from 'typesafe-actions';

import {
    GetPaymentActivitiesRequest,
    GetPaymentActivityRequest,
    GetPendingPaymentsRequest,
    GetStatementDocumentRequest,
    GetStatementRequest,
    PaymentActivityDetailResponse,
    PaymentPendingCancelRequest,
    PaymentPendingRequest,
} from '@Activity/interfaces';
import ActivityActionTypes from '@Activity/state/activityActionTypes';

export const getDocumentStatement = createAction(
    ActivityActionTypes.GET_DOCUMENT_STATEMENT,
    (request: GetStatementDocumentRequest) => (request)
)();

export const getPaymentActivities = createAction(
    ActivityActionTypes.GET_PAYMENT_ACTIVITIES,
    (request: GetPaymentActivitiesRequest) => (request)
)();

export const getPaymentStatements = createAction(
    ActivityActionTypes.GET_PAYMENT_STATEMENTS,
    (request: GetStatementRequest) => (request),
)();

export const getPaymentPendings = createAction(
    ActivityActionTypes.GET_PAYMENT_PENDINGS,
    (request: GetPendingPaymentsRequest) => (request),
)();

export const getPaymentActivityAction = createAction(
    ActivityActionTypes.GET_PAYMENT_ACTIVITY,
    (request: GetPaymentActivityRequest) => (request),
)();

export const savePaymentActivityAction = createAction(
    ActivityActionTypes.SAVE_PAYMENT_ACTIVITY,
    (response: PaymentActivityDetailResponse) => (response),
)();

export const updatePaymentPending = createAction(
    ActivityActionTypes.UPDATE_PENDING_REQUEST,
    (request: PaymentPendingRequest) => (request),
)();

export const cancelPaymentPendingAction = createAction(
    ActivityActionTypes.CANCEL_PENDING_REQUEST,
    (request: PaymentPendingCancelRequest) => (request)
)();
