import {
    StyleSheet,
} from 'react-native';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: colors.veryLightGray,
        flex: 1,
        borderRadius: 10,
    },
    title: {
        color: theme.colors.primary,
        fontSize: 24,
        fontWeight: '500',
        fontFamily: fonts.SFProDisplay,
        lineHeight: 28,
        marginTop: 32,
    },
    containerHeader: {
        alignItems: 'center',
        marginTop: 32,
        marginBottom: 16,
    },
    containerFlatFooter: {
        marginHorizontal: 8,
        marginTop: 8,
    },
});
