enum MakeAPaymentActionsTypes {
    CLEAR_PAYMENT_STATUS_REQUEST = '@payment/CLEAR_PAYMENT_STATUS_REQUEST',
    GET_CURRENT_PAYMENT_ERROR = '@payment/GET_CURRENT_PAYMENT_ERROR',
    GET_CURRENT_PAYMENT_REQUEST = '@payment/GET_CURRENT_PAYMENT_REQUEST',
    GET_CURRENT_PAYMENT_SUCCESS = '@payment/GET_CURRENT_PAYMENT_SUCCESS',
    GET_PAYMENT_STATUS_REQUEST = '@payment/GET_PAYMENT_STATUS_REQUEST',
    RESET_LEFT_MENU = '@payment/RESET_LEFT_MENU',
    SAVE_NEW_PAYMENTH_SCHEDULE_REQUEST = '@payment/SAVE_NEW_PAYMENTH_SCHEDULE_REQUEST',
    SHOULD_SHOW_PAYMENT_WARNING = '@payment/SHOULD_SHOW_PAYMENT_WARNING',
};

export default MakeAPaymentActionsTypes;
