import { createAction } from 'typesafe-actions';

import { Error } from '@Interfaces/BaseResponse';
import {
    GetZipCodeRequest,
    MenuOptions,
    PostClaimProfileRequest,
    PostPhoneUpdateRequest,
    UserInfo,
} from '@Profile/interfaces';
import ProfileActionTypes from '@Profile/state/profileActionTypes';

export const getMenuOptionsAction = createAction(
    ProfileActionTypes.PROFILE_MENU_OPTIONS_REQUEST,
)();

export const getMenuOptionsSuccessAction = createAction(
    ProfileActionTypes.PROFILE_MENU_OPTIONS_SUCCESS,
    (response: MenuOptions) => (response),
)();

export const getMenuOptionsErrorAction = createAction(
    ProfileActionTypes.PROFILE_MENU_OPTIONS_ERROR,
    (error: Error) => (error),
)();

export const getUserInfoAction = createAction(
    ProfileActionTypes.PROFILE_USER_INFO_REQUEST,
)();

export const getUserInfoSuccessAction = createAction(
    ProfileActionTypes.PROFILE_USER_INFO_SUCCESS,
    (response: UserInfo) => (response),
)();

export const getUserInfoErrorAction = createAction(
    ProfileActionTypes.PROFILE_USER_INFO_ERROR,
    (error: Error) => (error),
)();

export const putUserInfoAction = createAction(
    ProfileActionTypes.PROFILE_USER_UPDATE_REQUEST,
    (request: UserInfo) => (request),
)();

export const postPhoneUpdateVerificationAction = createAction(
    ProfileActionTypes.PROFILE_USER_UPDATE_PHONE_REQUEST,
    (request: PostPhoneUpdateRequest) => (request),
)();

export const getZipCodeInformationAction = createAction(
    ProfileActionTypes.PROFILE_USER_ZIP_CODE_REQUEST,
    (request: GetZipCodeRequest) => (request),
)();

export const cleanProfileUserInfoAction = createAction(
    ProfileActionTypes.PROFILE_USER_INFO_CLEAN,
)();

export const postClaimProfileAction = createAction(
    ProfileActionTypes.PROFILE_CLAIM_REQUEST,
    (request: PostClaimProfileRequest) => (request),
)();
