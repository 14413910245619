import { Subject } from 'rxjs';

import { Error } from '@Interfaces/BaseResponse';

const RefreshMethodsOfPaymentService = () => {
    const subject = new Subject();

    const onSuccess = (paymentMethodId: number) => {
        subject.next(paymentMethodId);
    };

    const onError = (error: Error) => {
        subject.next(error);
    };

    const subscribe = (process: any) => subject.subscribe(process);

    return {
        onError,
        onSuccess,
        subscribe,
    };

};

export default RefreshMethodsOfPaymentService();
