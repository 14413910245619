import React from 'react';

import {
    Text,
    View,
} from 'react-native';

import { PaymentDefinitionItem } from '@PaymentDefinitionsModal/interfaces';
import { styles } from '@PaymentDefinitionsModal/styles/PaymentDefinitionsModalItemStyles';

interface Props {
    item: PaymentDefinitionItem;
};

export const PaymentDefinitionsItemModal = ({ item }: Props) => (
    <View style={styles.viewRow}>
        <Text style={styles.title}>{item.title}</Text>
        <Text style={styles.subtitle}>{item.subtitle}</Text>
    </View>
);
