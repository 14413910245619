import {
    Platform,
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    dataContainer: {
        flex: 92,
        flexDirection: 'row',
    },
    error: {
        backgroundColor: colors.softRed,
    },
    floatContainer: {
        alignItems: 'center',
        backgroundColor: colors.white,
        borderRadius: 5,
        bottom: 0,
        elevation: 999,
        flex: 1,
        height: 78,
        justifyContent: 'center',
        left: 16,
        position: 'absolute',
        right: 16,
        top: 0,
        zIndex: 999,
        ...Platform.select({
            web: {
                maxWidth: 343,
                minWidth: 150,
                left: undefined,
            },
        }),
    },
    iconContainer: {
        alignItems: 'center',
        flex: 20,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                marginHorizontal: 8,
            },
        }),
    },
    infoContainer: {
        flex: 80,
        justifyContent: 'center',
        marginRight: 10,
        ...Platform.select({
            web: {
                maxWidth: 343,
                minWidth: 150,
            },
        }),
    },
    mainContainer: {
        flex: 100,
        flexDirection: 'row',
    },
    noIconContainer: {
        alignItems: 'center',
        flex: 5,
        justifyContent: 'center',
    },
    subtitle: {
        color: colors.white,
        fontFamily: fonts.SFProTextSemibold,
        fontSize: 13,
        fontWeight: '600',
    },
    success: {
        backgroundColor: colors.limeGreen,
    },
    timesContainer: {
        alignItems: 'flex-end',
        flex: 8,
        paddingRight: 16,
        paddingTop: 16,
    },
    title: {
        color: colors.white,
        fontFamily: fonts.SFProTextBold,
        fontSize: 17,
        fontWeight: '700',
    },
});
