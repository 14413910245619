import { ofType } from 'redux-observable';
import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    exhaustMap,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import AppAlertService from '@Alert/services/AppAlertService';
import Languages from '@i18n/index';
import { Error } from '@Interfaces/BaseResponse';
import { loadingDialogHide } from '@LoadingDialog/state/loadingDialogActions';
import LoggerService from '@Logger/services/LoggerService';
import {
    getApplicationsAPI,
    getDocumentAPI,
} from '@Profile/api/applicationsApi';
import {
    Loan,
    SaveApplications,
    SelectableApplication,
    STATUS_LOAN,
} from '@Profile/interfaces/index';
import ApplicationsService from '@Profile/services/applicationsService';
import DocumentService from '@Profile/services/documentService';
import {
    saveApplications,
    setApplicationSelected,
    setSelectableApplications,
} from '@Profile/state/applicationsAction';
import ApplicationsActionTypes from '@Profile/state/applicationsActionTypes';
import { parseDate } from '@Utils/FormatUtils';
import { formatAmount } from '@Utils/index';

export const applicationsEpic = ($action: Observable<any>, $state: any) => $action.pipe(
    ofType(ApplicationsActionTypes.GET_APPLICATIONS_REQUEST),
    mergeMap(() => from(new Promise((resolve) => {
        const { value } = $state;
        const { applicationsReducer } = value;
        resolve(applicationsReducer.loans);
    })).pipe(
        map((response: any) => {
            const data = [];
            const dataAPI = response as [];
            if (dataAPI) {
                const newDate: any = dataAPI.map((loan: Loan) => ({
                    ...loan,
                    contractDate: parseDate(loan.contractDate, 'MM/dd/yyyy'),
                    fccpExpirationDate: parseDate(loan.fccpExpirationDate, 'MM/dd/yyyy'),
                    interestRate: formatAmount(loan.interestRate.toFixed(2) ?? '0.00'),
                    figures: {
                        ...loan.figures,
                        amountFinanced: formatAmount(loan.figures.amountFinanced.units.toFixed(2) ?? '0.00'),
                        paymentAmount: formatAmount(loan.figures.paymentAmount.units.toFixed(2) ?? '0.00'),
                        currentBalance: formatAmount(loan.figures.currentBalance.units.toFixed(2) ?? '0.00'),
                    },
                }));
                const loansPast: any = newDate.filter((loan: Loan) => loan.status.name === STATUS_LOAN.PAID_IN_FULL);
                const loansActive: any = newDate.filter((loan: Loan) => loan.status.name === STATUS_LOAN.FUNDED);
                data.push({
                    header: false,
                    data: loansActive,
                });

                data.push({
                    header: true,
                    data: loansPast,
                });
            }

            ApplicationsService.onSuccess({
                data,
                success: true,
            });
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        })
    ))
);

export const downloadApplicationsMergeMap = ($state: any) => switchMap(() => from(getApplicationsAPI())
    .pipe(
        mergeMap((response: Loan[]) => from(
            new Promise<SaveApplications>(async (resolve, reject) => {
                try {
                    const mappedResponse = addApplicationPermissions(response);
                    const saveApplications: SaveApplications = setLoanSelected(mappedResponse, $state);

                    const { loans, loanSelected } = saveApplications;
                    const selectableApplications: SelectableApplication[] = buildSelectableApplications(loans, loanSelected);

                    saveApplications.selectableApplications = selectableApplications;

                    resolve(saveApplications);
                } catch (error) {
                    reject(error);
                }
            }),
        )),
    ),
);

export const downloadApplicationsEpic = ($action: Observable<any>, $state: any) => $action.pipe(
    ofType(ApplicationsActionTypes.DOWNLOAD_APPLICATIONS_REQUEST),
    exhaustMap(() => from(getApplicationsAPI())
        .pipe(
            mergeMap((response: Loan[]) => from(new Promise<SaveApplications>(async (resolve, reject) => {
                try {
                    const mappedResponse = addApplicationPermissions(response);
                    const saveApplications: SaveApplications = setLoanSelected(mappedResponse, $state);

                    const { loans, loanSelected } = saveApplications;
                    const selectableApplications: SelectableApplication[] = buildSelectableApplications(loans, loanSelected);

                    saveApplications.selectableApplications = selectableApplications;
                    resolve(saveApplications);
                } catch (error) {
                    reject(error);
                }
            })).pipe(
                mergeMap((response: SaveApplications) => [
                    saveApplications(response),
                    loadingDialogHide(),
                ]),
                catchError((error: Error) => {
                    LoggerService.logError(error);
                    return of(loadingDialogHide());
                }),
            )),
        ).pipe(
            catchError((error: Error) => {
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        ),
    ),
);

export const addApplicationPermissions = (loans: Loan[]): Loan[] => {
    const newLoans: Loan[] = loans.map((loan: Loan) => {
        loan.permissions = {
            canAddMethodOfPayment: loan.status.name === STATUS_LOAN.FUNDED,
            canDeleteMethodOfPayment: loan.status.name === STATUS_LOAN.FUNDED,
            canEditMethodOfPayment: loan.status.name === STATUS_LOAN.FUNDED,
            canMakeAPayment: loan.status.name === STATUS_LOAN.FUNDED,
            canSetAutoPay: loan.status.name === STATUS_LOAN.FUNDED,
            canSetPrimaryMethod: loan.status.name === STATUS_LOAN.FUNDED,
        };
        return loan;
    });
    return newLoans;
};

export const saveLoanSelectedEpic = ($action: Observable<any>, $state: any) => $action.pipe(
    ofType(ApplicationsActionTypes.SAVE_APPLICATION_SELECTED),
    switchMap(({ payload }) => from(new Promise(async (resolve) => {
        const { value } = $state;
        const { applicationsReducer } = value;
        const { loans } = applicationsReducer;
        const selectableApplications: SelectableApplication[] = buildSelectableApplications(loans, payload);

        resolve({
            selectableApplications,
            loanSelected: payload,
        });
    })).pipe(
        mergeMap((response: any) => [
            setSelectableApplications(response.selectableApplications),
            setApplicationSelected(response.loanSelected),
        ])
    )),
);

export const getDocumentEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ApplicationsActionTypes.GET_DOCUMENT),
    switchMap(({ payload }: any) => from(getDocumentAPI({
        ...payload,
        responseType: 'blob',
    })).pipe(
        mergeMap((response: Blob) => from(new Promise<any>((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onloadend = () => {
                    const dataResult = reader.result as string;
                    const data = dataResult.split(',')[1];
                    resolve(data);
                };
                reader.onerror = () => reject();
            } catch (error) {
                reject({
                    title: 'File reader error',
                    code: 666,
                } as Error);
            }
        })).pipe(
            map((response: string) => {
                DocumentService.onSuccess(response);
                return loadingDialogHide();
            }),
            catchError((error: Error) => {
                AppAlertService.showError({
                    message: error.title || Languages.InvalidContract,
                });
                LoggerService.logError(error);
                return of(loadingDialogHide());
            }),
        )),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title || Languages.InvalidContract,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    ))
);

export const getDocumentArrayBufferEpic = ($action: Observable<any>) => $action.pipe(
    ofType(ApplicationsActionTypes.GET_DOCUMENT_ARRAY_BUFFER),
    switchMap(({ payload }: any) => from(getDocumentAPI({
        ...payload,
        responseType: 'arraybuffer',
    })).pipe(
        map((response: string) => {
            DocumentService.onSuccess(response);
            return loadingDialogHide();
        }),
        catchError((error: Error) => {
            AppAlertService.showError({
                message: error.title || Languages.InvalidContract,
            });
            LoggerService.logError(error);
            return of(loadingDialogHide());
        }),
    )),
);

const setLoanSelected = (loans: Loan[], $state: any): SaveApplications => {
    const { value } = $state;
    const { applicationsReducer } = value;
    const loanSelected: Loan | undefined = applicationsReducer.loanSelected;

    const saveApplications: SaveApplications = {
        loans,
        loanSelected,
    };
    const currentLoanSelected = loans.find((loan) => loan.loanId === loanSelected?.loanId);
    if (!currentLoanSelected) {
        let currentLoans = loans.filter((loan: Loan) => loan.status.name !== STATUS_LOAN.PAID_IN_FULL);
        currentLoans = currentLoans.sort((a: Loan, b: Loan) =>
            new Date(b.contractDate).valueOf() - new Date(a.contractDate).valueOf()
        );
        saveApplications.loanSelected = currentLoans.length > 0 ? currentLoans[0] : loans[0];
    }

    return saveApplications;
};

const buildSelectableApplications = (loans: Loan[], loanSelected?: Loan): SelectableApplication[] => {
    let selectableApplications: SelectableApplication[] = [];

    if (loans) {
        let currentLoans = loans.filter((loan: Loan) => loan.status.name !== STATUS_LOAN.PAID_IN_FULL);
        let paidInFullLoans = loans.filter((loan: Loan) => loan.status.name === STATUS_LOAN.PAID_IN_FULL);

        /** Sorting */
        currentLoans = currentLoans.sort((a: Loan, b: Loan) =>
            new Date(b.contractDate).valueOf() - new Date(a.contractDate).valueOf()
        );
        paidInFullLoans = paidInFullLoans.sort((a: Loan, b: Loan) =>
            new Date(b.contractDate).valueOf() - new Date(a.contractDate).valueOf()
        );

        /** Merging */
        const fullLoans = [...currentLoans, ...paidInFullLoans];

        /** Mapping */
        selectableApplications = fullLoans.map((loan) => ({
            ...loan,
            isSelected: loan.applicationId === loanSelected?.applicationId,
        }));
    }

    return selectableApplications;
};
