import { createAction } from 'typesafe-actions';

import {
    AuthenticateRefreshTokenRequest,
    AuthenticateRequest,
    AuthenticateResponse,
    AuthenticateInternalRequest,
} from '@Auth/interfaces';
import AuthenticateActionsTypes from '@Auth/state/authenticateActionTypes';
import { Error } from '@Interfaces/BaseResponse';

export const authenticateAction = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_REQUEST,
    (request: AuthenticateRequest) => (request)
)();

export const authenticateError = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_ERROR,
    (error: Error) => (error)
)();

export const authenticateLogoutAction = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_LOGOUT,
)();

export const authenticateLogoutSuccess = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_LOGOUT_SUCCESS,
)();

export const authenticateRefreshTokenAction = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_REFRESH_TOKEN,
    (request: AuthenticateRefreshTokenRequest) => (request)
)();

export const authenticateSuccess = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_SUCCESS,
    (response: AuthenticateResponse) => (response)
)();

export const authenticateInternalAction = createAction(
    AuthenticateActionsTypes.AUTHENTICATE_INTERNAL_REQUEST,
    (request: AuthenticateInternalRequest) => (request),
)();
