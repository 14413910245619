import {
    StyleSheet,
} from 'react-native';

import {
    colors,
    fonts,
    globalStyle,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    mainScrollView: {

    },
    activitiesContainer: {
        display: 'flex',
        paddingHorizontal: 10,
        flexDirection: 'column',
    },
    activityCard: {
        ...globalStyle.dropShadow,
        display: 'flex',
        backgroundColor: colors.white,
        borderRadius: 8,
    },
    activityCardTitle: {
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 8,
        paddingVertical: 12,
        borderBottomColor: colors.grayLigthDark,
        borderBottomWidth: 1,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    activityCardTitleLink: {
        color: colors.green
    },
    activityCardTitleLinkText: {
        color: colors.greenSolid,
        fontFamily: fonts.SFProDisplayMedium,
        width: 'auto',
        fontSize: 16
    },
    activityCardTitleText: {
        color: colors.grayDarkColor,
        fontFamily: fonts.SFProDisplayMedium,
        fontSize: 18
    },
    activityCardBody: {
        display: 'flex',
        backgroundColor: colors.white,
        borderRadius: 8,
        overflow: 'hidden',
    },

    activityItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderTopColor: colors.grayLigthDark,
        borderTopWidth: 1,
        padding: 8,
        flex: 1,
    },
    activityItemLeft: {
        display: 'flex',
        width: '50%'
    },
    activityItemRight: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activityItemTitle: {
        color: colors.grayColor,
        fontFamily: fonts.SFProDisplaySemibold,
        fontSize: 16
    },
    activityItemMetaData: {
        color: colors.darkGray,
        fontFamily: fonts.SFProDisplay,
        fontSize: 14,
        marginTop: 3
    },
    activityAmount: {
        fontFamily: fonts.SFProTextSemibold,
        color: colors.grayDarkColor,
        fontSize: 16,
    },
    activityItemAmount: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%'
    },
    emptyStateContainer: {
        paddingVertical: 10,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemList: {
        borderRadius: 8,
        height: 'auto',
        maxHeight: 600,
        flexGrow: 0
    },
    activityPaymentItemStatus: {
        backgroundColor: 'orange',
        paddingVertical: 3,
        paddingHorizontal: 12,
        borderRadius: 16
    },
    activityPaymentItemStatusText: {
        fontSize: 14
    },

    activityGrid: {
        display: 'flex',
        flexDirection: 'column',
    },
    activityGridRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colors.veryPaleBlue,
        paddingVertical: 16,
        paddingHorizontal: 8,
    },
    activityGridRowAlt: {
        backgroundColor: colors.white,
    },
    activityGridRowButtons: {
        
    },
    activityGridText: {
        color: colors.grayColor,
        fontSize: 14
    },
    activityReturnColor: {
        color: colors.softRed,
    },
    buttonCancelText: {
        color: colors.softRed,
    },
    buttonCancel: {
        backgroundColor: 'transparent',
        width: '49%',
        height: 35,
        borderColor: colors.softRed,
        borderWidth: 1,
        // flex: 50,
        // height: 55,
    },
    buttonEdit: {
        backgroundColor: theme.colors.primary,
        width: '49%',
        height: 35,
    },
    buttonEditText: {
        color: colors.white
    },
    buttonStatement: {
        backgroundColor: theme.colors.primary,
        // width: '49%',
        height: 30,
        marginHorizontal: 5,
        paddingHorizontal: 5,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
    },
    goBackBtn: {
        backgroundColor: 'transparent',
        marginTop: 20,
    },
    goBackBtnTitle: {
        color: theme.colors.primary,
    },
    gridEmptyRow: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: 10,
        paddingHorizontal: 8,
        backgroundColor: colors.white,
        borderRadius: 8
    },
    gridEmptyRowText: {
        fontSize: 13,
        fontFamily: fonts.SFProDisplayMedium,
        color: colors.grayColor
    },
    isTodayPaidoffText: {
        color: 'white',
        fontFamily: fonts.SFProDisplay,
        fontSize: 13,
        fontWeight: '600',
    },
    isTodayPaidoffMessage: {
        backgroundColor: '#717171',
        padding: 16,
        width: '95%',
        borderRadius: 8,
        marginTop: 0
    },
    allContainer: {
        paddingVertical: 20,
        display: 'flex',
        alignItems: 'center',
    },
    buttonIcon: {
        marginHorizontal: 5,
        backgroundColor: 'transparent',
        borderRadius: 4,
        padding: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonIconText: {
        color: theme.colors.primary,
        marginLeft: 4,
        fontFamily: fonts.SFProDisplay
    },
    containerModal: {
        width: '90%',
        height: 450,
        alignItems: 'center',
    },
    containerModalButton: {
        backgroundColor: colors.softRed,
        marginTop: 16,
        width: '100%',
    },
    containerModalButtonText: {
        color: colors.white,
        fontSize: 17,
        lineHeight: 22,
        fontWeight: '600',
        fontFamily: fonts.SFProText,
    },
    containerModalPdf: {
        width: '100%',
        height: 450,
        borderRadius: 10,
        backgroundColor: colors.white,
        ...globalStyle.dropShadow,
    },
});
