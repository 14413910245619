import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    addDays,
    addMinutes,
    format,
    parse,
} from 'date-fns';
import {
    Platform,
    StyleSheet,
    Text,
    View,
} from 'react-native';
import {
    Calendar,
    LocaleConfig,
} from 'react-native-calendars';

import {
    ArrowRightIcon,
    ReturnSvg,
} from '@Assets/index';
import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';
import {
    isTimeToMakeAPayment,
    isTimeToMakeAPaymentByDate,
} from '@Utils/index';

LocaleConfig.locales.us = {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'July', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan.', 'Feb.', 'Mar', 'Apr', 'May', 'Jun', 'Jul.', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    today: 'Today',
};
LocaleConfig.defaultLocale = 'us';

export interface PressedDay {
    formatDate: string;
    date: Date;
}

interface Props {
    daySelected: string;
    disabled?: boolean;
    limitDayHour?: number;
    limitDayMinutes?: number;
    maxDate?: Date;
    minDate?: Date;
    onPressDay: (pressed: PressedDay) => void;
    selectToday?: boolean;
}

export const AppCalendar = ({
    daySelected,
    disabled,
    limitDayHour = 0,
    limitDayMinutes = 0,
    maxDate,
    minDate = new Date(),
    onPressDay = () => { },
    selectToday,
}: Props) => {
    const currentDateRef = useRef(new Date()).current;
    const [markedDays, setMarkedDays] = useState<any>();

    const [dateSelected, setDateSelected] = useState(daySelected);
    const [minCalendarDate, setMinCalendarDate] = useState(minDate);
    const [isSelectedToday, setIsSelectedToday] = useState(selectToday);

    useEffect(() => {
        if ((limitDayHour !== 0 || limitDayMinutes !== 0) && !disabled) {
            validateTime();
            const hoursInterval = setInterval(() => validateTime(), 5000);

            return () => {
                clearInterval(hoursInterval);
            };
        }
    }, []);

    useEffect(() => {
        if (isSelectedToday) {
            const formatedDay = format(new Date(), 'yyyy-MM-dd');
            const newMarkedDays = {
                [`${formatedDay}`]: {
                    selected: true,
                    customStyles: {
                        container: {
                            ...styles.selectedDay,
                        },
                    },
                },
            };
            setMarkedDays(newMarkedDays);
        } else if (dateSelected) {
            const newMarkedDays = {
                [`${dateSelected}`]: {
                    selected: true,
                    customStyles: {
                        container: {
                            ...styles.selectedDay,
                        },
                    },
                },
            };
            setMarkedDays(newMarkedDays);
        }
    }, [isSelectedToday]);

    const handleOnPressDay = (dateString: string) => {
        const newMarkedDays = {
            [`${dateString}`]: {
                selected: true,
                customStyles: {
                    container: {
                        ...styles.selectedDay,
                    },
                },
            },
        };
        setMarkedDays(newMarkedDays);
        const paseDate = parse(dateString, 'yyyy-MM-dd', new Date());
        if (isTimeToMakeAPaymentByDate(limitDayHour, limitDayMinutes, paseDate)) {
            onPressDay({
                formatDate: dateString,
                date: paseDate,
            });
        }
    };

    const handleRenderArrow = (direction: string) => {
        if (direction === 'left') {
            return <ReturnSvg fill={theme.colors.primary} />;
        }
        return <ArrowRightIcon fill={theme.colors.primary} />;
    };

    const handleRenderHeader = (date: Date) => {
        const gmtDate = new Date(date.toUTCString().slice(0, -4));
        const formatDate = format(
            addMinutes(gmtDate, gmtDate.getTimezoneOffset()),
            'MMMM yyyy',
        );
        return (
            <Text style={styles.header}>
                {formatDate}
            </Text>
        );
    };

    const validateTime = () => {
        const minDate = new Date();
        if (!isTimeToMakeAPayment(limitDayHour, limitDayMinutes) && !disabled) {
            minDate.setDate(addDays(minDate, 1).getDate());
            const dateString = format(minDate, 'yyyy-MM-dd');
            setIsSelectedToday(false);
            setDateSelected(dateString);
            setMinCalendarDate(minDate);
        }
    };

    return (
        <View style={styles.calendarContainer}>
            <Calendar
                current={currentDateRef}
                hideExtraDays
                markedDates={markedDays}
                minDate={minCalendarDate}
                maxDate={maxDate}
                markingType={'custom'}
                onDayPress={(day) => handleOnPressDay(day.dateString)}
                renderArrow={(direction) => handleRenderArrow(direction)}
                renderHeader={handleRenderHeader}
                disableMonthChange
                theme={calendarTheme.baseTheme}
                disabledByDefault={disabled}
                disableArrowLeft={disabled}
                disableArrowRight={disabled}
            />
        </View >
    );
};

const styles = StyleSheet.create({
    calendarContainer: {
        backgroundColor: colors.white,
        borderRadius: 13,
        marginHorizontal: 16,
        paddingHorizontal: 16,
        paddingBottom: 10,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,
        elevation: 9,
    },
    header: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 20,
        fontWeight: '600',
        lineHeight: 24,
    },
    selectedDay: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const calendarTheme = {
    baseTheme: {
        textSectionTitleColor: colors.veryDarkGrayishBlue30,
        selectedDayBackgroundColor: theme.colors.primary,
        selectedDayTextColor: colors.white,
        todayTextColor: theme.colors.primary,
        dayTextColor: theme.colors.primary,
        textDayFontFamily: fonts.SFProDisplay,
        textDayHeaderFontFamily: fonts.SFProTextSemibold,
        textDayHeaderFontWeight: '600',
        textDayHeaderFontSize: 13,
        textDisabledColor: colors.veryDarkGrayishBlue30,
        ...Platform.select({
            web: {
                textDayFontWeight: '400',
                textDayFontSize: 15,
            },
            default: {
                textDayFontWeight: '600',
                textDayFontSize: 20,
            },
        }),
    },
};
