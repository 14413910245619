import {
    ActionType,
    createReducer,
} from 'typesafe-actions';

import {
    GetListMenuActionSuccess,
    InitialStateMenu,
} from '@Home/interfaces';
import * as menuActions from '@Home/state/menuActions';

type ActionsType = ActionType<typeof menuActions>;

const initialState: InitialStateMenu = {
    pages: [],
};

export const menuReducer = createReducer<InitialStateMenu, ActionsType>(
    initialState,
).handleAction(
    menuActions.getListMenuActionSuccess,
    (state: InitialStateMenu, { payload }: GetListMenuActionSuccess) => ({
        ...state,
        pages: payload,
    })
).handleAction(
    menuActions.clearMenuListAction,
    () => (initialState),
).handleAction(
    menuActions.navigateLeftMenu,
    (state: InitialStateMenu, { payload }: any) => {
        state.pages.find((item: any) => {
            if (item.selected) {
                item.selected = false;
            };
        });
        const page = state.pages.find((page) => page.screen === payload);

        if (page) {
            state.pages[page?.index].selected = true;
        }

        return ({
            ...state,
            pages: [
                ...state.pages,
            ],
        });
    }
);
