import { ArrowDisplayOpen } from "@Assets/index";
import { theme } from "@Theme/appTheme";
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

interface Props {
    title: string;
    children: JSX.Element;
    isExpanded: boolean;
    handleOnPress: () => void;
};

export const AppAccordion = ({
    title,
    children,
    isExpanded,
    handleOnPress
}: Props) => {
    const [expanded, setExpanded] = useState<boolean>(isExpanded);

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded])

    return (
        <View style={{ width: '100%' }}>
            <TouchableOpacity
                style={styles.header}
                onPress={handleOnPress}
            >
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>
                        {title}
                    </Text>
                    <ArrowDisplayOpen
                        fill={theme.colors.primary}
                        style={!expanded && styles.rotateIcon}
                    />
                </View>
            </TouchableOpacity>
            {expanded && (
                <View>
                    {children}
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        textAlign: 'center'
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        fontSize: 15,
        fontWeight: '500',
        color: theme.colors.primary,
        marginRight: 5
    },
    rotateIcon: {
        transform: [{ rotate: '180deg' }]
    }
});