import { StyleSheet } from 'react-native';

import {
    colors,
    fonts,
    theme,
} from '@Theme/appTheme';

export const styles = StyleSheet.create({
    container: {
        flex: 100,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-evenly',
        paddingHorizontal: 16,
    },
    containerButtons: {
        flexDirection: 'row',
        paddingBottom: 24,
        paddingLeft: 510,
    },
    containerBody: {
        flex: 94,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 30,
    },
    containerHeader: {
        alignItems: 'center',
    },
    containerItem: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingVertical: 30,
        flexDirection: 'row',
        backgroundColor: 'red',
    },
    containerLeft: {
        backgroundColor: 'white',
        borderRadius: 10,
        flex: 32,
        flexDirection: 'column',
        height: 213,
        marginBottom: 24,
        marginRight: 24,
        paddingHorizontal: 8,
        minWidth: 290,
    },
    containerRight: {
        flex: 68,
        flexDirection: 'column',
        minWidth: 290,
        marginRight: 24,
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 600,
        paddingHorizontal: 24,
    },
    list: {
        flex: 1,
        width: 200,
    },
    logOut: {
        paddingLeft: 11,
        fontSize: 15,
        fontFamily: fonts.SFProText,
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 18,
        letterSpacing: 0,
        textAlign: 'left',
        color: colors.grayColor,
        paddingTop: 3,
    },
    logOutIconContainer: {
        marginTop: 24,
        flexDirection: 'row',
    },
    profileIconContainer: {
        marginTop: 21,
    },
    profileInput: {
        width: 365,
        height: 45,
        marginBottom: 16,
        marginRight: 16,
        paddingHorizontal: 24,
    },
    email: {
        color: colors.grayDarkColor,
        fontFamily: fonts.Roboto,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 19,
        marginTop: 8,
    },
    name: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplaySemibold,
        fontSize: 24,
        fontStyle: 'normal',
        lineHeight: 29,
        marginTop: 13,
    },
    text: {
        color: colors.grayLigth,
        fontSize: 17,
        paddingTop: 5,
    },
    titlePayment: {
        color: theme.colors.primary,
        fontFamily: fonts.SFProDisplay,
        fontSize: 31,
        fontWeight: '500',
    },
    shimmerIcon: {
        height: 60,
        width: 60,
        borderRadius: 27.5,
        marginTop: 5,
    },
    shimmerText: {
        height: 20,
        width: '90%',
        marginTop: 5,
    },
    shimmerTextEmail: {
        height: 20,
        width: '70%',
        marginTop: 5,
    },
    shimmerIconLogOut: {
        height: 25,
        width: 25,
        borderRadius: 27.5,
        marginLeft: 68,
        marginTop: 5,
    },
    shimmerTextLogOut: {
        height: 20,
        width: '40%',
        marginTop: 5,
    },
    viewTextTitle: {
        alignItems: 'center',
        flex: 5,
        flexDirection: 'row',
        marginTop: 25,
    },
});
