import React from 'react';

import {
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import { KebabMenuIcon } from '@Assets/index';
import { AppSwipeable } from '@Components/AppSwipeable';
import { Icon } from '@Components/Icon';
import {
    useAppDispatch,
    useAppSelector,
} from '@Hooks/appHooks';
import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import { setMethodOfPaymentSelected } from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import { styles } from '@MethodsOfPayment/styles/MethodsOfPaymentStyle';

interface Props {
    data: any;
    handleDelete: (item: PaymentMethodResponseBase) => void;
    handleEdit: (item: PaymentMethodResponseBase) => void;
    handleAutoPay: (item: PaymentMethodResponseBase) => void;
    canSetPrimaryMethod?: boolean;
    canDeletePrimaryMethod?: boolean;
    canEditPrimaryMethod?: boolean;
    hasAutoPayOn?: boolean
}

export const PaymentMethodCard = ({
    data,
    handleEdit,
    handleDelete,
    handleAutoPay,
    canSetPrimaryMethod,
    canDeletePrimaryMethod,
    canEditPrimaryMethod,
    hasAutoPayOn,
}: Props) => {
    const dispatch = useAppDispatch();
    const payment = useAppSelector(({ methodsOfPaymentDictReducer }) => methodsOfPaymentDictReducer[data.item]);

    if (payment === undefined || payment === null) {
        return null;
    }

    const handleSwipe = async (isSwipeOpen: boolean) => {
        dispatch(setMethodOfPaymentSelected({
            id: payment.id,
            value: isSwipeOpen,
        }));
    };

    /**
     * Enable Dragg action with this line inside, onLongPress={data.drag},
     * inside TouchableOpacity
     */
    return (
        <>
            <TouchableOpacity
                style={[
                    styles.containerItem,
                    (!hasAutoPayOn || data.index > 0) && styles.containerItemMarginBottom,
                    data.isActive && styles.containerItemSelected,
                ]}
                disabled={true}
                onLongPress={data.drag}
                accessibilityLabel={'paymentMethodCardTouchableDrag'}
                testID={'paymentMethodCardTouchableDrag'}
            >
                <AppSwipeable
                    accessibilityLabel={'paymentMethodCardAppSwipeableAction'}
                    disableDeleteAction={!canDeletePrimaryMethod}
                    disableEditAction={!canEditPrimaryMethod}
                    hideDelete={payment.isPrimary}
                    isSwipe={payment.selected}
                    item={payment}
                    onClickDelete={handleDelete}
                    onClickEdit={handleEdit}
                    onClickAutoPay={handleAutoPay}
                    onSwipe={handleSwipe}
                    testID={'paymentMethodCardAppSwipeableAction'}
                >
                    <View style={[
                        styles.contentSwipe,
                        data.isActive && styles.contentSwipeSelected,
                    ]}>
                        <View style={styles.containerSwipe} >
                            <View style={styles.containerNumberLogoPayment}>
                                <Icon name={payment.paymentNetwork} />
                                <Text style={styles.lastFourDigit}>{`* ${payment.lastFourDigit}`}</Text>
                            </View>
                            <View style={styles.containerNikenameNote}>
                                <Text style={styles.nickname}>{payment.nickname}</Text>
                                <Text style={styles.note}>{payment.note}</Text>
                            </View>
                            <View
                                accessibilityLabel={'paymentMethodCardTouchableDots'}
                                nativeID={'paymentMethodCardTouchableDots'}
                                style={styles.containerMenuSwipe}
                                testID={'paymentMethodCardTouchableDots'}
                            >
                                <View style={{ display: !payment.selected ? 'flex' : 'none' }}>
                                </View>
                            </View>
                        </View>
                    </View>
                </AppSwipeable>
            </TouchableOpacity>
            {
                (hasAutoPayOn && data.index === 0 && !data.isActive) && <View style={styles.separator} />
            }
        </>
    );
};
