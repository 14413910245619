import React, { useEffect, useRef, useState } from 'react';
import {
    FlatList,
    ListRenderItemInfo,
    Pressable,
    SafeAreaView,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native';
import {
    CurrentPaymentService,
    MakeAPaymentOption,
    MakeAPaymentOptionType,
    PaymentSchedule,
} from '@MakeAPayment/interfaces/index';
import { useAppDispatch, useAppSelector, } from '@Hooks/appHooks';
import { PaymentOptionsScreen } from '@MakeAPayment/screens/PaymentOptionsScreen';
import MakeAPaymentService from '@MakeAPayment/services/MakeAPaymentService';
import { getCurrentPaymentAction, saveAPaymentScheduleRequestAction } from '@MakeAPayment/state/MakeAPaymentActions';
import { styles } from '@MakeAPayment/styles/MakeAPaymentStyles';
import { RootStackParamList } from '@Navigation/BottomTabNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { PaymentMethodResponseBase } from '@MethodsOfPayment/interfaces';
import MethodsOfPaymentService from '@MethodsOfPayment/services/MethodsOfPaymentService';
import { getPaymentMethodsAction } from '@MethodsOfPayment/state/MethodsOfPaymentActions';
import RefreshMethodsOfPaymentService from '@MethodsOfPayment/services/RefreshMethodsOfPaymentService';
import Languages from '@i18n/index';
import { format } from 'date-fns';
import { CalendarPayment, CheckCircleSvg } from '@Assets/index';
import { ScrollView } from 'react-native-gesture-handler';
import { Icon } from '@Components/Icon';
import { AppButton } from '@Components/AppButton';
import LoggerService from '@Logger/services/LoggerService';
import { CongratulationsModal } from '@MakeAPayment/components/CongratulationsModal';
import SaveAPaymentService from '@MakeAPayment/services/SaveAPaymentService';
import { getPaymentPendings } from '@Activity/state/activityActions';
import PaymentPendingService from '@Activity/services/PaymentPendingService';
import { CalendarModal } from '@MakeAPayment/components/CalendarModal';
import { AppTextInput } from '@Components/AppTextInput';
import { toPST } from '@Utils/FormatUtils';
import { theme } from '@Theme/appTheme';
import { PressedDay } from '@Components/AppCalendar';
import { ConfirmSubmitPaymentModal } from '@MakeAPayment/components/ConfirmSubmitPaymentModal';
import PaymentDefinitionsModalService from '@PaymentDefinitionsModal/services/PaymentDefinitionsModalService';
import { PaymentDefinitionsModal } from '@PaymentDefinitionsModal/screens/PaymentDefinitionsModal';

interface Props extends StackScreenProps<RootStackParamList, 'MakeAPaymentScreen'> { }

export const MakeAPaymentScreen = ({ navigation }: Props) => {
    const dispatch = useAppDispatch();
    const [payment, setPayment] = useState<CurrentPaymentService>();
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [paymentAmountLabel, setPaymentAmountLabel] = useState('');
    const [isTodayPayoff, setIsTodayPayoff] = useState<boolean>(false);
    const [exitsPendingPayment, setExitsPendingPayment] = useState<boolean>(false);
    const [showCongratulationsModal, setShowCongratulationsModal] = useState<boolean>(false);
    const [showConfirmSubmitPaymentModal, setShowConfirmSubmitPaymentModal] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodResponseBase>();
    const paymentMethodsRef = useRef<PaymentMethodResponseBase[]>([]);
    const [paymentDate, setPaymentDate] = useState<Date>(new Date());
    const [paymentDateLabel, setPaymentDateLabel] = useState('');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isSelectedToday, setIsSelectedToday] = useState(true);
    const { loanSelected } = useAppSelector(
        ({ applicationsReducer }) => applicationsReducer,
    );
    const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 3));

    useEffect(() => {
        if (loanSelected?.loanId) {
            const {
                loanId,
            } = loanSelected;

            dispatch(getPaymentMethodsAction(loanId));
        }

        const methodsSubs = MethodsOfPaymentService.subscribe((state: any) => {
            if (state.length || state.success) {
                const paymentMethods: PaymentMethodResponseBase[] = state.map((x: PaymentMethodResponseBase) => x)
                    .sort((a: PaymentMethodResponseBase, b: PaymentMethodResponseBase) => a.isPrimary ? -1 : 1);
                paymentMethodsRef.current = paymentMethods;
            }
        });

        const refreshPayments = RefreshMethodsOfPaymentService.subscribe(() => {
            if (loanSelected) {
                dispatch(getPaymentMethodsAction(loanSelected?.loanId));
            }
        });

        return () => {
            methodsSubs.unsubscribe();
            refreshPayments.unsubscribe();
        };
    }, []);

    useEffect(() => {
        dispatch(getCurrentPaymentAction({ loanId: loanSelected?.loanId }));

        if (loanSelected) {
            dispatch(getPaymentPendings({ loanId: loanSelected?.loanId }));
        }

        const getAPaymentSubscribe = MakeAPaymentService.subscribe((state: CurrentPaymentService) => {
            setPayment(state);
        });

        const saveAPaymentService = SaveAPaymentService.subscribe(() => {
            setShowCongratulationsModal(true);
        });

        const paymentPendingService = PaymentPendingService.subscribe((pendingPayments: any) => {
            setExitsPendingPayment(pendingPayments?.length > 0);
        });

        return () => {
            getAPaymentSubscribe?.unsubscribe();
            saveAPaymentService?.unsubscribe();
            paymentPendingService?.unsubscribe();
        };
    }, [loanSelected]);

    const handleOnTapContinue = (
        amount: number,
        optionSelected: MakeAPaymentOption,
    ) => {
        setPaymentAmount(amount);
        const pAmountLabel = (amount / 100).toString();
        setPaymentAmountLabel(pAmountLabel);
        
        if (isTodayPayoff && optionSelected?.id !== MakeAPaymentOptionType.TODAYS_PAYOFF) setPaymentDateLabel('');
        
        setIsTodayPayoff(optionSelected?.id === MakeAPaymentOptionType.TODAYS_PAYOFF);
        if (optionSelected?.id === MakeAPaymentOptionType.TODAYS_PAYOFF) { 
            setIsSelectedToday(true)
            setPaymentDate(new Date());
            setPaymentDateLabel(format(new Date(), "MMM dd, yyyy"));
        }
    };

    const showDatepicker = () => {
        setShowDatePicker(true);
    };

    const handleSubmitSaveAPaymentSchedule = () => {
        if (paymentMethod) {
            const object: PaymentSchedule = {
                paymentAmount: paymentAmount,
                runDate: format(paymentDate, "yyyy-MM-dd"),
                paymentMethodId: paymentMethod.id,
                paymentMethodType: paymentMethod.type,
                isTodayPayoff: isTodayPayoff
            };
            saveAPaymentSchedule(object);
        }
    };

    const shouldDisableMakeAPaymentButton = () => {
        return paymentAmount == 0 || paymentMethod?.id == undefined || paymentDateLabel == '';
    }

    const handleAccept = () => {
        setShowCongratulationsModal(false);
        navigation.reset({
            index: 0,
            routes: [
                {
                    name: 'Dashboard',
                },
            ],
        });
    }

    const saveAPaymentSchedule = (values: PaymentSchedule) => {
        if (loanSelected?.loanId) {
            dispatch(saveAPaymentScheduleRequestAction({
                ...values,
                loanId: loanSelected?.loanId,
            }));
            LoggerService.logEvent('ScheduledPayment', {
                ...values,
                loanId: loanSelected?.loanId,
            });
        };
    };

    const handleOnPressDay = (date?: PressedDay) => {
        if (!isTodayPayoff) {
            if (date) {
                setPaymentDate(date.date);
                setPaymentDateLabel(format(date.date, "MMM dd, yyyy"));
            }
            setIsSelectedToday(false);
        }
        setShowDatePicker(false);
    }

    const datePickerInput = () => {
        return (
            <SafeAreaView>
                <TouchableOpacity style={styles.dateContainerInputStyle}
                    onPress={showDatepicker}>
                    <AppTextInput
                        isFocused={true}
                        enabled={true}
                    >
                        <View style={styles.viewPaymenDateTextInput}>
                            <TextInput
                                accessibilityLabel={'addPaymenDateTextInput'}
                                editable={false}
                                placeholder={Languages.PaymentDate}
                                onPressIn={showDatepicker}
                                style={styles.paymenDateTextInput}
                                testID={'addPaymenDateTextInput'}
                                value={paymentDateLabel}
                            />

                            <CalendarPayment onPress={showDatepicker} />

                            {showDatePicker && (
                                <CalendarModal
                                    maxDate={maxDate}
                                    daySelected={format(paymentDate, "yyyy-MM-dd")}
                                    limitDayHour={isTodayPayoff ? 0 : 13}
                                    limitDayMinutes={isTodayPayoff ? 0 : 30}
                                    disabled={isTodayPayoff}
                                    onPressDay={(date) => handleOnPressDay(date)}
                                    show={showDatePicker}
                                    selectToday={isSelectedToday}
                                    handleAccept={handleOnPressDay}
                                />
                            )}
                        </View>
                    </AppTextInput>
                </TouchableOpacity>
            </SafeAreaView >
        );
    };

    const renderItem = (data: ListRenderItemInfo<PaymentMethodResponseBase>) => {
        const isSelected = paymentMethod?.id == data.item.id;
        const iconName = data?.item?.paymentNetwork == 'Discover' ? 'DiscoverImage' : data?.item?.paymentNetwork;
        return (
            <TouchableOpacity
                style={[styles.containerItem, isSelected && styles.outlineContainerItem]}
                disabled={false}
                accessibilityLabel={`paymentMethodCardTouchableDrag${data.item.id}`}
                testID={`paymentMethodCardTouchableDrag${data.item.id}`}
                onPress={() => setPaymentMethod(data.item)}
            >
                <View style={[styles.paymentMethodItem, styles.containerNumberLogoPayment]}>
                    <Icon name={iconName} />
                </View>
                <View style={[styles.paymentMethodItem, styles.containerNikenameNote]}>
                    <Text style={styles.nickname}>{data?.item?.nickname}</Text>
                </View>
                <View style={[styles.paymentMethodItem, styles.containerFourDigits]}>
                    <Text style={styles.lastFourDigit}>{`* ${data?.item?.lastFourDigit}`}</Text>
                    <Text style={styles.expirationDateLabel}>{data?.item?.expirationDate}</Text>
                </View>
                <View style={[styles.containerSelectedPaymentMethod]}>
                    {
                        isSelected ? <CheckCircleSvg fill={theme.colors.primary} style={styles.selectedPaymentCheckMark} /> : null
                    }
                </View>
            </TouchableOpacity>
        );
    };

    const isAfterOneThirtyPMPST = (): Boolean => {
        const pstDate = toPST(new Date());
        return (pstDate.getHours() >= 13 && pstDate.getMinutes() >= 30) || pstDate.getHours() > 13;
    }

    const renderPaymentMethods = () => (
        paymentMethodsRef.current != null && (
            <SafeAreaView style={styles.containerListPayment}>
                <View style={styles.container}>
                    <Text style={styles.titlePaymentMethod}>{Languages.SelectPaymentMethod}</Text>
                </View >
                <FlatList
                    data={paymentMethodsRef.current}
                    scrollEnabled={false}
                    extraData={paymentMethodsRef.current}
                    renderItem={data => renderItem(data)}
                    keyExtractor={(item: any, index: number) => item.id + index.toString()}
                    contentContainerStyle={styles.contentContainerListPayment}
                />
            </SafeAreaView>
        )
    );

    const renderCurrentMonthlyPayment = () => (
        <View style={styles.viewCurrentOpen}>
            <Text style={styles.text}>{Languages.CurrentMonthlyPayment}</Text>
            <Text style={styles.amount}>{`$${payment?.currentMonthlyPayment ?? '0.00'}`}</Text>
            <Text style={styles.textInformation}>{Languages.NextDueDate}: {payment?.dueDate}</Text>
        </View>
    );

    const renderSelectPaymentDate = () => (
        <View style={styles.dateContainer}>
            <Text style={styles.titlePayment}>{`${Languages.SelectPaymentDate}`}</Text>
            {datePickerInput()}
            {isAfterOneThirtyPMPST() ?
                <Text style={styles.dateLabel}>
                    {`${Languages.SelectPaymentDateAfterOnePmPSTStaticMessage}`}
                </Text> :
                <Text style={styles.dateLabel}>
                    {`${Languages.SelectPaymentDateStaticMessage}`}
                </Text>
            }
            {exitsPendingPayment && (
                <Text style={styles.pendingPaymentLabel}>
                    {`${Languages.SelectPaymentDatePendingStaticMessage}`}
                </Text>
            )}
        </View>
    );

    const handleOnTapInfoPaymentOptions = () => {
        requestAnimationFrame(() => {
            PaymentDefinitionsModalService.show();
        });
    }

    return (
        <ScrollView>
            <View style={styles.container}>
                {renderCurrentMonthlyPayment()}
                {payment && (
                    <PaymentOptionsScreen
                        onTapCancel={() => { }}
                        onTapContinue={handleOnTapContinue}
                        onTapInfoPaymentOptions={handleOnTapInfoPaymentOptions}
                        payment={payment}
                    />
                )}
                {renderSelectPaymentDate()}
                {renderPaymentMethods()}
                <View style={styles.makeAPaymentContainer}>
                    <AppButton
                        accessibilityLabel={'makeAPaymentButtonSubmit'}
                        buttonStyle={styles.buttonSubmit}
                        disabled={shouldDisableMakeAPaymentButton()}
                        testID={'makeAPaymentButtonSubmit'}
                        title={Languages.SubmitPayment}
                        handleOnChange={() => setShowConfirmSubmitPaymentModal(true)} />
                </View>
                <PaymentDefinitionsModal />
                <CongratulationsModal
                    show={showCongratulationsModal}
                    handleAccept={handleAccept}
                />
                <ConfirmSubmitPaymentModal
                    show={showConfirmSubmitPaymentModal}
                    paymentAmount={paymentAmountLabel}
                    paymentDate={paymentDateLabel}
                    handleAccept={() => {
                        handleSubmitSaveAPaymentSchedule();
                        setShowConfirmSubmitPaymentModal(false);
                    }}
                    handleCancel={() => setShowConfirmSubmitPaymentModal(false)}
                />
            </View>
        </ScrollView>
    );
};